<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>Nieuwe template</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="addTemplate()">
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.name.length}">
                                <label>{{"Technische naam"}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.name" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.description.length}">
                                <label>{{"Omschrijving"}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.description" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.nl.length}" >
                                <label>{{"Nederlandse text"}} *</label>
                                <textarea style="height:200px" ref="name" class="form-control" v-model="fields.nl" required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.en.length}">
                                <label>{{"Engelse text"}} *</label>
                                <textarea style="height:200px"  type="text" ref="name" class="form-control" v-model="fields.en" required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="industriesDropdownToggle = !industriesDropdownToggle" :class="{active: industriesDropdownToggle}">
                            <span v-if="fields.industry" class="selected">{{fields.industry.display_name}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="industriesDropdownToggle" :class="{search: industries.length > 9}">
                            <ul>
                                <li v-on:click="deselectIndustry()"><br></li>
                                <li v-for="industry in industries" v-on:click="selectIndustry(industry)" :key="industry.id_industry">
                                    {{industry.display_name}}
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <button type="submit" class="button button--cta button--icon"><i class="fa-solid fa-plus"></i> Template toevoegen</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            industriesDropdownToggle: false,
            managers: [],
            industries: [],
            fields: {
                name: '',
                description: '',
                nl: '',
                en: '',
                industry: {},
            },
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.loadIndustries();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        loadIndustries() {
            let self = this;
            fetch('/api/v1/industry?')
            .then(response => { response.json().then(
                function(data) {
                    self.industries = data.data;
                });
            });
        },
        getIndustryName(id){
            const industry = this.industries.find(i => i.id_industry === id);
            return industry ? industry.display_name : '';
        },
        selectIndustry(industry) {
            this.industriesDropdownToggle = false;
            this.fields.industry = industry;
        },
        deselectIndustry() {
            this.industriesDropdownToggle = false;
            this.fields.industry = {};
        },
        addTemplate() {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][name]', this.fields.name);
            data.append('data[0][description]', this.fields.description);
            data.append('data[0][nl]', this.fields.nl);
            data.append('data[0][en]', this.fields.en);
            data.append('data[0][industry_constant]', this.fields.industry.constant_name);
            fetch('/api/v1/text_language', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>