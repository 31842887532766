<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>Nieuw nieuwsbericht</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="addNews()">
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.title.length}">
                                <label>{{"Titel"}} </label>
                                <input type="text" ref="name" class="form-control" v-model="fields.title" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field"  :class="{focus:fields.body.length}">
                                <label>{{"Tekst"}}</label>
                                <textarea type="text" ref="name" style="height: 300px;" class="form-control" v-model="fields.body"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="typeOptionsDropdown = !typeOptionsDropdown" :class="{active: typeOptionsDropdown}">
                            <span v-if="fields.type" class="selected">{{getTypeOptionName(fields.type)}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="typeOptionsDropdown">
                            <ul>
                                <li v-for="(option, index) in typeOptions" v-on:click="selectTypeOption(index)" :key="index">
                                    {{option.label}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="roleOptionsDropdown = !roleOptionsDropdown" :class="{active: roleOptionsDropdown}">
                            <span v-if="fields.for_role" class="selected">{{getRoleOptionName(fields.for_role)}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="roleOptionsDropdown">
                            <ul>
                                <li v-for="(option, index) in roleoptions" v-on:click="selectRoleOption(index)" :key="index">
                                    {{option.label}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button type="submit" class="button button--cta button--icon"><i class="fa-solid fa-plus"></i> Nieuwsbericht toevoegen</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            industriesDropdownToggle: false,
            managers: [],
            industries: [],
            typeOptionsDropdown: false,
            roleOptionsDropdown: false,
            fields: {
                title: '',
                body: '',
                type: 'RELEASE',
                for_role: 'ALL',
            },
            typeOptions: [
                { label: 'Release', category: 'RELEASE' },
                { label: 'Warning', category: 'WARNING' },
            ],
            roleoptions: [
                { label: 'Alle', category: 'ALL' },
                { label: 'Beheerder', category: 'BUILDINGMANAGER' },
                { label: 'Specialist', category: 'MECHANIC' },
                { label: 'Leverancier', category: 'REPAIRCOMPANY' },
            ],
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        loadIndustries() {
            let self = this;
            fetch('/api/v1/industry?')
            .then(response => { response.json().then(
                function(data) {
                    self.industries = data.data;
                });
            });
        },
        getIndustryName(id){
            const industry = this.industries.find(i => i.id_industry === id);
            return industry ? industry.display_name : '';
        },
        selectIndustry(industry) {
            this.industriesDropdownToggle = false;
            this.fields.industry = industry;
        },
        deselectIndustry() {
            this.industriesDropdownToggle = false;
            this.fields.industry = {};
        },
        selectTypeOption(index) {
            this.fields.type = this.typeOptions[index].category;
            this.typeOptionsDropdown = false;
        },
        getTypeOptionName(category) {
            const selectedOption = this.typeOptions.find(option => option.category === category);
            return selectedOption ? selectedOption.label : '';
        },
        selectRoleOption(index) {
            this.fields.for_role = this.roleoptions[index].category;
            this.roleOptionsDropdown = false;
        },
        getRoleOptionName(category) {
            const selectedOption = this.roleoptions.find(option => option.category === category);
            return selectedOption ? selectedOption.label : '';
        },
        addNews() {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][title]', this.fields.title);
            data.append('data[0][sub_title]', '');
            data.append('data[0][body]', this.fields.body);
            data.append('data[0][type]', this.fields.type);
            data.append('data[0][for_role]', this.fields.for_role);
            fetch('/api/v1/newsmessage', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>