<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-list"></i>{{profile.actionable.create_recordinglist}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createRecording()">
                    <div class="form-field" :class="{focus:fields.name.length}">
                        <label>{{profile.assets.assetmodal.name}} *</label>
                        <input type="text" data-testid="inputRecordingListName" class="form-control" v-model="fields.name" ref="name" required>
                    </div>
                    <button type="submit" data-testid="newRecordingListSubmit" class="button button--icon"><i class="fas fa-list"></i>{{profile.actionable.create_recordinglist}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            fields: {
                name: ''
            }
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        createRecording() {
            const data = new URLSearchParams();
            data.append('name', this.fields.name);
            if (this.fields.name.length) {
                let self = this;
                fetch('/api/v1/actionablelist/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Opnamelijst aanmaken',
                    });
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.closeModal();
                });
            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
