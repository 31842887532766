<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>Branch aanpassen</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="addBranch()">
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.display_name.length}">
                                <label>{{"Display naam"}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.display_name" value="this.industry_display_name" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.constant_name.length}">
                                <label>{{"Canonieke naam"}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.constant_name" required>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="assetTypeDropdownToggle = !assetTypeDropdownToggle" :class="{active: assetTypeDropdownToggle}">
                            <span v-if="!selectedAssetType.id_asset_type">Kies een standaard objecttype</span>
                            <span v-if="selectedAssetType.id_asset_type" class="selected">{{selectedAssetType.display_name}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="assetTypeDropdownToggle" :class="{search: assetTypes.length > 9}">
                            <ul>
                                <li v-for="asset_type in assetTypes" v-on:click="selectAssetType(asset_type)" :key="asset_type.id_asset_type">
                                    {{asset_type.display_name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <button type="submit" class="button button--cta button--icon"><i class="fa-solid fa-plus"></i> Pas branch aan</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['industry_display_name', 'industry_constant_name', 'industry_default_id_asset_type', 'industryID'],
    data() {
        return {
            profile: {},
            assetTypeDropdownToggle: false,
            assetTypes: [],
            selectedAssetType: {},
            fields: {
                display_name: '',
                constant_name: ''
            }
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getAssetTypes();
        this.fields.display_name = this.industry_display_name;
        this.fields.constant_name = this.industry_constant_name;
        // this.selectedAssetType = this.industry.default_id_asset_type;
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getAssetTypes() {
            let self = this;
            fetch('/api/v1/asset_type?')
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                });
            });
        },
        selectAssetType(asset_type) {
            this.assetTypeDropdownToggle = false;
            this.selectedAssetType = asset_type;
        },
        addBranch() {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.industryID + '][display_name]', this.fields.display_name);
            data.append('data[' + this.industryID + '][constant_name]', this.fields.constant_name);
            data.append('data[' + this.industryID + '][default_id_asset_type]', this.selectedAssetType.id_asset_type);
            fetch('/api/v1/industry', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>