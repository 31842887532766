<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="far fa-trash-alt"></i>{{profile.actionable.delete_actionable}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.actionable.delete_actionable_sure}} <b>{{selectedActionable.name}}</b> {{profile.actionable.delete_actionable_sure_2}}<br /><br /></p>
                <button class="button button--icon button--right" data-testid="removeActionSubmit" @click="removeActionable()"><i class="far fa-trash-alt"></i>{{profile.actionable.delete_actionable}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    props: ['selectedActionable'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        removeActionable() {
            const data = new URLSearchParams();
            data.append('id_actionable', this.selectedActionable.id_actionable);
            let self = this;
            fetch('/api/v1/actionable/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.incident_detail.select_this_repaircompany);
                self.$emit('remove');
                document.querySelector('body').classList.remove('modal-open');
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
