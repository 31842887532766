<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>{{profile.assets.add_object}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createAsset()">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-field" :class="{focus:fields.name.length}">
                                <label>{{profile.assets.assettable.name}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.name" required />
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="dropdown-section" style="margin-bottom: 10px;">
                                <span class="dropdown" :class="{active: assetTypeDropdownToggled}" v-on:click="assetTypeDropdownToggled = !assetTypeDropdownToggled">
                                    <span v-if="!selectedAssetType.id_asset_type" class="placeholder">{{profile.assets.select_objecttype}}</span>
                                    <span v-if="selectedAssetType.id_asset_type" class="selected">{{selectedAssetType.display_name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="assetTypeDropdownToggled" :class="{search: assetTypes.length > 9}">
                                    <input type="text" @input="e => assetTypeSearchTerm = e.target.value" :value="assetTypeSearchTerm" :placeholder="profile.assets.select_objecttype" @keyup="searchAssetTypes()" v-if="assetTypes.length > 9" />
                                    <ul>
                                        <li v-on:click="deselectAssetType()" v-if="!assetTypeNoResult && !tempAssetTypes.length">{{profile.assets.none}}</li>
                                        <li v-for="assetType in assetTypes" v-on:click="selectAssetType(assetType)" :key="assetType.id" v-show="!tempAssetTypes.length && !assetTypeNoResult">{{assetType.display_name}}</li>
                                        <li v-for="(assetType, index) in tempAssetTypes" v-on:click="selectAssetType(assetType)" :key="index" v-show="tempAssetTypes.length">{{assetType.display_name}}</li>
                                        <li v-if="assetTypeNoResult" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <button type="submit" class="button button--icon"><i class="fa-solid fa-plus"></i>{{profile.assets.add_object}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            fields: {
                name: ''
            },
            assetTypes: [],
            tempAssetTypes: [],
            assetTypeNoResult: false,
            assetTypeDropdownToggled: false,
            assetTypeSearchTerm: '',
            selectedAssetType: {},
            nameError: false,
            assetTypeFields: {
                extra_description_a: '',
                extra_description_b: '',
                extra_description_c: '',
                extra_description_d: '',
                extra_description_e: '',
                extra_description_f: '',
                extra_description_g: '',
                extra_description_h: '',
                extra_description_i: '',
                extra_description_j: ''
            }
        }
    },
    props: ['placeInfo'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getCurrentBm();
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getCurrentBm() {
            let self = this;
            fetch('/api/v1/buildingmanagerstats')
            .then(response => { response.json().then(
                function(data) {
                    let id_bm = data.building_manager.id_building_manager;
                    self.getAssetTypes(id_bm);
                });
            });
        },
        getAssetTypes(id_bm) {
            let self = this;
            fetch('/api/v1/asset_type/bybuildingmanager?id_building_manager=' + id_bm)
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    self.assetTypes = sortBy(self.assetTypes, 'display_name');
                });
            });
        },
        createAsset() {
            if (this.fields.name.length) {
                let self = this;
                const data = new URLSearchParams();
                let qrcodeParam = 'data[0][qr_code]';
                let idPlaceParam = 'data[0][id_place]';
                let holderParam = 'data[0][holder]';
                let shortNameParam = 'data[0][short_name]';
                let nameParam = 'data[0][name]';
                let assetTypeParam = 'data[0][id_asset_type]';
                let assetTypeFieldAParam = 'data[0][extra_description_a]';
                let assetTypeFieldBParam = 'data[0][extra_description_b]';
                let assetTypeFieldCParam = 'data[0][extra_description_c]';
                let assetTypeFieldDParam = 'data[0][extra_description_d]';
                let assetTypeFieldEParam = 'data[0][extra_description_e]';
                let assetTypeFieldFParam = 'data[0][extra_description_f]';
                let assetTypeFieldGParam = 'data[0][extra_description_g]';
                let assetTypeFieldHParam = 'data[0][extra_description_h]';
                let assetTypeFieldIParam = 'data[0][extra_description_i]';
                let assetTypeFieldJParam = 'data[0][extra_description_j]';
                data.append('action', 'create');
                data.append(qrcodeParam, '');
                data.append(idPlaceParam, self.placeInfo.id_place);
                data.append(holderParam, true);
                data.append(shortNameParam, '');
                data.append(nameParam, self.fields.name);
                if (self.selectedAssetType.id_asset_type) {
                    data.append(assetTypeParam, self.selectedAssetType.id_asset_type);
                }
                if (self.assetTypeFields.extra_description_a) {
                    data.append(assetTypeFieldAParam, self.assetTypeFields.extra_description_a);
                }
                if (self.assetTypeFields.extra_description_b) {
                    data.append(assetTypeFieldBParam, self.assetTypeFields.extra_description_b);
                }
                if (self.assetTypeFields.extra_description_c) {
                    data.append(assetTypeFieldCParam, self.assetTypeFields.extra_description_c);
                }
                if (self.assetTypeFields.extra_description_d) {
                    data.append(assetTypeFieldDParam, self.assetTypeFields.extra_description_d);
                }
                if (self.assetTypeFields.extra_description_e) {
                    data.append(assetTypeFieldEParam, self.assetTypeFields.extra_description_e);
                }
                if (self.assetTypeFields.extra_description_f) {
                    data.append(assetTypeFieldFParam, self.assetTypeFields.extra_description_f);
                }
                if (self.assetTypeFields.extra_description_g) {
                    data.append(assetTypeFieldGParam, self.assetTypeFields.extra_description_g);
                }
                if (self.assetTypeFields.extra_description_h) {
                    data.append(assetTypeFieldHParam, self.assetTypeFields.extra_description_h);
                }
                if (self.assetTypeFields.extra_description_i) {
                    data.append(assetTypeFieldIParam, self.assetTypeFields.extra_description_i);
                }
                if (self.assetTypeFields.extra_description_j) {
                    data.append(assetTypeFieldJParam, self.assetTypeFields.extra_description_j);
                }
                fetch('/api/v1/asset', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function() {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('success');
                });
            }
            else {
                this.nameError = true;
            }
        },
        searchAssetTypes() {
            this.tempAssetTypes = [];
            if (this.assetTypeSearchTerm.length > 1) {
                let searchTerm = this.assetTypeSearchTerm.toLowerCase();
                for (var i = 0; i < this.assetTypes.length; i++) {
                    let assetType = this.assetTypes[i];
                    let assetName = assetType.display_name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssetTypes.push(assetType);
                    }
                    if (!this.tempAssetTypes.length) {
                        this.assetTypeNoResult = true;
                    }
                    else {
                        this.assetTypeNoResult = false;
                    }
                }
            }
            else {
                this.assetTypeNoResult = false;
            }
        },
        selectAssetType(assetType) {
            this.assetTypeDropdownToggled = false;
            this.selectedAssetType = assetType;
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
            if (assetType.extra_description_a_default) {
                this.assetTypeFields.extra_description_a = assetType.extra_description_a_default;
            }
            if (assetType.extra_description_b_default) {
                this.assetTypeFields.extra_description_b = assetType.extra_description_b_default;
            }
            if (assetType.extra_description_c_default) {
                this.assetTypeFields.extra_description_c = assetType.extra_description_c_default;
            }
            if (assetType.extra_description_d_default) {
                this.assetTypeFields.extra_description_d = assetType.extra_description_d_default;
            }
            if (assetType.extra_description_e_default) {
                this.assetTypeFields.extra_description_e = assetType.extra_description_e_default;
            }
            if (assetType.extra_description_f_default) {
                this.assetTypeFields.extra_description_f = assetType.extra_description_f_default;
            }
            if (assetType.extra_description_g_default) {
                this.assetTypeFields.extra_description_g = assetType.extra_description_g_default;
            }
            if (assetType.extra_description_h_default) {
                this.assetTypeFields.extra_description_h = assetType.extra_description_h_default;
            }
            if (assetType.extra_description_i_default) {
                this.assetTypeFields.extra_description_i = assetType.extra_description_i_default;
            }
            if (assetType.extra_description_j_default) {
                this.assetTypeFields.extra_description_j = assetType.extra_description_j_default;
            }
        },
        deselectAssetType() {
            this.assetTypeDropdownToggled = false;
            this.selectedAssetType = {};
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
        }
    }
}
</script>
