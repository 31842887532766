<template>
    <div>
        <div class="block block--location_detail">
            <section class="incident-head">
                <h1>Gerelateerde beheerders</h1>
            </section>
            <div style="height: 300px; padding-right: 10px; margin-top: 10px;">
                <div class="list-item partner-list-item" v-for="(manager, index) in relatedBuildingmanagers" :key="index" style="border: 1px solid #dbdbdb; margin-bottom: 10px;">
                    <b style="float: left; margin-left: 20px;" @click="selectManager(manager)">{{ manager.name }} </b>
                    <span class="edit-button icon" @click="removeRelatedBMModalOpen = true; BmID=manager.id_building_manager_building_manager; managerToRemove=manager" style="position: absolute; top: 5px;
                            bottom: 0; margin: auto; height: 35px;"><i class="far fa-trash-alt"></i></span>
                </div>
                <div class="incident-contents" style="position:relative;">
                    <div class="incident-info">
                        <div class="row">
                            <div class="col-sm-3">
                                <div class="dropdown-section" style="margin-bottom: 20px;">
                                    <select class="form-select" v-model="managerToAdd">
                                        <option value="" disabled selected>Kies een beheerder</option>
                                        <option v-for="(manager, index) in buildingmanagers" :value="manager.id_building_manager" :key="index">{{manager.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <button class="button button--icon" v-on:click="addRelatedPartner()"><i class="fa-solid fa-plus"></i>Beheerder toevoegen</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RemoveRelatedPartnerModal v-if="removeRelatedBMModalOpen" @close="removeRelatedBMModalOpen = false; getRelatedBuildingmanagers()" :managerID="BmID" :manager="managerToRemove" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import RemoveRelatedPartnerModal from '@/views/s1mone/modals/RemoveRelatedPartnerModal.vue';

export default {
    mixins: [setLocale],
    props: ['managerID'],
    components: {
        RemoveRelatedPartnerModal
    },
    data() {
        return {
            relatedBuildingmanagers: [],
            buildingmanagers: [],
            removeRelatedBMModalOpen: false,
            BmID: '',
            managerToRemove: [],
            managerToAdd: '',
        }
    },
    created() {
        this.getRelatedBuildingmanagers();
        this.getBuildingmanagers();
    },
    methods: {
        getRelatedBuildingmanagers() {
            let self = this;
            fetch('/api/v1/buildingmanagerbuildingmanager/byidbuildingmanager?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.relatedBuildingmanagers = data.data;
                });
            });
        },
        getBuildingmanagers() {
            let self = this;
            fetch('/api/v1/buildingmanagerbuildingmanager/not_with_building_manager?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.buildingmanagers = data;
                });
            });
        },
        addRelatedPartner() {
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][id_building_manager_a]', this.managerID);
            data.append('data[0][id_building_manager_b]', this.managerToAdd);
            let self = this;
            fetch('/api/v1/buildingmanagerbuildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.getRelatedBuildingmanagers();
                if(self.managerToAdd != "") {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                }
                self.managertoAdd = "";
            });
        },
        selectManager(manager) {
            // TODO: probleem wanneer er meerdere beheerders met dezelfde naam zijn
            let managerid = '';
            for (let i = 0; i < this.buildingmanagers.length; i++) {
                if (this.buildingmanagers[i].name == manager.name) {
                    managerid = this.buildingmanagers[i].id_building_manager;
                }
            }
            this.$router.push('/s1mone/buildingmanager-detail?id=' + managerid);
        }
    }
}

</script>