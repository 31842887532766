<template>
    <div class="modal-wrapper" v-if="profile.assets">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-regular fa-file-pdf"></i>{{profile.invoicing.order_history}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div v-if="!loading">
                    <table class="table table-striped" v-if="orders.length">
                        <thead>
                            <tr>
                                <th scope="col" @click="sortTable('date')">{{profile.s1mone.stickertable.created}} <i class="fas fa-sort"></i></th>
                                <th scope="col" @click="sortTable('description')">{{profile.invoicing.order_sheet}} <i class="fas fa-sort"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in orderList" :key="index" data-testid="orderFormLine" @click="openOrder(item.file_url)">
                                <td style="padding-left: 20px; color: #0867b3; text-decoration: underline;">{{item.date}}</td>
                                <td style="color: #0867b3; text-decoration: underline; overflow: hidden;">{{item.file_url}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            loading: false,
            sortOrder: 'asc',
            orderList: []
        }
    },
    props: ['orders'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        this.orderList = this.orders;
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.orderList = sortBy(this.orderList, sorter);
            if (this.sortOrder === 'desc') {
                this.orderList.reverse();
            }
        },
        openOrder(url) {
            window.open(url, '_blank');
        }
    }
}
</script>
