<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Branches</h1>
                                <span class="edit-button icon" @click="newIndustryModalOpen = true"><i class="fa-solid fa-plus"></i></span>
                            </section>
                            <div class="row">
                                <div class="col-sm-8">
                                    <section class="search search-alt" v-if="industries.length">
                                        <form @submit.prevent="searchSuggest()">
                                            <input type="text" ref="search" v-bind:placeholder="'Zoek een branch op naam'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                        </form>
                                    </section>
                                </div>
                            </div>
                            <div>
                                <h4 class="result-counter" v-if="!searchActive">{{industries.length}} {{'branches'}}</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="!searchSuggestions.length && searchActive">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                            </div>
                            <div class="table-wrap" style="height: 600px; overflow: auto;">
                                <table class="table table-striped" v-if="industries.length && !searchActive">
                                    <colgroup>
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 5%;" />
                                        <col style="width: 5%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('display_name')" :class="{active: industrySearchType === 'display_name'}">Display naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('constant_name')" :class="{active: industrySearchType === 'constant_name'}">Canonieke naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('')" :class="{active: industrySearchType === ''}">Standaard objecttype <i class="fas fa-sort"></i></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(industry, index) in industriesShown" :key="index">
                                            <td>{{industry.display_name}}</td>
                                            <td>{{industry.constant_name}}</td>
                                            <td></td>
                                            <td><i class="fas fa-pen" @click="editIndustryModalOpen = true; editIndustry=industry"></i></td>
                                            <td><i class="far fa-trash-alt" @click="removeIndustryModalOpen = true; removeIndustryID=industry.id_industry"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-striped" v-if="industries.length && searchActive">
                                    <colgroup>
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 5%;" />
                                        <col style="width: 5%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('display_name')" :class="{active: industrySearchType === 'display_name'}">Display naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('constant_name')" :class="{active: industrySearchType === 'constant_name'}">Canonieke naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('')" :class="{active: industrySearchType === ''}">Standaard objecttype <i class="fas fa-sort"></i></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(industry, index) in searchSuggestions" :key="index">
                                            <td>{{industry.display_name}}</td>
                                            <td>{{industry.constant_name}}</td>
                                            <td></td>
                                            <td><i class="fas fa-pen" @click="editIndustryModalOpen = true; editIndustry=industry"></i></td>
                                            <td><i class="far fa-trash-alt" @click="removeIndustryModalOpen = true; removeIndustryID=industry.id_industry"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <NewIndustryModal v-if="newIndustryModalOpen" @close="loadIndustries()" />
        <RemoveIndustryModal v-if="removeIndustryModalOpen" @close="loadIndustries()" :industryID="removeIndustryID" />
        <EditIndustryModal v-if="editIndustryModalOpen" @close="loadIndustries()" :industry_display_name="editIndustry.display_name" :industry_constant_name="editIndustry.constant_name" :industryID="editIndustry.id_industry"/>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy'
import NewIndustryModal from "@/views/s1mone/modals/NewIndustryModal.vue";
import RemoveIndustryModal from "@/views/s1mone/modals/RemoveIndustryModal.vue";
import EditIndustryModal from '../modals/EditIndustryModal.vue';

export default {
    mixins: [setLocale],
    components: {
        NewIndustryModal,
        RemoveIndustryModal,
        EditIndustryModal
    },
    data() {
        return {
            searchTerm: '',
            searchSuggestions: [],
            industries: [],
            industriesShown: [],
            searchActive: false,
            industrySearchType: '',
            removeIndustryID: '',
            newIndustryModalOpen: false,
            removeIndustryModalOpen: false,
            editIndustryModalOpen: false,
            editIndustry: {},
        }
    },
    created() {
        document.title = 'S1MONE | Industries';
        this.loadIndustries();
    },
    methods: {
        loadIndustries() {
            let self = this;
            this.newIndustryModalOpen = false;
            this.removeIndustryModalOpen = false;
            this.editIndustryModalOpen = false;
            fetch('/api/v1/industry?')
            .then(response => { response.json().then(
                function(data) {
                    self.industries = data.data;
                    self.industriesShown = data.data;
                });
            });
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.industriesShown.length; i++) {
                    let industrie = this.industriesShown[i];
                    let name = industrie.display_name.toLowerCase();
                    if (name.includes(searchTerm)) {
                        this.searchSuggestions.push(industrie);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        sortTable(sorter) {
            this.industrySearchType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.industriesShown = sortBy(this.industriesShown, sorter);
            if (this.sortOrder === 'desc') {
                this.industriesShown.reverse();
            }
        }
        // selectasset(id) {
        //     this.$router.push('/s1mone/buildingmanager-detail?id=' + id);
        // }
    }
}
</script>
