<template>
    <div>
        <div class="main-content" v-if="profile.places">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block">
                    <div class="profile-card location" style="border-right: 0; width: 300px;">
                        <div class="row">
                            <div class="col col-sm-3">
                                <a :href="mapsUrl" target="blank" class="detail-map">
                                    <img :src="mapsApiUrl" id="address-map" />
                                </a>
                            </div>
                            <div class="col-xs-12 col-sm-9">
                                <h4>{{profile.dashboard.building_manager.message_table.place}}</h4>
                                <h2>{{placeInfo.name}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <div class="block block--location_detail">
                            <section class="incident-head">
                                <h1 data-testid="locationEditTitle">{{profile.accountPage.account.general}}</h1>
                            </section>
                            <section class="location-contents">
                                <div class="location-info">
                                    <div>
                                        <br />
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.assets.assettable.location_name}}</label>
                                                <p data-testid="locationNameText" style="white-space: normal;">{{placeInfo.name}}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.incidents.incidenttable.address}}</label>
                                                <p data-testid="locationAddressText">{{placeInfo.address}} {{placeInfo.house_number}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.incident_detail.floor_name}}</label>
                                                <p data-testid="locationFloorText" v-if="placeInfo.floor_name === 'NVT'">{{profile.places.n_a}}</p>
                                                <p data-testid="locationFloorText" v-if="placeInfo.floor_name === '0'">{{profile.places.b_g}}</p>
                                                <p data-testid="locationFloorText" v-if="placeInfo.floor_name !== '0' && placeInfo.floor_name !== 'NVT'">{{placeInfo.floor_name}}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.places.placetable.zipcode}}</label>
                                                <p data-testid="locationZipText">{{placeInfo.zipcode}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.places.placetable.city}}</label>
                                                <p data-testid="locationCityText">{{placeInfo.city}}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.places.placetable.country}}</label>
                                                <p data-testid="locationCountryText">{{placeInfo.country}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.incidents.new_incident.description}}</label>
                                                <p data-testid="locationDescriptionText">{{placeInfo.description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div class="col-md-5" v-if="allAssets.length">
                        <div class="block block--assets-list">
                            <div v-for="(asset, index) in allAssets" :key="index" @click="selectAssetToEdit(asset.id_asset)" class="asset-collection">
                                <div class="asset-item" :class="{assettype:asset.asset_type_name}">
                                    <label class="asset-type" v-if="asset.asset_type_name" v-tooltip.right="'Object typenaam'"><i class="fas fa-cube"></i> {{asset.asset_type_name}}</label>
                                    <h4>{{asset.name}}</h4>
                                    <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                    <span class="inactive-label" v-if="asset.hidden && asset.qr_code">{{profile.assets.inactive}}</span>
                                    <span class="indication-label" v-if="asset.qr_code">{{asset.id_asset}}</span>
                                    <div class="properties">
                                        <span class="property inactive" v-if="!asset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.no_sticker}}</span>
                                        <span class="property" v-if="asset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.sticker}}</span>
                                        <span class="property" v-if="asset.qr_code"><i class="fas fa-umbrella"></i> {{profile.assets.main_object}}</span>
                                    </div>
                                </div>
                                <div v-for="(subasset, index) in asset.subAssets" class="asset-item asset-item--sub" :key="index" @click="selectAssetToEdit(subasset.id_asset)">
                                    <h4>{{subasset.name}}<span v-if="subasset.qr_code"> | <i v-if="subasset.hidden">{{profile.assets.assettable.active}}</i><b v-if="!subasset.active">{{profile.assets.inactive}}</b></span><span class="indication-label" v-if="subasset.qr_code">{{asset.id_asset}}</span></h4>
                                    <div class="properties">
                                        <span class="property" v-if="subasset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.sticker}}</span>
                                        <span class="property" v-if="asset.qr_code"><i class="fas fa-umbrella"></i> {{profile.assets.main_object}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            placeInfo: [],
            placeId: '',
            mapsApiUrl: '',
            mapsUrl: '',
            allAssets: []
        }
    },
    computed: mapState(['mapsKey']),
    created() {
        this.getPlaceInfo();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getPlaceInfo() {
            this.allAssets = [];
            let queryString = window.location.search;
            this.placeId = queryString.split('=')[1];
            let self = this;
            fetch('/api/v1/place/byid?id_place=' + this.placeId)
            .then(response => { response.json().then(
                function(data) {
                    self.placeInfo = data;
                    self.placeInfo.zipcode = self.placeInfo.zipcode.substring(0, self.placeInfo.zipcode.length - 2) + ' ' + self.placeInfo.zipcode.substring(self.placeInfo.zipcode.length - 2);
                    if (self.placeInfo.description === "null") {
                        self.placeInfo.description = '';
                    }
                    let gmapsAddress    = self.placeInfo.address + "+" + self.placeInfo.house_number + "+" + self.placeInfo.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=150x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    document.title = 'S1MONE | ' + self.placeInfo.name;
                    self.getAssets(data.id_place);
                });
            });
        },
        getAssets(id_place) {
            let self = this;
            fetch('/api/v1/asset/filtered?id_place=' + id_place + '&id_owner=' + this.placeInfo.id_owner)
            .then(response => { response.json().then(
                function(data) {
                    let assets = data.data;
                    for (var i = 0; i < assets.length; i++) {
                        let asset = assets[i];
                        if (!asset.partof_id_asset) {
                            self.allAssets.push(asset);
                        }
                        if (asset.holder) {
                            asset.subAssets = [];
                        }
                    }
                    // Subassets arent currently used
                    for (var j = 0; j < self.allAssets.length; j++) {
                        let mainAsset = self.allAssets[j];
                        for (var k = 0; k < assets.length; k++) {
                            let asset = assets[k];
                            if (asset.partof_id_asset) {
                                if (asset.partof_id_asset === mainAsset.id_asset) {
                                    mainAsset.subAssets.push(asset);
                                }
                            }
                            if (!asset.qr_code) {
                                self.allAssetsQrCode = false;
                            }
                        }
                    }
                    self.allAssets = sortBy(self.allAssets, 'qr_code');
                });
            });
        },
        selectAssetToEdit(id_asset) {
            this.$router.push('/owner/asset-detail?asset=' + id_asset);
        }
    }
}
</script>
