<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="far fa-hand-point-right"></i>{{profile.incident_detail.pass_incident}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="passIncident()">
                    <div v-if="!success">
                        <p>{{profile.incident_detail.pass_incident_text}}</p>
                        <br />
                        <div class="row">
                            <div class="col-sm-6">
                                <select class="form-select" v-model="fields.bm">
                                    <option :value="null" disabled>{{profile.pleasechoose}}</option>
                                    <option v-for="(bm, index) in buildingmanagers" :key="index" :value="bm.id_building_manager">{{bm.name}}</option>
                                </select>
                            </div>
                        </div>
                        <button type="submit" class="button button--cta button--icon" data-testid="passIncidentSumbit"><i class="far fa-hand-point-right"></i>{{profile.incident_detail.pass_incident}}</button>
                    </div>
                    <div class="notification notification--success" v-if="success">{{this.profile.incident_detail.pass_confirmation}}</div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'passIncidentModal',
    data() {
        return {
            profile: {},
            bmDropdownToggled: false,
            success: false,
            fields: {
                bm: null
            },
        }
    },
    props: ['incidentId', 'buildingmanagers'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        passIncident() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('to_id_building_manager', this.fields.bm);
            let self = this;
            fetch('/api/v1/buildingmanagerbuildingmanager/assignincident', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Incident toekennen aan andere beheerder',
                });
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.success = true;
                setTimeout(function () {
                    self.$router.push('/buildingmanager');
                }.bind(self), 3000);
            });
        }
    }
}
</script>
