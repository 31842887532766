<template>
    <div class="block block--account block--border" :class="{empty: !accountInfo.invoice_email || !accountInfo.invoice_address, edit: invoiceEdit}">
        <div class="account-info">
            <h4>{{profile.invoicing.invoicing}} {{profile.invoicing.and_order_sheet}} <i class="fas fa-info-circle" v-tooltip.right="profile.invoicing.invoicing_tooltip" style="cursor: pointer;"></i></h4>
            <span class="edit-button icon" @click="invoiceEdit = true" v-if="!invoiceEdit && this.accountInfo.building_manager_admin" style="margin-top: 4px; right: 10px;" data-testid="billingEditButton" ><i class="fas fa-pen" style="font-size: 20px;"></i></span>
            <span class="edit-button icon" @click="saveInvoiceInfo()" v-if="invoiceEdit" data-testid="billingSaveButton" style="top: 8px; right: 10px;"><i class="fa fa-save" style="font-size: 18px;"></i></span>
            <div class="account-contents" v-if="!invoiceEdit">
                <div class="row">
                    <div class="col-md-6" v-if="accountInfo.invoice_email" style="padding-top: 10px;">
                        <label>{{profile.invoicing.invoice_email}}</label>
                        <p data-testid="invoiceEmailText" style="margin-bottom: 0;">{{accountInfo.invoice_email}}</p>
                    </div>
                    <div class="col-md-6" v-if="accountInfo.invoice_address" style="padding-top: 10px;">
                        <label>{{profile.invoicing.invoice_address}}</label>
                        <p data-testid="invoiceNumberText" style="margin-bottom: 0;">{{accountInfo.invoice_address}} {{accountInfo.invoice_house_number}}, {{accountInfo.invoice_zipcode}} {{accountInfo.invoice_city}}</p>
                    </div>
                </div>
                <label v-if="accountInfo.invoice_default_text">{{profile.invoicing.standard_text}} <i class="fas fa-info-circle" v-tooltip.right="profile.invoicing.standard_text_tooltip" style="cursor: pointer;"></i></label>
                <p data-testid="invoiceDefaultText" style="margin-bottom: 0;" v-if="accountInfo.invoice_default_text">{{accountInfo.invoice_default_text}}</p>
            </div>
            <div class="account-contents" v-else>
                <div class="notification notification--error" v-if="invoiceError" style="margin: 20px 0;">
                    <p style="font-size: 16px; margin-bottom: 0; color: #bd0b0b;">{{profile.invoicing.invoice_error}}</p>
                </div>
                <label>{{profile.invoicing.invoice_email}}</label>
                <input type="text" class="form-control" v-model="accountInfo.invoice_email" :class="{error: invoiceError, error: invalidInvoiceEmail}" @input="validateInvoiceEmail()" data-testid="billingInputEmail" />
                <br />
                <div class="row">
                    <div class="col-sm-3">
                        <label>{{profile.places.placetable.address}}</label>
                        <input type="text" class="form-control" v-model="accountInfo.invoice_address" :class="{error: invoiceError}" data-testid="billingInputStreet" />
                    </div>
                    <div class="col-sm-3">
                        <label>{{profile.places.placetable.house_number}}</label>
                        <input type="text" class="form-control" v-model="accountInfo.invoice_house_number" :class="{error: invoiceError}" data-testid="billingInputNumber" />
                    </div>
                    <div class="col-sm-3">
                        <label>{{profile.places.placetable.zipcode}}</label>
                        <input type="text" class="form-control" v-model="accountInfo.invoice_zipcode" :class="{error: invoiceError}" data-testid="billingInputZip" />
                    </div>
                    <div class="col-sm-3">
                        <label>{{profile.places.placetable.city}}</label>
                        <input type="text" class="form-control" v-model="accountInfo.invoice_city" :class="{error: invoiceError}" data-testid="billingInputCity" />
                    </div>
                </div>
                <br />
                <label>{{profile.invoicing.standard_text}} <i class="fas fa-info-circle" v-tooltip.right="profile.invoicing.standard_text_tooltip" style="cursor: pointer;"></i></label>
                <textarea class="form-control" v-model="accountInfo.invoice_default_text" data-testid="billingInputText" ></textarea>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    name: 'InvoicingBlock',
    mixins: [setLocale],
    data: function () {
        return {
            invoiceEdit: false,
            invoiceError: false,
            validInvoiceEmail: false,
            invalidInvoiceEmail: false
        }
    },
    props: ['accountInfo'],
    methods: {
        validateInvoiceEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.accountInfo.invoice_email)) {
                this.validInvoiceEmail = true;
                this.invalidInvoiceEmail = false;
            }
            else {
                this.invalidInvoiceEmail = true;
                this.validInvoiceEmail = false;
            }
            if (!this.accountInfo.invoice_email.length) {
                this.invalidInvoiceEmail = false;
                this.validInvoiceEmail = false;
            }
        },
        saveInvoiceInfo() {
            this.invoiceError = false;
            if (!this.accountInfo.invoice_email) {
                if (!this.accountInfo.invoice_address && !this.accountInfo.invoice_house_number && !this.accountInfo.invoice_zipcode && !this.accountInfo.invoice_city) {
                    this.invoiceError = true;
                }
            }
            if (!this.accountInfo.invoice_address || !this.accountInfo.invoice_house_number || !this.accountInfo.invoice_zipcode || !this.accountInfo.invoice_city) {
                if (!this.accountInfo.invoice_email) {
                    this.invoiceError = true;
                }
            }
            if (!this.accountInfo.invoice_email.length && !this.accountInfo.invoice_address.length && !this.accountInfo.invoice_house_number.length && !this.accountInfo.invoice_zipcode.length && !this.accountInfo.invoice_city.length) {
                this.invoiceError = false;
            }
            if (this.accountInfo.invoice_email.length && !this.invalidInvoiceEmail) {
                this.invoiceError = false;
            }
            if (this.accountInfo.invoice_email.length && this.invalidInvoiceEmail) {
                this.invoiceError = true;
            }
            if (!this.accountInfo.invoice_email) {
                this.accountInfo.invoice_email = '';
            }
            if (!this.accountInfo.invoice_address) {
                this.accountInfo.invoice_address = '';
            }
            if (!this.accountInfo.invoice_house_number) {
                this.accountInfo.invoice_house_number = '';
            }
            if (!this.accountInfo.invoice_zipcode) {
                this.accountInfo.invoice_zipcode = '';
            }
            if (!this.accountInfo.invoice_city) {
                this.accountInfo.invoice_city = '';
            }
            if (!this.accountInfo.invoice_default_text) {
                this.accountInfo.invoice_default_text = '';
            }
            if (!this.invoiceError && !this.invalidInvoiceEmail) {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[0][invoice_email]', this.accountInfo.invoice_email);
                data.append('data[0][invoice_address]', this.accountInfo.invoice_address);
                data.append('data[0][invoice_house_number]', this.accountInfo.invoice_house_number);
                data.append('data[0][invoice_zipcode]', this.accountInfo.invoice_zipcode);
                data.append('data[0][invoice_city]', this.accountInfo.invoice_city);
                data.append('data[0][invoice_default_text]', this.accountInfo.invoice_default_text);
                let self = this;
                fetch('/api/v1/buildingmanager', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.invoiceEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
            else {
                this.invoiceError = true;
            }
        }
    }
}
</script>
