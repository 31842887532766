<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Features</h1>
                                <div class="fas fa-info-circle" v-tooltip.right="'In dit overzicht worden alleen beheerders getoond die \'Actief\' zijn en \'Geen demo\' .'"></div>
                            </section>
                            <section class="search search-alt" v-if="managers.length">
                                <form @submit.prevent="searchSuggest()">
                                    <input type="text" ref="search" v-bind:placeholder="'Zoek een beheerder op naam'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                    <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                </form>
                            </section>
                            <div class="table-wrap features">
                                    <h4 class="result-counter" v-if="!searchActive">{{ managers.length }} Beheerders</h4>
                                    <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                                    <h4 class="result-counter" v-if="!searchSuggestions.length & searchActive">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                                    <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                                <table class="table features table-striped" v-if="!searchActive">
                                    <colgroup>
                                        <col style="width: 40%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('name')"><span>{{"Naam"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('is_paid_account')" class="table-header"><span>{{"Servicedesk betaald account"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('can_copy_incidents')" class="table-header"><span>{{"Can link incidents"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('can_change_external_ids')" class="table-header"><span>{{"Can change external IDs"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('whatsapp_flow')" class="table-header"><span>{{"Whatsapp flow"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('twinq_flow')" class="table-header"><span>{{"Twinq flow"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('twinq_no_status_updates')" class="table-header"><span>{{"twinq no status updates"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('is_vve')" class="table-header"><span>{{"Is VVE"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('disable_edit_basedata')" class="table-header last-base"><span>{{"Aanpassen basisdata uitzetten"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('comprehensive_emails_repair_company')" class="table-header"><span>{{"Uitgebreide e-mail reparateur"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('comprehensive_emails_owner')" class="table-header"><span>{{"Uitgebreide e-mail eigenaar"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('comprehensive_emails_building_manager')" class="table-header"><span>{{"Uitgebreide e-mail beheerder"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('can_autodispatch')" class="table-header"><span>{{"Autodispatch"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('has_mandates')" class="table-header"><span>{{"Mandaten"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('has_actionable_work')" class="table-header"><span>{{"Opname Module"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('recurring_job_module')" class="table-header"><span>{{"Repeterend werk module"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('can_owner_chat')" class="table-header"><span>{{"Eigenarenchat"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('updates_via_wa_active')" class="table-header"><span>{{"Updates via WhatsApp"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('has_agreement_flow')" class="table-header"><span>{{"Akkorderingsflow"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('outside_office_hours_extra')" class="table-header"><span>{{"Mededelen extra kosten buiten kantoor uren"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('auto_translate')" class="table-header"><span>{{"Auto translate"}} <i class="fas fa-sort"></i></span></th>
                                        </tr>
                                        <tr style="background:#f5f5f5;">
                                            <td></td>
                                            <td colspan="8" class="last-base"><b>Basis features</b></td>
                                            <td colspan="12"><b>Premium features</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(manager, index) in managers" :key="index">
                                            <td>{{manager.name}}</td>
                                            <td><input type="checkbox" v-model="manager.is_paid_account" @change="toggleFeature(manager, 'is_paid_account', manager.is_paid_account)"></td>
                                            <td><input type="checkbox" v-model="manager.can_copy_incidents" @change="toggleFeature(manager, 'can_copy_incidents', manager.can_copy_incidents)"></td>
                                            <td><input type="checkbox" v-model="manager.can_change_external_ids" @change="toggleFeature(manager, 'can_change_external_ids', manager.can_change_external_ids)"></td>
                                            <td><input type="checkbox" v-model="manager.whatsapp_flow" @change="toggleFeature(manager, 'whatsapp_flow', manager.whatsapp_flow)"></td>
                                            <td><input type="checkbox" v-model="manager.twinq_flow" @change="toggleFeature(manager, 'twinq_flow', manager.twinq_flow)"></td>
                                            <td><input type="checkbox" v-model="manager.twinq_no_status_updates" @change="toggleFeature(manager, 'twinq_no_status_updates', manager.twinq_no_status_updates)"></td>
                                            <td><input type="checkbox" v-model="manager.is_vve" @change="toggleFeature(manager, 'is_vve', manager.is_vve)"></td>
                                            <td class="last-base"><input type="checkbox" v-model="manager.disable_edit_basedata" @change="toggleFeature(manager, 'disable_edit_basedata', manager.disable_edit_basedata)"></td>
                                            <td><input type="checkbox" v-model="manager.comprehensive_emails_repair_company" @change="toggleFeature(manager, 'comprehensive_emails_repair_company', manager.comprehensive_emails_repair_company)"></td>
                                            <td><input type="checkbox" v-model="manager.comprehensive_emails_owner" @change="toggleFeature(manager, 'comprehensive_emails_owner', manager.comprehensive_emails_owner)"></td>
                                            <td><input type="checkbox" v-model="manager.comprehensive_emails_building_manager" @change="toggleFeature(manager, 'comprehensive_emails_building_manager', manager.comprehensive_emails_building_manager)"></td>
                                            <td><input type="checkbox" v-model="manager.can_autodispatch" @change="toggleFeature(manager, 'can_autodispatch', manager.can_autodispatch)"></td>
                                            <td><input type="checkbox" v-model="manager.has_mandates" @change="toggleFeature(manager, 'has_mandates', manager.has_mandates)"></td>
                                            <td><input type="checkbox" v-model="manager.has_actionable_work" @change="toggleFeature(manager, 'has_actionable_work', manager.has_actionable_work)"></td>
                                            <td><input type="checkbox" v-model="manager.recurring_job_module" @change="toggleFeature(manager, 'recurring_job_module', manager.recurring_job_module)"></td>
                                            <td><input type="checkbox" v-model="manager.can_owner_chat" @change="toggleFeature(manager, 'can_owner_chat', manager.can_owner_chat)"></td>
                                            <td><input type="checkbox" v-model="manager.updates_via_wa_active" @change="toggleFeature(manager, 'updates_via_wa_active', manager.updates_via_wa_active)"></td>
                                            <td><input type="checkbox" v-model="manager.has_agreement_flow" @change="toggleFeature(manager, 'has_agreement_flow', manager.has_agreement_flow)"></td>
                                            <td><input type="checkbox" v-model="manager.outside_office_hours_extra" @change="toggleFeature(manager, 'outside_office_hours_extra', manager.outside_office_hours_extra)"></td>
                                            <td><input type="checkbox" v-model="manager.auto_translate" @change="toggleFeature(manager, 'auto_translate', manager.auto_translate)"></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table features table-striped" v-else>
                                    <colgroup>
                                        <col style="width: 40%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('name')"><span>{{"Naam"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('is_paid_account')" class="table-header"><span>{{"Servicedesk betaald account"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('can_copy_incidents')" class="table-header"><span>{{"Can link incidents"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('can_change_external_ids')" class="table-header"><span>{{"Can change external IDs"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('whatsapp_flow')" class="table-header"><span>{{"Whatsapp flow"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('twinq_flow')" class="table-header"><span>{{"Twinq flow"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('twinq_no_status_updates')" class="table-header"><span>{{"twinq no status updates"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('is_vve')" class="table-header"><span>{{"Is VVE"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('disable_edit_basedata')" class="table-header last-base"><span>{{"Aanpassen basisdata uitzetten"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('comprehensive_emails_repair_company')" class="table-header"><span>{{"Uitgebreide e-mail reparateur"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('comprehensive_emails_owner')" class="table-header"><span>{{"Uitgebreide e-mail eigenaar"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('comprehensive_emails_building_manager')" class="table-header"><span>{{"Uitgebreide e-mail beheerder"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('can_autodispatch')" class="table-header"><span>{{"Autodispatch"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('has_mandates')" class="table-header"><span>{{"Mandaten"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('has_actionable_work')" class="table-header"><span>{{"Opname Module"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('recurring_job_module')" class="table-header"><span>{{"Repeterend werk module"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('can_owner_chat')" class="table-header"><span>{{"Eigenarenchat"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('updates_via_wa_active')" class="table-header"><span>{{"Updates via WhatsApp"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('has_agreement_flow')" class="table-header"><span>{{"Akkorderingsflow"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('outside_office_hours_extra')" class="table-header"><span>{{"Mededelen extra kosten buiten kantoor uren"}} <i class="fas fa-sort"></i></span></th>
                                            <th scope="col" @click="sortTable('auto_translate')" class="table-header"><span>{{"Auto translate"}} <i class="fas fa-sort"></i></span></th>
                                        </tr>
                                        <tr style="background:#f5f5f5;">
                                            <td></td>
                                            <td colspan="8"><b>Basis features</b></td>
                                            <td colspan="12"><b>Premium features</b></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(manager, index) in searchSuggestions" :key="index">
                                            <td>{{manager.name}}</td>
                                            <td><input type="checkbox" v-model="manager.is_paid_account" @change="toggleFeature(manager, 'is_paid_account', manager.is_paid_account)"></td>
                                            <td><input type="checkbox" v-model="manager.can_copy_incidents" @change="toggleFeature(manager, 'can_copy_incidents', manager.can_copy_incidents)"></td>
                                            <td><input type="checkbox" v-model="manager.can_change_external_ids" @change="toggleFeature(manager, 'can_change_external_ids', manager.can_change_external_ids)"></td>
                                            <td><input type="checkbox" v-model="manager.whatsapp_flow" @change="toggleFeature(manager, 'whatsapp_flow', manager.whatsapp_flow)"></td>
                                            <td><input type="checkbox" v-model="manager.twinq_flow" @change="toggleFeature(manager, 'twinq_flow', manager.twinq_flow)"></td>
                                            <td><input type="checkbox" v-model="manager.twinq_no_status_updates" @change="toggleFeature(manager, 'twinq_no_status_updates', manager.twinq_no_status_updates)"></td>
                                            <td><input type="checkbox" v-model="manager.is_vve" @change="toggleFeature(manager, 'is_vve', manager.is_vve)"></td>
                                            <td class="last-base"><input type="checkbox" v-model="manager.disable_edit_basedata" @change="toggleFeature(manager, 'disable_edit_basedata', manager.disable_edit_basedata)"></td>
                                            <td><input type="checkbox" v-model="manager.comprehensive_emails_repair_company" @change="toggleFeature(manager, 'comprehensive_emails_repair_company', manager.comprehensive_emails_repair_company)"></td>
                                            <td><input type="checkbox" v-model="manager.comprehensive_emails_owner" @change="toggleFeature(manager, 'comprehensive_emails_owner', manager.comprehensive_emails_owner)"></td>
                                            <td><input type="checkbox" v-model="manager.comprehensive_emails_building_manager" @change="toggleFeature(manager, 'comprehensive_emails_building_manager', manager.comprehensive_emails_building_manager)"></td>
                                            <td><input type="checkbox" v-model="manager.can_autodispatch" @change="toggleFeature(manager, 'can_autodispatch', manager.can_autodispatch)"></td>
                                            <td><input type="checkbox" v-model="manager.has_mandates" @change="toggleFeature(manager, 'has_mandates', manager.has_mandates)"></td>
                                            <td><input type="checkbox" v-model="manager.has_actionable_work" @change="toggleFeature(manager, 'has_actionable_work', manager.has_actionable_work)"></td>
                                            <td><input type="checkbox" v-model="manager.recurring_job_module" @change="toggleFeature(manager, 'recurring_job_module', manager.recurring_job_module)"></td>
                                            <td><input type="checkbox" v-model="manager.can_owner_chat" @change="toggleFeature(manager, 'can_owner_chat', manager.can_owner_chat)"></td>
                                            <td><input type="checkbox" v-model="manager.updates_via_wa_active" @change="toggleFeature(manager, 'updates_via_wa_active', manager.updates_via_wa_active)"></td>
                                            <td><input type="checkbox" v-model="manager.has_agreement_flow" @change="toggleFeature(manager, 'has_agreement_flow', manager.has_agreement_flow)"></td>
                                            <td><input type="checkbox" v-model="manager.outside_office_hours_extra" @change="toggleFeature(manager, 'outside_office_hours_extra', manager.outside_office_hours_extra)"></td>
                                            <td><input type="checkbox" v-model="manager.auto_translate" @change="toggleFeature(manager, 'auto_translate', manager.auto_translate)"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            managers: [],
            managerId: null,
            managerDetails: [],
            sortOrder: 'asc',
            partnersSortType: '',
            searchSuggestions: [],
            searchTerm: '',
            searchActive: false

        }
    },
    created() {
        if (localStorage.getItem('S1-partnerSortOrder')) {
            this.sortOrder = localStorage.getItem('S1-partnerSortOrder');
        }
        if (localStorage.getItem('S1-partnerSorter')) {
            this.placesSortType = localStorage.getItem('S1-partnerSorter');
        }
        document.title = 'S1MONE | Features';
        this.loadManagers();
    },
    methods: {
        loadManagers() {
            let self = this;
            fetch('/api/v1/buildingmanager/s1monedashboard')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                    self.managers = self.managers.filter(manager => manager.active && !manager.demo_account)
                    self.loadDetailsManager();
                });
            });
        },
        loadDetailsManager(){
            let self = this;
            for (let i = 0; i < self.managers.length; i++) {
                let managerID = self.managers[i].id_building_manager;
                fetch('/api/v1/buildingmanager/byid?id_building_manager=' + managerID)
                .then(response => { response.json().then(
                    function(data) {
                        self.managers.splice(i, 1, data);
                    });
                });
            }
        },
        selectManager(id) {
            this.$router.push('/buildingmanager/repaircompany-detail?id_building_manager=' + id);
        },
        toggleFeature(manager, feature, value) {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[' + manager.id_building_manager + '][' + feature +  ']', value);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
        },
        sortTable(sorter) {
            this.partnersSortType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.managers = sortBy(this.managers, sorter);
            if (this.sortOrder === 'desc') {
                this.managers.reverse();
            }
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.managers.length; i++) {
                    let manager = this.managers[i];
                    let managerName = manager.name.toLowerCase();
                    if (managerName.includes(searchTerm)) {
                        this.searchSuggestions.push(manager);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        }
    }
}
</script>
