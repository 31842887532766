<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-photo-video"></i> {{profile.accountPage.account.add_files}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <h4 v-if="files.length">{{profile.accountPage.account.manage_files}}</h4>
                <div class="buttons" v-if="files.length && (selectedDialog.type === 'reporterChat' || selectedDialog.type === 'ownerChat') && !uploadType">
                    <span class="button" data-testid="buttonFileAuto" @click="uploadType = 'auto'" style="margin-right: 20px;">{{ profile.accountPage.account.add_from_incident }}</span>
                    <span class="button" data-testid="buttonFileManual" @click="uploadType = 'manual'">{{ profile.accountPage.account.add_file_system }}</span>
                </div>
                <p v-if="uploadType === 'manual'">{{profile.accountPage.account.upload_media}}<br /><br /></p>
                <p v-if="uploadType === 'auto'">{{profile.incident_detail.select_material}}<br /><br /></p>
                <div class="incident-media" v-if="media.length && uploadType === 'auto'" style="overflow: hidden;">
                    <label>{{profile.incident_detail.imagery}}</label>
                    <div class="media-wrapper" style="clear: both;">
                        <div class="media" data-testid="fileJpg" v-for="(item, index) in media" :key="index" :class="{video: item.content_type === 'video/mp4', selected: item.selected}" v-tooltip.left="item.description" @click="selectItem(item)">
                            <img :src="item.file_url" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                            <div class="iframe_overlay" v-if="item.i_frame_code" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                            <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                            <div class="video_overlay" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                            <video v-if="item.content_type === 'video/mp4'"><source :src="item.file_url" type="video/mp4"></video>
                            <span class="label" v-if="profile.incident_detail[item.type] && !item.selected">{{profile.incident_detail[item.type]}}</span>
                            <div class="selected" v-if="item.selected"><i class="fas fa-check"></i></div>
                        </div>
                    </div>
                </div>
                <div class="incident-docs" v-if="docs.length && uploadType === 'auto'">
                    <label>{{profile.incident_detail.documents}}</label>
                    <ul>
                        <li v-for="(item, index) in docs" :key="index" :class="{selected: item.selected}" @click="item.selected = !item.selected" style="cursor: pointer;" class="doc" data-testid="filePdf">
                            <i class="far fa-file-pdf"></i>
                            <span v-if="!item.description">{{item.filename}} <i class="fas fa-check" v-if="item.selected"></i></span>
                            <span v-else>{{item.description}} <i class="fas fa-check" v-if="item.selected"></i></span>
                        </li>
                    </ul>
                </div>
                <div class="row" v-if="uploadType === 'auto'">
                    <div class="col">
                        <span class="button button--small" @click="selectAllItems()">{{ profile.actionable.select_all }}</span>
                    </div>
                </div>
                <div class="row" v-if="uploadType">
                    <div class="col">
                        <form v-on:submit.prevent="addFiles()">
                            <div v-if="uploadType === 'manual'">
                                <div class="form-field-upload" style="margin-top: 10px;">
                                    <input type="file" data-testid='inputFileLink' @change="fileChange" />
                                </div>
                                <div class="row" v-if="fields.file && (fields.file[0] && fields.file[0].type === 'application/pdf')">
                                    <div class="col-sm-6">
                                        <div class="form-field" :class="{focus:fields.filename.length}">
                                            <label>{{profile.incident_detail.document_name}} *</label>
                                            <input type="text" class="form-control" :class="{error: nameError}" data-testid='inputFileName' v-model="fields.filename" @input="validateFilename()" :required="fields.file">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span class="notification notification--error" v-if="uploadError" style="margin-bottom: 10px;">{{profile.accountPage.account.upload_error_incident}}</span>
                            <span class="notification notification--error" v-if="addError" style="margin-bottom: 10px; float: left; margin-top: 15px;">{{profile.accountPage.account.add_error}}</span>
                            <button type="submit" class="button button--icon" data-testid='submitFileButton'><i class="fas fa-photo-video"></i>{{profile.accountPage.account.add_files}}</button>
                        </form>
                        <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" style="float: left;" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    name: 'mediaModal',
    data() {
        return {
            media: [],
            docs: [],
            fields: {
                filename: '',
                file: null,
            },
            uploadError: false,
            addError: false,
            loading: false,
            message: '',
            mediaSelected: false,
            uploadType: '',
            nameError: false
        }
    },
    props: ['incidentId', 'files', 'selectedDialog'],
    created() {
        if (!this.files.length) {
            this.uploadType = 'manual';
        }
        if (this.selectedDialog.type !== 'reporterChat' && this.selectedDialog.type !== 'ownerChat') {
            this.uploadType = 'manual';
        }
        if (this.files) {
            this.media = [];
            this.docs = [];
            let invoices = [];
            for (var i = 0; i < this.files.length; i++) {
                let media = this.files[i];
                if (!media.file_url.includes('.pdf')) {
                    media.rotate = 1;
                    if (media.type === 'incident_reporter') {
                        media.order = 1;
                    }
                    if (media.type === 'incident_building_manager') {
                        media.order = 2;
                    }
                    if (media.type === 'incident_mechanic') {
                        media.order = 3;
                    }
                    this.media.push(media);
                    this.media = sortBy(this.media, 'order');
                }
                else {
                    let n = media.file_url.lastIndexOf('/');
                    let fileName = media.file_url.substring(n + 1);
                    media.pdf = true;
                    media.filename = fileName;
                    if (media.type === 'invoice_pdf') {
                        invoices.push(media);
                    }
                    else {
                        this.docs.push(media);
                    }
                }
            }
            if (this.media.length === 1) {
                this.selectItem(this.media[0]);
            }
            if (invoices.length) {
                let latestInvoice = invoices.reduce((max, invoice) => max.created > invoice.created ? max : invoice);
                if (this.locale === 'nl') {
                    latestInvoice.description = latestInvoice.description.replace('invoice', 'opdrachtbon')
                }
                else {
                    latestInvoice.description = latestInvoice.description.replace('invoice', 'orderform')
                }
                this.docs.push(latestInvoice);
            }
        }
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        selectItem(item) {
            this.addError = false;
            item.selected = !item.selected;
            if (this.media.filter(e => e.selected === true).length > 0) {
                this.mediaSelected = true;
            }
            else {
                this.mediaSelected = false;
            }
        },
        selectAllItems() {
            this.addError = false;
            if (this.media.length) {
                for (var i = 0; i < this.media.length; i++) {
                    let media = this.media[i];
                    media.selected = true;

                }
            }
            if (this.docs.length) {
                for (var i = 0; i < this.docs.length; i++) {
                    let doc = this.docs[i];
                    doc.selected = true;
                }
            }
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        validateFilename() {
            this.fields.filename = this.fields.filename.replace('.', '');
            if (this.fields.filename.length) {
                this.nameError = false;
            }
            else {
                this.nameError = true;
            }
        },
        addFiles() {
            let filesToAdd = [];
            this.loading = true;
            let self = this;
            for (var i = 0; i < this.media.length; i++) {
                let media = this.media[i];
                if (media.selected) {
                    filesToAdd.push(media);
                    const data = new URLSearchParams();
                    data.append('id_file_upload', media.id_file_upload);
                    data.append('id_incident', this.incidentId);
                    if (this.selectedDialog.type === 'reporterChat') {
                        data.append('type', 'reporter');
                    }
                    if (this.selectedDialog.type === 'ownerChat') {
                        data.append('type', 'owner');
                    }
                    fetch('/api/v1/chat/sendpicturetowhatsapp', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        if (data.SUCCESS) {
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                            self.$emit('success');
                        }
                        else {
                            self.uploadError = true;
                        }
                    });
                }
            }
            if (this.uploadType === 'auto') {
                for (var i = 0; i < this.docs.length; i++) {
                    let doc = this.docs[i];
                    if (doc.selected) {
                        filesToAdd.push(doc);
                        const data = new URLSearchParams();
                        data.append('id_file_upload', doc.id_file_upload);
                        data.append('id_incident', this.incidentId);
                        if (this.selectedDialog.type === 'reporterChat') {
                            data.append('type', 'reporter');
                        }
                        if (this.selectedDialog.type === 'ownerChat') {
                            data.append('type', 'owner');
                        }
                        fetch('/api/v1/chat/sendpicturetowhatsapp', {
                            method: 'post',
                            body: data
                        }).then((resp) => {return resp.json();
                        }).then(function(data) {
                            if (data.SUCCESS) {
                                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                                self.closeModal();
                                self.$emit('success');
                            }
                            else {
                                self.uploadError = true;
                            }
                        });
                    }
                }
            }
            if (!filesToAdd.length && !this.fields.file) {
                this.loading = false;
                this.addError = true;
            }
            if (this.fields.file) {
                if (this.fields.file[0].type === 'application/pdf') {
                    if (this.fields.filename.length) {
                        const data = new FormData();
                        data.append('file', this.fields.file[0]);
                        data.append('filename', this.fields.filename.toLowerCase());
                        data.append('id_incident', this.incidentId);
                        fetch('/api/v1/fileupload/uploadincidentpdf', {
                            method: 'post',
                            body: data
                        }).then((resp) => {return resp.json();
                        }).then(function(data) {
                            if (data.url && (self.selectedDialog.type === 'reporterChat' || self.selectedDialog.type === 'ownerChat')) {
                                const params = new URLSearchParams();
                                params.append('id_file_upload', data.id_file_upload);
                                params.append('id_incident', self.incidentId);
                                if (self.selectedDialog.type === 'reporterChat') {
                                    params.append('type', 'reporter');
                                }
                                if (self.selectedDialog.type === 'ownerChat') {
                                    params.append('type', 'owner');
                                }
                                fetch('/api/v1/chat/sendpicturetowhatsapp', {
                                    method: 'post',
                                    body: params
                                }).then((resp) => {return resp.json();
                                }).then(function(data) {
                                    if (data.SUCCESS) {
                                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                                        self.closeModal();
                                        self.$emit('success');
                                    }
                                    else {
                                        self.uploadError = true;
                                    }
                                });
                            }
                            if (data.url && (self.selectedDialog.type !== 'reporterChat' && self.selectedDialog.type !== 'ownerChat')) {
                                if (self.locale === 'nl') {
                                    self.message = 'Er is een nieuw bestand / beeld toegevoegd aan de melding. <a href="' + data.url + '" target="_blank" style="display: inline; color: #34343d; text-decoration: underline;">Bekijken</a>';
                                }
                                else {
                                    self.message = 'A new file/image has been added to the incident. <a href="' + data.url + '" target="_blank" style="display: inline; color: #34343d; text-decoration: underline;">View</a>';
                                }
                                self.$emit('sendMessage', self.message);
                                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                            }
                            if (!data.url) {
                                self.uploadError = true;
                            }
                        });
                    }
                    else {
                       this.nameError = true;         
                    }
                }
                if (this.fields.file[0].type === 'video/mp4' || this.fields.file[0].type === 'video/3gp' || this.fields.file[0].type === 'video/ts' || this.fields.file[0].type === 'video/mkv' || this.fields.file[0].type === 'video/mov' || this.fields.file[0].type === 'video/avi') {
                    const data = new FormData();
                    let filename = this.fields.filename.split(' ').join('');
                    let filetype = this.fields.file[0].type.split('/')[1];
                    filename = filename + '.' + filetype;
                    data.append('file', this.fields.file[0]);
                    data.append('filename', filename);
                    data.append('id_incident', this.incidentId);
                    fetch('/api/v1/fileupload/uploadincidentvideo', {
                        method: 'post',
                        body: data
                    }).then((resp) => {
                        if (!resp.ok) {
                            self.uploadError = true;
                            console.log('error');
                        }
                        return resp.json();
                    }).then(function(data) {
                        self.uploadError = false;
                        self.loading = false;
                        if (!self.uploadError && (self.selectedDialog.type === 'reporterChat' || self.selectedDialog.type === 'ownerChat')) {
                            const params = new URLSearchParams();
                            params.append('id_file_upload', data.id_file_upload);
                            params.append('id_incident', self.incidentId);
                            if (self.selectedDialog.type === 'reporterChat') {
                                params.append('type', 'reporter');
                            }
                            if (self.selectedDialog.type === 'ownerChat') {
                                params.append('type', 'owner');
                            }
                            fetch('/api/v1/chat/sendpicturetowhatsapp', {
                                method: 'post',
                                body: params
                            }).then((resp) => {return resp.json();
                            }).then(function(data) {
                                self.loading = false;
                                if (data.SUCCESS) {
                                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                                    self.closeModal();
                                    self.$emit('success');
                                }
                                else {
                                    self.uploadError = true;
                                }
                            });
                        }
                        if (data.url && (self.selectedDialog.type !== 'reporterChat' && self.selectedDialog.type !== 'ownerChat')) {
                            if (self.locale === 'nl') {
                                self.message = 'Er is een nieuw bestand / beeld toegevoegd aan de melding. <a href="' + data.url + '" target="_blank" style="display: inline; color: #34343d; text-decoration: underline;">Bekijken</a>';
                            }
                            else {
                                self.message = 'A new file/image has been added to the incident. <a href="' + data.url + '" target="_blank" style="display: inline; color: #34343d; text-decoration: underline;">View</a>';
                            }
                            self.$emit('sendMessage', self.message);
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        }
                    });
                }
                if (this.fields.file[0].type === 'image/bmp' ||
                    this.fields.file[0].type === 'image/eps' ||
                    this.fields.file[0].type === 'image/gif' ||
                    this.fields.file[0].type === 'image/ico' ||
                    this.fields.file[0].type === 'image/jpeg' ||
                    this.fields.file[0].type === 'image/jpg' ||
                    this.fields.file[0].type === 'image/pcx' ||
                    this.fields.file[0].type === 'image/png' ||
                    this.fields.file[0].type === 'image/raf' ||
                    this.fields.file[0].type === 'image/tiff' ||
                    this.fields.file[0].type === 'image/webp') {
                    const data = new FormData();
                    data.append('file', this.fields.file[0]);
                    data.append('filename', this.fields.filename);
                    data.append('id_incident', this.incidentId);
                    fetch('/api/v1/fileupload/uploadincidentimage', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        if (data.url && (self.selectedDialog.type === 'reporterChat' || self.selectedDialog.type === 'ownerChat')) {
                            const params = new URLSearchParams();
                            params.append('id_file_upload', data.id_file_upload);
                            params.append('id_incident', self.incidentId);
                            if (self.selectedDialog.type === 'reporterChat') {
                                params.append('type', 'reporter');
                            }
                            if (self.selectedDialog.type === 'ownerChat') {
                                params.append('type', 'owner');
                            }
                            fetch('/api/v1/chat/sendpicturetowhatsapp', {
                                method: 'post',
                                body: params
                            }).then((resp) => {return resp.json();
                            }).then(function(data) {
                                if (data.SUCCESS) {
                                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                                    self.closeModal();
                                    self.$emit('success');
                                }
                                else {
                                    self.uploadError = true;
                                }
                            });
                        }
                        if (data.url && (self.selectedDialog.type !== 'reporterChat' && self.selectedDialog.type !== 'ownerChat')) {
                            if (self.locale === 'nl') {
                                self.message = 'Er is een nieuw bestand / beeld toegevoegd aan de melding. <a href="' + data.url + '" target="_blank" style="display: inline; color: #34343d; text-decoration: underline;">Bekijken</a>';
                            }
                            else {
                                self.message = 'A new file/image has been added to the incident. <a href="' + data.url + '" target="_blank" style="display: inline; color: #34343d; text-decoration: underline;">View</a>';
                            }
                            self.$emit('sendMessage', self.message);
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        }
                        if (!data.url) {
                            self.uploadError = true;
                        }
                    });
                }
            }
        }
    }
}
</script>
