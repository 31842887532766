<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-redo"></i>{{profile.actionable.repeating_action}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="newRepeatingAction()">
                    <label>{{ profile.dashboard.building_manager.message_table.place }}</label>
                    <div class="form-field clearable" :class="{focus: placeSearchTerm.length, error: placeError, active: places.length || generalPlaces.length}" style="margin-bottom: 10px;">
                        <label v-if="!selectedPlace.id_place">{{profile.incidents.new_incident.loc_placeholder}} *</label>
                        <div>
                            <div class="selected-info seperate" v-if="selectedPlace.name" style="border: 2px solid #00C900;">
                                <h4>{{selectedPlace.name}}</h4>
                                <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                                <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                                <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                                <span class="general-label" v-if="selectedPlace.floor_name === 'VVEVEST'">{{profile.incidents.new_incident.general_spaces}}</span>
                            </div>
                            <input type="text" data-testid="newJobLocationSearch" ref="place" class="form-control" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                        </div>
                    </div>
                    <ul v-if="places.length || generalPlaces.length" class="dropdown-list" style="padding: 0; margin-top: -12px;">
                        <li v-for="place in generalPlaces" :key="place.id_place" class="dropdown-list-item" v-on:click="selectPlace(place)">
                            <h4 data-testid="possibleLocationName">{{place.name}}</h4>
                            <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                            <span class="general-label" v-if="place.floor_name === 'VVEVEST'">{{profile.incidents.new_incident.general_spaces}}</span>
                        </li>
                        <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" v-on:click="selectPlace(place)">
                            <h4 data-testid="possibleLocationName">{{place.name}}</h4>
                            <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                        </li>
                    </ul>
                    <div v-if="selectedPlace.id_place">
                        <label>{{ profile.incidents.new_incident.id_asset }}</label>
                        <div class="dropdown-section">
                            <span class="dropdown" data-testid="selectAssetDropdown" v-on:click="assetsDropdownToggled = !assetsDropdownToggled" :class="{active: assetsDropdownToggled, error: assetError, valid: selectedAssetId}">
                                <span v-if="!selectedAssetId">{{profile.incidents.new_incident.choose_asset}} *</span>
                                <span v-if="selectedAssetId" class="selected">{{selectedAssetName}}</span>
                                <i class="fas fa-chevron-down chevron"></i></span>
                            <div class="dropdown-list" v-if="assetsDropdownToggled" :class="{search: assets.length > 9}">
                                <input type="text" @input="e => assetSearchTerm = e.target.value" :value="assetSearchTerm" placeholder="Zoek een locatienaam..." @keyup="searchAssets()" v-if="assets.length > 9"  />
                                <ul>
                                    <li v-for="asset in assets" v-on:click="selectAsset(asset)" :key="asset.id_asset" v-show="!tempAssets.length && !assetsNoResults">
                                        {{asset.name}}
                                        <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                        <span class="inactive-label" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                                    </li>
                                    <li v-for="(asset, index) in tempAssets" v-on:click="selectAsset(asset)" :key="index" v-show="tempAssets.length">{{asset.name}}</li>
                                    <li v-if="assetsNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <label>{{profile.actionable.work}}</label>
                    <textarea class="form-control" data-testid="newJobDescription" v-model="fields.action" style="margin-bottom: 10px;" :class="{error: workError}"></textarea>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.actionable.frequency}}</label>
                            <div class="dropdown-section">
                                <span class="dropdown" data-testid="newJobFrequence" v-on:click="frequencyDropdownToggled = !frequencyDropdownToggled" :class="{active: frequencyDropdownToggled, error: frequencyError}">
                                    <span v-if="!fields.frequency">{{profile.actionable.frequency}} *</span>
                                    <span v-if="fields.frequency" class="selected">{{fields.frequency}}</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="frequencyDropdownToggled">
                                    <ul>
                                        <li v-for="(option, index) in frequencyOptions" :key="index" @click="selectFrequency(option)">{{option}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <label>{{ profile.incident_detail.category }}</label>
                    <div class="row">
                        <div class="col">
                            <div class="dropdown-section">
                                <span class="dropdown" data-testid="newJobCategory" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled, error: categoryError, valid: selectedCategory.dutch_name}">
                                    <span v-if="!selectedCategory.id_incident_category">{{profile.incident_detail.category}} *</span>
                                    <span v-if="selectedCategory.id_incident_category && locale === 'nl'" class="selected">{{selectedCategory.dutch_name}}</span>
                                    <span v-if="selectedCategory.id_incident_category && locale === 'en'" class="selected">{{selectedCategory.english_name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i></span>
                                <div class="dropdown-list" v-if="categoryDropdownToggled" :class="{search: categories.length > 9}">
                                    <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" :placeholder="profile.incident_detail.search_category" @keyup="searchCategories()" v-if="categories.length > 9"  />
                                    <ul>
                                        <li v-for="category in categories" v-on:click="selectCategory(category)" :key="category.id_category" v-show="!tempCategories.length && !categoriesNoResults">
                                            <span v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span v-if="locale === 'en'">{{category.english_name}}</span>
                                        </li>
                                        <li v-for="(category, index) in tempCategories" v-on:click="selectCategory(category)" :key="index" v-show="tempCategories.length">
                                            <span v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span v-if="locale === 'en'">{{category.english_name}}</span>
                                        </li>
                                        <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <label>{{profile.actionable.suggestion_first_action}}</label>
                    <flat-pickr v-model="fields.firstAction" class="form-control" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date" @on-change="firstActionError = false"></flat-pickr>
                    <div class="notification notification--error" v-if="firstActionError">{{ profile.actionable.first_action_error }}</div>
                    <button type="submit" data-testid="newJobSubmit" class="button button--icon"><i class="fas fa-redo"></i>{{ profile.actionable.repeating_action}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    components: {
        flatPickr
    },
    data() {
        return {
            profile: {},
            places: [],
            generalPlaces: [],
            assets: [],
            lacesDropdownToggled: false,
            assetsDropdownToggled: false,
            selectedPlace: {},
            selectedAssetId: 0,
            selectedAssetName: '',
            assetsNoResults: false,
            placesNoResults: false,
            tempAssets: [],
            placeSearchTerm: '',
            assetSearchTerm: '',
            placeError: false,
            assetError: false,
            fields: {
                action: '',
                frequency: '',
                firstAction: '',
                firstActionEpoch: '',
                category: null
            },
            frequencyDropdownToggled: false,
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            },
            tomorrow: null,
            frequencyError: false,
            firstActionError: false,
            workError: false,
            frequencyOptions: [],
            categories: [],
            selectedCategory: {},
            categoryDropdownToggled: false,
            categorySearchTerm: '',
            categoryError: false,
            tempCategories: [],
            categoriesNoResults: false
        }
    },
    computed: mapState(['bmId']),
    props: ['selectedAction'],
    created() {
        this.getFrequencyOptions();
        this.getCategories();
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        this.tomorrow = new Date();
        this.tomorrow.setDate(this.tomorrow.getDate()+1);
        this.tomorrow = moment(this.tomorrow).format();
        this.tomorrow = this.tomorrow.split('T')[0];
        this.flatPickrDateConfig.minDate = this.tomorrow;
        this.fields.action = this.selectedAction.planned_action_name;
        this.fields.frequency = this.selectedAction.frequency;
        this.fields.firstAction = this.selectedAction.firstAction;
    },
    methods: {
        getCategories() {
            let self = this;
            fetch('/api/v1/incident_category/bybuildingmanager?id_building_manager=' + this.bmId)
            .then(response => { response.json().then(
                function(data) {
                    self.categories = data.data;
                    self.categories = uniqBy(self.categories, 'id_incident_category');
                    for (var i = 0; i <self.categories.length; i++) {
                        let category = self.categories[i];
                        category.dutch_name = category.dutch_name.split('*')[0];
                        category.english_name = category.english_name.split('*')[0];
                        if (category.dutch_name.includes('-2') || category.dutch_name.includes('-3')|| category.dutch_name.includes('-4')) {
                            self.categories.splice(i, 1);
                        }
                    }
                    if (self.locale === 'nl') {
                        self.categories = sortBy(self.categories, 'dutch_name');
                    }
                    else {
                        self.categories = sortBy(self.categories, 'english_name');
                    }
                });
            });
        },
        searchCategories() {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i];
                    let categoryName;
                    if (this.locale === 'en') {
                        categoryName = category.english_name.toLowerCase();
                    }
                    else {
                        categoryName = category.dutch_name.toLowerCase();
                    }
                    if (categoryName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        selectCategory(category) {
            this.categoryDropdownToggled = false;
            this.selectedCategory = category;
            this.tempCategories = [];
            this.categorySearchTerm = '';
            this.categoryError = false;
        },
        getFrequencyOptions() {
            let self = this;
            fetch('/api/v1/recurringaction/frequencyoptions')
            .then(response => { response.json().then(
                function(data) {
                    self.frequencyOptions = data;
                });
            });
        },
        getAssets() {
            let self = this;
            fetch('/api/v1/asset/activebyplacewithqr?id_place=' + this.selectedPlace.id_place)
            .then(response => { response.json().then(
                function(data) {
                    if (data.data.length) {
                        self.assets = [];
                        self.fields.asset = '';
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            if (item.name) {
                                self.assets.push(item);
                            }
                        }
                    }
                });
            });
        },
        selectPlace(place) {
            this.places = [];
            this.generalPlaces = [];
            this.placesDropdownToggled = false;
            this.selectedPlace = place;
            this.placeSearchTerm = '';
            this.getAssets();
            this.selectedAssetId = 0;
            this.assetsDropdownToggled = false;
            this.placeError = false;
        },
        selectAsset(asset) {
            this.assetsDropdownToggled = false;
            this.selectedAssetName = asset.name;
            this.selectedAssetId = asset.id_asset;
            this.tempAssets = [];
            this.assetSearchTerm = '';
            this.assetError = false;
        },
        searchPlaces() {
            if (this.placeSearchTerm.length > 2) {
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '?search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/bybuildingmanageronlywithassets' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        self.generalPlaces = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            if (place.floor_name === 'VVEVEST') {
                                self.generalPlaces.push(place);
                            }
                            else {
                                self.places.push(place);
                            }
                        }
                        if (!self.places && !self.generalPlaces) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
                this.generalPlaces = [];
            }
        },
        removePlaceSelection() {
            this.selectedPlace = {};
            this.generalPlaces = [];
            this.places = [];
        },
        searchAssets() {
            this.tempAssets = [];
            if (this.assetSearchTerm.length > 1) {
                let searchTerm = this.assetSearchTerm.toLowerCase();
                for (var i = 0; i < this.assets.length; i++) {
                    let asset = this.assets[i];
                    let assetName = asset.name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssets.push(asset);
                    }
                    if (!this.tempAssets.length) {
                        this.assetsNoResults = true;
                    }
                    else {
                        this.assetsNoResults = false;
                    }
                }
            }
            else {
                this.assetsNoResults = false;
            }
        },
        newRepeatingAction() {
            if (!this.fields.action) {
                this.workError = true;
            }
            else {
                this.workError = false;
            }
            if (!this.fields.frequency) {
                this.frequencyError = true;
            }
            else {
                this.frequencyError = false;
            }
            if (!this.fields.firstAction) {
                this.firstActionError = true;
            }
            else {
                this.firstActionError = false;
            }
            if (!this.selectedAssetId) {
                this.assetError = true;
            }
            if (!this.selectedCategory.id_incident_category) {
                this.categoryError = true;
            }
            if (!this.workError && !this.frequencyError && !this.firstActionError && !this.assetError && !this.categoryError) {
                let date = new Date(this.fields.firstAction)
                this.fields.firstActionEpoch = date.getTime();
                let frequency;
                if (this.fields.frequency === 'Wekelijks') {
                    frequency = 'WEEKLY'
                }
                if (this.fields.frequency === 'Maandelijks') {
                    frequency = 'MONTHLY'
                }
                if (this.fields.frequency === 'Per kwartaal') {
                    frequency = 'QUARTERLY'
                }
                if (this.fields.frequency === 'Jaarlijks') {
                    frequency = 'YEARLY'
                }
                if (this.fields.frequency === 'Elke 1.5 jaar') {
                    frequency = 'YEAR_AND_A_HALFLY'
                }
                if (this.fields.frequency === 'Elke 2 jaar') {
                    frequency = 'TWO_YEARLY'
                }
                if (this.fields.frequency === 'Elke 3 jaar') {
                    frequency = 'THREE_YEARLY'
                }
                if (this.fields.frequency === 'Elke 3 jaar') {
                    frequency = 'FOUR_YEARLY'
                }
                if (this.fields.frequency === 'Elke 3 jaar') {
                    frequency = 'FIVE_YEARLY'
                }    
                const data = new URLSearchParams();
                data.append('id_asset', this.selectedAssetId);
                data.append('frequency', frequency);
                data.append('start_date', this.fields.firstActionEpoch);
                data.append('id_incident_category', this.selectedCategory.id_incident_category);
                data.append('description', this.fields.action);
                let self = this;
                fetch('/api/v1/recurringaction/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Aanmaken repeterend werk',
                    });
                    self.$emit('success');
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.closeModal();
                });
            }
        },
        selectFrequency(frequency) {
            this.frequencyDropdownToggled = false;
            this.fields.frequency = frequency;
            this.frequencyError = false;
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
