<template>
    <div class="block block--account block--border">
        <div class="account-info">
            <h4>{{profile.accountPage.account.withhold_tenants}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.withhold_tooltip" style="cursor: pointer;"></i></h4>
            <div class="account-contents">
                <div class="row">
                    <div class="col">
                        <div>
                            <div class="switch-wrapper switch-wrapper--full alt-hover" :class="{disabled: !accountInfo.building_manager_admin && !twinq, active: accountInfo.withhold_tenant_requests}">                                <div class="inner" @click="toggleWithHoldTenants()">
                                    <i class="fa-regular fa-hand"></i>
                                    <p>{{profile.accountPage.account.withhold_tenants}}</p>
                                    <span class="label">{{ profile.switches.withhold }}</span>
                                    <label class="switch">
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <br v-if="accountInfo.withhold_tenant_requests" />
                            <div class="employees" v-if="accountInfo.withhold_tenant_requests">
                                <div class="employee">
                                    <span class="edit-button icon" @click="editTexts = true;" v-if="!editTexts && (accountInfo.building_manager_admin || twinq)"><i class="fas fa-pen"></i></span>                                    <span class="edit-button edit-button--small" @click="saveTexts()" v-if="editTexts"><i class="fas fa-save"></i></span>
                                    <div  v-if="!editTexts">
                                        <label>{{profile.accountPage.account.withold_text}}</label>
                                        <p>{{accountInfo.withhold_tenant_requests_text_two}}</p>
                                    </div>
                                    <div v-if="editTexts">
                                        <label>{{profile.accountPage.account.withold_text}}</label>
                                        <textarea class="form-control" v-model="accountInfo.withhold_tenant_requests_text_two"></textarea>
                                    </div>
                                </div>   
                            </div>                               
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';

export default {
    mixins: [setLocale],
    data: function () {
        return {
            profile: {},
            editTexts: false
        }
    },
    props: ['accountInfo'],
    computed: mapState(['twinq', 'admin']),
    methods: {
        toggleWithHoldTenants() {
            this.accountInfo.withhold_tenant_requests = !this.accountInfo.withhold_tenant_requests;
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][withhold_tenant_requests]', this.accountInfo.withhold_tenant_requests);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveTexts() {
            this.editTexts = false;
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][withhold_tenant_requests_text_two]', this.accountInfo.withhold_tenant_requests_text_two);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
