<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-history"></i>{{profile.incident_detail.history_of_asset}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" data-testid="historyClose" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div class="row">
                    <div class="col-sm-8">
                        <section class="modal-search" :class="{active: searchSuggestions.length}">
                            <form v-on:submit.prevent>
                                <input type="text" data-testid="historyIncidentInput" :placeholder="profile.incident_detail.search_history" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" />
                                <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                            </form>
                        </section>
                    </div>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col" @click="sortTable('id_incident')">{{profile.incident_detail.number}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('created')">{{profile.dashboard.building_manager.message_table.date}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('incident_description')">{{profile.incidents.incidenttable.description}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('status')">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in incidents" :key="item.id_incident" @click="toIncident(item.id_incident)" v-show="!searchSuggestions.length">
                            <td data-testid="historyIncidentId">{{item.id_incident}}</td>
                            <td data-testid="historyIncidentCreated">{{item.date}} {{item.time}}</td>
                            <td data-testid="historyIncidentDescription">{{item.incident_description}}</td>
                            <td data-testid="historyIncidentStatus" v-if="!profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status]}}</td>
                            <td data-testid="historyIncidentStatusManager" v-if="profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status].buildingmanager}}</td>
                        </tr>
                        <tr v-for="(item, index) in searchSuggestions" :key="index" @click="toIncident(item.id_incident)" v-show="searchSuggestions.length">
                            <td>{{item.id_incident}}</td>
                            <td>{{item.date}} {{item.time}}</td>
                            <td>{{item.incident_description}}</td>
                            <td v-if="!profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status]}}</td>
                            <td v-if="profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status].buildingmanager}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export default {
    mixins: [setLocale],
    name: 'historyModal',
    data() {
        return {
            profile: {},
            incidents: [],
            searchSuggestions: [],
            searchTerm: '',
            incidentSortType: '',
            incidentsInView: [],
            sortOrder: 'asc'
        }
    },
    props: ['incidentInfo', 'originSupplier'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        fetch('/api/v1/incidentcombined/previousincidents?id_incident=' + this.incidentInfo.id_incident)
        .then(response => { response.json().then(
            function(data) {
                for (var i = 0; i < data.length; i++) {
                    let item = data[i];
                    item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                    item.time = moment(item.created).locale('nl').format('LT');
                    self.incidents.push(item);
                }
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.logBook = sortBy(this.logBook, sorter);
            if (this.sortOrder === 'desc') {
                this.logBook.reverse();
            }
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm) {
                for (var i = 0; i < this.incidents.length; i++) {
                    let item = this.incidents[i];
                    let itemDescr = item.incident_description.toLowerCase();
                    let itemId = item.id_incident.toString();
                    if (itemDescr.includes(searchTerm) || itemId.includes(searchTerm)) {
                        this.searchSuggestions.push(item);
                    }
                }
            }
            this.searchSuggestions = uniqBy(this.searchSuggestions, 'id_incident');
            if ($event.keyCode === 27) {
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
        },
        toIncident(id) {
            if (!this.originSupplier) {
                this.$emit('close');
                this.$router.replace({ path: '/buildingmanager/incident-detail', query: { id: id} });    
            }
        }
    }
}
</script>
