<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="block block--owners" >
                    <section class="incident-head">
                        <h1>Standaard categorieën</h1>
                        <span class="edit-button icon" @click="newDefaultCategoryModalOpen = true"><i class="fa-solid fa-plus"></i></span>
                    </section>
                    <section class="search search-alt" v-if="categories.length">
                        <form @submit.prevent="searchSuggest()">
                            <input type="text" ref="search" v-bind:placeholder="'Zoek een categorie op naam'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                        </form>
                    </section>
                    <div v-if="searchActive">
                        <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                        <h4 class="result-counter" v-if="!searchSuggestions.length">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                        <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                        <div v-for="category in searchSuggestions" :key="category.id_repair_company" class="list-item partner-list-item">
                            <div v-if="!category.edit">
                                <b style="float: left; margin-left: 20px ;margin-right: 20px">{{ category.dutch_name }} </b>
                                <div v-for="(specialism, index) in getSpecialisms(category.id_default_incident_category)" :key="index">
                                    <span class="active-label" style="top: 0;background: grey; font-size: 13px; float: left; position: relative; margin-right: 5px;">{{ specialism }}</span>
                                </div>
                                <span class="edit-button edit-button--small" v-if="!category.edit" @click="category.edit=true" style="top: 0; right: 0; font-size: 13px; float: right; position: relative;"><i class="fas fa-pen"></i></span>
                            </div>
                            <form v-on:submit.prevent="editCategory(category); category.edit=false" v-else>
                                <div class="row">
                                    <div class="col-11"></div>
                                    <div class="col-1">
                                        <button type="submit" class="edit-button edit-button--small" style="top: 0; right: 0; font-size: 13px; float: right; position: relative; margin-bottom: 10px"><i class="fas fa-save"></i></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-field" :class="{focus:category.dutch_name.length}">
                                            <label>{{"Naam (NL)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.dutch_name" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-field" :class="{focus:category.english_name.length}">
                                            <label>{{"Naam (EN)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.english_name" >
                                        </div>  
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.ukrainian_name ? category.ukrainian_name.length : 0)}">
                                            <label>{{"Naam (UKR)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.ukrainian_name" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-field" :class="{focus: (category.russian_name ? category.russian_name.length : 0)}">
                                            <label>{{"Naam (RU)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.russian_name" >
                                        </div>
                                    </div>
                                </div>  
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.polish_name ? category.polish_name.length : 0)}">
                                            <label>{{"Naam (PL)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.polish_name" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.italian_name ? category.italian_name.length : 0)}">
                                            <label>{{"Naam (IT)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.italian_name" >
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.german_name ? category.german_name.length : 0)}">
                                            <label>{{"Naam (DE)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.german_name" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.french_name ? category.french_name.length : 0)}">
                                            <label>{{"Naam (FR)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.french_name" >
                                        </div>
                                    </div>
                                </div>
                                <div style="padding-left:5px; font-weight: 400;">Specialismes</div>
                                <div class="row">
                                    <div class="col-6">
                                        <div v-for="(specialism, index) in getSpecialisms(category.id_default_incident_category)" :key="index" style="padding-left: 15px; ">
                                            <span class="active-label" style="top: 0;background: grey; font-size: 13px; float: left; position: relative; margin-right: 5px;">{{ specialism }} 
                                                <span @click="removeSpecialism(index, category)">X</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style="padding-left:5px; padding-top: 10px; font-weight: 400;">Selecteer een specialisme</div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="dropdown-section">
                                            <span class="dropdown" v-on:click="specialismsDropwdownToggle = !specialismsDropwdownToggle" :class="{active: specialismsDropwdownToggle}">
                                                <span class="selected" v-if="!selectedSpeclialism.name">Geen</span>
                                                <span class="selected" v-else>{{selectedSpeclialism.name}}</span>
                                                <i class="fas fa-chevron-down chevron"></i>
                                            </span>
                                            <div class="dropdown-list" v-if="specialismsDropwdownToggle">
                                                <ul>
                                                    <li v-for="(specialism, index) in specialisms" v-on:click="selectSpecialism(index)" :key="index">
                                                        {{specialism.name}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </form>
                        </div>
                    </div>
                    <div v-else style="height: 600px; overflow: auto; padding-right: 10px; margin-top: 10px;">
                        <h4 class="result-counter">{{categoriesShown.length}} {{'standaard categorieën'}}</h4>
                        <div class="list-item partner-list-item" v-for="(category, index) in categoriesShown" :key="index" style="border: 1px solid #dbdbdb; margin-bottom: 10px;"> 
                            <div v-if="!category.edit">
                                <b style="float: left; margin-left: 20px; margin-right: 20px">{{ category.dutch_name }} </b>
                                <div v-for="(specialism, index) in getSpecialisms(category.id_default_incident_category)" :key="index">
                                    <span class="active-label" style="top: 0;background: grey; font-size: 13px; float: left; position: relative; margin-right: 5px;">{{ specialism }}</span>
                                </div>
                                <span class="edit-button edit-button--small" @click="category.edit=true" style="top: 0; right: 0; font-size: 13px; float: right; position: relative;"><i class="fas fa-pen"></i></span>
                            </div>
                            <form v-on:submit.prevent="editCategory(category); category.edit=false" v-else>
                                <div class="row">
                                    <div class="col-11"></div>
                                    <div class="col-1">
                                        <button type="submit" class="edit-button edit-button--small" style="top: 0; right: 0; font-size: 13px; float: right; position: relative; margin-bottom: 10px"><i class="fas fa-save"></i></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-field" :class="{focus:category.dutch_name.length}">
                                            <label>{{"Naam (NL)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.dutch_name" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-field" :class="{focus:category.english_name.length}">
                                            <label>{{"Naam (EN)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.english_name" >
                                        </div>  
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.ukrainian_name ? category.ukrainian_name.length : 0)}">
                                            <label>{{"Naam (UKR)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.ukrainian_name" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-field" :class="{focus: (category.russian_name ? category.russian_name.length : 0)}">
                                            <label>{{"Naam (RU)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.russian_name" >
                                        </div>
                                    </div>
                                </div>  
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.polish_name ? category.polish_name.length : 0)}">
                                            <label>{{"Naam (PL)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.polish_name" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.italian_name ? category.italian_name.length : 0)}">
                                            <label>{{"Naam (IT)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.italian_name" >
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.german_name ? category.german_name.length : 0)}">
                                            <label>{{"Naam (DE)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.german_name" >
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-field " :class="{focus: (category.french_name ? category.french_name.length : 0)}">
                                            <label>{{"Naam (FR)"}}</label>
                                            <input type="text" ref="name" class="form-control" v-model="category.french_name" >
                                        </div>
                                    </div>
                                </div>
                                <div style="padding-left:5px; font-weight: 400;">Specialismes</div>
                                <div class="row">
                                    <div class="col-6">
                                        <div v-for="(specialism, index) in getSpecialisms(category.id_default_incident_category)" :key="index" style="padding-left: 15px; ">
                                            <span class="active-label" style="top: 0;background: grey; font-size: 13px; float: left; position: relative; margin-right: 5px;">{{ specialism }} 
                                                <span @click="removeSpecialism(index, category)">X</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style="padding-left:5px; padding-top: 10px; font-weight: 400;">Selecteer een specialisme</div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="dropdown-section">
                                            <span class="dropdown" v-on:click="specialismsDropwdownToggle = !specialismsDropwdownToggle" :class="{active: specialismsDropwdownToggle}">
                                                <span class="selected" v-if="!selectedSpeclialism.name">Geen</span>
                                                <span class="selected" v-else>{{selectedSpeclialism.name}}</span>
                                                <i class="fas fa-chevron-down chevron"></i>
                                            </span>
                                            <div class="dropdown-list" v-if="specialismsDropwdownToggle">
                                                <ul>
                                                    <li v-for="(specialism, index) in specialisms" v-on:click="selectSpecialism(index)" :key="index">
                                                        {{specialism.name}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </form>
                        </div>
                    </div>   
                </div>
            </div>    
        </div>
        <NewDefaultCategoryModal v-if="newDefaultCategoryModalOpen" @close="loadCategories()" @load="loadCategories" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import NewDefaultCategoryModal from '../modals/NewDefaultCategoryModal.vue';

export default {
    mixins: [setLocale],
    components: {
        NewDefaultCategoryModal
    },
    data() {
        return {
            searchTerm: '',
            searchSuggestions: [],
            categories: [],
            categoriesShown: [],
            searchActive: false,
            specialisms: [],
            specialismsDropwdownToggle: false,
            selectedSpeclialism: {},
            newDefaultCategoryModalOpen: false
        }
    },
    created() {
        document.title = 'S1MONE | Default Categories';
        this.loadCategories();
        this.laodSpecialisms();
    },
    methods: {
        loadCategories() {
            this.newDefaultCategoryModalOpen = false;
            let self = this;
            this.categories = [];
            this.categoriesShown = [];
            fetch('/api/v1/defaultincidentcategory')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let category = data.data[i];
                        category.edit = false;
                        self.categories.push(category);
                        self.categoriesShown.push(category);
                    }
                });
            });
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.categoriesShown.length; i++) {
                    let category = this.categoriesShown[i];
                    let categoryName = category.dutch_name.toLowerCase();
                    if (categoryName.includes(searchTerm)) {
                        this.searchSuggestions.push(category);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        laodSpecialisms(){
            let self = this;
            fetch('/api/v1/specialism')
            .then(response => { response.json().then(
                function(data) {
                    self.specialisms = data.data;
                });
            });
        },
        getSpecialisms(categoryID){
            const category = this.categories.find(cat => cat.id_default_incident_category === categoryID);
            if (category && category.specialisms && Object.keys(category.specialisms).length > 0) {
                return category.specialisms;
            } else {
                return {}; // Return an empty object if no specialisms are found
            }
        },
        selectSpecialism(index){
            this.specialismsDropwdownToggle = false;
            this.selectedSpeclialism = this.specialisms[index];
        },
        editSpecialisms(category){
            const data = new URLSearchParams();
            data.append('id_specialism', this.selectedSpeclialism.id_specialism);
            data.append('id_default_incident_category', category.id_default_incident_category);
            fetch('/api/v1/specialism/adddefaultincidentcategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.selectedSpeclialism = {};
            });
        },
        removeSpecialism(index, category){
            let self = this;
            const data = new URLSearchParams();
            data.append('id_specialism', index);
            data.append('id_default_incident_category', category.id_default_incident_category);
            fetch('/api/v1/specialism/deletedefaultincidentcategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                category.edit = false;
                self.loadCategories();
            });

        },
        editCategory(category){
            let self = this;
            if(self.selectedSpeclialism.id_specialism){
                self.editSpecialisms(category);
            }
            const data = new URLSearchParams();
            data.append('action', 'edit');
            for (const key in category) {
                if (key.endsWith('_name')) {
                    const value = category[key];
                    data.append('data['+category.id_default_incident_category+'][' + key + ']', value);
                }
            }
            fetch('/api/v1/defaultincidentcategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.loadCategories();
            });
        }
        // selectCategory(id) {
        //     this.$router.push('/s1mone/buildingcategory-detail?id=' + id);
        // }
    }
}
</script>
