<template>
    <div class="modal-wrapper" v-if="profile.incidents">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>{{profile.incidents.new_incident.title}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner" id="inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createNewIncident()" style="overflow: hidden;">
                    <h5>{{profile.accountPage.account.general}}</h5>
                    <div class="row">
                        <div class="col">
                            <div class="dropdown-section">
                                <span class="dropdown" data-testid="selectCategory" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled, error: categoryError, valid: selectedCategory.dutch_name}">
                                    <span v-if="!selectedCategory.id_incident_category">{{profile.incident_detail.category}} *</span>
                                    <span v-if="selectedCategory.id_incident_category && locale === 'nl'" class="selected">{{selectedCategory.dutch_name}}</span>
                                    <span v-if="selectedCategory.id_incident_category && locale === 'en'" class="selected">{{selectedCategory.english_name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i></span>
                                <div class="dropdown-list" v-if="categoryDropdownToggled" :class="{search: categories.length > 9}">
                                    <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" :placeholder="profile.incident_detail.search_category" @keyup="searchCategories()" v-if="categories.length > 9"  />
                                    <ul>
                                        <li v-for="category in categories" v-on:click="selectCategory(category)" :key="category.id_category" v-show="!tempCategories.length && !categoriesNoResults">
                                            <span data-testid="selectCategoryResultNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span data-testid="selectCategoryResultEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                        </li>
                                        <li v-for="(category, index) in tempCategories" v-on:click="selectCategory(category)" :key="index" v-show="tempCategories.length">
                                            <span data-testid="selectCategoryResultIndexNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span data-testid="selectCategoryResultIndexEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                        </li>
                                        <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-field" :class="{focus:fields.descr.length, error: descriptionError, valid: fields.descr.length}">
                        <label>{{profile.incidents.new_incident.description}} *</label>
                        <textarea class="form-control" v-model="fields.descr" data-testid="inputDescription" @input="checkDescr()"></textarea>
                    </div>
                    <div class="form-field-upload">
                        <input type="file" data-testid="inputFile" @change="fileChange" multiple />
                    </div>
                    <div class="switch-wrapper switch-wrapper--full" v-if="recording && recording.id_planned_action" :class="{active: fields.archive}">
                        <div class="inner" @click.stop="fields.archive = !fields.archive">
                            <i class="fa-regular fa-box-archive"></i>
                            <p>{{profile.actionable.archive_recording}}</p>
                            <span>{{profile.switches.archive_recording}}</span>
                            <label class="switch">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div> 
                    <span class="notification notification--error" v-if="uploadError" style="margin-bottom: 10px;">{{profile.accountPage.account.upload_error}}</span>
                    <button type="submit" class="button button--icon" data-testid="buttonSubmit" id="incidentSubmit" v-if="!incidentCreate"><i class="fa-solid fa-plus"></i>{{profile.dashboard.building_manager.create_incident}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    props: ['recording'],
    data() {
        return {
            profile: {},
            fields: {
                category: null,
                descr: '',
                file: [],
                archive: true
            },
            uploadError: false,
            categorySearchTerm: '',
            tempCategories: [],
            categories: [],
            categoryError: false,
            categoryDropdownToggled: false,
            selectedCategory: {},
            incidentCreate: false,
            descriptionError: false,
            categoriesNoResults: false
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getCategories();
    },
    methods: {
        getCategories() {
            let self = this;
            fetch('/api/v1/asset/byid?id_asset='+this.recording.id_asset)
            .then(response => { response.json().then(
                function(data) {
                    self.categories = data.categories;
                    self.categories = uniqBy(self.categories, 'id_incident_category');
                    for (var i = 0; i <self.categories.length; i++) {
                        let category = self.categories[i];
                        category.dutch_name = category.dutch_name.split('*')[0];
                        category.english_name = category.english_name.split('*')[0];
                        if (category.dutch_name.includes('-2') || category.dutch_name.includes('-3')|| category.dutch_name.includes('-4')) {
                            self.categories.splice(i, 1);
                        }
                    }
                    if (self.locale === 'nl') {
                        self.categories = sortBy(self.categories, 'dutch_name');
                    }
                    else {
                        self.categories = sortBy(self.categories, 'english_name');
                    }
                });
            });
        },
        searchCategories(type) {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i];
                    let categoryName;
                    if (this.locale === 'en') {
                        categoryName = category.english_name.toLowerCase();
                    }
                    else {
                        categoryName = category.dutch_name.toLowerCase();
                    }
                    if (categoryName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        selectCategory(category) {
            this.categoryDropdownToggled = false;
            this.selectedCategory = category;
            this.tempCategories = [];
            this.categorySearchTerm = '';
            this.categoryError = false;
        },
        createNewIncident() {
            const data = new URLSearchParams();
            data.append('qr_code', this.recording.qr_code);
            data.append('id_incident_category', this.selectedCategory.id_incident_category);
            if (this.recording && this.recording.id_planned_action) {
                data.append('id_planned_action', this.recording.id_planned_action);
                data.append('archive_planned_action', this.fields.archive);
            }
            data.append('description', this.fields.descr);
            if (!this.fields.descr) {
                this.descriptionError = true;
            }
            if (!this.selectedCategory.id_incident_category) {
                this.categoryError = true;
            }
            if (this.fields.descr && this.selectedCategory.id_incident_category) {
                this.incidentCreate = true;
                let self = this;
                fetch('/api/v1/incident/createsimple', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Aanmaken melding verrichting',
                    });
                    document.querySelector('body').classList.remove('modal-open');
                    if (self.fields.file.length) {
                        self.uploadMedia(data.id_incident);
                    }
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('success');
                });
            }
        },
        uploadMedia(id) {
            this.loading = true;
            this.uploadError = false;
            this.numberOfFiles = this.fields.file.length;
            let self = this;
            for (var i = 0; i < this.fields.file.length; i++) {
                let file = this.fields.file[i];
                this.numberOfFiles = i;
                if (file.type === 'video/mp4' || file.type === 'video/3gp' || file.type === 'video/ts' || file.type === 'video/mkv' || file.type === 'video/mov' || file.type === 'video/quicktime' || file.type === 'video/avi') {
                    const data = new FormData();
                    data.append('file', file);
                    data.append('filename', file.name);
                    data.append('id_incident', id);
                    fetch('/api/v1/fileupload/uploadincidentvideo', {
                        method: 'post',
                        body: data
                    }).then((resp) => {
                        if (!resp.ok) {
                            self.uploadError = true;
                        }
                        return resp.json();
                    }).then(function(data) {  
                    });
                }
                if (file.type === 'image/bmp' ||
                    file.type === 'image/eps' ||
                    file.type === 'image/gif' ||
                    file.type === 'image/ico' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/pcx' ||
                    file.type === 'image/png' ||
                    file.type === 'image/raf' ||
                    file.type === 'image/tiff' ||
                    file.type === 'image/webp') {
                    const data = new FormData();
                    data.append('file', file);
                    data.append('id_incident', id);
                    fetch('/api/v1/fileupload/uploadincidentimage', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {  
                    });
                }
            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        checkDescr() {
            if (this.fields.descr) {
                this.descriptionError = false;
            }
            else {
                this.descriptionError = true;
            }
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        }
    }
}
</script>
