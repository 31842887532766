<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <router-link tag="a" to="/monteur" data-testid="tabBackButton" class="back-link"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</router-link>
                    </div>
                </div>
                <div class="tabs" style="margin-top: 0;">
                    <div class="tab" @click="currentTab = 'general'" :class="{active: currentTab === 'general'}">
                        <h3 data-testid="tabGeneralButton">{{profile.accountPage.account.general}}</h3>
                    </div>
                    <div class="tab" @click="selectAppointmentTab()" :class="{active: currentTab === 'appointment'}">
                        <h3 data-testid="tabAppointmentButton">{{profile.incident_detail.plan_appointment}}</h3>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'general'}">
                    <section class="incident-head">
                        <h1 class="incident_nr" data-testid="incidentIdText">{{incidentInfo.id_incident}}</h1>
                        <span class="status-bar">
                            <div v-html="profile['status_icons'][incidentInfo.status]"></div>
                            <span data-testid="incidentStatusText">{{profile['mechanic_statusses'][incidentInfo.status]}}</span>
                        </span>
                        <span class="status_reason" v-if="incidentInfo.pause_reason && incidentInfo.status === 'paused_mechanic'" style="float: left;"><b>{{profile.incident_detail.status_reason}}:</b> {{incidentInfo.pause_reason}}</span>
                        <div class="checkin-buttons" data-testid="incidentPlanButton" v-if="(incidentInfo.status === 'planned' || incidentInfo.status === 'paused_mechanic' || incidentInfo.status === 'on_location_mechanic') && incidentInfo.calendar_choice !== 'choose_latest_possible'">
                            <span class="button button--cta button--icon" data-testid="incidentCheckInButton" @click="checkIn()" v-if="incidentInfo.status === 'planned' || incidentInfo.status === 'paused_mechanic'" style="margin-right: 0;"><i class="fas fa-play"></i>Check in</span>
                            <span class="button button--cta button--icon" data-testid="incidentCheckOutButton" @click="mechanicCheckoutModalOpen = true" style="margin-right: 0;" v-if="incidentInfo.status === 'on_location_mechanic'"><i class="fas fa-stop"></i>Check out</span>
                            <span class="button button--icon" data-testid="incidentPauseButton" @click="mechanicPauseModalOpen = true" v-if="incidentInfo.status === 'on_location_mechanic'"><i class="fas fa-pause"></i>{{profile.incident_detail.paused_mechanic_alt}}</span>
                        </div>
                        <div class="checkin-buttons" v-if="incidentInfo.calendar_choice === 'choose_latest_possible'">
                            <span class="button button--cta button--icon" data-testid="incidentPlanLatestButton" @click="goToCreateAppointment()" style="margin-right: 0;"><i class="fas fa-calendar-day"></i>{{profile.incident_detail.create_appointment}}</span>
                        </div>
                    </section>
                    <section class="incident-contents">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="incident-info">
                                    <div class="incident-media mobile mechanic">
                                        <label>{{profile.incident_detail.imagery}}</label>
                                        <span class="edit-button edit-button--small icon" @click="uploadMediaModalOpen = true"><i class="fa-solid fa-plus"></i></span>
                                        <div class="media-wrapper" v-if="media.length || movs.length">
                                            <div class="media" v-for="(item, index) in media" :key="index" :class="{active: item.popover, video: item.content_type === 'video/mp4'}" v-tooltip.left="item.description">
                                                <img data-testid="mediaImagePlaceholderMobile" :src="item.file_url" @click="item.popover = true" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                                                <div class="iframe_overlay" v-if="item.i_frame_code" @click="item.popover = true" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                                                <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                                                <div class="video_overlay" @click="item.popover = true" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                                                <video data-testid="mediaVideoPlaceholderMobile" v-if="item.content_type === 'video/mp4'" @click="item.popover = true">
                                                    <source :src="item.file_url" type="video/mp4">
                                                </video>
                                                <span class="label" data-testid="mediaLabelTextMobile" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                                <span class="remove" data-testid="mediaDeleteButtonMobile" v-if="item.type === 'incident_mechanic'" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                                                <div class="media-pop-over">
                                                    <span class="close-popover" @click="closePopover()"><i class="fa fa-times"></i></span>
                                                    <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                                    <span class="media-counter">{{index + 1}} / {{media.length}}</span>
                                                    <img src="@/assets/icons/rotate.svg" class="rotate hidden-xs" @click="rotateMedia(item)" />
                                                    <figure class="zoom" :style="{ 'background-image': 'url(' + item.file_url + ')' }" @mousemove="zoom" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}">
                                                        <img data-testid="mediaImageOverlayMobile" @click="closePopover()" :src="item.file_url" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}" />
                                                    </figure>
                                                    <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: 40px;"></div>
                                                    <video data-testid="mediaVideoOverlayMobile" v-if="item.content_type === 'video/mp4'" :class="{rotated: item.rotate}" controls><source :src="item.file_url" type="video/mp4"></video>
                                                    <p v-if="item.description">{{item.description}}</p>
                                                    <span class="media-control left" v-if="index !== 0" @click="selectMedia('prev', index)"><i class="fa fa-chevron-left"></i></span>
                                                    <span class="media-control right" v-if="index + 1 !== media.length" @click="selectMedia('next', index)"><i class="fa fa-chevron-right"></i></span>
                                                </div>
                                            </div>
                                            <div class="media video mov" v-for="item in movs" :key="item.id_file_upload">
                                                <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                                <span class="placeholder"><i class="fas fa-file-video"></i></span>
                                                <a :href="item.file_url" class="video_overlay download" v-if="item.content_type === 'video/quicktime'"></a>
                                                <span class="remove" v-if="item.type === 'incident_mechanic'" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <label>{{profile.incident_detail.latest_appointment}}</label>
                                    <p data-testid="detailLatestTimeText" v-if="incidentInfo.calendar_choice === 'choose_latest_possible'">{{profile.incident_detail.choose_latest_possible}}:{{incidentInfo.dateTime}}</p>
                                    <p data-testid="detailTimeText" v-else>{{incidentInfo.dateTime}} - {{incidentInfo.dateTimeEnd}}</p>
                                    <label>{{profile.incident_detail.description}}</label>
                                    <p data-testid="detailDescriptionText">{{incidentInfo.description}}</p>
                                    <label>{{profile.incident_detail.rating_comment}}</label>
                                    <p data-testid="detailReporterText">{{incidentInfo.reporter_name}} |
                                        <a data-testid="detailEmailText" v-if="incidentInfo.reporter_email_address" :href="'mailto:' + incidentInfo.reporter_email_address"><i class="fas fa-at" v-tooltip.right="incidentInfo.reporter_email_address"></i></a>
                                        <span v-if="incidentInfo.reporter_email_address && incidentInfo.reporter_phone">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                                        <a data-testid="detailPhoneText" v-if="incidentInfo.reporter_phone" :href="'tel:' + incidentInfo.reporter_phone"><i class="fas fa-mobile-alt" v-tooltip.right="incidentInfo.reporter_phone"></i></a>
                                    </p>
                                    <label v-if="incidentInfo.reporter_address">{{ profile.employees.address }}</label>
                                    <p v-if="incidentInfo.reporter_address">{{ incidentInfo.reporter_address }} {{ incidentInfo.reporter_house_number }}, {{ incidentInfo.reporter_zipcode }}</p>
                                    <label>{{profile.incidents.incidenttable.repair_company}}</label>
                                    <p data-testid="detailCompanyText">{{incidentInfo.repair_company_name}}</p>
                                    <label>{{profile.incidents.incidenttable.building_manager}}</label>
                                    <p data-testid="detailManagerText">{{incidentInfo.building_manager_name}} ({{incidentInfo.assigned_to_name_building_manager}})</p>
                                    <label v-if="incidentInfo.extra_repair_company">{{profile.incident_detail.extra_info}} {{incidentInfo.building_manager_name}}</label>
                                    <p data-testid="detailOwnerText1" v-if="incidentInfo.extra_repair_company">{{incidentInfo.extra_repair_company}}</p>
                                    <label>{{profile.incident_detail.incident_owner}}</label>
                                    <p data-testid="detailOwnerText">{{incidentInfo.owner_name}}</p>
                                    <label>Object</label>
                                    <p style="margin-bottom: 5px;">{{asset.name}}</p>
                                    <span class="button button--small" style="display: inline-block; float: none; margin-bottom: 15px;" @click="toAsset()">{{ profile.assets.to_asset }}</span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="incident-media desktop mechanic">
                                    <label>{{profile.incident_detail.imagery}}</label>
                                    <span data-testid="mediaUploadButton" class="edit-button edit-button--small icon" @click="uploadMediaModalOpen = true" style="margin-top: 0;"><i class="fa-solid fa-plus"></i></span>
                                    <div class="media-wrapper" v-if="media.length">
                                        <div class="media media--small" v-for="(item, index) in media" :key="index" :class="{active: item.popover, video: item.content_type === 'video/mp4'}" v-tooltip.left="item.description">
                                            <img data-testid="mediaImagePlaceholder" :src="item.file_url" @click="item.popover = true" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                                            <div class="iframe_overlay" v-if="item.i_frame_code" @click="item.popover = true" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                                            <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                                            <div class="video_overlay" @click="item.popover = true" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                                            <video data-testid="mediaVideoPlaceholder" v-if="item.content_type === 'video/mp4'" @click="item.popover = true">
                                                <source :src="item.file_url" type="video/mp4">
                                            </video>
                                            <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                            <span class="remove" v-if="item.type === 'incident_mechanic'" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                                            <div class="media-pop-over">
                                                <span class="close-popover" @click="closePopover()"><i class="fa fa-times"></i></span>
                                                <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                                <span class="media-counter">{{index + 1}} / {{media.length}}</span>
                                                <img src="@/assets/icons/rotate.svg" class="rotate hidden-xs" @click="rotateMedia(item)" />
                                                <img data-testid="mediaImageOverlay" @click="closePopover()" :src="item.file_url" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}" />
                                                <video data-testid="mediaVideoOverlay" v-if="item.content_type === 'video/mp4'" :class="{rotated: item.rotate}" controls><source :src="item.file_url" type="video/mp4"></video>
                                                <p v-if="item.description">{{item.description}}</p>
                                                <span class="media-control left" v-if="index !== 0" @click="selectMedia('prev', index)"><i class="fa fa-chevron-left"></i></span>
                                                <span class="media-control right" v-if="index + 1 !== media.length" @click="selectMedia('next', index)"><i class="fa fa-chevron-right"></i></span>
                                            </div>
                                            <div class="timestamp">{{ item.date }}</div>
                                        </div>
                                        <div class="media video mov" v-for="item in movs" :key="item.id_file_upload">
                                            <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                            <span class="placeholder"><i class="fas fa-file-video"></i></span>
                                            <a :href="item.file_url" class="video_overlay download" v-if="item.content_type === 'video/quicktime'"></a>
                                            <div class="timestamp">{{ item.date }}</div>
                                            <span class="remove" v-if="item.type === 'incident_mechanic'" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="incident-docs">
                                    <label>{{profile.incident_detail.documents}}</label>
                                    <span class="edit-button edit-button--small icon" style="margin-top: 0;" data-testid="documentUploadButton" @click="uploadMediaModalOpen = true"><i class="fa-solid fa-plus"></i></span>
                                    <ul v-if="docs.length">
                                        <li v-for="(item, index) in docs" :key="index">
                                            <div class="timestamp">{{ item.date }}</div>
                                            <a :href="item.file_url" data-testid="documentNameText" target="blank" v-if="item.description"><i class="fas fa-file-pdf"></i>{{item.description}}</a>
                                            <a :href="item.file_url" data-testid="documentNameText" target="blank" v-else><i class="fas fa-file-pdf"></i>{{item.filename}}</a>
                                            <span class="remove" data-testid="documentRemoveButton" v-if="item.type === 'incident_mechanic'" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="block block--border" style="margin-top: 20px;">
                        <section class="incident-head" style="margin-bottom: 10px;">
                            <h1 style="float: left; margin-top: 10px; font-size: 21px;">{{profile.assets.assetmodal.place}}</h1>
                        </section>
                        <div class="row">
                            <div class="col">
                                <a :href="mapsUrl" target="_blank" style="margin-bottom: 10px;">
                                    <img :src="mapsApiUrl" id="address-map" />
                                </a>
                            </div>
                        </div>
                        <p data-testid="locationAddressText" style="margin-bottom: 5px;">
                            {{asset.name}}<br />
                            {{incidentInfo.address}} {{incidentInfo.house_number}}<br />
                            {{incidentInfo.zipcode}} {{incidentInfo.city}}<br />
                            {{profile.incident_detail.floor_name}}: {{incidentInfo.floor_name}}
                        </p>    
                        <div class="row">
                            <div class="col">
                                <span class="button button--neutral" data-testid="locationHistoryButton" @click="historyModalOpen = true" style="float: left; width: auto;"><i class="fas fa-history"></i>{{profile.incident_detail.asset_history}}</span>
                            </div>
                        </div>                   
                    </div>  
                </div>
                <div class="tab-view" :class="{active: currentTab === 'appointment'}">
                    <div class="checkin-buttons" data-testid="incidentPlanPausedButton" style="float: right; margin-bottom: 20px;" v-if="(incidentInfo.status === 'planned' || incidentInfo.status === 'paused_mechanic' || incidentInfo.status === 'on_location_mechanic') && incidentInfo.calendar_choice !== 'choose_latest_possible'">
                        <span class="button button--cta button--icon" data-testid="incidentCheckInButton" @click="checkIn()" v-if="incidentInfo.status === 'planned' || incidentInfo.status === 'paused_mechanic'" style="margin-right: 0;"><i class="fas fa-play"></i>Check in</span>
                        <span class="button button--cta button--icon" data-testid="incidentCheckOutButton" @click="mechanicCheckoutModalOpen = true" style="margin-right: 0;" v-if="incidentInfo.status === 'on_location_mechanic'"><i class="fas fa-stop"></i>Check out</span>
                        <span class="button button--icon" data-testid="incidentPauseButton" @click="mechanicPauseModalOpen = true" v-if="incidentInfo.status === 'on_location_mechanic'"><i class="fas fa-pause"></i>{{profile.incident_detail.paused_mechanic_alt}}</span>
                    </div>
                    <Schedule   :incidentId="incidentId"
                                :incidentInfo="incidentInfo"
                                ref="scheduleRef"
                                @getIncidentInfo="getIncidentInfo()"
                                v-if="currentTab === 'appointment'"
                    />
                </div>
            </div>
        </div>
        <Chat :incidentId="incidentId" :incidentInfo="incidentInfo" @getIncidentInfo="getIncidentInfo()"  />
        <HistoryModal v-if="historyModalOpen" @close="historyModalOpen = false" :mechanicId="mechanicId" :incidentId="incidentId" />
        <UploadMediaModal v-if="uploadMediaModalOpen" @close="uploadMediaModalOpen = false" @success="getIncidentInfo()" :incidentId="incidentId" />
        <MechanicPauseModal v-if="mechanicPauseModalOpen" @close="mechanicPauseModalOpen = false" :incidentId="incidentId" @pause="getIncidentInfo()" />
        <MechanicCheckOutModal v-if="mechanicCheckoutModalOpen" @close="mechanicCheckoutModalOpen = false" :incidentId="incidentId" :incidentInfo="incidentInfo" :qrCode="qrCode" @checkout="getIncidentInfo()" />
        <RemoveFileModal v-if="removeFileModalOpen" @close="removeFileModalOpen = false" :file="file" @success="getIncidentInfo()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { mapState } from 'vuex';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import HistoryModal from "@/components/mechanic/modals/HistoryModal.vue";
import UploadMediaModal from "@/components/mechanic/modals/UploadMediaModal.vue";
import MechanicPauseModal from "@/components/mechanic/modals/MechanicPauseModal.vue";
import MechanicCheckOutModal from "@/components/mechanic/modals/MechanicCheckOutModal.vue";
import RemoveFileModal from "@/components/mechanic/modals/RemoveFileModal.vue";
import Chat from '@/components/mechanic/Chat.vue';
import Schedule from '@/components/mechanic/Schedule.vue';

export default {
    mixins: [setLocale],
    components: {
        HistoryModal,
        UploadMediaModal,
        MechanicPauseModal,
        MechanicCheckOutModal,
        RemoveFileModal,
        Schedule,
        Chat
    },
    data() {
        return {
            profile: {},
            currentTab: 'general',
            incidentId: '',
            incidentInfo: {},
            asset: {},
            media: [],
            movs: [],
            docs: [],
            mapsUrl: '',
            mapsApiUrl: '',
            historyModalOpen: false,
            uploadMediaModalOpen: false,
            mechanicPauseModalOpen: false,
            mechanicCheckoutModalOpen: false,
            removeFileModalOpen: false,
            mechanicId: 0,
            qrCode: null,
            historyList: []
        }
    },
    computed: mapState(['mapsKey']),
    created() {
        let queryString = window.location.search;
        this.incidentId = queryString.split('=')[1];
        this.getIncidentInfo();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getIncidentInfo() {
            this.mechanicCheckoutModalOpen = false;
            this.mechanicPauseModalOpen = false;
            this.removeFileModalOpen = false;
            this.media = [];
            this.movs = [];
            this.docs = [];
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/idincident?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    self.qrCode = data.asset.qr_code;
                    self.mechanicId = data.appointment.id_mechanic;
                    self.incidentId = data.appointment.id_incident;
                    self.asset = data.asset;
                    document.title = 'S1MONE | ' + self.incidentId;
                    if (data.appointment.calendar_choice === 'choose_latest_possible') {
                        data.appointment.dateTime = moment(data.appointment.start_time).locale('nl').format('D MMMM YYYY');
                    }
                    else {
                        data.appointment.dateTime = moment(data.appointment.start_time).locale('nl').format('D MMMM YYYY, HH:mm');
                        data.appointment.dateTimeEnd = moment(data.appointment.end_time).locale('nl').format('HH:mm');
                    }
                    self.incidentInfo = data.appointment;
                    if (self.incidentInfo.floor_name === 'VVEVEST') {
                        if (self.locale === 'nl') {
                            self.incidentInfo.floor_name = 'Niet van toepassing'
                        }
                        if (self.locale === 'en') {
                            self.incidentInfo.floor_name = 'Not applicable'
                        }
                    }
                    self.incidentInfo.pictures = data.pictures;
                    let fullAddress = data.appointment.address + ' ' + data.appointment.house_number;
                    let gmapsAddress    = fullAddress.replace(/ /g , "+")+"+"+data.appointment.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=250x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    for (var i = 0; i < data.pictures.length; i++) {
                        let media = data.pictures[i];
                        if (self.locale === 'nl') {
                            media.date = moment(media.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                        }
                        else {
                            media.date = moment(media.created).format('MMM D YYYY HH:mm:ss');
                        }
                        if ((!media.file_url.includes('.pdf') && media.content_type.toLowerCase() !== 'application/pdf') && media.content_type !== 'video/quicktime') {
                            media.popover = false;
                            media.rotate = 1;
                            if (media.type === 'incident_reporter') {
                                media.order = 1;
                            }
                            if (media.type === 'incident_building_manager') {
                                media.order = 2;
                            }
                            if (media.type === 'incident_mechanic') {
                                media.order = 3;
                            }
                            media.selected = false;
                            if (!media.content_type) {
                                media.content_type = 'image/jpeg';
                            }
                            self.media.push(media);
                            self.media = sortBy(self.media, 'order');
                        }
                        if (media.file_url.includes('.pdf') || media.content_type === 'application/pdf') {
                            let n = media.file_url.lastIndexOf('/');
                            let fileName = media.file_url.substring(n + 1);
                            media.pdf = true;
                            media.filename = fileName;
                            media.selected = false;
                            if (media.type !== 'order_request_supplier') {
                                self.docs.push(media);
                            }
                        }
                        if (media.content_type === 'video/quicktime') {
                            self.movs.push(media);
                        }
                    }
                });
            });
        },
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        closePopover() {
            for (var i = 0; i < this.media.length; i++) {
                this.media[i].popover = false;
            }
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        },
        selectFileToRemove(item) {
            this.removeFileModalOpen = true;
            this.file = item;
        },
        selectAppointmentTab() {
            this.currentTab = 'appointment';
        },      
        goToCreateAppointment() {
            this.currentTab = 'appointment';
            localStorage.setItem('S1-CreateAppointment', true);
        },
        zoom(e){
            let offsetX;
            let offsetY;
            let x;
            let y;
            let zoomer = e.currentTarget;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        },
        checkIn() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('checkin', true);
            data.append('checkout', false);
            data.append('qr_code', this.qrCode);
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/checkincheckout', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getIncidentInfo();
            });
        },
        toAsset() {
            this.$router.push('/monteur/asset-detail?id_incident=' + this.incidentId);
        }
    }
}
</script>
