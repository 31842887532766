import { EventBus } from '@/js/event-bus.js';

export const setLocale = {
    data() {
        return {
            profile: {},
            locale: ''
        }
    },
    created() {
        this.locale = localStorage.getItem('S1-locale');
        if (!this.locale) {
            this.locale = 'nl';
        }
        if (window.location.href.includes('login=true')) {
            if (window.location.href.includes('locale')) {
                if (this.getUrlParameter('locale') === 'en') {
                    this.locale = 'en';
                    localStorage.setItem('S1-locale', 'en');
                }
            }
        }
        if (window.location.href.includes('login=true')) {
            if (!window.location.href.includes('locale')) {
                this.locale = 'nl';
                localStorage.setItem('S1-locale', 'nl');
            }
        }
        if (this.locale) {
            this.profile = require('../assets/locales/' + this.locale + '/housing.json');
        }

        EventBus.$on('langChange', busObj => {
            this.locale = busObj.locale;
            this.profile = {};
            this.profile = require('../assets/locales/' + busObj.locale + '/housing.json');
        });
    },
    methods: {
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        }
    }
}
