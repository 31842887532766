<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Telefoonnummer reset</h1>
                            </section>             
                            <div style="margin-top: 15px; margin-bottom: 20px;">
                                <span>Nadat je het telefoonnummer en de beheerder heeft ingevuld, kan je de resultaten lijst bekijken.<br></span>
                                <span>Druk op Submit na het kijken van de resultaten lijst.<br></span>
                                <span>Dit zet de status van alle meldingen met deze telefoonnummer/beheerder combinatie op "geannuleerd".<br></span>
                                <span>Meldingen die als status "geannuleerd" of "gesloten" hebben, zullen niet veranderen.</span>
                            </div>           
                            <form v-on:submit.prevent="resetNumber()">
                                <div class="row">
                                    <div class="col-6">
                                        <vue-tel-input v-model="phoneNumber" class="form-control" v-bind="bindProps" :class="{valid: validPhone, error: invalidPhone}" @input="validateMobile"></vue-tel-input>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="dropdown-section">
                                            <span class="dropdown" v-on:click="managerDropdownToggle = !managerDropdownToggle" :class="{active: managerDropdownToggle}">
                                                <span v-if="!selectedManager.id_building_manager">Kies een beheerder</span>
                                                <span v-if="selectedManager.id_building_manager" class="selected">{{selectedManager.name}} ({{ selectedManager.count }})</span>
                                                <i class="fas fa-chevron-down chevron"></i>
                                            </span>
                                            <div class="dropdown-list" v-if="managerDropdownToggle" :class="{search: managers.length > 9}">
                                                <ul>
                                                    <li v-on:click="deselectManager()"><br></li>
                                                    <li v-for="manager in managers" v-on:click="selectManager(manager)" :key="manager.id_building_manager">
                                                        {{manager.name}} ({{ manager.count }})
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                                <div style="margin-top:7px">
                                    <span style="font-weight: 500;">Resultaten lijst</span>
                                    <table v-if="incidentsShown" style="overflow: auto;">
                                        <colgroup>
                                            <col style="width: 10%">
                                            <col style="width: 21%">
                                            <col style="width: 25%">
                                            <col style="width: 50%">
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th style="font-weight: 500;">ID</th>
                                                <th style="font-weight: 500;">Status</th>
                                                <th style="font-weight: 500;">Datum aangemaakt</th>
                                                <th style="font-weight: 500;">Beschrijving</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="incident in incidents" :key="incident.id_incident">
                                                <td>{{incident.id_incident}}</td>
                                                <td>{{incident.status}}</td>
                                                <td>{{toDate(incident.created)}}</td>
                                                <td>{{incident.description}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <button type="submit" style="margin-top: 15px;"> Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
import { VueTelInput } from 'vue-tel-input';

export default {
    mixins: [setLocale],
    components: {
        VueTelInput
    },
    data() {
        return {
            managers: [],
            incidents: [],
            phoneNumber: '',
            validPhone: false,
            invalidPhone: false,
            formattedPhone: '',
            formattedPhone2: '',
            managerDropdownToggle: false,
            incidentsShown: false,
            selectedManager: {},
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder:'',
                }
            }
        }
    },
    created() {
        document.title = 'S1MONE | Number reset';
        this.bindProps.inputOptions.placeholder = "+31 6 12345678";
    },
    methods: {
        resetNumber(){
            let self = this;
            const data = new URLSearchParams();
            data.append('reporter_phone', this.formattedPhone2);
            data.append('id_building_manager', this.selectedManager.id_building_manager);
            fetch('/api/v1/incident/cancelincidents', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.findManagers();
                self.findIncidents();
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });
        },
        findManagers(){
            let self = this;
            fetch('/api/v1/incident/findopenwaincidents?reporter_phone=%2B'+this.formattedPhone)
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data;
                });
            });
        },
        selectManager(manager) {
            this.managerDropdownToggle = false;
            this.selectedManager = manager;
            this.findIncidents();
        },
        deselectManager() {
            this.managerDropdownToggle = false;
            this.selectedManager = {};
            this.incidentsShown = false;
        },
        findIncidents(){
            let self = this;
            this.incidentsShown = true;
            fetch('/api/v1/incident/findopenwaincidents?id_building_manager='+this.selectedManager.id_building_manager+'&reporter_phone=%2B'+this.formattedPhone)
            .then(response => { response.json().then(
                function(data) {
                    self.incidents = data;
                });
            });
        },
        toDate(date) {
            let options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            return new Date(date).toLocaleDateString('nl', options);
        },
        validateMobile: function (number, object) {
            if (object.formatted) {
                if (object.valid) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                    this.formattedPhone = number.replace(/\+/g, '').replace(/\s/g, '');
                    this.formattedPhone2 = number.replace(/\s/g, '');
                    this.findManagers();
                }
                else {
                    this.validPhone = false;
                    this.invalidPhone = true;
                }
            }
            else {
                this.validPhone = false;
                this.invalidPhone = false;
            }
        }
    }
}
</script>
