<template>
    <div>
        <div class="main-content" >
            <div class="container">
                <div class="block block--actions" style="border-radius: 20px;" v-if="!emptyAsset && !asset.name && admin">
                    <section class="incident-head">
                        <h1>{{profile.scanner.scan_qode}}</h1>
                    </section>
                    <p style="margin-top: 20px; font-size: 16px;">{{profile.scanner.scan_expla}}</p>
                    <span class="text-link" v-if="!readMore" style="float: none; margin: 10px 0;" @click="readMore = true">{{profile.scanner.read_more}} <i class="fas fa-chevron-down" style="font-size: 14px; text-decoration: underline;"></i></span>
                    <span class="text-link" v-if="readMore" style="float: none; margin: 10px 0;" @click="readMore = false">{{profile.scanner.read_less}} <i class="fas fa-chevron-up" style="font-size: 14px; text-decoration: underline;"></i></span>
                    <p style="margin-top: 20px; font-size: 16px;" v-if="readMore">{{profile.scanner.scan_expla_2}}</p>
                    <div class="scan-wrap" style="position: relative;">
                        <Scanner @scanned="getAsset" v-if="!asset.name && !cantScan" />
                        <span class="button button--right button--icon" style="position: absolute; top: 20px; right: 20px;" @click="scrollDown()" v-if="!cantScan"><i class="fas fa-camera"></i> {{profile.scanner.i_cant_scan}}</span>
                    </div>
                    <div class="row" v-if="cantScan">
                        <div class="col">
                            <p style="font-size: 16px; margin-top: 10px;">{{profile.scanner.cant_scan}} <span style="text-decoration: underline; color: #0867b3; cursor: pointer;" @click="cantScan = false;">{{profile.scanner.back_scanner}}</span>.</p>
                            <label>{{profile.scanner.asset_code}}</label>
                            <input type="text" class="form-control" placeholder="100004321" v-model="customAssetCode" />
                            <div class="notification notification--error" style="margin-top: 20px; float: left;" v-if="assetIdError">{{profile.scanner.fill_correct_code}}</div>
                            <span class="button button--right button--icon" style="margin: 20px 0 0 0; clear: both;" @click="getAsset(false)"><i class="fas fa-cube"></i>{{profile.incident_detail.continue}}</span>
                        </div>
                    </div>
                </div>
                <ChangeQr v-if="qrCode && !emptyAsset && asset.name" :qrCode="qrCode" :asset="asset" :locationInfo="locationInfo" :flow="flow" @reset="resetAll" />
                <ConnectQr v-if="emptyAsset" :qrCode="qrCode" :assetId="asset.id_asset" :flow="flow" :selected_place="selectedPlace" @reset="resetAll" />
            </div>
        </div>
        <div class="block block--actions" style="border-radius: 20px;" v-if="!admin">
            <p style="margin-top: 20px; font-size: 16px;">{{profile.scanner.no_admin_rights}}</p>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import Scanner from '@/components/qr/Scanner.vue';
import ConnectQr from '@/components/qr/ConnectQr.vue';
import ChangeQr from '@/components/qr/ChangeQr.vue';

export default {
    mixins: [setLocale],
    components: {
        Scanner,
        ConnectQr,
        ChangeQr
    },
    data() {
        return {
            profile: {},
            scanCode: false,
            qrCode: 0,
            generateCode: false,
            locationInfo: {},
            qrEdit: false,
            emptyAsset: false,
            asset: {},
            fields: {
                name: ''
            },
            flow: '',
            customAssetCode: '',
            cantScan: false,
            selectedPlace: {},
            admin: false,
            readMore: false,
            assetIdError: false
        }
    },
    created() {
        let self = this;
        fetch('/api/v1/userandbuildingmanager')
        .then(response => { response.json().then(
            function(data) {
                self.admin = data.building_manager_admin;
            });
        });
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAsset(object, id) {
            let assetId = '';
            if (object) {
                this.qrCode = object.qr_code;
                assetId = object.id_asset
            }
            else {
                assetId = this.customAssetCode;
            }
            let self = this;
            fetch('/api/v1/asset/byid?id_asset=' + assetId).then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((data) => {
                if (data) {
                    self.asset = data;
                    if (!self.asset.name && !self.asset.id_place) {
                        self.emptyAsset = true;
                        self.$vToastify.success('Nieuwe QR Code', 'Nieuwe QR code gevonden');
                    }
                    else {
                        self.$vToastify.success('Bestaande QR Code', 'Bestaande QR code gevonden');
                        self.getLocationInfo();
                    }
                    if (!self.qrCode) {
                        self.qrCode = data.qr_code;
                    }
                }
                else {
                    self.assetIdError = true;
                }
            })
            .catch((error) => {
                self.assetIdError = true;
            });
        },
        scrollDown() {
            this.cantScan = true;
            setTimeout(function () {
                window.scrollTo(0,document.body.scrollHeight);
            }.bind(this), 200);
        },
        getLocationInfo() {
            let self = this;
            fetch('/api/v1/place/byid?id_place=' + this.asset.id_place)
            .then(response => { response.json().then(
                function(data) {
                    self.locationInfo = data;
                    self.locationInfo.fullLocation = self.locationInfo.name + ', ' + self.locationInfo.address + ' ' + self.locationInfo.house_number + ', ' + self.locationInfo.city;
                });
            });
        },
        saveAsset() {
            const data = new URLSearchParams();
            data.append('qr_code', this.asset.qr_code);
            data.append('id_asset', this.asset.id_asset);
            data.append('id_place', this.selectedPlace.id_place);
            data.append('holder', true);
            data.append('name', this.fields.name);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.newAsset = false;
                self.emptyAsset = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                let object = {
                    'id_asset': self.asset.id_asset,
                    'qr_code': self.asset.qr_code
                }
                self.getAsset(object);
            });
        },
        resetAll(flowObject) {
            this.asset = {};
            this.assetIdError = false;
            this.emptyAsset = false;
            this.customAssetCode = '';
            this.qrCode = 0;
            if (flowObject) {
                this.flow = flowObject.type;
                this.selectedPlace = flowObject.selectedPlace;
            }
            else {
                this.flow = '';
            }
        }
    }
}
</script>
