<template>
    <div class="modal-wrapper" v-if="profile.incidents">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa fa-exclamation"></i>{{profile.mandates.mandate_violation}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner" id="inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="uploadMedia()" style="overflow: hidden;">
                    <label>{{profile.mandates.mandate_amount}}</label>
                    <p data-testid="violateAmountText">€ {{mandate.amount}} <span v-if="mandate.in_btw">({{profile.mandates.in_btw}})</span><span v-else>({{profile.mandates.out_btw}})</span></p>
                    <div class="row">
                        <div class="col">
                            <label style="margin-bottom: 10px;">Type</label>
                            <label class="radio-container" style="margin-bottom: 5px;">
                                <input data-testid="violateOfferRadio" type="radio" name="violationType" value="quotation_amount" id="quotation" v-model="fields.type">
                                <label for="quotation">{{profile.invoicing.quotation}}</label>
                            </label>
                            <label class="radio-container">
                                <input data-testid="violateTargetRadio" type="radio" name="violationType" value="target_price" id="price" v-model="fields.type">
                                <label for="price">{{profile.invoicing.target_price}}</label>
                            </label>
                        </div>
                    </div>   
                    <div class="form-field-upload" v-if="fields.type === 'quotation_amount'">
                        <label>{{ profile.invoicing.add_quotation }} *</label>
                        <input type="file" @change="fileChange" required />
                    </div>           
                    <div class="row">
                        <div class="col">
                            <label>{{profile.accountPage.account.amount}} *</label>
                            <input data-testid="violateAmountInput" type="text" class="form-control" v-model="fields.amount" @input="calculateViolationAmount()" required>
                        </div>
                    </div>
                    <div class="row" v-if="violationAmount">
                        <div class="col">
                            <label>{{ profile.mandates.violation_amount }} *</label>
                            <p data-testid="violateDifferenceText" style="margin-bottom: 10px;">{{ violationAmount }}</p>
                        </div>
                    </div>
                    <div class="row" v-if="fields.type === 'quotation_amount'">
                        <div class="col">
                            <label>{{profile.invoicing.quotation_number}} *</label>
                            <input data-testid="violateOfferInput" type="text" class="form-control" v-model="fields.quotationNumber" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.incident_detail.remark}}</label>
                            <textarea data-testid="violateCommentInput" class="form-control" v-model="fields.remark"></textarea>
                        </div>
                    </div>
                    <span class="notification notification--error" v-if="uploadError" style="margin-bottom: 10px;">{{profile.accountPage.account.upload_error}}</span>
                    <button type="submit" data-testid="violateSubmitButton" class="button button--icon" ><i class="fa fa-exclamation"></i>{{profile.mandates.submit_violation}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['incidentInfo', 'mandate'],
    data() {
        return {
            profile: {},
            fields: {
                type: 'quotation_amount',
                amount: null,
                quotationNumber: null,
                remark: '',
                file: null
            },
            violationAmount: null,
            uploadError: false
        }
    },
    created() {
        if (this.incidentInfo.quotation_number_supplier) {
            this.fields.quotationNumber = this.incidentInfo.quotation_number_supplier;
        }
        if (this.incidentInfo.quotation_value_supplier) {
            this.fields.amount = this.incidentInfo.quotation_value_supplier;
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        calculateViolationAmount() {
            this.fields.amount = this.fields.amount.replace(/[A-Za-z!@#$%^&*()]/g, '');
            let violationAmount = this.fields.amount - this.mandate.amount;
            if (violationAmount > 0) {
                this.violationAmount = violationAmount;
            }
            else {
                this.violationAmount = null;
            }
        },
        uploadMedia() {
            this.uploadError = false;
            if (this.fields.file) {
                for (var i = 0; i < this.fields.file.length; i++) {
                    let file = this.fields.file[i];
                    if (file.type === 'application/pdf') {
                        this.loading = true;
                        const data = new FormData();
                        let filename = 'offerte-' + this.fields.number + '.pdf';
                        data.append('file', file);
                        data.append('filename', filename);
                        data.append('id_incident', this.incidentInfo.id_incident);
                        data.append('filetype', 'quotation_pdf_supplier');
                        let self = this;
                        fetch('/api/v1/fileupload/uploadincidentpdf', {
                            method: 'post',
                            body: data
                        }).then((resp) => {return resp.json();
                        }).then(function(data) {
                            if (!data.url) {
                                self.uploadError = true;
                            }
                            else {
                                self.$store.dispatch('updateQuotationAdded', true);
                                self.violateIncident();
                            }
                        });
                    }
                    else {
                        this.uploadError = true;
                    }
                }
            }
            else {
                this.violateIncident();
            }
        },
        violateIncident() {
            this.fields.amount = this.fields.amount.replaceAll(',', '.');
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('quotation_value_supplier', this.fields.amount);
            data.append('mandate_warning', this.fields.type);
            data.append('quotation_number_supplier', this.fields.quotationNumber);
            data.append('comment', this.fields.remark);
            let self = this;
            fetch('/api/v1/order/suppliermandatewarning', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$router.push('/supplier');
            });
        }
    }
}
</script>
