<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa fa-times"></i>{{profile.accountPage.account.account_change}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <p>{{ profile.accountPage.account.account_change_text}}
                    <br />
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],    
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        setTimeout(function () {
            window.location = '/signin.html';
        }.bind(this), 3000) 
    }
}
</script>
