<template>
    <div>
        <div class="loading" v-if="loading">
            <img src="@/assets/icons/loading.gif" />
        </div>
        <div class="modal-wrapper" v-if="profile.assets"> 
            <div class="modal">
                <div class="modal-header">
                    <h3><i class="fas fa-briefcase"></i>{{profile.user_place.title}}</h3>
                    <img src="@/assets/img/logo-white.png" class="logo" />
                </div>
                <div class="modal-inner">
                    <span class="close" data-testid="portfolioClose" @click="closeModal()"><i class="fas fa-times"></i></span>
                    <p v-if="vve">{{profile.user_place.explain_vve}}</p>
                    <p v-else>{{profile.user_place.explain}}</p>
                    <br />
                    <div style="position: relative;">
                        <section class="search search-alt" v-if="owners.length">
                            <form @submit.prevent="searchSuggest()">
                                <input type="text" ref="search" v-bind:placeholder="profile.places.search_vve" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" style="margin-bottom: 5px;" v-if="vve" />
                                <input type="text" ref="search" v-bind:placeholder="profile.places.search_owner" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" style="margin-bottom: 5px;" class="no-margin" v-else />
                                <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                            </form>
                        </section>
                        <div class="row" style="margin-bottom: 15px;">
                            <div class="col">
                                <div class="filter-checkboxes">
                                    <div class="filter-checkbox-block">
                                        <div class="checkbox-indicator" @click="selectFilter()">
                                            <i class="far fa-check-square" v-if="filter"></i>
                                            <i class="far fa-square" v-else></i>
                                        </div>
                                        <label @click="selectFilter()">{{ profile.user_place.selected }}</label>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                        <div class="notification notification--warning" v-if="filter && !owners.length" style="float: none;">{{profile.user_place.none_selected}}</div>
                        <div class="button-wrap button-wrap--left" style="margin-bottom: 0;" v-if="!searchActive && owners.length">
                            <span class="button button--icon" @click="deselectAllOwners()" data-testid="portfolioDeSelect"><i class="far fa-square"></i><span v-if="vve" style="font-weight: 500;">{{profile.user_place.deselect_all_vve}}</span><span style="font-weight: 500;" v-else>{{profile.user_place.deselect_all}}</span></span>
                            <span class="button button--icon" @click="finish()" style="float: right;"><i class="fas fa-save"></i>{{ profile.buttons.save }}</span>
                        </div>
                        <div v-if="searchActive">
                            <h4 class="result-counter" v-if="searchSuggestions.length === 1" style="font-size: 16px;">{{searchSuggestions.length}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                            <h4 class="result-counter" v-if="!searchSuggestions.length" style="font-size: 16px;">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                            <h4 class="result-counter" v-if="searchSuggestions.length > 1" style="font-size: 16px;">{{searchSuggestions.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                            <div v-for="(owner, index) in searchSuggestions" :key="index" class="checkbox-row" @click="selectOwner(owner.id_owner)">
                                <div class="checkbox-indicator" @click="selectOwner(owner)" style="font-size: 18px;">
                                    <i class="far fa-square" v-if="!owner.selected"></i>
                                    <i class="far fa-check-square" v-if="owner.selected"></i>
                                </div>
                                <label @click="selectOwner(owner)" v-if="owner.name">{{owner.name}}</label>
                            </div>
                        </div>
                        <div v-else>
                            <div class="checkbox-row checkbox-row--block" v-for="owner in owners" :key="owner.id_owner">
                                <div class="checkbox-indicator" @click="selectOwner(owner)" style="font-size: 18px;">
                                    <i class="far fa-square" v-if="!owner.selected"></i>
                                    <i class="far fa-check-square" v-if="owner.selected"></i>
                                </div>
                                <label @click="selectOwner(owner)" v-if="owner.name">{{owner.name}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';
export default {
    mixins: [setLocale],
    name: 'portfolioModal',
    data() {
        return {
            profile: {},
            selectedOwners: [],
            allSelectedOwners: [],
            totalOwners: 0,
            owners: [],
            searchTerm: '',
            searchSuggestions: [],
            searchActive: false,
            loading: false,
            filter: false
        }
    },
    computed: mapState(['vve']),
    props: ['selectedUser'],
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getSelectedOwners();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        finish() {
            this.$vToastify.success(this.profile.toast.edited, this.profile.toast.changes_saved);
            this.closeModal();
        },
        getSelectedOwners() {
            let self = this;
            fetch('/api/v1/shirouserowner/byidshirouser?id_shiro_user=' + this.selectedUser.id_shiro_user)
            .then(response => { response.json().then(
                function(data) {
                    self.selectedOwners = data;
                    self.getOwners();
                });
            });
        },
        getOwners() {
            this.owners = [];
            this.allSelectedOwners = [];
            let self = this;
            fetch('/api/v1/owner')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let owner = data.data[i];
                        owner.selected = false;
                        for (var j = 0; j < self.selectedOwners.length; j++) {
                            let selectedOwner = self.selectedOwners[j];
                            if (owner.id_owner === selectedOwner.id_owner) {
                                owner.selected = true;
                            }
                        }
                    }
                    self.owners = data.data;
                    self.owners = sortBy(self.owners, 'name');
                });
            });
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.owners.length; i++) {
                    let owner = this.owners[i];
                    let ownerName = owner.name.toLowerCase();
                    if (ownerName.includes(searchTerm)) {
                        this.searchSuggestions.push(owner);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        selectOwner(owner) {
            owner.selected = !owner.selected;
            if (!owner.selected) {
                const data = new URLSearchParams();
                data.append('id_shiro_user', this.selectedUser.id_shiro_user);
                data.append('id_owner', owner.id_owner);
                let self = this;
                fetch('/api/v1/shirouserowner/delete', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    if (self.filter) {
                        for (var i = 0; i < self.allSelectedOwners.length; i++) {
                            let selectedOwner = self.allSelectedOwners[i];
                            if (selectedOwner.id_owner === owner.id_owner) {
                                self.allSelectedOwners.splice(i, 1);
                            }
                        }
                    }
                    
                });
            }
            else {
                const data = new URLSearchParams();
                data.append('id_shiro_user', this.selectedUser.id_shiro_user);
                data.append('id_owner', owner.id_owner);
                let self = this;
                fetch('/api/v1/shirouserowner/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        deselectAllOwners() {
            let selectedOwners = [];
            for (var i = 0; i < this.owners.length; i++) {
                let owner = this.owners[i];
                if (owner.selected) {
                    selectedOwners.push(owner);
                }
                owner.selected = false;
            }
            this.totalOwners = selectedOwners.length;
            if (selectedOwners.length) {
                this.loading = true;
                for (var i = 0; i < selectedOwners.length; i++) {
                    let owner = selectedOwners[i];
                    const data = new URLSearchParams();
                    data.append('id_shiro_user', this.selectedUser.id_shiro_user);
                    data.append('id_owner', owner.id_owner);
                    let self = this;
                    fetch('/api/v1/shirouserowner/delete', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.text();
                    }).then(function(data) {
                        self.totalOwners--;
                        if (self.totalOwners === 0) {
                            self.filter = false;
                            self.loading = false;
                            self.getSelectedOwners();
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);       
                        }
                    });
                }
            }
        },
        selectFilter(preselect) {
            this.filter = !this.filter;
            if (this.filter) {
                for (var i = 0; i < this.owners.length; i++) {
                    let owner = this.owners[i];
                    if (owner.selected) {
                        this.allSelectedOwners.push(owner);
                    }
                }
                this.owners = this.allSelectedOwners;
            }
            else {
                this.getSelectedOwners();
            }
        }
    }
}
</script>
