<template>
    <div class="modal-wrapper" v-if="profile.employees">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-trash-alt"></i>{{profile.employees.delete_employee}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.areyousurethat}} {{profile.disable}} <b>{{selectedUser.username}}</b> {{profile.wanttoremove}}<br /><br /></p>
                <button class="button button--icon button--right" data-testid="employeeSubmitDeactivateButton" v-on:click="disableEmployee()"><i class="fas fa-trash-alt"></i>{{profile.employees.delete_employee}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'disableEmployeeModal',
    props: ['selectedUser'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        disableEmployee() {
            const data = new URLSearchParams();
            data.append('action', 'remove');
            data.append('data[' + this.selectedUser.id_shiro_user + '][id_shiro_user]', this.selectedUser.id_shiro_user);
            data.append('data[' + this.selectedUser.id_shiro_user + '][username]', this.selectedUser.username);
            data.append('data[' + this.selectedUser.id_shiro_user + '][id_shiro_user]', this.selectedUser.mobile);
            data.append('data[' + this.selectedUser.id_shiro_user + '][actual_name]', this.selectedUser.actual_name);
            data.append('data[' + this.selectedUser.id_shiro_user + '][buildingmanager_employee]', this.selectedUser.buildingmanager_employee);
            data.append('data[' + this.selectedUser.id_shiro_user + '][buildingmanager_administrator]', this.selectedUser.buildingmanager_administrator);
            let self = this;
            fetch('/api/v1/buildingmanageremployee', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
