<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-sticky-note"></i>{{profile.incident_detail.see_note}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fa fa-times"></i></span>
                <form>     
                    <h4>{{profile.incident_detail.see_note}}</h4>
                    <span class="written-by" style="max-width: 275px; margin-bottom: 10px;"><i class="fas fa-feather"></i> {{note.actual_name}}</span>
                    <div class="form-field" :class="{focus:note.content.length}" style="clear: both;">
                        <p style="float: left; margin: 15px 0;">{{note.content}}</p>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {}
        }
    },
    props: ['note'],
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            const data = new URLSearchParams();
            data.append('id_incident_note', this.note.id_incident_note);
            data.append('id_shiro_user_receiver', this.note.incident_note_notification_id_shiro_user_receiver);
            data.append('incident_note_notification_incident_note_read', this.note.incident_note_notification_incident_note_read);
            let self = this;
            fetch('/api/v1/incident_note/setnotificationasread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });        
        }
    }
}
</script>
