<template>
    <div class="modal-wrapper" v-if="profile.assets">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-history"></i>{{profile.nav.incident_history}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div v-if="!loading">
                    <table class="table table-striped" v-if="historyList.length">
                        <thead>
                            <tr>
                                <th scope="col" @click="sortTable('id')">{{profile.incidents.incidenttable.id_incident}} <i class="fas fa-sort"></i></th>
                                <th scope="col" @click="sortTable('date')">{{profile.s1mone.stickertable.created}} <i class="fas fa-sort"></i></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in historyList" :key="index" @click="toIncident(item.id)">
                                <td data-testid="historyIncidentId" style="padding-left: 20px; color: #0867b3; text-decoration: underline;">{{item.id}}</td>
                                <td data-testid="historyIncidentDate" style="color: #0867b3; text-decoration: underline;">{{item.date}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    name: 'incidentHistoryModal',
    data() {
        return {
            profile: {},
            loading: false,
            sortOrder: 'asc'
        }
    },
    props: ['historyList'],
    created() {
        document.querySelector('body').classList.add('modal-open');
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.historyList = sortBy(this.historyList, sorter);
            if (this.sortOrder === 'desc') {
                this.historyList.reverse();
            }
        },
        toIncident(id) {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
            this.$router.push('/buildingmanager/incident-detail?id=' + id);
        }
    }
}
</script>
