<template>
    <div class="modal-wrapper" v-if="profile.owners">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>Nieuwe categorie</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="addCategory()">
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled}">
                            <span v-if="!selectedCategory.id_default_incident_category">{{profile.accountPage.account.autodispatch_category}} *</span>
                            <span v-if="selectedCategory.id_default_incident_category" class="selected">{{selectedCategory.dutch_name}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="categoryDropdownToggled" :class="{search: defaultCategories.length > 9}">
                            <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" placeholder="Zoek een categorie..." @keyup="searchCategories()" v-if="defaultCategories.length > 9"  />
                            <ul>
                                <li v-for="category in defaultCategories" v-on:click="selectCategory(category)" :key="category.id_default_incident_category" v-show="!tempCategories.length && !categoriesNoResults">
                                    {{category.dutch_name}}
                                </li>
                                <li v-for="(category, index) in tempCategories" v-on:click="selectCategory(category)" :key="index" v-show="tempCategories.length">{{category.dutch_name}}</li>
                                <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                            </ul>
                        </div>
                    </div>
                    <button type="submit" class="button button--cta button--icon"><i class="fa-solid fa-plus"></i> Categorie toevoegen</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['bmId', 'categories'],
    data() {
        return {
            profile: {},
            categoryDropdownToggled: false,
            defaultCategories: [],
            tempCategories: [],
            selectedCategory: {},
            categoriesNoResults: false,
            categorySearchTerm: '',
            fields: {
                dutch_name: '',
                english_name: '',
                ukrainian_name: '',
                russian_name: '',
                polish_name: '',
                italian_name: '',
                german_name: '',
                french_name: ''
            }
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getDefaultCategories();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getDefaultCategories() {
            let self = this;
            fetch('/api/v1/defaultincidentcategory')
            .then(response => { response.json().then(
                function(data) {
                    self.defaultCategories = data.data.filter(item1 => !self.categories.some(item2 => item2.id_default_incident_category === item1.id_default_incident_category));
                });
            });
        },
        selectCategory(category) {
            this.categoryDropdownToggled = false;
            this.selectedCategory = category;
            this.tempCategories = [];
            this.categorySearchTerm = '';
        },
        searchCategories() {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.defaultCategories.length; i++) {
                    let category = this.defaultCategories[i];
                    let categoryName = category.dutch_name.toLowerCase();
                    if (categoryName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        addCategory() {
            const data = new URLSearchParams();
            data.append('id_building_manager', this.bmId);
            data.append('id_default_incident_category', this.selectedCategory.id_default_incident_category);
            let self = this;
            fetch('/api/v1/incident_category/addincidentcategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('success');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>