<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>Nieuwe standaard categorie</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="addCategory()">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.dutch_name.length}">
                                <label>{{"Naam (NL)"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.dutch_name" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.english_name.length}">
                                <label>{{"Naam (EN)"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.english_name" >
                            </div>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field " :class="{focus: (fields.ukrainian_name ? fields.ukrainian_name.length : 0)}">
                                <label>{{"Naam (UKR)"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.ukrainian_name" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus: (fields.russian_name ? fields.russian_name.length : 0)}">
                                <label>{{"Naam (RU)"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.russian_name" >
                            </div>
                        </div>
                    </div>  
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field " :class="{focus: (fields.polish_name ? fields.polish_name.length : 0)}">
                                <label>{{"Naam (PL)"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.polish_name" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field " :class="{focus: (fields.italian_name ? fields.italian_name.length : 0)}">
                                <label>{{"Naam (IT)"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.italian_name" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field " :class="{focus: (fields.german_name ? fields.german_name.length : 0)}">
                                <label>{{"Naam (DE)"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.german_name" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field " :class="{focus: (fields.french_name ? fields.french_name.length : 0)}">
                                <label>{{"Naam (FR)"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.french_name" >
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="button button--cta button--icon"><i class="fa-solid fa-plus"></i> Standaard categorie toevoegen</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            fields: {
                dutch_name: '',
                english_name: '',
                ukrainian_name: '',
                russian_name: '',
                polish_name: '',
                italian_name: '',
                german_name: '',
                french_name: ''
            }
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        addCategory() {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'create');
            for (let key in this.fields) {
                data.append('data[0]['+key+']', this.fields[key]);
            }
            fetch('/api/v1/defaultincidentcategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>