<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-handshake"></i>{{profile.incident_detail.agree_and_close}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p class="notification" v-if="connectedIncidents.length">{{profile.incident_detail.copied_and_open}}<br />
                    <span v-for="(item, index) in connectedIncidents" :key="index" @click="toIncident(item.id_incident)" class="connected-list-item"><i> | </i>{{item.id_incident}}</span>
                </p>
                <br v-if="connectedIncidents && connectedIncidents.length" />
                <div class="notification" v-if="incidentInfo.servicedByRep && incidentInfo.status !== 'fixed_agreed_repaircompany' && incidentInfo.status !== 'returned_to_buildingmanager'">
                    <span>{{profile.incident_detail.confirm_in_progress}}</span>
                </div>
                <p v-html="profile.incident_detail.confirm_close"></p>
                <br />
                <form v-on:submit.prevent="sendReviewRequest()">
                    <textarea class="form-control" v-model="fields.descr" :placeholder="profile.incident_detail.reason_agree_and_close"></textarea>
                    <div class="notification notification--error" style="margin-top: 20px;" v-if="descrError">
                        <p>{{profile.incident_detail.descr_error}}</p>
                    </div>
                    <button type="submit" class="button button--cta button--icon" data-testid="finishIncidentSubmit"><i class="fas fa-handshake"></i><span>{{profile.incident_detail.agree_and_close}}</span></button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            fields: {
                descr: ''
            },
            connectedIncidents: [],
            descrError: false
        }
    },
    props: ['incidentId', 'incidentInfo'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        fetch('/api/v1/incident/copiedandopen?id_incident=' + this.incidentId)
        .then(response => { response.json().then(
            function(data) {
                self.connectedIncidents = data;
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        finishIncident() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('message', this.fields.descr);
            data.append('status', 'closed');
            let self = this;
            fetch('/api/v1/incident/changestatus', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                let successText = self.profile.dashboard.building_manager.message_table.incident_ID + ' <b>' + self.incidentId + '</b> ' + self.profile.toast.closed_successfully;
                self.$vToastify.success(successText, self.profile.toast.incident_finished);
                document.querySelector('body').classList.remove('modal-open');
                self.$router.push('/buildingmanager');
            });
        },
        sendReviewRequest() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            let self = this;
            fetch('/api/v1/incident/sendreviewrequest', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.finishIncident();
            });
        },
        toIncident(id) {
            this.$router.replace({ path: 'incident-detail', query: { id: id} })
        },
        validateDescr() {
            if (this.fields.descr) {
                this.descrError = false;
            }
        }
    }
}
</script>
