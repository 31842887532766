<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.incident_detail.plan_mechanic_repair}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="manualCheckIn()">
                    <div class="row">
                        <div class="col-sm-3">
                            <select class="form-select" v-model="fields.day" required>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <select class="form-select" v-model="fields.month" required>
                                <option value="1">{{profile.months_short.jan}}</option>
                                <option value="2">{{profile.months_short.feb}}</option>
                                <option value="3">{{profile.months_short.mar}}</option>
                                <option value="4">{{profile.months_short.apr}}</option>
                                <option value="5">{{profile.months_short.may}}</option>
                                <option value="6">{{profile.months_short.jun}}</option>
                                <option value="7">{{profile.months_short.jul}}</option>
                                <option value="8">{{profile.months_short.aug}}</option>
                                <option value="9">{{profile.months_short.sep}}</option>
                                <option value="10">{{profile.months_short.oct}}</option>
                                <option value="11">{{profile.months_short.nov}}</option>
                                <option value="12">{{profile.months_short.dec}}</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <select class="form-select" v-model="fields.year" required>
                                <option v-for="(year, index) in years" :key="index" :value="year" :selected="year === 2018">{{year}}</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <flat-pickr v-model="fields.time" class="form-control" :config="flatPickrTimeConfig"></flat-pickr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <p style="margin-top: 12px;"><b>{{profile.incident_detail.duration}}</b></p>
                        </div>
                        <div class="col-sm-3">
                            <input type="number" class="form-control" v-model="fields.hours" min="0" required style="width: 50%; float: left; margin-right: 10px;">
                            <p style="margin-top: 12px;"><b>{{profile.incident_detail.hours}}</b></p>
                        </div>
                        <div class="col-sm-3">
                            <input type="number" class="form-control" v-model="fields.minutes" max="180" required style="width: 50%; float: left; margin-right: 10px;">
                            <p style="margin-top: 12px;"><b>{{profile.incident_detail.minutes}}</b></p>
                        </div>
                    </div>
                    <button type="submit" class="button button--icon"><i class="fa fa-toolbox"></i>{{profile.incident_detail.plan_mechanic_repair}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

export default {
    mixins: [setLocale],
    name: 'newLocationModal',
    components: {
        flatPickr
    },
    data() {
        return {
            profile: {},
            years: [],
            fields: {
                day: 1,
                month: 1,
                year: 2011,
                time: '',
                hours: 0,
                minutes: 30
            },
            flatPickrTimeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            }
        }
    },
    props: ['incidentId', 'repairCompany'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var startYear = 2018;
        while (startYear <= currentYear) {
            this.years.push(startYear);
            startYear++
        }
        this.fields.day = currentDate.getDate();
        this.fields.month = currentDate.getMonth() + 1;
        this.fields.year = currentDate.getFullYear();
        this.fields.time = moment(currentDate).format('HH:mm');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        manualCheckIn() {
            let epoch = new Date();
            epoch.setDate(this.fields.day);
            epoch.setMonth(this.fields.month - 1);
            epoch.setFullYear(this.fields.year);
            let hours = this.fields.time.split(':')[0];
            let minutes = this.fields.time.split(':')[1];
            epoch.setHours(hours);
            epoch.setMinutes(minutes);
            epoch.setSeconds(0);
            const data = new URLSearchParams();
            let paramStart = 'data[0][start_time]';
            let paramEnd = 'data[0][end_time]';
            let paramId = 'data[0][id_incident]';
            let paramRepairCompany = 'data[0][id_repair_company]';
            let paramMechanic = 'data[0][id_mechanic]';
            let endTime = epoch.getTime() + (60 * 60 * 1000 * this.fields.hours + 60 * 1000 * this.fields.minutes);
            data.append('action', 'create');
            data.append(paramStart, epoch.getTime());
            data.append(paramEnd, endTime);
            data.append(paramId, this.incidentId);
            data.append(paramRepairCompany, this.repairCompany);
            data.append(paramMechanic, 0);
            let self = this;
            fetch('/api/v1/appointment', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Afspraak maken',
                });
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('planned');
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
