<template>
    <div>
        <div class="main-content" v-if="profile.dashboard">
            <div class="container">
                <section class="block block--dashboard-actions" style="min-height: 0;">
                    <div class="profile-card">
                        <div class="row">
                            <div class="col-8">
                                <h2 style="float: none;" data-testid="managerNameText">{{ name }}</h2>
                                <h3 style="display: block; margin-top: 5px;">{{ userName }}</h3>
                            </div>
                        </div>
                    </div>
                </section>
                <div class="row d-block d-md-none">
                    <div class="col" style="position: relative;">
                        <span class="button button--icon new-incident" data-testid="createIncidentButton" @click="newIncidentModalOpen = true"><i class="fa-solid fa-plus"></i>{{profile.incidents.new_incident.title}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="searchterms" v-if="searchTerms.length">
                            <h4>{{profile.dashboard.building_manager.past_searches}}</h4>
                            <div v-for="(item, index) in searchTerms" :key="index" class="term-wrapper">
                                <span class="searchterm" @click="searchSuggest(item)">{{item}}</span>
                                <i class="fa fa-times" @click="removeTerm(item)"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <section class="search dashboard" style="margin-bottom: 20px;" :class="{active: searchSuggestions.length}">
                            <input type="text" data-testid="searchIncident" v-bind:placeholder="profile.dashboard.building_manager.search" @input="e => searchTerm = e.target.value" :value="searchTerm" @keyup="searchSuggest($event)" />
                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                            <ul class="search-suggestions" v-if="searchSuggestions.length">
                                <h4 class="result-counter" v-if="totalSuggestions === 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="totalSuggestions > 1" style="padding: 15px 10px 0 15px;">{{totalSuggestions}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                <li v-for="(suggestion, index) in searchSuggestions" :key="index" @click="toIncident(suggestion)" v-tooltip.center="suggestion.incident_description">
                                    <div class="indicators">
                                        <span class="number" data-testid="searchIncidentNumber">{{suggestion.id_incident}}</span>
                                        <span class="auto-forward" v-if="suggestion.id_ori_incident">auto <i class="fas fa-forward"></i></span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_PERSON' || suggestion.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                        <span class="agreement-bar" v-if="suggestion.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                        <span class="status" data-testid="searchIncidentStatus" v-if="!profile['statusses'][suggestion.status].buildingmanager">
                                            {{profile['statusses'][suggestion.status]}}
                                            <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                        </span>
                                        <span class="status" data-testid="searchIncidentStatus" v-if="profile['statusses'][suggestion.status].buildingmanager">
                                            {{profile['statusses'][suggestion.status].buildingmanager}}
                                            <div style="display: inline;" v-html="profile['status_icons'][suggestion.status]"></div>
                                        </span>
                                        <span class="date">{{profile.incidents.incidenttable.since}}{{suggestion.date}} {{suggestion.time}}</span>
                                    </div>
                                    <span class="category" v-if="locale === 'nl'">{{suggestion.category_dutch_name}}</span>
                                    <span class="category" v-else>{{suggestion.category_english_name}}</span>
                                    <span class="date" style="clear: both; display: block; margin-left: 0;">{{profile.incidents.incidenttable.datetime}}: {{suggestion.createdDate}}</span>
                                    <p data-testid="searchPlaceText">{{suggestion.place_name}}, {{suggestion.address}} {{suggestion.house_number}}</p>
                                    <span class="asset">{{suggestion.asset}}</span>
                                    <span class="repaircompany" v-if="suggestion.repair_company_name" style="display: block; font-weight: 500; clear:both; margin-top: 3px;"><i class="fas fa-tools"></i> {{suggestion.repair_company_name}}</span>
                                </li>
                            </ul>
                        </section>
                    </div>
                </div>
                <div class="row visible-xs">
                    <div class="col">
                        <div class="switch-wrapper dashboard">
                            <p>{{profile.dashboard.building_manager.required_actions}}</p>
                            <label class="switch">
                                <input type="checkbox" v-model="showType">
                                <span class="slider round" data-testid="filterActionSwitch"></span>
                            </label>
                            <p>{{profile.dashboard.building_manager.current_incidents}}</p>
                        </div>
                    </div>
                </div>
                <!-- Filters -->
                <div class="dashboard-filters d-sm-block d-md-block d-lg-block">
                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
                        <div class="col" v-if="owners.length">
                            <div class="dropdown-section">
                                <span v-if="settings.filters.owner" class="remove-selection" style="top: 16px; cursor: pointer; z-index: 2;" @click="removeOwnerSelection()"><i class="fas fa-times"></i></span>
                                <span class="dropdown" :class="{active: ownersDropdownToggled}" @click="ownersDropdownToggled = !ownersDropdownToggled">
                                    <span v-if="!settings.filters.owner" class="placeholder">{{profile.parts.part_filter}}</span>
                                    <span v-if="settings.filters.owner" class="selected">{{settings.filters.owner_name}}</span>
                                    <span v-if="settings.filters.owner" class="seperator">|</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="ownersDropdownToggled">
                                    <ul>
                                        <li v-for="owner in owners" v-on:click="selectOwner(owner)" :key="owner.id_owner">{{owner.name}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Action Required Items -->
                <div class="items-wrap">
                    <div class="row" :class="{hiddenxs: showType}">
                        <div class="col">
                            <div class="action-head">
                                <h3 class="action-indicator" data-testid="totalActionResult">{{profile.dashboard.incident_overview}} ({{actionRequiredCount}})</h3>
                                <div class="view-switch" v-if="actionRequiredItems.length">
                                    <span class="table-switch" @click="switchView('actionrequired', 'table')" :class="{active: actionRequiredView === 'table'}" v-tooltip.right="profile.dashboard.table_view"><i class="fas fa-list-ul"></i></span>
                                    <span class="grid-switch" @click="switchView('actionrequired', 'grid')" :class="{active: actionRequiredView === 'grid'}"  v-tooltip.right="profile.dashboard.list_view"><i class="fas fa-th"></i></span>
                                </div>
                                <div class="pagination" v-if="actionRequiredCount > 9" :class="{nomargin: actionRequiredCount < 9}">
                                    <span class="prev" v-if="actionRequiredCurrentPage !== 1" @click="prevPage('actionrequired')"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="actionRequiredCurrentPage !== 1" @click="firstPage('actionrequired')">1</span>
                                    <span class="page-indicator">{{actionRequiredCurrentPage}} / {{actionRequiredTotalPages}}</span>
                                    <span class="page page--last" v-if="actionRequiredTotalPages > 2" @click="lastPage('actionrequired')">{{actionRequiredTotalPages}}</span>
                                    <span class="next" v-if="actionRequiredCurrentPage !== actionRequiredTotalPages" @click="nextPage('actionrequired')"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-show="actionRequiredView === 'grid'" :class="{hiddenxs: showType}" v-if="actionRequiredItems.length">
                        <div class="col">
                            <span class="sort">{{profile.dashboard.building_manager.sort}}</span>
                            <div class="filters">
                                <span class="filter hidden-xs" data-testid="filterActionId" @click="sortTable('actionrequired', 'id_incident')" :class="{active: actionRequiredSortType === 'id_incident', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.dashboard.building_manager.message_table.incident_ID}}<i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterActionAddress" @click="sortTable('actionrequired', 'address')" :class="{active: actionRequiredSortType === 'address', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.address}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter hidden-xs" data-testid="filterActionAsset" @click="sortTable('actionrequired', 'asset')" :class="{active: actionRequiredSortType === 'asset', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.asset}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" data-testid="filterActionState" @click="sortTable('actionrequired', 'status')" :class="{active: actionRequiredSortType === 'status', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.status}} <i class="fa fa-chevron-down"></i></span>
                                <span class="filter" data-testid="filterActionSince" @click="sortTable('actionrequired', 'last_status_time')" :class="{active: actionRequiredSortType === 'last_status_time', asc: actionRequiredSortOrder === 'asc', desc: actionRequiredSortOrder === 'desc'}">{{profile.incidents.incidenttable.time_in_status}} <i class="fa fa-chevron-down"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="grid-view" v-show="actionRequiredView === 'grid'" :class="{hiddenxs: showType}" v-if="actionRequiredItems.length">
                        <div class="row">
                            <div class="col-sm-6 col-md-6 col-lg-4" v-for="(item, index) in actionRequiredItems" :key="index">
                                <div class="incident-wrap">
                                    <div class="block block--action" @click="toIncident(item)" v-tooltip.right="item.description">
                                        <div class="block-head">
                                            <span class="action-status" v-if="!profile['statusses'][item.status].buildingmanager">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status]}} <i class="fas fa-redo" aria-hidden="true" v-if="item.origin === 'recurring_action'" style="font-size: 12px; margin-left: 5px;" v-tooltip.right="profile.actionable.repeating_job"></i>
                                            </span>
                                            <span class="action-status" v-if="profile['statusses'][item.status].buildingmanager">
                                                <div style="display: inline-block;" v-html="profile['status_icons'][item.status]"></div>
                                                {{profile['statusses'][item.status].buildingmanager}} <i class="fas fa-redo" aria-hidden="true" v-if="item.origin === 'recurring_action'" style="font-size: 12px; margin-left: 5px;" v-tooltip.right="profile.actionable.repeating_job"></i>
                                                <span style="font-weight: 500;" v-if="item.status === 'returned_to_buildingmanager' && item.mandate_warning === 'no_warning'"> ({{profile.invoicing.refused}})</span>
                                                <span style="font-weight: 500; font-size: 9px;" v-if="item.status === 'returned_to_buildingmanager' && item.mandate_warning !== 'no_warning'"> ({{profile.mandates.mandate_violation}})</span>
                                            </span>
                                            <span class="number" data-testid="incidentActionNumber">
                                                {{ item.id_incident}}
                                            </span>
                                        </div>
                                        <div class="block-content">
                                            <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                            <div class="row">
                                                <div class="col">
                                                    <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                                    <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                                </div>
                                            </div>
                                            <h4>{{item.address}}</h4>
                                            <h5>{{item.asset}}</h5>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                            <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                            <span class="followup" v-if="item.wants_copy"><i class="fas fa-calendar-plus"></i> {{profile.incident_detail.followup_label}}</span>
                                            <!-- <p>{{item.date}}<br />{{item.time}}</p> -->
                                            <span class="time-indicator" :class="item.overtimeStatus">{{item.overtime}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table-view" v-show="actionRequiredView === 'table'" v-if="actionRequiredItems.length">
                        <div class="row">
                            <div class="col">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" @click="sortTable('actionrequired', 'id_incident')" :class="{active: actionRequiredSortType === 'id_incident'}">{{profile.dashboard.building_manager.message_table.incident_ID}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'address')" :class="{active: actionRequiredSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'asset')" :class="{active: actionRequiredSortType === 'asset'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'status')" :class="{active: actionRequiredSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'last_status_time')" :class="{active: actionRequiredSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'dutch_category_name')" :class="{active: actionRequiredSortType === 'dutch_category_name'}" v-if="locale === 'nl'">{{profile.incident_detail.category}}<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('actionrequired', 'english_category_name')" :class="{active: actionRequiredSortType === 'english_category_name'}" v-else>{{profile.incident_detail.category}}<i class="fas fa-sort"></i></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item, index) in actionRequiredItems" :key="index" @click="toIncident(item)">
                                            <td style="position: relative;">
                                                <span class="id_incident">{{item.id_incident}}</span>
                                                <div>
                                                    <span class="post-it mandate-warning" v-if="item.mandate_warning !== 'no_warning'" v-tooltip.right="profile.mandates.mandate_violation"><i class="fas fa-euro-sign"></i></span>
                                                </div>
                                                <span class="additional" v-if="item.autodispatch || item.id_ori_incident"><i class="fas fa-info-circle"></i></span>
                                                <div class="additional-wrap">
                                                    <span class="additional-item" v-if="item.autodispatch"><i class="fas fa-forward"></i> {{profile.dashboard.auto_forward}}</span>
                                                    <span class="additional-item" v-if="item.id_ori_incident"><i class="fas fa-copy" style="margin-right: 5px;"></i>{{profile.dashboard.copy_of}} {{item.id_ori_incident}}</span>
                                                </div>
                                                <div class="labels">
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                                                    <span class="agreement-bar" v-if="item.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                                                </div>
                                            </td>
                                            <td v-tooltip.right="item.description">{{item.address}}</td>
                                            <td v-tooltip.right="item.description">{{item.asset}}</td>
                                            <td class="status" v-if="!profile['statusses'][item.status].buildingmanager" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status]}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                            </td>
                                            <td class="status" v-if="profile['statusses'][item.status].buildingmanager" v-tooltip.right="item.description">
                                                {{profile['statusses'][item.status].buildingmanager}}
                                                <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                                <span style="font-size: 10px;" v-if="item.status === 'returned_to_buildingmanager' && item.mandate_warning === 'no_warning'"> ({{profile.invoicing.refused}})</span>
                                                <span style="font-size: 10px;" v-if="item.status === 'returned_to_buildingmanager' && item.mandate_warning !== 'no_warning'"> ({{profile.mandates.mandate_violation}})</span>

                                            </td>
                                            <td v-tooltip.right="item.description" :class="item.overtimeStatus">{{item.overtime}}</td>
                                            <td v-tooltip.right="item.dutch_category_name" v-if="locale === 'nl'">{{item.dutch_category_name}}</td>
                                            <td v-tooltip.right="item.english_category_name" v-else>{{item.english_category_name}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Closed incidents -->
                <div class="row hidden-xs" v-if="closedIncidentItems.length">
                    <div class="col">
                        <div class="action-head">
                            <h3 class="action-indicator" data-testid="totalClosedResult" @click="toggleClosed()" style="cursor: pointer;">{{profile.dashboard.building_manager.closed_incidents}} ({{closedIncidentsCount}})</h3>
                            <i class="fas fa-chevron-down toggleclosed" @click="toggleClosed()" :class="{active: showClosed}"></i>
                            <div class="pagination" style="margin-top: 5px;" v-if="closedIncidentTotalPages >= 1 && showClosed">
                                <span class="prev" v-if="closedIncidentCurrentPage !== 1" @click="prevPage('closedincident')"><i class="fas fa-chevron-left"></i></span>
                                <span class="next" @click="nextPage('closedincident')" v-if="closedIncidentCurrentPage !== closedIncidentTotalPages"><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="table-view hidden-xs" v-if="showClosed && closedIncidentItems.length" style="margin-top: 20px;">
                    <div class="row">
                        <div class="col">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col" @click="sortTable('closedincident', 'id_incident')" :class="{active: closedIncidentSortType === 'id_incident'}">{{profile.dashboard.building_manager.message_table.incident_ID}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'address')" :class="{active: closedIncidentSortType === 'address'}">{{profile.incidents.incidenttable.address}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'asset')" :class="{active: closedIncidentSortType === 'asset'}">{{profile.incidents.incidenttable.asset}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" @click="sortTable('closedincident', 'status')" :class="{active: closedIncidentSortType === 'status'}">{{profile.incidents.incidenttable.status}}<i class="fas fa-sort"></i></th>
                                        <th scope="col" style="cursor: auto;">{{profile.incidents.incidenttable.repair_company}}</th>
                                        <th scope="col" style="cursor: auto;">{{profile.incidents.incidenttable.datetime}}</th>
                                        <th scope="col" @click="sortTable('closedincident', 'last_status_time')" :class="{active: closedIncidentSortType === 'last_status_time'}">{{profile.incidents.incidenttable.time_in_status}}<i class="fas fa-sort"></i></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in closedIncidentItems" :key="index" @click="toIncident(item)" v-tooltip.right="item.description">
                                        <td>{{item.id_incident}}
                                            <span class="auto-forward" v-if="item.autodispatch">auto <i class="fas fa-forward"></i></span>
                                            <span class="auto-forward" v-if="item.id_ori_incident">{{item.id_ori_incident}} <i class="fas fa-copy"></i></span>
                                        </td>
                                        <td>{{item.address}}</td>
                                        <td>{{item.asset}}</td>
                                        <td v-if="!profile['statusses'][item.status].buildingmanager">
                                            {{profile['statusses'][item.status]}}
                                            <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                        </td>
                                        <td v-if="profile['statusses'][item.status].buildingmanager">
                                            {{profile['statusses'][item.status].buildingmanager}}
                                            <p style="display: inline-block;" v-html="profile['status_icons'][item.status]"></p>
                                        </td>
                                        <td>{{item.repair_company}}</td>
                                        <td>{{item.date}} {{item.time}}</td>
                                        <td :class="item.overtimeStatus">{{item.since}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AgreementFlowItemsModal v-if="agreementFlowItemsModalOpen" :agreementFlowItems="agreementFlowItems" @close="agreementFlowItemsModalOpen = false" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { EventBus } from '@/js/event-bus.js';
import moment from 'moment';
import debounce from 'lodash/debounce';
import AgreementFlowItemsModal from "@/components/modals/AgreementFlowItemsModal.vue";

export default {
    mixins: [setLocale],
    components: {
        AgreementFlowItemsModal
    },
    data() {
        return {
            profile: {},
            bmId: 0,
            userName: '',
            name: '',
            actionRequiredView: 'grid',
            actionRequiredItems: [],
            actionRequiredSortType: '',
            actionRequiredCurrentPage: 1,
            actionRequiredTotalPages: 1,
            actionRequiredCount: 0,
            closedIncidentsCount: 0,
            closedIncidentItems: [],
            closedIncidentSortType: 'last_status_time',
            closedIncidentCurrentPage: 1,
            suggestOpen: true,
            searchTerm: '',
            totalSuggestions: 0,
            searchSuggestions: [],
            searchActive: false,
            actionRequiredSortOrder: '',
            closedIncidentsSortOrder: 'desc',
            settings: {},
            searchTerms: [],
            showType: false,
            showClosed: false,
            agreementFlowItems: [],
            agreementFlowItemsModalOpen: false,
            itemsInterval: null,
            owners: [],
            ownersDropdownToggled: false
        }
    },
    created() {
        this.applySettings();
        document.querySelector('body').classList.remove('modal-open');
        document.querySelector('body').classList.remove('media-open');
        document.title = 'S1MONE | Dashboard';
        EventBus.$on('langChange', busObj => {
            for (var i = 0; i < this.actionRequiredItems.length; i++) {
                let item = this.actionRequiredItems[i];
                if (busObj.locale === 'nl') {
                    item.date = moment(item.created).locale(busObj.locale).format('D MMMM YYYY');
                }
                if (busObj.locale === 'en') {
                    item.date = moment(item.created).locale(busObj.locale).format('MMMM D YYYY');
                }
            }
        });
        this.getCurrentUser();
        this.itemsInterval = setInterval(function () {
            this.getAvailableFilters();
            this.getActionRequiredItems();
            this.getAgreementFlowItems();
            this.getClosedIncidentItems();
        }.bind(this), 300000);
    },
    beforeDestroy() {
        clearInterval(this.itemsInterval)
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        applySettings() {
            // Apply LocalStorage settings to vue instance
            if (localStorage.getItem('S1-settings') !== null) {
                let settings = localStorage.getItem('S1-settings');
                settings = JSON.parse(settings);
                this.settings = settings;
                this.actionRequiredView = this.settings.actionRequiredView;
                if (this.settings.actionRequiredSortOrder) {
                    this.actionRequiredSortOrder = this.settings.actionRequiredSortOrder;
                }
                else {
                    this.actionRequiredSortOrder ='asc';
                    this.settings.actionRequiredSortOrder ='asc';
                }
                this.actionRequiredSortType = this.settings.actionRequiredSort;
                this.actionRequiredCurrentPage = this.settings.actionRequiredCurrentPage;
                this.showClosed = this.settings.showClosed;
                if (!this.settings.filters) {
                    this.settings.filters = {};
                }
                if (!this.settings.checkboxes) {
                    this.settings.checkboxes = {};
                }
            }
            else {
                this.settings.actionRequiredView = 'grid';
                this.settings.actionRequiredSort = 'id_incident';
                this.settings.actionRequiredSortOrder = 'asc';
                this.settings.actionRequiredCurrentPage = 1;
                this.actionRequiredSortType = this.settings.actionRequiredSort;
                this.actionRequiredCurrentPage = this.settings.actionRequiredCurrentPage;
                this.settings.filters = {};
                this.settings.checkboxes = {};
                this.showClosed = true;
                localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            }
        },
        getAvailableFilters() {
            let self = this;
            fetch('/api/v1/incidentcombined/availablefilters')
	        .then(response => { response.json().then(
	            function(data) {
                   self.owners = data.available_owners;
				});
	        });
        },
        getIncidents() {
            this.getActionRequiredItems();
            this.getAgreementFlowItems();
            this.getClosedIncidentItems();
            this.newIncidentModalOpen = false;
        },
        getActionRequiredItems() {
            let orderParam = '?sort_dir=' + this.settings.actionRequiredSortOrder;
            let sortParam = '';
            if (this.settings.actionRequiredSort) {
                sortParam = '&sort_col=' + this.settings.actionRequiredSort;
            }
            let filterParam = '';
            if (this.settings.filters.owner) {
                filterParam = '&id_owner=' + this.settings.filters.owner;
            }
            else {
                filterParam = '';
            }
            let offSetParam = this.settings.actionRequiredCurrentPage - 1;
            offSetParam = offSetParam * 9;
            let self = this;
            fetch('/api/v1/incidentcombined/incidentsowner' + orderParam + sortParam + filterParam + '&limit=9&offset=' + offSetParam)
            .then(response => { response.json().then(
                function(data) {
                    self.actionRequiredItems = [];
                    if (data) {
                        let items = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            let locale = localStorage.getItem('S1-locale');
                            if (locale === 'nl') {
                                item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                            }
                            if (locale === 'en') {
                                item.date = moment(item.created).locale('en').format('MMMM D YYYY');
                            }
                            item.time = moment(item.created).locale('nl').format('LT');
                            item.type = 'actionrequired';
                            let created = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                            if (item.last_status_time) {
                                created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                            }
                            let now = moment().format('DD/MM/YYYY HH:mm:ss');
                            let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                            let differenceObj = self.convertToDays(difference);
                            let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                            item.overtime = differenceString;
                            if (differenceObj.days < 1) {
                                item.overtimeStatus = 'green';
                            }
                            if (differenceObj.days >= 1) {
                                item.overtimeStatus = 'orange';
                            }
                            if (differenceObj.days > 3) {
                                item.overtimeStatus = 'red';
                            }
                            items.push(item);
                        }
                        self.actionRequiredCount = data.recordsTotal;
                        self.actionRequiredItems = items;
                        let totalPagesRaw = (self.actionRequiredCount) / 9;
                        self.actionRequiredTotalPages = Math.ceil(totalPagesRaw);
                        if (self.actionRequiredCurrentPage > self.actionRequiredTotalPages) {
                            self.actionRequiredCurrentPage = self.actionRequiredTotalPages;
                            self.settings.actionRequiredCurrentPage = self.actionRequiredCurrentPage;
                        }
                        if (self.actionRequiredCount < 10 && self.settings.actionRequiredCurrentPage > 1) {
                            self.actionRequiredCurrentPage = 1;
                            self.actionRequiredTotalPages = 1;
                            self.settings.actionRequiredCurrentPage = 1;
                            self.getActionRequiredItems();
                        }
                    }
                });
            });
        },
        getAgreementFlowItems() {
            let self = this;
            fetch('/api/v1/incidentcombined/inagreementflowowner?sort_dir=asc&limit=100')
            .then(response => { response.json().then(
                function(data) {
                    self.agreementFlowItems = [];
                    if (data) {
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            item.createdDate = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                            if (item.incident_agreement_flow === 'WAITING_ON_PERSON' || item.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED') {
                                self.agreementFlowItems.push(item);
                            }
                        }
                    }
                });
            });
        },
        getClosedIncidentItems() {
            let orderParam = '&sort_dir=' + this.closedIncidentsSortOrder;
            let self = this;
            let from;
            if (this.closedIncidentCurrentPage === 1) {
                from = 0;
            }
            else {
                from = (this.closedIncidentCurrentPage - 1) * 25;
            }
            let fromParam = '&offset=' + from;
            let sortParam = '';
            if (this.closedIncidentSortType) {
                sortParam = '&sort_col=' + this.closedIncidentSortType;
            }
            fetch('/api/v1/incidentcombined/closedincidentsowner?limit=25'+ orderParam + fromParam  + sortParam)
            .then(response => { response.json().then(
                function(data) {
                    self.closedIncidentItems = [];
                    let items = [];
                    if (data) {
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            let locale = localStorage.getItem('S1-locale');
                            if (locale === 'nl') {
                                item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                            }
                            if (locale === 'en') {
                                item.date = moment(item.created).locale('en').format('MMMM D YYYY');
                            }
                            item.time = moment(item.created).locale('nl').format('LT');
                            item.type = 'closedincident';
                            let created = moment(item.created).format('DD/MM/YYYY HH:mm:ss');
                            if (item.last_status_time) {
                                created = moment(item.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                            }
                            let now = moment().format('DD/MM/YYYY HH:mm:ss');
                            let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                            let differenceObj = self.convertToDays(difference);
                            let differenceString = differenceObj.days + 'd ' + differenceObj.hours + 'u ' + differenceObj.minutes + 'm';
                            item.since = differenceString;
                            if (differenceObj.days < 1) {
                                item.overtimeStatus = 'green';
                            }
                            if (differenceObj.days >= 1) {
                                item.overtimeStatus = 'orange';
                            }
                            if (differenceObj.days > 3) {
                                item.overtimeStatus = 'red';
                            }
                            items.push(item);
                        }
                        self.closedIncidentItems = items;
                        self.closedIncidentsCount = data.recordsTotal;
                        self.closedIncidentTotalPages = data.recordsTotal / 25;
                        self.closedIncidentTotalPages = Math.ceil(self.closedIncidentTotalPages);
                    }
                });
            });
        },
        getCurrentUser() {
            let self = this;
            fetch('/api/v1/getcurrentuser')
            .then(response => { response.json().then(
                function(data) {
                    self.name = data.name;
                    self.userName = data.username;
                    if (self.settings.userName !== data.username) {
                        self.searchTerms = [];
                        self.settings.userName = data.username;
                        self.settings.actionRequiredView = 'grid';
                        self.settings.actionRequiredSort = 'owner_name';
                        self.settings.actionRequiredSortOrder = 'desc';
                        self.settings.actionRequiredCurrentPage = 1;
                    }
                    else {
                        if (localStorage.getItem('S1-searchterms')) {
                            let searchTerms = localStorage.getItem('S1-searchterms');
                            searchTerms = JSON.parse(searchTerms);
                            self.searchTerms = searchTerms;
                            if (self.searchTerms) {
                                for (var i = 0; i < self.searchTerms.length; i++) {
                                    let searchTerm = self.searchTerms[i];
                                    if (searchTerm === "") {
                                        self.searchTerms.splice(i, 1);
                                    }
                                }
                            }
                        }
                    }
                    localStorage.setItem('S1-settings', JSON.stringify(self.settings));
                    localStorage.setItem('S1-searchterms', JSON.stringify(self.searchTerms));
                    self.getActionRequiredItems();
                    self.getAgreementFlowItems();
                    self.getClosedIncidentItems();
                    self.getAvailableFilters();
                });
            });
        },
        searchSuggest: debounce(function (e) {
            this.searchSuggestions = [];
            if (typeof e === 'string') {
                this.searchTerm = e;
            }
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                let self = this;
                fetch('/api/v1/incidentcombined/searchowner?search_term=' + searchTerm)
    	        .then(response => { response.json().then(
    	            function(data) {
                        self.totalSuggestions = data.data.length;
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            if (self.locale === 'nl') {
                                item.date = moment(item.last_status_time).locale('nl').format('D MMMM YYYY');
                            }
                            if (self.locale === 'en') {
                                item.date = moment(item.last_status_time).locale('en').format('MMMM D YYYY');
                            }
                            item.createdDate = moment(item.created).locale('nl').format('D MMMM YYYY, HH:mm');
                            item.time = moment(item.last_status_time).locale('nl').format('LT');
                            self.searchSuggestions.push(item);
                        }
    				});
    	        });
            }
            else {
                this.searchActive = false;
                this.searchSuggestions = [];
                this.totalSuggestions = 0;
            }
            if (e !== undefined) {
                if(e.keyCode === 27) {
                    this.searchActive = false;
                    this.searchTerm = '';
                    this.searchSuggestions = [];
                    this.totalSuggestions = 0;
                }
            }
        }, 500),
        removeTerm(item) {
            this.searchTerm = '';
            let index = this.searchTerms.indexOf(item);
            this.searchTerms.splice(index, 1);
            localStorage.setItem('S1-searchterms', JSON.stringify(this.searchTerms));
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
        },
        switchView(type, view) {
            if (type === 'actionrequired') {
                if (view === 'grid') {
                    this.actionRequiredView = 'grid';
                    this.settings.actionRequiredView = 'grid';
                }
                if (view === 'table') {
                    this.actionRequiredView = 'table';
                    this.settings.actionRequiredView = 'table';
                }
            }   
        },
        sortTable(type, sorter) {
            if (type === 'actionrequired') {
                if (this.settings.actionRequiredSort === sorter) {
                    this.actionRequiredSortOrder = this.actionRequiredSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.actionRequiredSortOrder = 'desc';
                }
                this.settings.actionRequiredSortOrder = this.actionRequiredSortOrder;
                this.settings.actionRequiredSort = sorter;
                this.actionRequiredSortType = sorter;
                this.getActionRequiredItems();
            }
            if (type === 'closedincident') {
                if (this.closedIncidentSortType === sorter) {
                    this.closedIncidentsSortOrder = this.closedIncidentsSortOrder !== 'asc' ? 'asc' : 'desc';
                }
                else {
                    this.closedIncidentsSortOrder = 'asc';
                }
                this.closedIncidentSortType = sorter;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        nextPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage++;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getActionRequiredItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage++;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        prevPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage--;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getActionRequiredItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage--;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        firstPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage = 1;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getActionRequiredItems();
            }
            if (type === 'closedincident') {
                this.closedIncidentCurrentPage = 1;
                this.getClosedIncidentItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        lastPage(type) {
            if (type === 'actionrequired') {
                this.actionRequiredCurrentPage = this.actionRequiredTotalPages;
                this.settings.actionRequiredCurrentPage = this.actionRequiredCurrentPage;
                this.getActionRequiredItems();
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        toIncident(incident) {
            if (this.searchTerms.length === 5) {
                this.searchTerms.splice(0, 1);
            }
            let searchTerm = this.searchTerm.toLowerCase();
            if (!this.searchTerms.includes(searchTerm)) {
                this.searchTerms.push(searchTerm);
                localStorage.setItem('S1-searchterms', JSON.stringify(this.searchTerms));
            }
            if (incident.status === 'closed' || incident.status === 'cancelled') {
                this.settings.showClosed = true;
            }
            else {
                this.settings.showClosed = false;
            }
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.$router.push('/owner/incident-detail?id=' + incident.id_incident);
        },
        convertToDays(milliSeconds){
            let days = Math.floor(milliSeconds/(86400 * 1000));
            milliSeconds -= days*(86400*1000);
            let hours = Math.floor(milliSeconds/(60 * 60 * 1000 ));
            milliSeconds -= hours * (60 * 60 * 1000);
            let minutes = Math.floor(milliSeconds/(60 * 1000));
            return {
                days, hours, minutes
            }
        },
        toggleClosed() {
            this.showClosed = !this.showClosed;
            this.settings.showClosed = this.showClosed;
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
        },
        selectOwner(owner) {
            this.ownersDropdownToggled = false;
            this.settings.filters = {};
            this.settings.filters.owner = owner.id_owner;
            this.settings.filters.owner_name = owner.name;
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getActionRequiredItems();
        },
        removeOwnerSelection() {
            this.ownersDropdownToggled = false;
            this.settings.actionRequiredCurrentPage = 1;
            this.settings.currentIncidentCurrentPage = 1;
            this.settings.filters = {};
            localStorage.setItem('S1-settings', JSON.stringify(this.settings));
            this.getActionRequiredItems();
        }
    }
}
</script>
