<template>
    <div>
        <div class="main-content" v-if="profile.incident_detail">
            <div class="container">
                <div class="tabs">
                    <div class="tab" data-testid="tabRecurring" @click="saveTab('repeterend')" :class="{active: currentTab === 'repeterend'}">
                        <h3>{{profile.actionable.repeating_job}}</h3>
                    </div>
                    <div class="tab" data-testid="tabQueue" @click="saveTab('queue')" :class="{active: currentTab === 'queue'}">
                        <h3>{{profile.actionable.waiting_list}} <span class="counter" v-if="overtimeItems"> {{overtimeItems}}</span></h3>
                    </div>
                </div>
                 <!-- Repeating work Tab -->
                 <div class="tab-view" :class="{active: currentTab === 'repeterend'}" style="border-radius: 0 0 5px 5px;">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.actionable.repeating_job}}</h1>
                            <Recurring  />
                        </div>
                    </div>
                </div>
                 <!--Queue Tab -->
                 <div class="tab-view" :class="{active: currentTab === 'queue'}" style="border-radius: 0 0 5px 5px;">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.actionable.waiting_list}}</h1>
                            <Queue  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { setLocale } from '@/mixins/setLocale.js';
import Recurring from "@/components/recurringactions/Recurring.vue";
import Queue from "@/components/recurringactions/Queue.vue";

export default {
    mixins: [setLocale],
    components: {
        Recurring,
        Queue
    },
    data() {
        return {
            profile: {},
            currentTab: 'repeterend'
        }
    },
    computed: mapState(['overtimeItems']),
    created() {
        if (this.profile.actionable) {
            document.title = 'S1MONE | ' + this.profile.actionable.repeating_job;
        }
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        saveTab(type) {
            this.currentTab = type;
        }
    }
}
</script>
