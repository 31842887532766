<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="row">
            <div class="col">
                <div class="block block--incident_detail block--repaircompany" id="repblock" v-if="serviceTeamId !== -1 || incidentInfo.has_order || incidentInfo.incident_status === 'fixed_agreed_repaircompany'">
                    <div class="incident-head" style="margin-bottom: 10px;">
                        <h1>{{profile.invoicing.commissioning}}</h1>
                        <div class="text-link-wrap" v-if="!readOnly && incidentInfo.incident_status !== 'fixed_agreed_auto' && incidentInfo.incident_status !== 'fixed_agreed_repaircompany' && incidentInfo.id_repair_company && (incidentInfo.original_id_building_manager === incidentInfo.id_building_manager || !incidentInfo.original_id_building_manager)">
                            <span @click="removeCommissionModalOpen = true">
                                <i class="fa fa-times"></i>
                                <span class="text-link" data-testid="commissionCancel">{{profile.invoicing.cancel_commission}}</span>
                            </span>
                        </div>
                        <span class="cancel-edit" data-testid="buttonCancelCompany" @click="cancelEdit()" v-tooltip.right="profile.tooltips.cancel_edit" v-if="preStep && !incidentInfo.id_repair_company"><i class="fa fa-times"></i></span>
                    </div>
                    <div class="pre-step" v-if="!incidentInfo.id_repair_company">
                        <label>{{profile.invoicing.choose_serviceteam}}</label>
                        <label class="radio-container" v-if="incidentInfo.canbe_id_repair_company && incidentInfo.canbe_id_repair_company !== -1">
                            <input type="radio" name="prestep" value="serviceteam" id="craftsman" data-testid="radioServiceteam" v-model="preStep" @change="submitRepairCompany('serviceteam')">
                            <label for="craftsman">{{profile.invoicing.craftsman}}</label>
                        </label>
                        <label class="radio-container">
                            <input type="radio" name="prestep" value="other" id="supplier" data-testid="radioSupplier" v-model="preStep" @change="scrollDown('repaircompany')">
                            <label for="supplier">{{profile.invoicing.supplier}}</label>
                        </label>
                    </div>
                    <div class="selected-repaircompany" :class="{'has-mechanic': incidentInfo.id_mechanic || (serviceTeamId !== incidentInfo.id_repair_company && incidentInfo.id_appointment) || (serviceTeamId === incidentInfo.id_repair_company && incidentInfo.id_appointment && !incidentInfo.id_mechanic)}" v-else>
                        <div v-if="incidentInfo.id_repair_company === incidentInfo.canbe_id_repair_company && incidentInfo.id_mechanic">
                            <div class="row">
                                <div class="col-sm-2">
                                    <img :src="incidentInfo.mechanic_profile_image_loc" class="repaircompany-logo" v-if="incidentInfo.mechanic_profile_image_loc" />
                                    <div class="logo-placeholder" v-else>
                                        <i class="far fa-image" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-sm-10">
                                    <p data-testid="commissionSpecialistInfoName1" style="float: left;">{{incidentInfo.mechanic_name}}</p>
                                    <span class="role-label role-label--specialist">{{profile.incidents.incidenttable.mechanic}}</span>
                                    <a v-if="incidentInfo.mechanic_mobile && incidentInfo.mechanic_mobile !== 'null'" :href="'tel:' + incidentInfo.mechanic_mobile" style="clear: both; display: block;"><i class="fas fa-mobile-alt" v-tooltip.right="incidentInfo.mechanic_mobile"></i>{{incidentInfo.mechanic_mobile}}</a>
                                    <div class="invoice-info" v-if="incidentInfo.appointment_created_full">
                                        <div class="row">
                                            <div class="col">
                                                <label style="color: gray; width: 100%;">{{profile.invoicing.commissioned}} {{incidentInfo.appointment_created_full}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="no_simone" v-if="incidentInfo.mechanic_only_email">{{profile.repair_companies.repaircompanydetail.onlyemailshort}}</span>
                                    <p class="invoice-toggler" data-testid="commissionOfferInfo1" :class="{active: quotationInfoToggled}" @click="quotationInfoToggled = !quotationInfoToggled" v-if="incidentInfo.quotation_value_supplier || incidentInfo.quotationUrl">{{profile.invoicing.quotation}}<a class="url-link" :href="incidentInfo.quotationUrl" target="_blank" v-if="incidentInfo.quotationUrl" v-tooltip.right="profile.invoicing.click_open_file"><i class="fas fa-file-pdf" style="font-size: 18px;"></i></a> <i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="quotationInfoToggled">
                                        <div class="row" v-if="incidentInfo.quotation_number_supplier">
                                            <div class="col">
                                                <label>{{profile.invoicing.quotation_number}}</label>
                                                <p data-testid="commissionOfferInfoNumber1">{{incidentInfo.quotation_number_supplier}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.quotation_value_supplier">
                                            <div class="col">
                                                <label>{{profile.accountPage.account.amount}}</label>
                                                <p data-testid="commissionOfferInfoValue1">€ {{incidentInfo.quotation_value_supplier}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionValueInfo1" :class="{active: invoicingInfoToggled}" @click="invoicingInfoToggled = !invoicingInfoToggled" v-if="invoiceList.length">{{profile.invoicing.invoices}}<i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="invoicingInfoToggled && invoiceList.length">
                                        <div class="invoice-item" v-for="invoice in invoiceList" :key="invoice.id_supplier_invoice" v-tooltip.right="profile.invoicing.click_open_file">                                          
                                            <span class="edit-button icon" @click="removeInvoice(invoice)" v-if="admin">
                                                <i class="far fa-trash-alt"></i>
                                            </span>
                                            <span class="edit-button icon" @click="editInvoice(invoice)" v-if="admin" style="right: 25px;">
                                                <i class="fas fa-pen" style="font-size: 14px;"></i>
                                            </span>
                                            <span style="color: #6a6a6f;font-weight: 500;font-size: 12px; display: block;">{{ invoice.date }}</span>
                                            <a class="url-link" :href="invoice.file_url" target="_blank" v-if="invoice.file_url">
                                                <div class="row" v-if="invoice.invoice_number">
                                                    <div class="col">
                                                        <label style="width: 120px;">{{profile.invoicing.invoice_number}}</label>
                                                        <p data-testid="commissionValueInfoNumber1">
                                                            {{invoice.invoice_number}}                       
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="invoice.invoice_value">
                                                    <div class="col">
                                                        <label style="width: 120px; margin-bottom: 0;">{{profile.accountPage.account.amount}}</label>
                                                        <p data-testid="commissionValueInfoAmount1" style="margin-bottom: 0;">€ {{invoice.invoice_value}}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>                       
                                    </div>
                                    <div v-if="currentAppointment">
                                        <div class="incident-info wide" style="clear: both;">
                                            <div class="row">
                                                <div class="col">
                                                    <div v-if="incidentInfo.calendar_choice === 'choose_latest_possible'">
                                                        <label v-if="currentAppointmentDate" style="margin: 10px 0 0 0;">{{profile.incident_detail.choose_latest_possible}}</label>
                                                        <p data-testid="commissionSpecialistInfoDate1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{currentAppointmentDate}}</p>
                                                    </div>
                                                    <div v-else>
                                                        <label v-if="currentAppointment" style="margin: 10px 0 0 0;">{{profile.incident_detail.latest_appointment}}</label>
                                                        <p data-testid="commissionSpecialistAppointment1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{currentAppointment}}</p>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div v-if="currentAppointment && repairInfo.start">
                                                            <label style="margin: 10px 0 0 0;">{{profile.incident_detail.start_repair}}</label>
                                                            <p data-testid="commissionSpecialistInfoStart1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.start}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div v-if="currentAppointment && repairInfo.start">
                                                            <label style="margin: 10px 0 0 0;">{{profile.incident_detail.end_repair}}</label>
                                                            <p data-testid="commissionSpecialistInfoEnd1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.end}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div v-if="currentAppointment && repairInfo.start">
                                                            <label style="margin: 10px 0 0 0;">{{profile.incident_detail.duration_repair}}</label>
                                                            <p data-testid="commissionSpecialistInfoPassed1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.timeWorked}}</p>
                                                        </div>
                                                    </div>
                                                </div>     
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div v-if="incidentInfo.pause_reason && incidentInfo.incident_status === 'paused_mechanic'">
                                                        <label style="margin: 10px 0 0 0;">{{profile.incident_detail.paused_incident_reason}}</label>
                                                        <p data-testid="commissionSpecialistInfoPauseReason1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.pause_reason}}</p>
                                                    </div>
                                                    <div v-if="incidentInfo.repaircompany_feedback" style="margin-top: 10px;">
                                                        <label>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.repair_company}}</label>
                                                        <p data-testid="commissionSpecialistInfoFeedback1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.repaircompany_feedback}}</p>
                                                    </div>
                                                    <div v-if="incidentInfo.extra_mechanic" style="margin-top: 10px;">
                                                        <label>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.mechanic}}</label>
                                                        <p data-testid="commissionSpecialistInfoExtra1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.extra_mechanic}}</p>
                                                    </div>
                                                </div>
                                            </div>                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <label v-if="incidentInfo.incident_extra_repair_company" style="margin: 0;">{{profile.incident_detail.extra_mech}}</label>
                            <p v-if="incidentInfo.incident_extra_repair_company" style="font-size: 16px; font-weight: 100; margin-top: 0;">{{incidentInfo.incident_extra_repair_company}}</p>
                        </div>
                        <div v-if="(incidentInfo.id_repair_company && incidentInfo.id_repair_company !== incidentInfo.canbe_id_repair_company) || (serviceTeamId === incidentInfo.id_repair_company && incidentInfo.id_appointment && !incidentInfo.id_mechanic)">
                            <div class="row">
                                <div class="col-sm-3">
                                    <img :src="incidentInfo.repair_company_profile_image_loc" class="repaircompany-logo" v-if="incidentInfo.repair_company_profile_image_loc" />
                                    <div class="logo-placeholder" v-else>
                                        <i class="far fa-image" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <router-link tag="a" class="text-link" v-bind:to="'/buildingmanager/repaircompany-detail?id_repair_company=' + incidentInfo.id_repair_company" style="float: left; font-weight: 500; margin-top: 2px; font-size: 20px;">{{incidentInfo.repair_company_name}}</router-link>
                                    <span class="role-label role-label--supplier">{{profile.invoicing.contractor}}</span>
                                    <a :href="'tel:' + incidentInfo.repair_company_mobile" class="tel" style="display: block; clear: both; text-decoration: none; color: #212529; font-weight: 500;" v-if="incidentInfo.repair_company_mobile"><i class="fas fa-phone-alt" style="margin-right: 5px;"></i>{{incidentInfo.repair_company_mobile}}</a>
                                    <div class="invoice-info" v-if="incidentInfo.appointment_created_full">
                                        <div class="row">
                                            <div class="col">
                                                <!-- <label style="color: gray; width: 100%;">{{profile.invoicing.commissioned}} {{incidentInfo.appointment_created_full}}</label> -->
                                                <label style="color: #00C900; width: 100%;" v-if="incidentInfo.latest_fixed_agreed_repaircompany_date && (incidentInfo.incident_status === 'fixed_agreed_repaircompany' || incidentInfo.incident_status === 'returned_to_buildingmanager' || incidentInfo.incident_status === 'cancelled' || incidentInfo.incident_status === 'fixed_agreed_reporter' || incidentInfo.incident_status === 'fixed_agreed_auto')">{{profile.invoicing.completed_on}} {{incidentInfo.latest_fixed_agreed_repaircompany_date}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="invoice-info" v-if="invoiceParty.date">
                                        <div class="row">
                                            <div class="col">
                                                <label style="color: #bd0b0b; width: 100%; margin-top: -5px;" v-if="incidentInfo.incident_status === 'returned_to_buildingmanager' && incidentInfo.mandate_warning === 'no_warning'">{{profile.invoicing.commission_cancelled}} {{incidentInfo.last_status_full}}</label>
                                                <label data-testid="commissionViolationWarningText" style="color: #bd0b0b; width: 100%;" v-if="incidentInfo.incident_status === 'returned_to_buildingmanager' && incidentInfo.mandate_warning !== 'no_warning'">{{profile.invoicing.mandate_violated_on}} {{incidentInfo.last_status_full}}</label>
                                            </div>
                                        </div>
                                        <span class="status_reason" style="margin-bottom: 10px; padding-top: 0;" v-if="incidentInfo.returned_to_buildingmanager_text && incidentInfo.incident_status === 'returned_to_buildingmanager' && incidentInfo.mandate_warning === 'no_warning'"><b>{{profile.incident_detail.refuse_reason}}:</b> {{incidentInfo.returned_to_buildingmanager_text}}</span>
                                        <span class="status_reason" style="margin-bottom: 10px; padding-top: 0;" v-if="incidentInfo.repaircompany_feedback"><b>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.repair_company}}:</b> {{incidentInfo.repaircompany_feedback}}</span>
                                    </div>
                                    <span class="no_simone" v-if="incidentInfo.repair_company_only_email">{{profile.repair_companies.repaircompanydetail.onlyemailshort}}</span>
                                    <span class="no_simone" v-if="incidentInfo.repair_company_no_communication">{{profile.repair_companies.repaircompanydetail.nocommshort}}</span>
                                    <p class="invoice-toggler" data-testid="commissionMandateInfo" :class="{active: mandateInfoToggled}" @click="mandateInfoToggled = !mandateInfoToggled" v-if="incidentMandate && incidentMandate.amount" style="overflow: hidden;">
                                        <span style="float: left; font-weight: 500;">{{profile.mandates.mandate}}<i class="fas fa-exclamation-circle" style="margin: 4px 0 0 5px; color: #bd0b0b;" v-if="incidentInfo.mandate_warning !== 'no_warning'"></i></span>
                                        <span class="button button--small button--outline" data-testid="commissionMandateInfoHistory" style="position: absolute; height: 25px; line-height: 25px; right: 40px; font-size: 10px;" @click="mandateHistoryModalOpen = true">
                                            <i class="fas fa-book" style="font-size: 10px; margin: 7px 0 0 3px;"></i>
                                            {{profile.mandates.mandate_log}}
                                        </span>
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </p>
                                    <div class="invoice-info" v-if="mandateInfoToggled && incidentMandate" @click="mandateInfoToggled = !mandateInfoToggled" style="position: relative; margin-bottom: 20px;">
                                        <div class="row">
                                            <div class="col" style="position: relative;">
                                                <label style="margin-top: 2px;">{{profile.mandates.mandate_amount}}</label>
                                                <p data-testid="commissionMandateInfoAmount" style="margin-top: 2px;">€ {{incidentMandate.amount}}
                                                    <span class="btw" v-if="incidentMandate.in_btw" data-testid="commissionMandateInfoBtwIn" style="margin-left: 5px; font-size: 11px; line-height: 11px;">Incl. BTW</span>
                                                    <span class="btw btw--out" data-testid="commissionMandateInfoBtwOut" v-else style="margin-left: 5px; font-size: 11px; line-height: 11px;">{{profile.mandates.out_btw}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.mandate_warning !== 'no_warning'">
                                            <div class="col" style="position: relative;">
                                                <label data-testid="commissionViolationOfferText" style="margin-top: 2px;" v-if="incidentInfo.mandate_warning === 'quotation_amount'">{{profile.invoicing.quotation_amount}}</label>
                                                <label data-testid="commissionViolatioTargetText" style="margin-top: 2px;" v-if="incidentInfo.mandate_warning === 'target_price'">{{profile.invoicing.target_price}}</label>
                                                <p data-testid="commissionViolationAmountText" style="margin-top: 2px;">€ {{incidentInfo.quotation_value_supplier}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.mandate_warning !== 'no_warning'">
                                            <div class="col" style="position: relative;">
                                                <label style="margin-top: 2px;">{{profile.mandates.mandate_violation}}</label>
                                                <p data-testid="commissionViolationDifferenceText" style="margin-top: 2px;">€ {{incidentMandate.violationAmount}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionInvoiceInfo" :class="{active: invoiceInfoToggled}" @click="invoiceInfoToggled = !invoiceInfoToggled" v-if="serviceTeamId !== incidentInfo.id_repair_company && invoiceParty.to_name">{{profile.invoicing.invoicing}} <i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="invoiceInfoToggled" @click="invoiceInfoToggled = !invoiceInfoToggled" style="margin-bottom: 10px;">
                                        <div class="row">
                                            <div class="col">
                                                <label>{{profile.dashboard.building_manager.message_table.name}}</label>
                                                <p data-testid="commissionInvoiceInfoName">{{invoiceParty.to_name}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="invoiceParty.to_email">
                                            <div class="col">
                                                <label>{{profile.invoicing.invoice_email}}</label>
                                                <p data-testid="commissionInvoiceInfoEmail">{{invoiceParty.to_email}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="invoiceParty.to_address">
                                            <div class="col">
                                                <label>{{profile.invoicing.invoice_address}}</label>
                                                <p data-testid="commissionInvoiceInfoInvoice">{{invoiceParty.to_address}} {{invoiceParty.to_house_number}}, {{invoiceParty.to_zipcode}} {{invoiceParty.to_city}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="invoiceParty.to_phone">
                                            <div class="col">
                                                <label>{{profile.incidents.new_incident.phone}}</label>
                                                <p data-testid="commissionInvoiceInfoPhone">{{invoiceParty.to_phone}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <label>{{profile.invoicing.prospect_in_advance}}</label>
                                                <p data-testid="commissionInvoiceInfoOfferYes" v-if="invoiceParty.offer_needed">{{profile.incident_detail.yes}}</p>
                                                <p data-testid="commissionInvoiceInfoOfferNo" v-else>{{profile.incident_detail.no}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionOfferInfo2" :class="{active: quotationInfoToggled}" @click="quotationInfoToggled = !quotationInfoToggled" v-if="incidentInfo.quotation_value_supplier || incidentInfo.quotationUrl">{{profile.invoicing.quotation}}<a class="url-link" :href="incidentInfo.quotationUrl" target="_blank" v-if="incidentInfo.quotationUrl" v-tooltip.right="profile.invoicing.click_open_file"><i class="fas fa-file-pdf" style="font-size: 18px;"></i></a> <i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="quotationInfoToggled">
                                        <div class="row" v-if="incidentInfo.quotation_number_supplier">
                                            <div class="col">
                                                <label>{{profile.invoicing.quotation_number}}</label>
                                                <p data-testid="commissionOfferInfoNumber2">{{incidentInfo.quotation_number_supplier}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.quotation_value_supplier">
                                            <div class="col">
                                                <label>{{profile.accountPage.account.amount}}</label>
                                                <p data-testid="commissionOfferInfoAmount2">€ {{incidentInfo.quotation_value_supplier}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionValueInfo2" :class="{active: invoicingInfoToggled}" @click="invoicingInfoToggled = !invoicingInfoToggled" v-if="invoiceList.length">{{profile.invoicing.invoices}} ({{ invoiceList.length }})<i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="invoicingInfoToggled && invoiceList.length">
                                        <div class="invoice-item" v-for="invoice in invoiceList" :key="invoice.id_supplier_invoice" v-tooltip.right="profile.invoicing.click_open_file">
                                            <span class="edit-button icon" @click="removeInvoice(invoice)" v-if="admin">
                                                <i class="far fa-trash-alt"></i>
                                            </span>
                                            <span class="edit-button icon" @click="editInvoice(invoice)" v-if="admin" style="right: 25px;">
                                                <i class="fas fa-pen" style="font-size: 14px;"></i>
                                            </span>
                                            <span style="color: #6a6a6f;font-weight: 500;font-size: 12px; display: block;">{{ invoice.date }}</span>
                                            <a class="url-link" :href="invoice.file_url" target="_blank" v-if="invoice.file_url">
                                                <div class="row" v-if="invoice.invoice_number">
                                                    <div class="col">
                                                        <label style="width: 120px;">{{profile.invoicing.invoice_number}}</label>
                                                        <p data-testid="commissionValueInfoNumber2">
                                                            {{invoice.invoice_number}}                       
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="invoice.invoice_value">
                                                    <div class="col">
                                                        <label style="width: 120px; margin-bottom: 0;">{{profile.accountPage.account.amount}}</label>
                                                        <p data-testid="commissionValueInfoAmount2" style="margin-bottom: 0;">€ {{invoice.invoice_value}}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>                       
                                    </div>
                                    <div v-if="currentAppointment">
                                        <div class="incident-info wide" style="margin-top: 15px;">
                                            <div class="row">
                                                <div class="col">
                                                    <div>
                                                        <label>{{profile.employees.mechanic}}</label>
                                                        <p data-testid="commissionSpecialistInfoName2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;" v-if="incidentInfo.id_mechanic">{{incidentInfo.mechanic_name}}</p>
                                                        <p data-testid="commissionSpecialistInfoName2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;" v-else>{{profile.incidents.incidenttable.mechanic_unknown}}</p>
                                                    </div>
                                                    <div v-if="incidentInfo.calendar_choice === 'choose_latest_possible'">
                                                        <label v-if="currentAppointmentDate" style="margin: 10px 0 0 0;">{{profile.incident_detail.choose_latest_possible}}</label>
                                                        <p data-testid="commissionSpecialistInfoDate2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{currentAppointmentDate}}</p>
                                                    </div>
                                                    <div v-else>
                                                        <label v-if="currentAppointment" style="margin: 10px 0 0 0;">{{profile.incident_detail.latest_appointment}}</label>
                                                        <p data-testid="commissionSpecialistInfoAppointment2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{currentAppointment}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">                                  
                                                <div class="col-sm-4">
                                                    <div v-if="currentAppointment && repairInfo.start">
                                                        <label style="margin: 10px 0 0 0;">{{profile.incident_detail.start_repair}}</label>
                                                        <p data-testid="commissionSpecialistInfoStart2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.start}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div v-if="currentAppointment && repairInfo.start">
                                                        <label style="margin: 10px 0 0 0;">{{profile.incident_detail.end_repair}}</label>
                                                        <p data-testid="commissionSpecialistInfoEnd2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.end}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div v-if="currentAppointment && repairInfo.start">
                                                        <label style="margin: 10px 0 0 0;">{{profile.incident_detail.duration_repair}}</label>
                                                        <p data-testid="commissionSpecialistInfoPassed2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.timeWorked}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="incidentInfo.pause_reason && incidentInfo.incident_status === 'paused_mechanic'">
                                                <label style="margin: 10px 0 0 0;">{{profile.incident_detail.paused_incident_reason}}</label>
                                                <p data-testid="commissionSpecialistInfoPauseReason2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.pause_reason}}</p>
                                            </div>
                                            <div v-if="incidentInfo.repaircompany_feedback" style="margin-top: 10px;">
                                                <label>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.repair_company}}</label>
                                                <p data-testid="commissionSpecialistInfoFeedback2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.repaircompany_feedback}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="incidentInfo.incident_extra_building_manager && serviceTeamId !== incidentInfo.id_repair_company" class="additional">
                            <hr />
                            <label>{{profile.incident_detail.extra_info}}</label>
                            <p v-if="incidentInfo.incident_extra_building_manager">{{incidentInfo.incident_extra_building_manager}}</p>
                        </div>
                        <span class="button button--icon button--small" data-testid="commissionOfferInfoAdd" style="float: right; margin: 20px 0 0 10px;" @click="startUploadInvoice()" v-if="(incidentInfo.original_id_building_manager === incidentInfo.id_building_manager || !incidentInfo.original_id_building_manager)"><i class="fas fa-file-pdf"></i> {{profile.invoicing.add_invoice}}</span>
                        <span class="button button--icon button--small" data-testid="commissionInvoiceInfoAdd" style="float: right; margin: 20px 0 0 10px;" @click="startUploadQuotation()" v-if="(incidentInfo.original_id_building_manager === incidentInfo.id_building_manager || !incidentInfo.original_id_building_manager)"><i class="fas fa-file-pdf"></i> {{profile.invoicing.add_quotation}}</span>
                        <span class="button button--icon button--small" style="float: right; margin-top: 20px;" @click="resendEmail()" v-if="incidentInfo.has_order && (incidentInfo.original_id_building_manager === incidentInfo.id_building_manager || !incidentInfo.original_id_building_manager)"><i class="far fa-envelope"></i> {{profile.invoicing.send_again}}</span>
                    </div>
                </div>
                <div class="block block--incident_detail block--repaircompany" v-if="!incidentInfo.id_repair_company && preStep === 'other'" id="invoiceBlock">
                    <div class="incident-head" style="margin-bottom: 10px;">
                        <h1>{{profile.invoicing.commission_to_supplier}}</h1>
                    </div>
                    <div class="steps-wrapper" v-if="preStep || repairCompanyPresent">
                        <div class="row" v-if="!repairCompanyPresent && preStep !== 'serviceteam'">
                            <div class="col">
                                <div class="steps">
                                    <div class="step" data-testid="commissionCompanyStep" :class="{active: repairSelectStep === 'selection'}" @click="prevStep('selection')"><span>1</span> <i class="fas fa-check" v-if="repairSelectStep === 'invoicing' || repairSelectStep === 'extra_info'"></i>{{profile.invoicing.repair_select}}</div>
                                    <div class="step" data-testid="comissionBillingStep" :class="{active: repairSelectStep === 'invoicing'}" @click="prevStep('invoicing')"><span>2</span> <i class="fas fa-check" v-if="repairSelectStep === 'mandates'  || repairSelectStep === 'extra_info'"></i>{{profile.invoicing.invoicing}}</div>
                                    <div class="step" data-testid="commissionMandateStep" :class="{active: repairSelectStep === 'mandates'}" @click="prevStep('mandates')" v-if="hasMandates"><span>3</span> <i class="fas fa-check" v-if="repairSelectStep === 'extra_info'"></i>{{profile.mandates.mandates}}</div>
                                    <div class="step" data-testid="comissionInfoStep" :class="{active: repairSelectStep === 'extra_info'}"><span v-if="hasMandates">4</span><span v-else>3</span>{{profile.incident_detail.extra_info}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="step-content" v-if="(repairSelectStep === 'selection' && preStep !== 'serviceteam') || repairCompanyPresent">
                            <div v-if="!repairCompanyPresent && preStep !== 'serviceteam'">
                                <div v-if="preferredSuppliers.length">
                                    <label>{{profile.accountPage.account.pref_sup}}</label>
                                    <p v-for="(supplier, index) in preferredSuppliers" :key="index">{{supplier.name_repair_company}}</p>
                                </div>
                                <div class="dropdown-section" :class="{disabled: readOnly}" style="margin-bottom: 20px;">
                                    <span class="dropdown" data-testid="selectRepairCompany" v-on:click="repairCompanyDropdownToggled = !repairCompanyDropdownToggled" :class="{active: repairCompanyDropdownToggled}" @click="scrollDown('dropdown')">
                                        <span v-if="!selectedIdRepairCompany" style="color: #212529;">{{profile.incident_detail.select_a_repaircompany}} *</span>
                                        <span v-if="selectedIdRepairCompany" class="selected">{{selectedRepairCompanyName}}</span>
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </span>
                                    <div class="dropdown-list" data-testid="ListRepairCompany" v-if="repairCompanyDropdownToggled">
                                        <input type="text" @input="e => repairCompanySearchTerm = e.target.value" :value="repairCompanySearchTerm" id="searchReps" :placeholder="profile.repair_companies.repaircompanytable.search_repaircompany" @keyup="searchRepairCompanies()" v-if="repairCompanies.length > 9" style="margin: 10px 0;"  />
                                        <ul>
                                            <li v-for="(company, index) in repairCompanies" class="with-label" v-on:click="selectRepairCompany(company)" :key="index" v-show="!tempRepairCompanies.length && !repairCompaniesNoResults && company.name && company.id_repair_company !== incidentInfo.canbe_id_repair_company">
                                                <span data-testid="ListRepairCompanyResult">{{company.name}}</span>
                                                <span class="no_simone" data-testid="ListRepairCompanyResult" v-if="company.only_email">{{profile.repair_companies.repaircompanydetail.onlyemailshort}}</span>
                                                <span class="no_simone" data-testid="ListRepairCompanyResult" v-if="company.no_communication">{{profile.repair_companies.repaircompanydetail.nocommshort}}</span>
                                            </li>
                                            <li v-for="company in tempRepairCompanies" class="with-label" v-on:click="selectRepairCompany(company)" :key="company.id_repair_company" v-show="tempRepairCompanies.length && company.id_repair_company !== incidentInfo.canbe_id_repair_company">
                                                <span data-testid="ListRepairCompanyResult">{{company.name}}</span>
                                                <span class="no_simone" data-testid="ListRepairCompanyResult" v-if="company.only_email">{{profile.repair_companies.repaircompanydetail.onlyemailshort}}</span>
                                                <span class="no_simone" data-testid="ListRepairCompanyResult" v-if="company.no_communication">{{profile.repair_companies.repaircompanydetail.nocommshort}}</span>
                                            </li>
                                            <li v-if="repairCompaniesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="temp-repaircompany" v-if="tempRepairCompany.name" id="temprepair">
                                    <div class="row">
                                        <div class="col-sm-3 col-md-2">
                                            <img :src="tempRepairCompany.profile_image_loc" class="repaircompany-logo" v-if="tempRepairCompany.profile_image_loc" />
                                            <div class="logo-placeholder" v-else>
                                                <i class="far fa-image" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div class="col-sm-9 col-md-10">
                                            <div class="invoice-info">
                                                <div class="row">
                                                    <div class="col">
                                                        <router-link tag="a" class="text-link" v-bind:to="'/buildingmanager/repaircompany-detail?id_repair_company=' + tempRepairCompany.id_repair_company" style="font-weight: 500; margin-top: 0; color: #0867b3; font-size: 20px;" v-if="tempRepairCompany.id_repair_company">{{tempRepairCompany.name}}</router-link>
                                                        <p style="font-weight: 500; cursor: auto;" v-else>{{tempRepairCompany.name}}</p>
                                                    </div>
                                                </div>
                                                <span class="no_simone" v-if="tempRepairCompany.only_email">{{profile.repair_companies.repaircompanydetail.onlyemailshort}}</span>
                                                <span class="no_simone" v-if="tempRepairCompany.no_communication">{{profile.repair_companies.repaircompanydetail.nocommshort}}</span>
                                                <span class="no_simone serviceteam" v-if="tempRepairCompany.id_repair_company === incidentInfo.canbe_id_repair_company">{{profile.accountPage.account.own_service}}</span>
                                            </div>
                                            <div class="switch-wrapper color-orange no-radius no-hover" :class="{active: incidentInfo.urgency_level}" style="margin-top: 15px;">
                                                <div class="inner" @click="saveUrgency()">
                                                    <i class="fa-regular fa-circle-exclamation"></i>                                    
                                                    <p>Urgent</p>
                                                    <span class="label" style="float: none; text-align: left;">{{profile.switches.urgency}}</span>
                                                    <label class="switch">
                                                        <span class="slider round" data-testid="switchUrgentCommission"></span>
                                                    </label>
                                                </div>       
                                            </div>
                                        </div>
                                    </div>
                                    <span class="button button--icon" data-testid="buttonInvoicing" @click="selectStep('invoicing')" style="float: right;"><i class="fas fa-caret-right"></i>{{profile.buttons.next}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="step-content" id="stepinvoice" v-if="repairSelectStep === 'invoicing' && !repairCompanyPresent">
                            <label>{{profile.invoicing.invoice_party}}</label>
                            <div class="dropdown-section" :class="{disabled: readOnly}">
                                <span class="dropdown" data-testid="selectInvoiceParty" v-on:click="invoiceDropdownToggled = !invoiceDropdownToggled" :class="{active: invoiceDropdownToggled}">
                                    <span v-if="!invoicePartySelected.invoice_name">{{profile.invoicing.select_invoice_party}} *</span>
                                    <span v-if="invoicePartySelected.invoice_name && invoicePartySelected.invoice_name !== 'other' && invoicePartySelected.invoice_name !== 'not_applicable'" class="selected">{{invoicePartySelected.invoice_name}}</span>
                                    <span v-if="invoicePartySelected.invoice_name && invoicePartySelected.invoice_name === 'other'" class="selected">{{profile.invoicing.different}}</span>
                                    <span v-if="invoicePartySelected.invoice_name && invoicePartySelected.invoice_name === 'not_applicable'" class="selected">{{profile.places.n_a}}</span>                               
                                    <i class="fas fa-chevron-down chevron"></i></span>
                                <div class="dropdown-list" data-testid="listInvoiceParty" v-if="invoiceDropdownToggled">
                                    <ul>
                                        <li @click="selectInvoiceParty('owner')" data-testid="listInvoiceName" v-if="incidentInfo.id_owner">{{incidentInfo.owner_name}}</li>
                                        <li @click="selectInvoiceParty('manager')" data-testid="listInvoiceName">{{incidentInfo.building_manager_name}}</li>
                                        <li @click="selectInvoiceParty('manager_pa_owner')">{{ incidentInfo.owner_name }} {{ profile.invoicing.per_address }} {{incidentInfo.building_manager_name}}</li>
                                        <li @click="selectInvoiceParty('other')" data-testid="listInvoiceName">{{profile.invoicing.different}}</li>
                                        <li @click="selectInvoiceParty('not_applicable')" data-testid="listInvoiceName">{{profile.places.n_a}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="incident-info" v-if="invoicePartySelected.invoice_name && invoicePartySelected.invoice_name !== 'other' && invoicePartySelected.invoice_name !== 'not_applicable'" style="margin-top: 15px; float: left; width: 100%;">
                                <div class="notification notification--error" v-if="invoiceError">
                                    <p style="font-size: 16px; margin-bottom: 0;">{{profile.invoicing.invoice_error}}</p>
                                </div>
                                <div v-if="!invoiceError">
                                    <label>{{profile.dashboard.building_manager.message_table.name}}</label>
                                    <p data-testid="invoiceNameText">{{invoicePartySelected.invoice_name}}</p>
                                    <div class="row">
                                        <div class="col-md-6" v-if="invoicePartySelected.invoice_email">
                                            <label>{{profile.invoicing.invoice_email}}</label>
                                            <p  data-testid="invoiceEmailText">{{invoicePartySelected.invoice_email}}</p>
                                        </div>
                                        <div class="col-md-6" v-if="invoicePartySelected.invoice_address">
                                            <label>{{profile.invoicing.invoice_address}}</label>
                                            <p data-testid="listInvoiceAddressText">{{invoicePartySelected.invoice_address}} {{invoicePartySelected.invoice_house_number}}, {{invoicePartySelected.invoice_zipcode}} {{invoicePartySelected.invoice_city}}</p>
                                        </div>
                                    </div>
                                    <label>{{profile.incidents.new_incident.phone}}</label>
                                    <p data-testid="invoicePhoneText">{{invoicePartySelected.mobile}}</p>
                                    <label v-if="buildingmanager.invoice_default_text">{{profile.invoicing.standard_text}}</label>
                                    <p v-if="buildingmanager.invoice_default_text">{{buildingmanager.invoice_default_text}}</p>
                                    <div class="switch-wrapper switch-wrapper--full" :class="{active: offer_needed}">
                                        <div class="inner" @click="offer_needed = !offer_needed">
                                            <i class="fa-regular fa-file-pdf"></i>
                                            <p>{{profile.invoicing.prospect_in_advance}}</p>
                                            <span class="label">{{profile.switches.offer_needed}}</span>
                                            <label class="switch">
                                                <span class="slider round" data-testid="switchOfferBefore"></span>
                                            </label>
                                        </div>                                     
                                    </div>  
                                </div>
                            </div>
                            <form>
                                <div class="incident-info" v-if="invoicePartySelected.invoice_name === 'other'">
                                    <label>{{profile.dashboard.building_manager.message_table.name}}</label>
                                    <input type="text" class="form-control" data-testid="inputInvoiceName" v-model="invoicePartySelected.invoice_name_new" :class="{error: invoiceError}" required @input="zipCodeSearch()" />
                                    <label>{{profile.invoicing.invoice_email}}</label>
                                    <input type="text" class="form-control" data-testid="inputInvoiceEmail" v-model="invoicePartySelected.invoice_email" :class="{error: invoiceError}" @input="validateEmail()" />
                                    <span class="text-link" style="float: none; margin-bottom: 10px;" @click="fillObjectAddress()">{{incidentInfo.place_address}} {{incidentInfo.house_number}}, {{incidentInfo.place_zipcode}} {{incidentInfo.place_city}}</span>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>{{profile.places.placetable.zipcode}}</label>
                                            <input type="text" class="form-control" data-testid="inputInvoiceZip" v-model="invoicePartySelected.invoice_zipcode" :class="{error: invoiceError}" @input="zipCodeSearch()" />
                                        </div>
                                        <div class="col-sm-6">
                                            <label>{{profile.places.placetable.house_number}}</label>
                                            <input type="text" class="form-control" data-testid="inputInvoiceNumber" v-model="invoicePartySelected.invoice_house_number" :class="{error: invoiceError}" @input="zipCodeSearch()" />
                                        </div>
                                    </div>
                                    <div class="notification notification--error" style="margin-bottom: 17px;" v-if="addressError"><i class="fas fa-exclamation-triangle"></i> {{profile.places.placetable.valid_zip}}</div>
                                    <div class="row" v-if="invoiceAddress">
                                        <div class="col-sm-6">
                                            <label>{{profile.places.placetable.address}}</label>
                                            <input type="text" class="form-control" v-model="invoiceAddress" disabled />
                                        </div>
                                        <div class="col-sm-6">
                                            <label>{{profile.places.placetable.city}}</label>
                                            <input type="text" class="form-control" v-model="invoiceCity" disabled />
                                        </div>
                                    </div>
                                    <label>{{profile.incidents.new_incident.phone}}</label>
                                    <input type="text" class="form-control" data-testid="inputInvoicePhone" v-model="invoicePartySelected.invoice_phone" />
                                    <label>{{profile.invoicing.custom_standard_text}}</label>
                                    <textarea class="form-control"  data-testid="inputInvoiceDescription" v-model="buildingmanager.invoice_default_text"></textarea>
                                    <div class="switch-wrapper switch-wrapper--full" :class="{active: offer_needed}">
                                        <div class="inner" @click="offer_needed = !offer_needed">
                                            <i class="fa-regular fa-file-pdf"></i>
                                            <p>{{profile.invoicing.prospect_in_advance}}</p>
                                            <span class="label">{{profile.switches.offer_needed}}</span>
                                            <label class="switch">
                                                <span class="slider round" data-testid="switchOfferBefore"></span>
                                            </label>
                                        </div>                                     
                                    </div>
                                    <div class="notification notification--error" v-if="invoiceError" style="margin-top: 20px;">
                                        <p style="font-size: 16px; margin-bottom: 0;">{{profile.invoicing.invoice_error}}</p>
                                    </div>
                                </div>
                                <span class="button button--icon step-back" @click="repairSelectStep = 'selection'" style="float: left; background: white; color: gray; padding-left: 0;"><i class="fas fa-caret-left"></i>{{profile.buttons.previous}}</span>
                                <button class="button button--icon" data-testid="buttonMandates" style="margin-top: 20px; float: right;" @click="selectStep('mandates')" :class="{disabled: invoiceError}" :disabled="invoiceError" v-if="hasMandates"><i class="fas fa-caret-right"></i>{{profile.buttons.next}}</button>
                                <button class="button button--icon" data-testid="buttonExtraInfo" style="margin-top: 20px; float: right;" @click="selectStep('extra_info')" :class="{disabled: invoiceError}" :disabled="invoiceError" v-else><i class="fas fa-caret-right"></i>{{profile.buttons.next}}</button>
                            </form>
                        </div>
                        <div class="step-content" v-if="repairSelectStep === 'mandates'">
                            <div class="row" v-if="incidentInfo.quotation_value_supplier">
                                <div class="col">
                                    <label v-if="incidentInfo.mandate_warning === 'quotation_amount'">{{profile.invoicing.quotation_amount}}</label>
                                    <label v-if="incidentInfo.mandate_warning === 'target_price'">{{profile.invoicing.target_price}}</label>
                                    <p style="font-size: 16px; margin-bottom: 10px;">€ {{incidentInfo.quotation_value_supplier}}</p>
                                </div>
                            </div>
                            <div class="dropdown-section" style="margin-bottom: 20px;" v-if="!createNewMandate && mandates.length">
                                <span class="dropdown" data-testid="commissionMandateDropdown" v-on:click="mandatesDropdownToggled = !mandatesDropdownToggled" :class="{active: mandatesDropdownToggled}">
                                    <span v-if="!selectedMandate.amount" style="color: #212529;">{{profile.mandates.select_mandate}} *</span>
                                    <span v-else class="selected">{{selectedMandate.name}}
                                        <b>(€ {{selectedMandate.amount}})</b>
                                        <span class="btw" v-if="selectedMandate.in_btw" style="position: absolute; right: 70px; top: 15px; float: none; width: auto; font-size: 11px; line-height: 11px;">Incl. BTW</span>
                                        <span class="btw btw--out" v-else style="position: absolute; right: 70px; top: 15px; float: none; width: auto; font-size: 11px; line-height: 11px;">{{profile.mandates.out_btw}}</span>
                                        <span class="cancel" data-testid="commissionMandateCancel" @click.stop="removeSelectedMandate()" style="position: absolute; right: 42px; top: 15px; float: none; width: auto; font-size: 21px; line-height: 11px;"><i class="fa fa-times"></i></span>
                                    </span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="mandatesDropdownToggled">
                                    <ul>
                                        <li v-for="(mandate, index) in mandates" v-on:click="selectMandate(mandate)" :key="index">
                                            <span data-testid="commissionMandateId">{{mandate.name}} <b data-testid="commissionMandateAmount">(€ {{mandate.amount}})</b></span>
                                            <span class="level" data-testid="commissionMandateLevel" style="font-size: 12px;">Level {{mandate.level}}</span>
                                            <span class="btw" data-testid="commissionMandateBtwIn" v-if="mandate.in_btw" style="position: absolute; right: 5px; top: 5px; float: none; width: auto; font-size: 11px; line-height: 11px;">Incl. BTW</span>
                                            <span class="btw btw--out" v-else data-testid="commissionMandateBtwOut" style="position: absolute; right: 5px; top: 5px; float: none; width: auto; font-size: 11px; line-height: 11px;">{{profile.mandates.out_btw}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <span class="text-link" data-testid="commissionMandateCreate" style="margin: 0 0 20px 0; width: 100%;" @click="createNewMandate = true" v-if="!createNewMandate">{{profile.mandates.create_mandate}} +</span>
                            <div class="row" v-if="createNewMandate">
                                <div class="col">
                                    <div>
                                        <h3>{{profile.mandates.create_mandate}}</h3>
                                        <div class="row" style="position: relative;">
                                            <div class="col-sm-6">
                                                <label>{{profile.accountPage.account.amount}}</label>
                                                <input type="text" class="form-control" data-testid="commissionMandateCreateAmount" v-model="tempMandate.amount" @keyup="validateAmount()" />
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="switch-wrapper switch-wrapper--full alt-hover" :class="{active: tempMandate.in_btw}" style="margin-bottom: -10px;">
                                                    <div class="inner" @click.stop="tempMandate.in_btw = !tempMandate.in_btw">
                                                        <i class="fa-regular fa-scroll"></i>
                                                        <p>Incl. BTW</p>
                                                        <span class="label">{{ profile.switches.mandate_inc }}</span>
                                                        <label class="switch">
                                                            <span data-testid="commissionMandateCreateBtw" class="slider round"></span>
                                                        </label>
                                                    </div>     
                                                </div>
                                                <span class="cancel-edit" data-testid="commissionMandateCreateCancel" @click="createNewMandate = false" v-if="createNewMandate" style="margin-top: 20px;"><i class="fa fa-times"></i></span>
                                            </div>
                                        </div>
                                        <div class="errors">
                                            <span class="notification notification--error" v-if="mandateAmountError">{{profile.mandates.amount_error_2}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span class="button button--icon step-back" @click="repairSelectStep = 'invoicing'" style="float: left; background: white; color: gray; padding-left: 0;"><i class="fas fa-caret-left"></i>{{profile.buttons.previous}}</span>
                            <button class="button button--icon" data-testid="commissionMandateSubmit" style="margin: 20px 0 0 0; float: right;" @click="selectStep('extra_info')" v-if="selectedMandate || tempMandate.amount"><i class="fas fa-caret-right"></i>{{profile.buttons.next}}</button>
                        </div>
                        <div class="step-content" v-if="repairSelectStep === 'extra_info' && !repairCompanyPresent">
                            <form v-if="tempRepairCompany.id_repair_company !== incidentInfo.canbe_id_repair_company" class="rep-extra-info" id="repinfo">
                                <label>{{profile.incident_detail.extra_info_mech}}</label>
                                <textarea class="form-control" data-testid="inputExtraInfo" v-model="tempAdditionalRep" style="height: 120px;"></textarea>
                            </form>
                            <span class="button button--icon step-back" @click="repairSelectStep = 'mandates'" style="float: left; background: white; color: gray; padding-left: 0;" v-if="hasMandates"><i class="fas fa-caret-left"></i>{{profile.buttons.previous}}</span>
                            <span class="button button--icon step-back" @click="repairSelectStep = 'invoicing'" style="float: left; background: white; color: gray; padding-left: 0;" v-else><i class="fas fa-caret-left"></i>{{profile.buttons.previous}}</span>
                            <span class="button button--icon" data-testid="buttonSubmitrepairCompany" style="margin-top: 20px; float: right;" @click="submitRepairCompany()"><i class="fas fa-thumbs-up"></i>{{profile.invoicing.submit}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RemoveCommissionModal v-if="removeCommissionModalOpen" @close="removeCommissionModalOpen = false" @remove="repairCompanyRemoved()" :incidentInfo="incidentInfo" :incidentId="incidentInfo.id_incident" />
        <RemoveInvoiceModal v-if="removeInvoiceModalOpen" @close="removeInvoiceModalOpen = false" @success="removeInvoiceModalOpen = false" :incidentInfo="incidentInfo" :selectedInvoice="selectedInvoice" />
        <MandateHistoryModal v-if="mandateHistoryModalOpen" @close="mandateHistoryModalOpen = false" :incidentMandateLog="incidentMandateLog" />
        <UploadQuotationModal v-if="uploadQuotationModalOpen" @close="uploadQuotationModalOpen = false" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" @success="quotationUploaded()" />
        <UploadInvoiceModal v-if="uploadInvoiceModalOpen" @close="uploadInvoiceModalOpen = false" :incidentId="incidentInfo.id_incident"  :incidentInfo="incidentInfo" @success="quotationUploaded()" />
        <EditInvoiceModal v-if="editInvoiceModalOpen" @close="editInvoiceModalOpen = false" :incidentId="incidentInfo.id_incident"  :incidentInfo="incidentInfo" :selectedInvoice="selectedInvoice" @success="quotationUploaded()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import VueScrollTo from 'vue-scrollto';
import RemoveCommissionModal from "@/components/modals/RemoveCommissionModal.vue";
import RemoveInvoiceModal from "@/components/modals/RemoveInvoiceModal.vue";
import MandateHistoryModal from "@/components/modals/MandateHistoryModal.vue";
import UploadQuotationModal from "@/components/modals/UploadQuotationModal.vue";
import UploadInvoiceModal from "@/components/modals/UploadInvoiceModal.vue";
import EditInvoiceModal from "@/components/modals/EditInvoiceModal.vue";
export default {
    name: 'Commission',
    mixins: [setLocale],
    components: {
        RemoveCommissionModal,
        RemoveInvoiceModal,
        MandateHistoryModal,
        UploadQuotationModal,
        UploadInvoiceModal,
        EditInvoiceModal
    },
    data: function () {
        return {
            loading: false,
            refNumberEdit: false,
            repairSelectStep: 'selection',
            invoiceParty: {},
            invoiceInfoToggled: false,
            invoiceDropdownToggled: false,
            removeCommissionModalOpen: false,
            repairCompanyEdit: false,
            selectedRepairCompanyName: '',
            repairCompanyDropdownToggled: false,
            repairCompanySearchTerm: '',
            repairCompaniesNoResults: false,
            tempRepairCompanies: [],
            tempRepairCompany: {},
            serviceTeam: false,
            serviceTeamId: 0,
            serviceTeamName: '',
            autodispatchType: null,
            selectedIdRepairCompany: null,
            preStep: '',
            repairCompanyPresent: false,
            invoicePartySelected: {},
            offer_needed: false,
            addressError: false,
            invoiceError: false,
            validEmail: false,
            invalidEmail: false,
            invoiceAddress: '',
            invoiceCity: '',
            preStepComplete: false,
            tempAdditionalRep: '',
            mandates: [],
            selectedMandate: {},
            mandatesDropdownToggled: false,
            noMandatesError: false,
            createNewMandate: false,
            tempMandate: {
                amount: 0,
                in_btw: false,
            },
            mandateAmountError: false,
            incidentMandateLog: [],
            incidentMandate: {},
            mandateInfoToggled: false,
            mandateHistoryModalOpen: false,
            uploadQuotationModalOpen: false,
            uploadInvoiceModalOpen: false,
            quotationInfoToggled: false,
            invoicingInfoToggled: false,
            mandateSelected: false,
            invoiceList: [],
            removeInvoiceModalOpen: false,
            selectedInvoice: {},
            editInvoiceModalOpen: false
        }
    },
    computed: mapState(['ownerLoaded', 'recommission', 'invoices', 'mandateAdded']),
    watch: {
        ownerLoaded() {     
            this.invoicePartySelected = this.selectedInvoiceParty;
        },
        recommission() {
            this.recommissionIncident();
        },
        invoices() {
            this.invoiceList = this.invoices;
        },
        mandateAdded() {
            this.getIncidentMandate();
        }
    },
    props: ['incidentInfo',
        'readOnly',
        'admin',
        'hasMandates',
        'preStepChoice',
        'hasRepairCompany',
        'preferredSuppliers',
        'selectedRepairCompanyId',
        'selectedInvoiceParty',
        'buildingmanager',
        'owner',
        'currentAppointment',
        'currentAppointmentDate',
        'repairInfo'
    ],
    created() {
        this.getInvoiceParty();
        this.getIncidentMandate();
        this.getRepairCompanies();
        this.getServiceTeamId();
        this.getInvoices();
        this.selectedIdRepairCompany = this.selectedRepairCompanyId;
        this.preStep = this.preStepChoice;
        this.repairCompanyPresent = this.hasRepairCompany;
        this.invoicePartySelected = this.selectedInvoiceParty;
        if (this.preferredSuppliers.length) {
            this.selectedRepairCompanyName = this.preferredSuppliers[0].name_repair_company;
            this.selectedIdRepairCompany = this.preferredSuppliers[0].id_repair_company;
            this.selectRepairCompany(this.preferredSuppliers[0]);
        }
    },
    methods: {
        getInvoiceParty() {
            this.selectedType = '';
            let self = this;
            fetch('/api/v1/invoice_data/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    self.invoiceParty = data;
                    if (self.invoiceParty.created) {
                        self.invoiceParty.date = moment(self.invoiceParty.created).format('DD/MM/YYYY HH:mm:ss');
                    }
                    self.loading = false;
                });
            });
        },
        getIncidentMandate() {
            let self = this;
            fetch('/api/v1/incidentmandate/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    self.selectedMandate = {};
                    self.mandateAmountError = false;
                    for (var i = 0; i < data.length; i++) {
                        let mandate = data[i];
                        mandate.date = moment(mandate.created).format('DD/MM/YYYY HH:mm:ss');
                        if (mandate.active) {
                            self.incidentMandate = mandate;
                        }
                    }
                    if (self.incidentMandate.amount && self.incidentMandate.amount.toString().includes('.')) {
                        self.incidentMandate.amount = (Math.round(self.incidentMandate.amount * 100) / 100).toFixed(2);
                    }
                    self.incidentMandateLog = data;
                    self.incidentMandate.violationAmount = self.incidentInfo.quotation_value_supplier - self.incidentMandate.amount;
                    if (self.incidentMandate.amount) {
                        self.incidentMandate.amount = self.incidentMandate.amount.toString().replaceAll('.', ',');
                    }
                });
            });
        },
        getInvoices() {
            let self = this;
            fetch('/api/v1/order/getsupplierinvoices?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    if (data.length) {
                        let invoices = [];
                        for (var i = 0; i < data.length; i++) {
                            let invoice = data[i];
                            if (self.locale === 'nl') {
                                invoice.date = moment(invoice.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                                invoice.description = 'Factuur-' + invoice.invoice_number;
                            }
                            else {
                                invoice.date = moment(invoice.created).format('MMM D YYYY HH:mm:ss');
                                invoice.description = 'Invoice-' + invoice.invoice_number;
                            }
                            if (invoice.invoice_value && invoice.invoice_value.toString().includes('.')) {
                                invoice.invoice_value = (Math.round(invoice.invoice_value * 100) / 100).toFixed(2);
                                invoice.invoice_value = invoice.invoice_value.toString().replaceAll('.', ',');
                            } 
                            invoices.push(invoice);
                        }
                        self.invoiceList = invoices;
                    }                 
                });
            });
        },
        submitRepairCompany(type) {
            this.repairCompanyPresent = true;
            if (type === 'serviceteam') {
                this.serviceTeam = true;
                this.repairSelectStep = 'selection';
                this.selectedIdRepairCompany = null;
                this.tempRepairCompany = {};
                this.tempAdditionalRep = '';
                this.$emit('showCalendar', true);
            }
            else {
                this.invoiceAddress = '';
                this.saveIncidentInvoicing();
            }
        },
        submitRepairCompanyAction() {
            this.createIncidentMandate();
            if (this.tempRepairCompany.id_repair_company) {
                let self = this;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('id_repair_company', this.selectedIdRepairCompany);
                if (this.tempAdditionalRep) {
                    this.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Opdrachtverlening - Aanvullende informatie',
                    });
                    data.append('extra_building_manager', this.tempAdditionalRep);
                }
                this.tempAdditionalRep = '';
                fetch('/api/v1/incident/torepaircompany', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.incidentInfo.repair_company_name = self.tempRepairCompany.name;
                    self.incidentInfo.repair_company_profile_image_loc = self.tempRepairCompany.profile_image_loc;
                    self.incidentInfo.id_repair_company = self.tempRepairCompany.id_repair_company;
                    self.repairCompanyEdit = false;
                    if (self.incidentInfo.id_repair_company && self.incidentInfo.canbe_id_repair_company !== -1) {
                        self.$emit('getmechanics');
                    }
                    if (self.incidentInfo.id_repair_company !== self.incidentInfo.canbe_id_repair_company && !self.tempRepairCompany.only_email && !self.tempRepairCompany.no_communication) {
                        self.$emit('repaircompanysubmit');
                    }
                    self.tempRepairCompany = {};
                    if (self.incidentInfo.mandate_warning !== 'no_warning') {
                        self.removeMandateWarning();
                    }
                    else {
                        self.$emit('getIncidentInfo', false, true);
                    }
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getInvoiceParty();
                    self.getInvoices();
                });
            }
        },
        removeMandateWarning() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            let self = this;
            fetch('/api/v1/order/resetsuppliermandatewarning', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('getIncidentInfo', false, true);
            });
        },
        repairCompanyRemoved() {
            this.removeCommissionModalOpen = false;
            if (this.incidentMandate.id_incident_mandate) {
                this.removeIncidentMandate();
            }
            this.$emit('getIncidentInfo', false, true, true);
            this.$emit('commissionRemoved');
            setTimeout(function () {
                window.scrollTo(0,document.body.scrollHeight);
            }.bind(this), 1500);
        },
        quotationUploaded() {
            this.uploadQuotationModalOpen = false;
            this.$emit('getIncidentInfo', false, false, true);
        },
        scrollDown(type) {
            if (type === 'repaircompany') {
                this.$emit('showCalendar', false);
                this.repairCompanyPresent = false;
                setTimeout(function () {
                    let element = document.getElementById('invoiceBlock');
                    VueScrollTo.scrollTo(element);
                }.bind(this), 200);
            }
            else {
                setTimeout(function () {
                    if (type === 'dropdown') {
                        if (document.getElementById('searchReps')) {
                            document.getElementById('searchReps').focus();
                        }
                    }
                    if (window.innerWidth > 991) {
                        window.scrollTo(0,document.body.scrollHeight);
                    }
                    else {
                        let element = document.getElementById('repblock');
                        VueScrollTo.scrollTo(element);
                    }
                }.bind(this), 500);
            }
        },
        getServiceTeamId() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.autodispatchType = data.autodispatch_invoice_type;
                    self.serviceTeamId = data.canbe_id_repair_company;
                    if (self.autodispatchType === 'BUILDINGMANAGER') {
                       self.selectInvoiceParty('manager');
                    }
                    if (self.autodispatchType === 'OWNER') {
                       self.selectInvoiceParty('owner');
                    }
                    if (self.autodispatchType === 'OWNER_PA_BUILDINGMANAGER') {
                       self.selectInvoiceParty('manager_pa_owner');
                    }
                    if (self.autodispatchType === 'OTHER') {
                       self.selectInvoiceParty('not_applicable');
                    }
                });
            });
        },
        getRepairCompanies() {
            let self = this;
            fetch('/api/v1/repaircompany/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.repairCompanies = data.data;
                    for (var i = 0; i < self.repairCompanies.length; i++) {
                        let repairCompany = self.repairCompanies[i];
                        if (repairCompany.id_repair_company === self.incidentInfo.canbe_id_repair_company) {
                            self.serviceTeamName = repairCompany.name;
                            self.serviceTeamId = repairCompany.id_repair_company;
                        }
                    }
                    self.repairCompanies = sortBy(self.repairCompanies, 'name');
                });
            });
        },
        selectRepairCompany(company) {
            this.repairCompanyDropdownToggled = false;
            this.selectedIdRepairCompany = company.id_repair_company;
            if (company.name) {
                this.selectedRepairCompanyName = company.name;
            }
            if (company.name_repair_company) {
                this.selectedRepairCompanyName = company.name_repair_company;
            }
            this.tempRepairCompanies = [];
            let self = this;
            if (this.selectedIdRepairCompany) {
                fetch('/api/v1/repaircompany/byid?id_repair_company=' + this.selectedIdRepairCompany)
                .then(response => { response.json().then(
                    function(data) {
                        self.tempRepairCompany = data;
                        setTimeout(function () {
                            var options = {
                                offset: -60
                            }
                            let element = document.getElementById('temprepair');
                            VueScrollTo.scrollTo(element, 200, options);
                        }.bind(this), 200);
                    });
                });
            }
            else {
                this.tempRepairCompany = company;
            }
        },
        searchRepairCompanies() {
            this.tempRepairCompanies = [];
            if (this.repairCompanySearchTerm.length > 1) {
                let searchTerm = this.repairCompanySearchTerm.toLowerCase();
                for (var i = 0; i < this.repairCompanies.length; i++) {
                    let repairCompany = this.repairCompanies[i];
                    let repairCompanyName = repairCompany.name.toLowerCase();
                    if (repairCompanyName.includes(searchTerm)) {
                        this.tempRepairCompanies.push(repairCompany);
                    }
                    if (!this.tempRepairCompanies.length) {
                        this.repairCompaniesNoResults = true;
                    }
                    else {
                        this.repairCompaniesNoResults = false;
                    }
                }
            }
            else {
                this.assetsNoResults = false;
            }
        },
        selectInvoiceParty(type) {
            this.invoiceError = false;
            this.invoiceDropdownToggled = false;
            if (type === 'owner') {
                this.invoicePartySelected = this.owner;
                this.invoicePartySelected.invoice_name = this.incidentInfo.owner_name;
                this.invoicePartySelected.invoice_default_text = this.buildingmanager.invoice_default_text;
                this.invoicePartySelected.invoice_phone = this.invoicePartySelected.mobile;
                this.invoicePartySelected.invoice_type = 'owner';
            }
            if (type === 'manager') {
                this.invoicePartySelected = this.buildingmanager;
                this.invoicePartySelected.invoice_name = this.buildingmanager.building_manager_name;
                this.invoicePartySelected.mobile = this.buildingmanager.building_manager_mobile;
                this.invoicePartySelected.invoice_phone = this.invoicePartySelected.mobile;
                this.invoicePartySelected.invoice_type = 'manager';
            }
            if (type === 'manager_pa_owner') {
                this.invoicePartySelected = this.buildingmanager;
                this.invoicePartySelected.invoice_name = this.incidentInfo.owner_name + ' ' +  this.profile.invoicing.per_address + ' ' + this.incidentInfo.building_manager_name;
                this.invoicePartySelected.to_name = this.incidentInfo.owner_name + ' ' +  this.profile.invoicing.per_address + ' ' + this.incidentInfo.building_manager_name;
                this.invoicePartySelected.mobile = this.buildingmanager.building_manager_mobile;
                this.invoicePartySelected.invoice_phone = this.invoicePartySelected.mobile;
                this.invoicePartySelected.invoice_type = 'manager';
            }
            if (type === 'owner' || type === 'manager' || type === 'manager_pa_owner') {
                if (!this.invoicePartySelected.invoice_email) {
                    if (!this.invoiceAddress) {
                        this.invoiceError = true;
                    }
                }
            }
            if (type === 'other') {
                this.selectedType = 'other';
                this.invoicePartySelected = {};
                this.invoicePartySelected.invoice_name = 'other';
                this.invoicePartySelected.invoice_type = 'other';

            }
            if (type === 'not_applicable') {
                this.selectedType = 'not_applicable';
                this.invoicePartySelected = {};
                this.invoicePartySelected.invoice_name = 'not_applicable';
                this.invoicePartySelected.invoice_type = 'not_applicable';
            }
            var options = {
                offset: -60
            }
            let element = document.getElementById('stepinvoice');
            VueScrollTo.scrollTo(element, 200, options);
        },
        saveIncidentInvoicing() {
            if (this.invoiceParty.id_invoice_data) {
                this.editIncidentInvoicing();
            }
            else {
                if (!this.invoiceError) {
                    if (this.offer_needed) {
                        this.$gtag.event('click', {
                            event_category: 'Button',
                            event_label: 'Opdrachtverlening - Offerte vooraf',
                        });
                    }
                    this.loading = true;
                    const data = new URLSearchParams();
                    let idParam = 'data[0][id_incident]';
                    let defaultTextParam = 'data[0][default_text]';
                    let nameParam = 'data[0][to_name]';
                    let emailParam = 'data[0][to_email]';
                    let addressParam = 'data[0][to_address]';
                    let houseNumberParam = 'data[0][to_house_number]';
                    let zipcodeParam = 'data[0][to_zipcode]';
                    let cityParam = 'data[0][to_city]';
                    let phoneParam = 'data[0][to_phone]';
                    let numberParam = 'data[0][to_number]';
                    let offerNeededParam = 'data[0][offer_needed]';
                    data.append('action', 'create');
                    data.append(idParam, this.incidentInfo.id_incident);
                    if (!this.invoicePartySelected.invoice_name) {
                        this.invoicePartySelected.invoice_name = '';
                    }
                    if (!this.invoicePartySelected.invoice_default_text) {
                        this.invoicePartySelected.invoice_default_text = '';
                    }
                    if (this.selectedType === 'other') {
                        if (this.buildingmanager.invoice_default_text) {
                            this.invoicePartySelected.invoice_default_text = this.buildingmanager.invoice_default_text;
                        }
                        else {
                            this.invoicePartySelected.invoice_default_text = '';
                        }
                    }
                    if (!this.invoicePartySelected.invoice_email) {
                        this.invoicePartySelected.invoice_email = '';
                    }
                    if (!this.invoicePartySelected.invoice_address) {
                        this.invoicePartySelected.invoice_address = '';
                    }
                    if (!this.invoicePartySelected.invoice_house_number) {
                        this.invoicePartySelected.invoice_house_number = '';
                    }
                    if (!this.invoicePartySelected.invoice_zipcode) {
                        this.invoicePartySelected.invoice_zipcode = '';
                    }
                    if (!this.invoicePartySelected.invoice_city) {
                        this.invoicePartySelected.invoice_city = '';
                    }
                    if (!this.invoicePartySelected.invoice_phone) {
                        this.invoicePartySelected.invoice_phone = '';
                    }
                    if (!this.invoicePartySelected.invoice_number) {
                        this.invoicePartySelected.invoice_number = '';
                    }
                    if (this.invoicePartySelected.invoice_name === 'not_applicable') {
                        this.invoicePartySelected.invoice_name = '';
                        this.invoicePartySelected.invoice_default_text = this.buildingmanager.invoice_default_text;
                    }
                    data.append(defaultTextParam, this.invoicePartySelected.invoice_default_text);
                    data.append(emailParam, this.invoicePartySelected.invoice_email);
                    data.append(houseNumberParam, this.invoicePartySelected.invoice_house_number);
                    data.append(zipcodeParam, this.invoicePartySelected.invoice_zipcode);
                    data.append(phoneParam, this.invoicePartySelected.invoice_phone);
                    data.append(numberParam, this.invoicePartySelected.invoice_number);
                    data.append(offerNeededParam, this.offer_needed);
                    if (this.selectedType === 'other') {
                        data.append(nameParam, this.invoicePartySelected.invoice_name_new);
                        data.append(addressParam, this.invoiceAddress);
                        data.append(cityParam, this.invoiceCity);
                    }
                    else {
                        data.append(nameParam, this.invoicePartySelected.invoice_name);
                        data.append(addressParam, this.invoicePartySelected.invoice_address);
                        data.append(cityParam, this.invoicePartySelected.invoice_city);
                    }
                    let self = this;
                    fetch('/api/v1/invoice_data', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.text();
                    }).then(function(data) {
                        self.submitRepairCompanyAction();
                    });
                }
                if (this.invoicePartySelected.invoice_name === 'not_applicable') {
                    this.submitRepairCompanyAction();
                }
            }
        },
        editIncidentInvoicing() {
            if (this.invoiceParty.id_invoice_data) {
                if (!this.invoiceAddress) {
                    if (!this.invoicePartySelected.invoice_email) {
                        this.invoiceError = true;
                    }
                }
                if (this.invoiceAddress) {
                    this.invoiceError = false;
                }
                const data = new URLSearchParams();
                data.append('action', 'edit');
                let idParam = 'data[' + this.invoiceParty.id_invoice_data + '][id_incident]';
                let defaultTextParam = 'data[' + this.invoiceParty.id_invoice_data + '][default_text]';
                let nameParam = 'data[' + this.invoiceParty.id_invoice_data + '][to_name]';
                let emailParam = 'data[' + this.invoiceParty.id_invoice_data + '][to_email]';
                let addressParam = 'data[' + this.invoiceParty.id_invoice_data + '][to_address]';
                let houseNumberParam = 'data[' + this.invoiceParty.id_invoice_data + '][to_house_number]';
                let zipcodeParam = 'data[' + this.invoiceParty.id_invoice_data + '][to_zipcode]';
                let numberParam = 'data[' + this.invoiceParty.id_invoice_data + '][to_number]';
                let cityParam = 'data[' + this.invoiceParty.id_invoice_data + '][to_city]';
                let phoneParam = 'data[' + this.invoiceParty.id_invoice_data + '][to_phone]';
                let offerNeededParam = 'data[' + this.invoiceParty.id_invoice_data + '][offer_needed]';
                if (!this.invoicePartySelected.invoice_default_text) {
                    this.invoicePartySelected.invoice_default_text = '';
                }
                if (this.selectedType === 'other') {
                    if (this.buildingmanager.invoice_default_text) {
                        this.invoicePartySelected.invoice_default_text = this.buildingmanager.invoice_default_text;
                    }
                    else {
                        this.invoicePartySelected.invoice_default_text = '';
                    }
                }
                if (this.selectedType === 'not_applicable') {
                    this.invoicePartySelected.invoice_default_text = this.buildingmanager.invoice_default_text;
                }
                if (!this.invoicePartySelected.invoice_email) {
                    this.invoicePartySelected.invoice_email = '';
                }
                if (!this.invoicePartySelected.invoice_address) {
                    this.invoicePartySelected.invoice_address = '';
                }
                if (!this.invoicePartySelected.invoice_house_number) {
                    this.invoicePartySelected.invoice_house_number = '';
                }
                if (!this.invoicePartySelected.invoice_zipcode) {
                    this.invoicePartySelected.invoice_zipcode = '';
                }
                if (!this.invoicePartySelected.invoice_city) {
                    this.invoicePartySelected.invoice_city = '';
                }
                if (!this.invoicePartySelected.invoice_phone) {
                    this.invoicePartySelected.invoice_phone = '';
                }
                if (!this.invoicePartySelected.invoice_number) {
                    this.invoicePartySelected.invoice_number = '';
                }
                if (this.invoicePartySelected.invoice_name === 'not_applicable') {
                    this.invoicePartySelected.invoice_name = '';
                }
                data.append(idParam, this.incidentInfo.id_incident);
                data.append(defaultTextParam, this.invoicePartySelected.invoice_default_text);
                data.append(emailParam, this.invoicePartySelected.invoice_email);
                data.append(houseNumberParam, this.invoicePartySelected.invoice_house_number);
                data.append(zipcodeParam, this.invoicePartySelected.invoice_zipcode);
                data.append(phoneParam, this.invoicePartySelected.invoice_phone);
                data.append(numberParam, this.invoicePartySelected.invoice_number);
                data.append(offerNeededParam, this.offer_needed);
                if (this.invoicePartySelected.invoice_name === 'other') {
                    data.append(addressParam, this.invoiceAddress);
                    data.append(cityParam, this.invoiceCity);
                    data.append(nameParam, this.invoicePartySelected.invoice_name_new);
                }
                else {
                    data.append(nameParam, this.invoicePartySelected.invoice_name);
                    data.append(addressParam, this.invoicePartySelected.invoice_address);
                    data.append(cityParam, this.invoicePartySelected.invoice_city);
                }
                let self = this;
                fetch('/api/v1/invoice_data', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.loading = true;
                    self.submitRepairCompanyAction();
                });
            }
        },
        fillObjectAddress() {
            this.invoicePartySelected.invoice_house_number = this.incidentInfo.place_house_number;
            this.invoicePartySelected.invoice_zipcode = this.incidentInfo.place_zipcode;
            this.zipCodeSearch();
        },
        zipCodeSearch() {
            let self = this;
            let zipcodeRegex = new RegExp('^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$');
            this.validZipCode = zipcodeRegex.test(this.invoicePartySelected.invoice_zipcode);
            if (this.validZipCode && this.invoicePartySelected.invoice_house_number) {
                setTimeout(function () {
                    fetch('/api/v1/zipcode/getaddress?zipcode=' + this.invoicePartySelected.invoice_zipcode + '&housenumber=' + this.invoicePartySelected.invoice_house_number)
                    .then(response => { response.json().then(
                        function(data) {
                            if (data.message) {
                                self.addressError = true;
                                self.invoiceAddress = data.street;
                                self.invoiceCity = data.city;
                            }
                            else {
                                self.invoiceAddress = data.street;
                                self.invoiceCity = data.city;
                            }
                            if (!data.message && self.invoicePartySelected.invoice_name_new) {
                                self.addressError = false;
                            }
                            self.validateAddress();
                        });
                    });
                }.bind(this), 500);
            }
            if (!self.invoicePartySelected.invoice_zipcode && !this.invoicePartySelected.invoice_house_number && !this.invoicePartySelected.invoice_name_new) {
                this.addressError = true;
            }
            if (!this.invoicePartySelected.invoice_name_new) {
                this.invoiceError = true;
            }
        },
        validateAddress() {
            if (!this.invoiceAddress) {
                if (!this.invoicePartySelected.invoice_email) {
                    this.invoiceError = true;
                }
            }
            if (this.invoiceAddress && this.invoicePartySelected.invoice_name_new) {
                this.invoiceError = false;
            }
        },
        prevStep(type) {
            if (type === 'selection') {
                if (this.repairSelectStep === 'invoicing' || this.repairSelectStep === 'extra_info' || this.repairSelectStep === 'mandates') {
                    this.repairSelectStep = 'selection';
                }
            }
            if (type === 'invoicing') {
                if (this.repairSelectStep === 'invoicing' || this.repairSelectStep === 'extra_info' || this.repairSelectStep === 'mandates') {
                    this.repairSelectStep = 'invoicing';
                }
            }
            if (type === 'mandates') {
                if (this.repairSelectStep === 'invoicing' || this.repairSelectStep === 'extra_info' || this.repairSelectStep === 'mandates') {
                    this.repairSelectStep = 'mandates';
                }
            }
        },
        selectStep(type) {
            var options = {
                offset: -60
            }
            if (type === 'invoicing') {
                this.repairSelectStep = 'invoicing';
                setTimeout(function () {
                    let element = document.getElementById('stepinvoice');
                    VueScrollTo.scrollTo(element, 200, options);
                }.bind(this), 500);
                if (!this.selectedInvoiceParty.invoice_address) {
                    if (!this.invoicePartySelected.invoice_email) {
                        this.invoiceError = true;
                    }
                }
            }
            if (type === 'mandates') {
                if (!this.invoiceAddress && !this.invoicePartySelected.invoice_name_new) {
                    if (!this.invoicePartySelected.invoice_email) {
                        this.invoiceError = true;
                    }
                }
                if (this.invoiceAddress && this.invoicePartySelected.invoice_name_new) {
                    this.invoiceError = false;
                }
                if (this.invoicePartySelected.invoice_name === 'not_applicable') {
                    this.invoiceError = false;
                }
                if (!this.invoiceError) {
                    this.getMandates();
                    this.repairSelectStep = 'mandates';
                    setTimeout(function () {
                        let element = document.getElementById('repinfo');
                        VueScrollTo.scrollTo(element, 200, options);
                    }.bind(this), 200);
                }
            }
            if (type === 'extra_info') {
                this.repairSelectStep = 'extra_info';
                setTimeout(function () {
                    let element = document.getElementById('repinfo');
                    VueScrollTo.scrollTo(element, 200, options);
                }.bind(this), 200);
            }
        },
        getMandates() {
            if (this.incidentInfo.id_owner) {
                this.getOwnerMandates();
            }
            else {
                this.getDefaultMandates();
            }
        },
        getOwnerMandates() {
            let self = this;
            fetch('/api/v1/mandate/byowner?id_owner=' + this.incidentInfo.id_owner)
            .then(response => { response.json().then(
                function(data) {
                    self.mandates = data;
                    if (!self.mandates.length) {
                        self.getDefaultMandates();
                    }
                    else {
                        self.mandates = sortBy(self.mandates, 'level');
                        self.selectMandate(self.mandates[0]);
                    }
                    if (self.incidentInfo.quotation_value_supplier) {
                        self.selectMandate(false, true);
                    }
                });
            });
        },
        getDefaultMandates() {
            let self = this;
            fetch('/api/v1/defaultmandate')
            .then(response => { response.json().then(
                function(data) {
                    self.mandates = data;
                    if (!self.mandates.length) {
                        self.noMandatesError = true;
                    }
                    else {
                        self.mandates = sortBy(self.mandates, 'level');
                        self.selectMandate(self.mandates[0]);
                    }
                    if (self.incidentInfo.quotation_value_supplier) {
                        self.selectMandate(false, true);
                    }
                });
            });
        },
        selectMandate(mandate) {
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Opdrachtverlening - Mandaat gekozen',
            });
            this.mandatesDropdownToggled = false;
            if (mandate) {
                this.mandateSelected = true;
                this.selectedMandate = mandate;
            }
            else {
                let higherMandates = [];
                if (this.mandates.length) {
                    for (var i = 0; i < this.mandates.length; i++) {
                        let mandate = this.mandates[i];
                        if (mandate.amount >= this.incidentInfo.quotation_value_supplier) {
                            higherMandates.push(mandate);
                        }
                    }
                    this.selectedMandate = higherMandates[0];
                    if (!higherMandates.length) {
                        this.selectedMandate = this.mandates.at(-1);
                    }
                    this.mandateSelected = true;
                }
                else {
                    this.mandateSelected = false;
                }
            }
        },
        removeSelectedMandate() {
            this.mandatesDropdownToggled = false;
            this.selectedMandate = {};
            this.mandateSelected = false;
        },
        validateAmount() {
            this.tempMandate.amount = this.tempMandate.amount.replace(/[A-Za-z!@#$%^&*()]/g, '');
        },
        createIncidentMandate() {
            this.createNewMandate = false;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            if (this.mandateSelected && !this.tempMandate.amount) {
                if (this.selectedMandate.id_mandate) {
                    data.append('id_mandate', this.selectedMandate.id_mandate);
                    data.append('free_amount', false);
                }
                else {
                    data.append('free_amount', true);
                }
                data.append('amount', this.selectedMandate.amount);
                data.append('in_btw', this.selectedMandate.in_btw);
                data.append('free_amount', false);
            }
            if (this.tempMandate.amount) {
                this.tempMandate.amount = this.tempMandate.amount.replaceAll(',', '.');
                data.append('amount', this.tempMandate.amount);
                data.append('in_btw', this.tempMandate.in_btw);
                data.append('free_amount', true);
            }
            if (this.tempMandate.amount === 0) {
                this.mandateAmountError = true;
            }
            else {
                this.mandateAmountError = false;
            }
            if (this.mandateSelected) {
                this.mandateAmountError = false;
            }
            if ((!this.mandateAmountError && this.tempMandate.amount) || this.mandateSelected) {
                let self = this;
                fetch('/api/v1/incidentmandate/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Opdrachtverlening - Mandaat opgeslagen',
                    });
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getIncidentMandate();
                    self.$store.dispatch('updateMandateAdded');
                });
            }
        },
        removeIncidentMandate() {
            const data = new URLSearchParams();
            data.append('id_incident_mandate', this.incidentMandate.id_incident_mandate);
            let self = this;
            fetch('/api/v1/incidentmandate/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getIncidentMandate();
            });
        },
        validateEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.invoicePartySelected.invoice_email)) {
                this.validEmail = true;
                this.invalidEmail = false;
                this.invoiceError = false;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
                this.invoiceError = true;
            }
        },
        cancelEdit() {
            this.$emit('showCalendar', false);
            this.preStep = '';
            this.repairCompanyEdit = false;
            this.tempRepairCompany = {};
            this.selectedIdRepairCompany = null;
            this.invoiceAddress = '';
            this.invoiceCity = '';
            this.repairCompanyPresent = false;
            if (this.incidentInfo.canbe_id_repair_company === -1 || !this.incidentInfo.canbe_id_repair_company) {
                this.preStep = 'other';
            }
            this.$emit('commissionRemoved');
        },
        recommissionIncident() {
            let currentIncidentId = this.getUrlParameter('id');
            if (currentIncidentId && currentIncidentId === this.incidentInfo.id_incident.toString() && !this.incidentInfo.autodispatch) {
                let self = this;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                fetch('/api/v1/incident/cancelrepaircompany', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$emit('getIncidentInfo', false, true, true);
                    self.preStep = true;
                    self.selectedIdRepairCompany = 0;
                    self.preStep = self.preStepChoice;
                    self.repairCompanyPresent = false;
                    setTimeout(function () {
                        let element = document.getElementById('repblock');
                        VueScrollTo.scrollTo(element);
                    }.bind(this), 500);
                });
            }
        },
        resendEmail() {
            let self = this;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            fetch('/api/v1/incident/resendmailtorepaircompany', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Opdracht opnieuw versturen per e-mail',
                });
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        removeInvoice(invoice) {
            this.selectedInvoice = invoice;
            this.removeInvoiceModalOpen = true;
        },
        editInvoice(invoice) {
            this.selectedInvoice = invoice;
            this.editInvoiceModalOpen = true;
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        },
        startUploadQuotation() {
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Incident detail - Offerte toevoegen vanuit opdrachtverlening',
            });
            this.uploadQuotationModalOpen = true;
        },
        startUploadInvoice() {
            this.$gtag.event('click', {
                event_category: 'Button',
                event_label: 'Incident detail - Factuur toevoegen vanuit opdrachtverlening',
            });
            this.uploadInvoiceModalOpen = true;
        },
        saveUrgency() {
            this.incidentInfo.urgency_level = !this.incidentInfo.urgency_level;
            const data = new URLSearchParams();
            let urgencyLevel;
            if (this.incidentInfo.urgency_level) {
                urgencyLevel = 5;
            }
            else {
                urgencyLevel = 0;
            }
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('urgency_level', urgencyLevel);
            let self = this;
            fetch('/api/v1/incident/seturgencylevel', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Urgentie aanpassen',
                });
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
    }
}
</script>
