<template>
    <div class="block block--account block--border">
        <div class="account-info">
            <h4>{{profile.accountPage.account.general}}
                <span class="edit-button edit-button--small" @click="accountInfoEdit = true" v-if="!accountInfoEdit && admin" data-testid="accountEditButton"><i class="fas fa-pen"></i></span>
                <span class="edit-button edit-button--small" @click="saveAccountInfo()" v-if="accountInfoEdit" v-show="(passwordCorrect && passwordsMatch && newPassword.length) || !newPassword.length" v-tooltip.right="profile.buttons.save" data-testid="accountSaveButton"><i class="fas fa-save"></i></span>
                <span class="edit-button edit-button--small" @click="cancelAccountInfo()" v-if="accountInfoEdit" v-tooltip.right="profile.buttons.cancel"><i class="fas fa-times"></i></span>
            </h4>
            <div class="account-contents">
                <div v-if="!accountInfoEdit">
                    <label>{{profile.accountPage.account.password}}</label>
                    <p>•••••••••</p>
                    <div class="row" v-if="accountInfo.is_paid_account">
                        <div class="col">
                            <label>{{profile.accountPage.account.termsUrl}}</label>
                            <a data-testid="accountLinkText" :href="accountInfo.terms_conditions_url" target="blank" style="padding-right: 20px; word-break: break-all;">{{accountInfo.terms_conditions_url}}</a>
                        </div>
                    </div>
                </div>
                <div v-if="accountInfoEdit">
                    <div class="row">
                        <div class="col-lg-6">
                            <label>{{profile.accountPage.account.new_pw}}</label>
                            <input type="password" class="form-control" v-model="newPassword" @input="passwordCheck()" data-testid="accountInputPassword" />
                            <ul class="password-requirements" v-if="!passwordCorrect">
                                <li><i class="fas fa-times red" v-if="!passwordRequirements.characters"></i><i class="fas fa-check green" v-if="passwordRequirements.characters"></i> {{profile.accountPage.account.length}}</li>
                                <li><i class="fas fa-times red" v-if="!passwordRequirements.uppercase"></i><i class="fas fa-check green" v-if="passwordRequirements.uppercase"></i> {{profile.accountPage.account.ucase}}</li>
                                <li><i class="fas fa-times red" v-if="!passwordRequirements.lowercase"></i><i class="fas fa-check green" v-if="passwordRequirements.lowercase"></i> {{profile.accountPage.account.lcase}}</li>
                                <li><i class="fas fa-times red" v-if="!passwordRequirements.number"></i><i class="fas fa-check green" v-if="passwordRequirements.number"></i> {{profile.accountPage.account.nr}}</li>
                            </ul>
                        </div>
                        <div class="col-lg-6">
                            <label>{{profile.accountPage.account.confirm_new_pw}}</label>
                            <input type="password" class="form-control" v-model="newPasswordConfirm" @input="checkPasswords()" data-testid="accountInputConfirmPassword" />
                            <p class="password-requirements" v-if="!passwordsMatch"><b>{{profile.accountPage.account.match}}</b></p>
                        </div>
                    </div>
                    <label v-if="accountInfo.building_manager_admin">{{profile.accountPage.account.termsUrl}}</label>
                    <input type="text" class="form-control" v-model="accountInfo.terms_conditions_url" v-if="accountInfo.building_manager_admin && accountInfo.is_paid_account" data-testid="accountInputUrl" />
                    <a :href="accountInfo.terms_conditions_url" target="blank" v-else>{{accountInfo.terms_conditions_url}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';

export default {
    name: 'MainBlock',
    mixins: [setLocale],
    data: function () {
        return {
            accountInfoEdit: false,
            newPassword: '',
            newPasswordConfirm: '',
            passwordRequirements: {
                characters: false,
                uppercase: false,
                lowercase: false,
                number: false
            },
            passwordCorrect: true,
            passwordsMatch: true
        }
    },
    props: ['accountInfo'],
    computed: mapState(['admin']),
    methods: {
        saveAccountInfo() {
            let self = this;
            if (this.passwordCorrect && this.passwordsMatch && this.newPassword.length) {
                const data = new URLSearchParams();
                data.append('newpassword', this.newPassword);
                fetch('/api/v1/changepassword', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.accountInfoEdit = false;
                });
            }
            const params = new URLSearchParams();
            params.append('data[0][terms_conditions_url]', this.accountInfo.terms_conditions_url);
            params.append('action', 'edit');
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.accountInfoEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        cancelAccountInfo() {
            this.accountInfoEdit = false;
            this.newPassword = '';
            this.passwordConfirm = '';
            this.$emit('getAccountInfo');
        },
        passwordCheck() {
            const ucase = new RegExp("[A-Z]+");
            const lcase = new RegExp("[a-z]+");
            const num = new RegExp("[0-9]+");
            if (this.newPassword.length > 9) {
                this.passwordRequirements.characters = true;
            }
            else {
                this.passwordRequirements.characters = false;
                this.passwordCorrect = false;
            }
            if (ucase.test(this.newPassword)) {
                this.passwordRequirements.uppercase = true;
            }
            else {
                this.passwordRequirements.uppercase = false;
                this.passwordCorrect = false;
            }
            if (lcase.test(this.newPassword)) {
                this.passwordRequirements.lowercase = true;
            }
            else {
                this.passwordRequirements.lowercase = false;
                this.passwordCorrect = false;
            }
            if (num.test(this.newPassword)) {
                this.passwordRequirements.number = true;
            }
            else {
                this.passwordRequirements.number = false;
                this.passwordCorrect = false;
            }
            if (!this.newPassword.length) {
                this.passwordCorrect = true;
            }
            this.checkValidations();
        },
        checkValidations() {
            if (this.passwordRequirements.characters &&
                this.passwordRequirements.uppercase &&
                this.passwordRequirements.lowercase &&
                this.passwordRequirements.number &&
                this.newPassword === this.newPasswordConfirm) {
                    this.passwordCorrect = true;
            }
            else {
                this.passwordCorrect = false;
            }
            if (this.newPassword === this.newPasswordConfirm) {
                this.passwordsMatch = true;
            }
        },
        checkPasswords() {
            if (this.newPassword !== this.newPasswordConfirm) {
                this.passwordsMatch = false;
                this.passwordCorrect = false;
            }
            else {
                this.passwordsMatch = true;
            }
            this.checkValidations();
        }
    }
}
</script>
