<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>Nieuw specialisme</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="addSpecialism()">
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:specialismName.length}">
                                <label>{{"Specialisme naam"}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="specialismName" required>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="button button--cta button--icon"><i class="fa-solid fa-plus"></i> Specialisme toevoegen</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            specialismName: '',
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        addSpecialism() {
            let self = this;
            const data = new URLSearchParams();
            data.append('name', this.specialismName);
            fetch('/api/v1/specialism/create', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>