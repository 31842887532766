<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>Nieuwe melding categorie</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="addCategory()">
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="defaultCategoriesDropdownToggle = !defaultCategoriesDropdownToggle" :class="{active: defaultCategoriesDropdownToggle}">
                            <span v-if="fields.selectedDefaultCategory.id_default_incident_category" class="selected">{{fields.selectedDefaultCategory.dutch_name}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="defaultCategoriesDropdownToggle" :class="{search: defaultCategories.length > 9}">
                            <ul>
                                <li v-for="category in defaultCategories" v-on:click="selectDefaultCategory(category)" :key="category.id_default_incident_category">
                                    {{category.dutch_name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.dutch.length}">
                                <label>{{"Nederlands"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.dutch" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.english.length}">
                                <label>{{"Engels"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.english" >
                            </div>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field " :class="{focus:fields.ukranian.length}">
                                <label>{{"Oekraïens"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.ukranian" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.russian.length}">
                                <label>{{"Russisch"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.russian" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field " :class="{focus:fields.polish.length}">
                                <label>{{"Pools"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.polish" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field " :class="{focus:fields.italian.length}">
                                <label>{{"Italiaans"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.italian" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field " :class="{focus:fields.german.length}">
                                <label>{{"Duits"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.german" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field " :class="{focus:fields.french.length}">
                                <label>{{"Frans"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.french" >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field " :class="{focus:fields.contact_phone.length}">
                                <label>{{"Contact telefoon"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.contact_phone" >
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field " :class="{focus:fields.contact_url.length}">
                                <label>{{"Contact url"}}</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.contact_url" >
                            </div>
                        </div>
                    </div>  
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="managersDropwdownToggle = !managersDropwdownToggle" :class="{active: managersDropwdownToggle}">
                            <span v-if="fields.selectedManager" class="selected">{{getManagerName(fields.selectedManager)}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="managersDropwdownToggle" :class="{search: managers.length > 9}">
                            <ul>
                                <li v-for="manager in managers" v-on:click="selectManager(manager.id_building_manager)" :key="manager.id_building_manager">
                                    {{manager.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.list_order.length}">
                                <label>{{"Volgorde (voer een getal in)"}}</label>
                                <input type="number" ref="name" class="form-control" v-model="fields.list_order" >
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="optionsDropdownToggle = !optionsDropdownToggle" :class="{active: optionsDropdownToggle}">
                            <span v-if="fields.for_whom" class="selected">{{getOptionName(fields.for_whom)}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="optionsDropdownToggle">
                            <ul>
                                <li v-for="(option, index) in options" v-on:click="selectOption(index)" :key="index">
                                    {{option.label}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.active">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">{{"Actief?"}}</span>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.media_wanted">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">{{"Media gewenst?"}}</span>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.media_required">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">{{"Media verplicht?"}}</span>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="button button--cta button--icon"><i class="fa-solid fa-plus"></i> Melding categorie toevoegen</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            defaultCategoriesDropdownToggle: false,
            managersDropwdownToggle: false,
            optionsDropdownToggle: false,
            defaultCategories: [],
            fields: {
                selectedDefaultCategory: {},
                dutch: '',
                english: '',
                ukranian: '',
                russian: '',
                polish: '',
                italian: '',
                german: '',
                french: '', 
                contact_phone: '',
                contact_url: '',
                selectedManager: '',
                active: true,
                list_order: '',
                for_whom: '',
                media_wanted: false,
                media_required: false,
            },
            options: [
                { label: 'Niet van toepassing', category: 'N_2fA' },
                { label: 'Deze categorie gaat altijd naar mijzelf', category: 'ME' },
                { label: 'Deze categorie is altijd voor rekening van de verhuurder', category: 'OTHER' },
                { label: 'Voor deze categorie moeten we van de melder weten of deze huurder(indirect dus via een verhuurder met vve verbonden) of zelf-eigenaar is.', category: 'UNKNOWN' }
            ],
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getDefaultCategories();
        this.loadManagers();
        this.selectOption(0);
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getDefaultCategories() {
            let self = this;
            fetch('/api/v1/defaultincidentcategory?')
            .then(response => { response.json().then(
                function(data) {
                    self.defaultCategories = data.data;
                    self.selectDefaultCategory(self.defaultCategories[0]);
                });
            });
        },
        loadManagers(){
            let self = this;
            fetch('/api/v1/buildingmanager?')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                    self.selectManager(self.managers[0].id_building_manager);
                });
            });
        },
        getManagerName(id){
            const manager = this.managers.find(m => m.id_building_manager === id);
            return manager ? manager.name : '';
        },
        selectManager(managerID) {
            this.managersDropwdownToggle = false;
            this.fields.selectedManager = managerID;
        },
        selectDefaultCategory(category) {
            this.defaultCategoriesDropdownToggle = false;
            this.fields.selectedDefaultCategory = category;
        },
        selectOption(index) {
            this.fields.for_whom = this.options[index].category;
            this.optionsDropdownToggle = false;
        },
        getOptionName(category) {
            const selectedOption = this.options.find(option => option.category === category);
            return selectedOption ? selectedOption.label : '';
        },
        addCategory() {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][id_default_incident_category]', this.fields.selectedDefaultCategory.id_default_incident_category);
            data.append('data[0][dutch_name]', this.fields.dutch);
            data.append('data[0][english_name]', this.fields.english);
            data.append('data[0][ukranian_name]', this.fields.ukranian);
            data.append('data[0][russian_name]', this.fields.russian);
            data.append('data[0][polish_name]', this.fields.polish);
            data.append('data[0][italian_name]', this.fields.italian);
            data.append('data[0][german_name]', this.fields.german);
            data.append('data[0][french_name]', this.fields.french);
            data.append('data[0][preferred_contact_type]', "");
            data.append('data[0][contact_phone]', this.fields.contact_phone);
            data.append('data[0][contact_url]', this.fields.contact_url);
            data.append('data[0][id_building_manager]', this.fields.selectedManager);
            data.append('data[0][active]', this.fields.active);
            data.append('data[0][list_order]', this.fields.list_order);
            data.append('data[0][for_whom]', this.fields.for_whom);
            data.append('data[0][media_wanted]', this.fields.media_wanted);
            data.append('data[0][media_required]', this.fields.media_required);
            fetch('/api/v1/incident_category', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>