<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="far fa-trash-alt"></i> Objecttype categorie verwijderen </h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p> Weet u zeker dat u dit objecttype wilt verwijderen?</p>
                <button class="button button--icon button--right" v-on:click="removeAssetType()"><i class="far fa-trash-alt"></i>Objecttype verwijderen</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
export default {
    mixins: [setLocale],
    name: 'removeAssetTypeModal',
    props: ['assetType'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        removeAssetType() {
            const data = new URLSearchParams();
            data.append('action', 'remove');
            data.append('data['+this.assetType.id_asset_type+'][id_default_incident_assetType]', this.assetType.id_asset_type);
            data.append('data['+this.assetType.id_asset_type+'][display_name]', this.assetType.display_name);
            data.append('data['+this.assetType.id_asset_type+'][display_name]', this.assetType.constant_name);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_a]', this.assetType.extra_description_a);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_b]', this.assetType.extra_description_b);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_c]', this.assetType.extra_description_c);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_d]', this.assetType.extra_description_d);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_e]', this.assetType.extra_description_e);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_f]', this.assetType.extra_description_f);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_g]', this.assetType.extra_description_g);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_h]', this.assetType.extra_description_h);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_i]', this.assetType.extra_description_i);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_j]', this.assetType.extra_description_j);
            data.append('data['+this.assetType.id_asset_type+'][is_document_a]', this.assetType.is_document_a);
            data.append('data['+this.assetType.id_asset_type+'][is_document_b]', this.assetType.is_document_b);
            data.append('data['+this.assetType.id_asset_type+'][is_document_c]', this.assetType.is_document_c);
            data.append('data['+this.assetType.id_asset_type+'][is_document_d]', this.assetType.is_document_d);
            data.append('data['+this.assetType.id_asset_type+'][is_document_e]', this.assetType.is_document_e);
            data.append('data['+this.assetType.id_asset_type+'][is_document_f]', this.assetType.is_document_f);
            data.append('data['+this.assetType.id_asset_type+'][is_document_g]', this.assetType.is_document_g);
            data.append('data['+this.assetType.id_asset_type+'][is_document_h]', this.assetType.is_document_h);
            data.append('data['+this.assetType.id_asset_type+'][is_document_i]', this.assetType.is_document_i);
            data.append('data['+this.assetType.id_asset_type+'][id_industry]', this.assetType.id_industry);
            data.append('data['+this.assetType.id_asset_type+'][id_building_manager]', this.assetType.id_building_manager);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_a_default]', this.assetType.extra_description_a_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_b_default]', this.assetType.extra_description_b_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_c_default]', this.assetType.extra_description_c_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_d_default]', this.assetType.extra_description_d_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_e_default]', this.assetType.extra_description_e_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_f_default]', this.assetType.extra_description_f_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_g_default]', this.assetType.extra_description_g_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_h_default]', this.assetType.extra_description_h_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_i_default]', this.assetType.extra_description_i_default);
            data.append('data['+this.assetType.id_asset_type+'][extra_description_j_default]', this.assetType.extra_description_j_default);
            let self = this;
            fetch('/api/v1/asset_type', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>