<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Objecttypes</h1>
                                <span class="edit-button icon" @click="newAssetTypeModalOpen = true"><i class="fa-solid fa-plus"></i></span>
                            </section>
                            <div class="row">
                                <div class="col-sm-8">
                                    <section class="search search-alt" v-if="assetTypes.length">
                                        <form @submit.prevent="searchSuggest()">
                                            <input type="text" ref="search" v-bind:placeholder="'Zoek een objecttype'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                        </form>
                                    </section>
                                </div>
                            </div>
                            <div>
                                <h4 class="result-counter" v-if="!searchActive">{{assetTypes.length}} {{'objecttypes'}}</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="!searchSuggestions.length && searchActive">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                            </div>
                            <div class="table-wrap" style="height: 600px; overflow: auto;">
                                <table class="table table-striped" v-if="assetTypes.length && !searchActive">
                                    <colgroup>
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 12%;" />
                                        <col style="width: 10%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('display_name')" :class="{active: assetTypeSearchType === 'display_name'}">Display Naam<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('canconstant_nameonical')" :class="{active: assetTypeSearchType === 'constant_name'}">Canonieke naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('dutch_name')" :class="{active: assetTypeSearchType === 'dutch_name'}">Branche<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('english_name')" :class="{active: assetTypeSearchType === 'english_name'}">Beheerder<i class="fas fa-sort"></i></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(assetType, index) in assetTypesShown" :key="index">
                                            <td>{{assetType.display_name}}</td>
                                            <td>{{assetType.constant_name}}</td>
                                            <td>{{getIndustryName(assetType.id_industry) }}</td>
                                            <td>{{getManagerName(assetType.id_building_manager) }}</td>
                                            <td><i class="fas fa-pen" @click="editAssetTypeModalOpen = true; editAssetType=assetType"></i></td>
                                            <td><i class="far fa-trash-alt" @click="removeAssetTypeModalOpen = true; removeAssetType=assetType"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-striped" v-if="assetTypes.length && searchActive">
                                    <colgroup>
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 12%;" />
                                        <col style="width: 10%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('display_name')" :class="{active: assetTypeSearchType === 'display_name'}">Display Naam<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('canconstant_nameonical')" :class="{active: assetTypeSearchType === 'constant_name'}">Canonieke naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('dutch_name')" :class="{active: assetTypeSearchType === 'dutch_name'}">Branche<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('english_name')" :class="{active: assetTypeSearchType === 'english_name'}">Beheerder<i class="fas fa-sort"></i></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(assetType, index) in searchSuggestions" :key="index">
                                            <td>{{assetType.display_name}}</td>
                                            <td>{{assetType.constant_name}}</td>
                                            <td>{{getIndustryName(assetType.id_industry) }}</td>
                                            <td>{{getManagerName(assetType.id_building_manager) }}</td>
                                            <td><i class="fas fa-pen" @click="editAssetTypeModalOpen = true; editAssetType=assetType"></i></td>
                                            <td><i class="far fa-trash-alt" @click="removeAssetTypeModalOpen = true; removeAssetType=assetType"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <NewAssetTypeModal v-if="newAssetTypeModalOpen" @close="loadAssetTypes()" />  
        <RemoveAssetTypeModal v-if="removeAssetTypeModalOpen" :assetType="removeAssetType" @close="loadAssetTypes()" />
        <EditAssetTypeModal v-if="editAssetTypeModalOpen" :assetType="editAssetType" @close="loadAssetTypes()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy'
import NewAssetTypeModal from '@/views/s1mone/modals/NewAssetTypeModal.vue';
import RemoveAssetTypeModal from '@/views/s1mone/modals/RemoveAssetTypeModal';
import EditAssetTypeModal from '@/views/s1mone/modals/EditAssetTypeModal.vue';

export default {
    mixins: [setLocale],
    components: {
        NewAssetTypeModal,
        RemoveAssetTypeModal,
        EditAssetTypeModal
    },
    data() {
        return {
            searchTerm: '',
            searchSuggestions: [],
            assetTypes: [],
            assetTypesShown: [],
            managers: [],
            industries: [],
            searchActive: false,
            assetTypeSearchType: '',
            removeAssetType: {},
            newAssetTypeModalOpen: false,
            removeAssetTypeModalOpen: false,
            editAssetTypeModalOpen: false,
            editAssetType: {},
        }
    },
    created() {
        document.title = 'S1MONE | Asset Types';
        this.loadAssetTypes();
        this.loadManagers();
        this.loadIndustries();
    },
    methods: {
        loadAssetTypes() {
            let self = this;
            this.newAssetTypeModalOpen = false;
            this.removeAssetTypeModalOpen = false;
            this.editAssetTypeModalOpen = false;
            fetch('/api/v1/asset_type')
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    self.assetTypesShown = data.data;
                });
            });
        },
        loadManagers(){
            let self = this;
            fetch('/api/v1/buildingmanager?')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                });
            });
        },
        loadIndustries() {
            let self = this;
            fetch('/api/v1/industry?')
            .then(response => { response.json().then(
                function(data) {
                    self.industries = data.data;
                });
            });
        },
        getManagerName(id){
            const manager = this.managers.find(m => m.id_building_manager === id);
            return manager ? manager.name : '';
        },
        getIndustryName(id){
            const industry = this.industries.find(i => i.id_industry === id);
            return industry ? industry.display_name : '';
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.assetTypesShown.length; i++) {
                    let assetType = this.assetTypesShown[i];
                    let display = assetType.display_name.toLowerCase();
                    let canonical = assetType.constant_name.toLowerCase();
                    let industry = this.getIndustryName(assetType.id_industry).toLowerCase();
                    let manager = this.getManagerName(assetType.id_building_manager).toLowerCase();
                    if (display.includes(searchTerm) || canonical.includes(searchTerm) || industry.includes(searchTerm) || manager.includes(searchTerm)) {
                        this.searchSuggestions.push(assetType);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        sortTable(sorter) {
            this.assetTypeSearchType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.assetTypesShown = sortBy(this.assetTypesShown, sorter);
            if (this.sortOrder === 'desc') {
                this.assetTypesShown.reverse();
            }
        }
        // selectasset(id) {
        //     this.$router.push('/s1mone/buildingmanager-detail?id=' + id);
        // }
    }
}
</script>
