<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="loading" v-if="assetsLoading">
            <img src="@/assets/icons/loading.gif" />
        </div>
        <div class="loading" v-if="loading">
            <img src="@/assets/icons/loading.gif" />
            <span>{{profile.assets.assign_to}} {{uploadCounter}} {{profile.assets.assets}}</span>
        </div>
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-check-double"></i>{{profile.assets.assign_assettypes_title}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p v-if="assets.length === 1 && !allAssets">{{profile.assets.assign_assettypes_sure}} <b>{{selectedAssetTypeName}}</b> {{profile.assets.want_to_assign}} <b>{{assetCounter}}</b> {{profile.assets.asset}}?</p>
                <p v-if="assets.length > 1 && !allAssets">{{profile.assets.assign_assettypes_sure}} <b>{{selectedAssetTypeName}}</b> {{profile.assets.want_to_assign}} <b>{{assetCounter}}</b> {{profile.assets.assets}}?</p>
                <p v-if="allAssets">{{profile.assets.assign_assettypes_sure}} <b>{{selectedAssetTypeName}}</b> {{profile.assets.want_to_assign}} <b>{{totalAssets}}</b> {{profile.assets.assets}}?</p>
                <br />
                <section class="actions actions--full">
                    <div class="button-wrap">
                        <span class="button button--icon button--cta button--right" data-testid="typeGrantSubmit" @click="submitAssetTypes()"><i class="fas fa-check-double"></i>{{profile.assets.assign_assettypes}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    name: 'approveAssetModal',
    data() {
        return {
            assetsLoading: false,
            loading: false,
            profile: {},
            assets: [],
            uploadCounter: 0,
            totalCounter: 0
        }
    },
    props: ['allAssets', 'selectedAssets', 'selectedAssetTypeName', 'selectedAssetTypeToAssignId', 'selectedPlaceId', 'selectedAssetId', 'selectAssetsWithout', 'assetCounter', 'totalAssets'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        if (this.allAssets) {
            let self = this;
            let searchParam = '';
            let placeParam = '';
            let assetParam = '';
            let assetTypeParam = '';
            if (this.selectedPlaceId) {
                placeParam = '&id_place=' + this.selectedPlaceId;
            }
            if (this.selectedAssetId) {
                assetParam = '&search_name=' + this.selectedAssetId;
            }
            if (this.searchTerm) {
                searchParam = '&search_name=' + this.searchTerm;
            }
            if (this.selectedAssetTypeId) {
                assetTypeParam = '&id_asset_type=' + this.selectedAssetTypeId;
            }
            if (this.selectAssetsWithout) {
                assetTypeParam = '&id_asset_type=0';
            }
            this.assetsLoading = true;
            fetch('/api/v1/asset/filtered?limit=1000' + placeParam + assetParam + assetTypeParam + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    self.assets = data.data;
                    self.totalCounter = self.assets.length;
                    self.assetsLoading = false;
                });
            });
        }
        else {
            for (var i = 0; i < this.selectedAssets.length; i++) {
                let assetToAssign = this.selectedAssets[i];
                if (assetToAssign.tempIdAssetType) {
                    this.assets.push(assetToAssign);
                }
            }
        }
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        submitAssetTypes() {
            this.loading = true;
            if (!this.allAssets) {
                for (var i = 0; i < this.assets.length; i++) {
                    this.uploadCounter = this.assets.length;
                    let assetToAssign = this.assets[i];
                    const data = new URLSearchParams();
                    data.append('id_asset_type', this.selectedAssetTypeToAssignId);
                    data.append('id_asset', assetToAssign.id_asset);
                    let self = this;
                    fetch('/api/v1/asset/updateidassettype', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.text();
                    }).then(function(data) {
                        self.uploadCounter--;
                        if (!self.uploadCounter) {
                            self.loading = false;
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                            document.querySelector('body').classList.remove('modal-open');
                            self.$emit('saveAssetTypes');
                        }
                        self.$store.dispatch('updateGetStats');
                    });
                }
            }
            else {
                this.uploadCounter = this.assets.length;
                for (var i = 0; i < this.assets.length; i++) {
                    let assetToAssign = this.assets[i];
                    const data = new URLSearchParams();
                    data.append('id_asset_type', this.selectedAssetTypeToAssignId);
                    data.append('id_asset', assetToAssign.id_asset);
                    let self = this;
                    fetch('/api/v1/asset/updateidassettype', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.text();
                    }).then(function(data) {
                        self.uploadCounter--;
                        if (!self.uploadCounter) {
                            self.loading = false;
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                            document.querySelector('body').classList.remove('modal-open');
                            self.$emit('saveAssetTypes');
                        }
                        self.$store.dispatch('updateGetStats');
                    });
                }
            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
