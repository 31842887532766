<template>
    <div>
        <div class="back-to-scanner" @click="backToScanner()"><i class="fa fa-chevron-left"></i> Terug naar scanner</div>
        <div class="block block--actions" v-if="!connectSuccess && !replaceSuccess" style="overflow: visible; border-radius: 20px;">
            <section class="incident-head">
                <h1 style="width: 100%;">Nieuwe QR Code <span class="id_asset">{{assetId}}</span></h1>
            </section>
            <div v-if="!connect && !replaceExisting">
                <div class="row">
                    <div class="col">
                        <h3 style="margin-top: 20px;">Ik wil deze QR code:</h3>
                        <ul class="choice-list">
                            <span class="button button--icon" @click="connect = true;"><i class="fas fa-qrcode"></i>koppelen aan een object</span>
                            <span class="button button--icon" @click="replaceExisting = true" style="margin-bottom: 0;"><i class="fas fa-retweet"></i>vervangen voor bestaande QR-code</span>
                        </ul>
                    </div>
                </div>
            </div>
            <form v-on:submit.prevent="saveConnect()" v-if="connect">
                <h3 style="margin-top: 20px;">QR code koppelen aan een object</h3>
                <label v-if="!selectedPlace.id_place">Kies een locatie</label>
                <label v-if="selectedPlace.id_place">Geselecteerde locatie</label>
                <div class="form-field clearable" :class="{focus:placeSearchTerm.length}" style="margin-bottom: 10px;">
                    <label v-if="!selectedPlace.id_place">{{profile.incidents.new_incident.loc_placeholder}}</label>
                    <div>
                        <div class="selected-info seperate" v-if="selectedPlace.name">
                            <h4 style="color: #212529; margin-bottom: 4px;">{{selectedPlace.name}}</h4>
                            <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                            <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                        </div>
                        <input type="text" class="form-control" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" autofocus />
                    </div>
                </div>
                <ul v-if="places.length && !selectedPlace.id_place" class="dropdown-list" style="padding: 0; margin-top: -12px;">
                    <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" v-on:click="selectPlace(place)">
                        <h4>{{place.name}}</h4>
                        <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                        <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                    </li>
                </ul>
                <div v-if="selectedPlace.id_place">
                    <label v-if="!selectedAsset.id_asset && assetsByPlace.length">Kies een object</label>
                    <h3 v-if="!selectedAsset.id_asset && !assetsByPlace.length">Creëer een object</h3>
                    <span style="cursor: pointer; font-size: 18px; color: #5c5c5c; float: right; margin-right: 17px;" v-if="hasAssets && !assetsByPlace.length" @click="getAssetsById(selectedPlace.id_place)"><i class="fas fa-times"></i></span>
                    <label v-if="selectedAsset.id_asset">Geselecteerde object</label>
                    <div v-if="assetsByPlace.length">
                        <section class="search search-alt" style="margin-top: 0;" v-if="assetsByPlace.length > 7">
                            <form v-if="!selectedAsset.id_asset">
                                <input type="text" v-bind:placeholder="profile.assets.search_asset" @input="e => assetSearchTerm = e.target.value" :value="assetSearchTerm" v-on:keyup="searchAssets($event)" style="border-radius: 5px; margin-bottom: 10px;"/>
                                <span class="close-suggestions" @click="clearAssetSearch()" v-if="assetSearchTerm.length"><i class="fas fa-times"></i></span>
                            </form>
                        </section>
                        <div v-if="!assetSearchActive && !selectedAsset.name">
                            <div class="asset-block fullwidth" style="padding: 0" v-for="asset in assetsByPlace" :key="asset.id" @click.self="selectAsset(asset)">
                                <h4 style="color: #212529; float: none; padding: 10px 0 10px 10px; margin: 0;" @click="selectAsset(asset)">{{asset.name}}</h4>
                                <span class="id" style="top: 10px;" v-on:click="selectAsset(asset)">{{asset.id_asset}}</span>
                                <span class="show-more" v-if="asset.key_number || asset.asset_type_name || asset.extra_description_a || (asset.assetType && asset.assetType.extra_description_a_default)" @click="asset.showMore = !asset.showMore" :class="{active: asset.showMore}"><span v-if="!asset.showMore">Meer</span><span v-if="asset.showMore">Minder</span> <i class="fas fa-chevron-down chevron"></i></span>
                                <div v-show="asset.showMore" v-on:click="selectAsset(asset)" class="show-more-expanded" style="margin-bottom: -10px;">
                                    <div class="row" v-if="asset.key_number || (asset.assetType && asset.assetType.display_name)">
                                        <div class="col-sm-6" v-if="asset.key_number">
                                            <label>{{profile.assets.assetmodal.key_number}}</label>
                                            <p v-if="asset.key_number">{{asset.key_number}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.assetType.display_name">
                                            <label>{{profile.assets.assetmodal.assettype}}</label>
                                            <p v-if="asset.assetType.display_name">{{asset.assetType.display_name}}</p>
                                        </div>
                                    </div>
                                    <div v-if="asset.assetType">
                                        <div class="row" v-if="asset.assetType && (asset.extra_description_a || asset.assetType.extra_description_a_default)">
                                            <div class="col-sm-6" v-if="asset.extra_description_a || asset.assetType.extra_description_a_default">
                                                <label>{{asset.assetType.extra_description_a}}</label>
                                                <p v-if="asset.extra_description_a">{{asset.extra_description_a}}</p>
                                                <p v-else>{{asset.assetType.extra_description_a_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="asset.extra_description_b || asset.assetType.extra_description_b_default">
                                                <label>{{asset.assetType.extra_description_b}}</label>
                                                <p v-if="asset.extra_description_b">{{asset.extra_description_b}}</p>
                                                <p v-else>{{asset.assetType.extra_description_b_default}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="asset.assetType && (asset.extra_description_c || asset.assetType.extra_description_c_default)">
                                            <div class="col-sm-6" v-if="asset.extra_description_c || assetType.extra_description_c_default">
                                                <label>{{asset.assetType.extra_description_c}}</label>
                                                <p v-if="asset.extra_description_c">{{asset.extra_description_c}}</p>
                                                <p v-else>{{asset.assetType.extra_description_c_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="asset.extra_description_d || asset.assetType.extra_description_d_default">
                                                <label>{{asset.assetType.extra_description_d}}</label>
                                                <p v-if="asset.extra_description_d">{{asset.extra_description_d}}</p>
                                                <p v-else>{{asset.assetType.extra_description_d_default}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="asset.assetType && (asset.extra_description_e || asset.assetType.extra_description_e_default)">
                                            <div class="col-sm-6" v-if="asset.extra_description_e || assetType.extra_description_e_default">
                                                <label>{{asset.assetType.extra_description_e}}</label>
                                                <p v-if="asset.extra_description_e">{{asset.extra_description_e}}</p>
                                                <p v-else>{{asset.assetType.extra_description_e_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="asset.extra_description_f || asset.assetType.extra_description_f_default">
                                                <label>{{asset.assetType.extra_description_f}}</label>
                                                <p v-if="asset.extra_description_f">{{asset.extra_description_f}}</p>
                                                <p v-else>{{asset.assetType.extra_description_f_default}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="asset.assetType && (asset.extra_description_g || asset.assetType.extra_description_g_default)">
                                            <div class="col-sm-6" v-if="asset.extra_description_g || asset.assetType.extra_description_g_default">
                                                <label>{{asset.assetType.extra_description_g}}</label>
                                                <p v-if="asset.extra_description_g">{{asset.extra_description_g}}</p>
                                                <p v-else>{{asset.assetType.extra_description_g_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="asset.extra_description_h || asset.assetType.extra_description_h_default">
                                                <label>{{asset.assetType.extra_description_h}}</label>
                                                <p v-if="asset.extra_description_h">{{asset.extra_description_h}}</p>
                                                <p v-else>{{asset.assetType.extra_description_h_default}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="asset.assetType && (asset.extra_description_i || asset.assetType.extra_description_i_default)">
                                            <div class="col-sm-6" v-if="asset.extra_description_i || asset.assetType.extra_description_i_default">
                                                <label>{{asset.assetType.extra_description_i}}</label>
                                                <p v-if="asset.extra_description_i">{{asset.extra_description_i}}</p>
                                                <p v-else>{{asset.assetType.extra_description_i_default}}</p>
                                            </div>
                                            <div class="col-sm-6" v-if="asset.extra_description_j || asset.assetType.extra_description_j_default">
                                                <label>{{asset.assetType.extra_description_j}}</label>
                                                <p v-if="asset.extra_description_j">{{asset.extra_description_j}}</p>
                                                <p v-else>{{asset.assetType.extra_description_j_default}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="assetSearchActive && !selectedAsset.name">
                            <div class="asset-block fullwidth" style="padding: 0" v-for="asset in tempAssets" :key="asset.id" @click.self="selectAsset(asset)">
                                <h4 style="color: #212529; float: none; padding: 10px 0 10px 10px; margin: 0;" @click="selectAsset(asset)">{{asset.name}}</h4>
                                <span class="id" style="top: 10px;" @click="selectAsset(asset)">{{asset.id_asset}}</span>
                                <span class="show-more" v-if="asset.key_number || asset.asset_type_name || asset.extra_description_a || (asset.assetType && asset.assetType.extra_description_a_default)" @click="asset.showMore = !asset.showMore" :class="{active: asset.showMore}"><span v-if="!asset.showMore">Meer</span><span v-if="asset.showMore">Minder</span> <i class="fas fa-chevron-down chevron"></i></span>
                                <div v-show="asset.showMore" @click="selectAsset(asset)" class="show-more-expanded" style="margin-bottom: -10px;">
                                    <div class="row" v-if="asset.key_number || asset.asset_type_name">
                                        <div class="col-sm-6" v-if="asset.key_number">
                                            <label>{{profile.assets.assetmodal.key_number}}</label>
                                            <p v-if="asset.key_number">{{asset.key_number}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.asset_type_name">
                                            <label>{{profile.assets.assetmodal.assettype}}</label>
                                            <p v-if="asset.asset_type_name">{{asset.asset_type_name}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_a || asset.extra_description_b)">
                                        <div class="col-sm-6" v-if="asset.extra_description_a">
                                            <label>{{asset.assetType.extra_description_a}}</label>
                                            <p v-if="asset.extra_description_a">{{asset.extra_description_a}}</p>
                                            <p v-if="!asset.extra_description_a &&  (asset.assetType && asset.assetType.extra_description_a_default)">{{assetType.extra_description_a_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_b">
                                            <label>{{asset.assetType.extra_description_b}}</label>
                                            <p v-if="asset.extra_description_b">{{asset.extra_description_b}}</p>
                                            <p v-if="!asset.extra_description_b && asset.assetType.extra_description_b_default">{{assetType.extra_description_b_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_c || asset.extra_description_d)">
                                        <div class="col-sm-6" v-if="asset.extra_description_c">
                                            <label>{{asset.assetType.extra_description_c}}</label>
                                            <p v-if="asset.extra_description_c">{{asset.extra_description_c}}</p>
                                            <p v-if="!asset.extra_description_c && asset.assetType.extra_description_c_default">{{assetType.extra_description_c_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_d">
                                            <label>{{asset.assetType.extra_description_d}}</label>
                                            <p v-if="asset.extra_description_d">{{asset.extra_description_d}}</p>
                                            <p v-if="!asset.extra_description_d && asset.assetType.extra_description_d_default">{{assetType.extra_description_d_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_e || asset.extra_description_f)">
                                        <div class="col-sm-6" v-if="asset.extra_description_e">
                                            <label>{{asset.assetType.extra_description_e}}</label>
                                            <p v-if="asset.extra_description_e">{{asset.extra_description_e}}</p>
                                            <p v-if="!asset.extra_description_e && asset.assetType.extra_description_e_default">{{assetType.extra_description_e_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_f">
                                            <label>{{asset.assetType.extra_description_f}}</label>
                                            <p v-if="asset.extra_description_f">{{asset.extra_description_f}}</p>
                                            <p v-if="!asset.extra_description_f && asset.assetType.extra_description_f_default">{{assetType.extra_description_f_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_g || asset.extra_description_h)">
                                        <div class="col-sm-6" v-if="asset.extra_description_g">
                                            <label>{{asset.assetType.extra_description_g}}</label>
                                            <p v-if="asset.extra_description_g">{{asset.extra_description_g}}</p>
                                            <p v-if="!asset.extra_description_g && asset.assetType.extra_description_g_default">{{assetType.extra_description_g_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_h">
                                            <label>{{asset.assetType.extra_description_h}}</label>
                                            <p v-if="asset.extra_description_h">{{asset.extra_description_h}}</p>
                                            <p v-if="!asset.extra_description_h && asset.assetType.extra_description_h_default">{{assetType.extra_description_h_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="asset.assetType && (asset.extra_description_i || asset.extra_description_j)">
                                        <div class="col-sm-6" v-if="asset.extra_description_i">
                                            <label>{{asset.assetType.extra_description_i}}</label>
                                            <p v-if="asset.extra_description_i">{{asset.extra_description_i}}</p>
                                            <p v-if="!asset.extra_description_i && asset.assetType.extra_description_i_default">{{assetType.extra_description_i_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="asset.extra_description_j">
                                            <label>{{asset.assetType.extra_description_j}}</label>
                                            <p v-if="asset.extra_description_j">{{asset.extra_description_j}}</p>
                                            <p v-if="!asset.extra_description_j && asset.assetType.extra_description_j_default">{{assetType.extra_description_j_default}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="notification notification--error" v-if="!tempAssets.length && assetSearchActive">Geen objecten gevonden met deze zoekterm</div>
                        </div>
                        <span class="text-link" style="margin-top: 0;" @click="createNew()" v-if="!selectedAsset.name">+ Nieuw object</span>
                        <div class="selected-asset" v-if="selectedAsset.name">
                            <span class="remove-selection" @click="removeAssetSelection()"><i class="fas fa-times"></i></span>
                            <h3 style="margin-top: 0;">{{selectedAsset.name}}</h3>
                            <span class="id">{{selectedAsset.id_asset}}</span>
                            <div class="location-contents">
                                <div class="location-info">
                                    <div class="row" v-if="selectedAsset.key_number || selectedAsset.id_external">
                                        <div class="col-sm-6" v-if="selectedAsset.key_number">
                                            <label>{{profile.assets.assetmodal.key_number}}</label>
                                            <p>{{selectedAsset.key_number}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="selectedAsset.id_external && selectedAsset.id_external !== 'null'">
                                            <label>{{profile.assets.assetmodal.external_id}}</label>
                                            <p v-if="selectedAsset.id_external !== 'null'">{{selectedAsset.id_external}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="selectedAsset.assetType">
                                        <div class="col-sm-6">
                                            <label>{{profile.assets.assetmodal.assettype}}</label>
                                            <p>{{selectedAsset.assetType.display_name}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_a || selectedAsset.extra_description_b)">
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_a">
                                            <label>{{selectedAsset.assetType.extra_description_a}}</label>
                                            <p v-if="selectedAsset.extra_description_a">{{selectedAsset.extra_description_a}}</p>
                                            <p v-if="!selectedAsset.extra_description_a && selectedAsset.assetType.extra_description_a_default">{{assetType.extra_description_a_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_b">
                                            <label>{{selectedAsset.assetType.extra_description_b}}</label>
                                            <p v-if="selectedAsset.extra_description_b">{{selectedAsset.extra_description_b}}</p>
                                            <p v-if="!selectedAsset.extra_description_b && selectedAsset.assetType.extra_description_b_default">{{assetType.extra_description_b_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_c || selectedAsset.extra_description_d)">
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_c">
                                            <label>{{selectedAsset.assetType.extra_description_c}}</label>
                                            <p v-if="selectedAsset.extra_description_c">{{selectedAsset.extra_description_c}}</p>
                                            <p v-if="!selectedAsset.extra_description_c && selectedAsset.assetType.extra_description_c_default">{{assetType.extra_description_c_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_d">
                                            <label>{{selectedAsset.assetType.extra_description_d}}</label>
                                            <p v-if="selectedAsset.extra_description_d">{{selectedAsset.extra_description_d}}</p>
                                            <p v-if="!selectedAsset.extra_description_d && selectedAsset.assetType.extra_description_d_default">{{assetType.extra_description_d_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_e || selectedAsset.extra_description_f)">
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_e">
                                            <label>{{selectedAsset.assetType.extra_description_e}}</label>
                                            <p v-if="selectedAsset.extra_description_e">{{selectedAsset.extra_description_e}}</p>
                                            <p v-if="!selectedAsset.extra_description_e && selectedAsset.assetType.extra_description_e_default">{{assetType.extra_description_e_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_f">
                                            <label>{{selectedAsset.assetType.extra_description_f}}</label>
                                            <p v-if="selectedAsset.extra_description_f">{{selectedAsset.extra_description_f}}</p>
                                            <p v-if="!selectedAsset.extra_description_f && selectedAsset.assetType.extra_description_f_default">{{assetType.extra_description_f_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_g || selectedAsset.extra_description_h)">
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_g">
                                            <label>{{selectedAsset.assetType.extra_description_g}}</label>
                                            <p v-if="selectedAsset.extra_description_g">{{selectedAsset.extra_description_g}}</p>
                                            <p v-if="!selectedAsset.extra_description_g && selectedAsset.assetType.extra_description_g_default">{{assetType.extra_description_g_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_h">
                                            <label>{{selectedAsset.assetType.extra_description_h}}</label>
                                            <p v-if="selectedAsset.extra_description_h">{{selectedAsset.extra_description_h}}</p>
                                            <p v-if="!selectedAsset.extra_description_h && selectedAsset.assetType.extra_description_h_default">{{assetType.extra_description_h_default}}</p>
                                        </div>
                                    </div>
                                    <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_i || selectedAsset.extra_description_j)">
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_i">
                                            <label>{{selectedAsset.assetType.extra_description_i}}</label>
                                            <p v-if="selectedAsset.extra_description_i">{{selectedAsset.extra_description_i}}</p>
                                            <p v-if="!selectedAsset.extra_description_i && selectedAsset.assetType.extra_description_i_default">{{assetType.extra_description_i_default}}</p>
                                        </div>
                                        <div class="col-sm-6" v-if="selectedAsset.extra_description_j">
                                            <label>{{selectedAsset.assetType.extra_description_j}}</label>
                                            <p v-if="selectedAsset.extra_description_j">{{selectedAsset.extra_description_j}}</p>
                                            <p v-if="!selectedAsset.extra_description_j && selectedAsset.assetType.extra_description_j_default">{{assetType.extra_description_j_default}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="row">
                            <div class="col-sm-6">
                                <label>Objectnaam</label>
                                <div class="form-field" :class="{focus:fields.assetName.length}">
                                    <label>{{profile.assets.assettable.name}}</label>
                                    <input type="text" class="form-control" v-model="fields.assetName" />
                                </div>
                            </div>
                            <div class="col-sm-6" v-if="assetTypes.length">
                                <label>{{profile.assets.objecttype}}</label>
                                <div class="dropdown-section">
                                    <span class="dropdown" :class="{active: assetTypesDropdownToggled, selected: selectedAssetTypeId}">
                                        <span v-if="!selectedAssetTypeId" class="placeholder" @click="assetTypesDropdownToggled = !assetTypesDropdownToggled">{{profile.assets.choose_objecttype}}</span>
                                        <span v-if="selectedAssetTypeId" class="selected" @click="assetTypesDropdownToggled = !assetTypesDropdownToggled">{{selectedAssetTypeName}}</span>
                                        <span v-if="selectedAssetTypeId" class="remove-selection" @click="removeAssetTypeSelection()"><i class="fas fa-times"></i></span>
                                        <span v-if="selectedAssetTypeId" class="seperator">|</span>
                                        <i class="fas fa-chevron-down chevron" @click="assetTypesDropdownToggled = !assetTypesDropdownToggled"></i>
                                    </span>
                                    <div class="dropdown-list absolute" v-if="assetTypesDropdownToggled" :class="{search: assetTypes.length > 9}">
                                        <ul>
                                            <li @click="selectAssetType('none')">Geen</li>
                                            <li v-for="assetType in assetTypes" @click="selectAssetType(assetType)" :key="assetType.id">{{assetType.display_name}}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button type="submit" class="button button--right button--icon" :disabled="!newObjectComplete" :class="{disabled: !newObjectComplete}" style="margin-bottom: 0;"><i class="fas fa-cube"></i>Koppelen aan object</button>
                    </div>
                </div>
            </form>
            <form v-on:submit.prevent="saveReplaceExisting()" v-if="replaceExisting">
                <h3>Vervangen QR-code voor bestaande QR-code</h3>
                <label v-if="!selectedPlace.id_place">Kies een locatie</label>
                <label v-if="selectedPlace.id_place">Geselecteerde locatie</label>
                <div class="form-field clearable" :class="{focus:placeSearchTerm.length}" style="margin-bottom: 10px;">
                    <label v-if="!selectedPlace.id_place">{{profile.incidents.new_incident.loc_placeholder}}</label>
                    <div>
                        <div class="selected-info seperate" v-if="selectedPlace.name">
                            <h4 style="color: #212529; margin-bottom: 4px;">{{selectedPlace.name}}</h4>
                            <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                            <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                        </div>
                        <input type="text" class="form-control" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlacesWithQr()" v-if="!selectedPlace.name" autofocus />
                    </div>
                </div>
                <ul v-if="places.length && !selectedPlace.id_place" class="dropdown-list" style="padding: 0; margin-top: -12px;">
                    <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" v-on:click="selectPlace(place, 'allAssets')">
                        <h4>{{place.name}}</h4>
                        <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                        <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                    </li>
                </ul>
                <div v-if="selectedPlace.id_place">
                    <label v-if="!selectedAsset.id_asset">Kies een object</label>
                    <label v-if="selectedAsset.id_asset">Geselecteerd object</label>
                    <section class="search search-alt" style="margin-top: 0;" v-if="assetsByPlace.length > 7">
                        <form v-if="!selectedAsset.id_asset">
                            <input type="text" v-bind:placeholder="profile.assets.search_asset" @input="e => assetSearchTerm = e.target.value" :value="assetSearchTerm" v-on:keyup="searchAssets($event)" style="border-radius: 5px; margin-bottom: 10px;"/>
                            <span class="close-suggestions" @click="clearAssetSearch()" v-if="assetSearchTerm.length"><i class="fas fa-times"></i></span>
                        </form>
                    </section>
                    <div v-if="!assetSearchActive && !selectedAsset.name">
                        <div class="asset-block fullwidth" style="padding: 0" v-for="asset in assetsByPlace" :key="asset.id">
                            <h4 style="color: #212529; float: none; padding: 10px 0 10px 10px; margin: 0;" @click="selectAsset(asset)">{{asset.name}}</h4>
                            <span class="id" style="top: 10px;" v-on:click="selectAsset(asset)">{{asset.id_asset}}</span>
                            <span class="show-more" v-if="asset.key_number || asset.asset_type_name || asset.extra_description_a || (asset.assetType && asset.assetType.extra_description_a_default)" @click="asset.showMore = !asset.showMore" :class="{active: asset.showMore}"><span v-if="!asset.showMore">Meer</span><span v-if="asset.showMore">Minder</span> <i class="fas fa-chevron-down chevron"></i></span>
                            <div v-show="asset.showMore" v-on:click="selectAsset(asset)" class="show-more-expanded" style="margin-bottom: -10px;">
                                <div class="row" v-if="asset.key_number || asset.asset_type_name">
                                    <div class="col-sm-6" v-if="asset.key_number">
                                        <label>{{profile.assets.assetmodal.key_number}}</label>
                                        <p v-if="asset.key_number">{{asset.key_number}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.asset_type_name">
                                        <label>{{profile.assets.assetmodal.assettype}}</label>
                                        <p v-if="asset.asset_type_name">{{asset.asset_type_name}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_a || asset.assetType.extra_description_a_default)">
                                    <div class="col-sm-6" v-if="asset.extra_description_a || asset.assetType.extra_description_a_default">
                                        <label>{{asset.assetType.extra_description_a}}</label>
                                        <p v-if="asset.extra_description_a">{{asset.extra_description_a}}</p>
                                        <p v-else>{{asset.assetType.extra_description_a_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_b || asset.assetType.extra_description_b_default">
                                        <label>{{asset.assetType.extra_description_b}}</label>
                                        <p v-if="asset.extra_description_b">{{asset.extra_description_b}}</p>
                                        <p v-else>{{asset.assetType.extra_description_b_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_c || asset.assetType.extra_description_c_default)">
                                    <div class="col-sm-6" v-if="asset.extra_description_c || assetType.extra_description_c_default">
                                        <label>{{asset.assetType.extra_description_c}}</label>
                                        <p v-if="asset.extra_description_c">{{asset.extra_description_c}}</p>
                                        <p v-else>{{asset.assetType.extra_description_c_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_d || asset.assetType.extra_description_d_default">
                                        <label>{{asset.assetType.extra_description_d}}</label>
                                        <p v-if="asset.extra_description_d">{{asset.extra_description_d}}</p>
                                        <p v-else>{{asset.assetType.extra_description_d_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_e || asset.assetType.extra_description_e_default)">
                                    <div class="col-sm-6" v-if="asset.extra_description_e || assetType.extra_description_e_default">
                                        <label>{{asset.assetType.extra_description_e}}</label>
                                        <p v-if="asset.extra_description_e">{{asset.extra_description_e}}</p>
                                        <p v-else>{{asset.assetType.extra_description_e_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_f || asset.assetType.extra_description_f_default">
                                        <label>{{asset.assetType.extra_description_f}}</label>
                                        <p v-if="asset.extra_description_f">{{asset.extra_description_f}}</p>
                                        <p v-else>{{asset.assetType.extra_description_f_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_g || asset.assetType.extra_description_g_default)">
                                    <div class="col-sm-6" v-if="asset.extra_description_g || asset.assetType.extra_description_g_default">
                                        <label>{{asset.assetType.extra_description_g}}</label>
                                        <p v-if="asset.extra_description_g">{{asset.extra_description_g}}</p>
                                        <p v-else>{{asset.assetType.extra_description_g_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_h || asset.assetType.extra_description_h_default">
                                        <label>{{asset.assetType.extra_description_h}}</label>
                                        <p v-if="asset.extra_description_h">{{asset.extra_description_h}}</p>
                                        <p v-else>{{asset.assetType.extra_description_h_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_i || asset.assetType.extra_description_i_default)">
                                    <div class="col-sm-6" v-if="asset.extra_description_i || asset.assetType.extra_description_i_default">
                                        <label>{{asset.assetType.extra_description_i}}</label>
                                        <p v-if="asset.extra_description_i">{{asset.extra_description_i}}</p>
                                        <p v-else>{{asset.assetType.extra_description_i_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_j || asset.assetType.extra_description_j_default">
                                        <label>{{asset.assetType.extra_description_j}}</label>
                                        <p v-if="asset.extra_description_j">{{asset.extra_description_j}}</p>
                                        <p v-else>{{asset.assetType.extra_description_j_default}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="assetSearchActive && !selectedAsset.name">
                        <div class="asset-block fullwidth" style="padding: 0" v-for="asset in tempAssets" :key="asset.id">
                            <h4 style="color: #212529; float: none; padding: 10px 0 10px 10px; margin: 0;" @click="selectAsset(asset)">{{asset.name}}</h4>
                            <span class="id" style="top: 10px;" v-on:click="selectAsset(asset)">{{asset.id_asset}}</span>
                            <span class="show-more" v-if="asset.key_number || asset.asset_type_name || asset.extra_description_a  || asset.extra_description_a_default" @click="asset.showMore = !asset.showMore" :class="{active: asset.showMore}"><span v-if="!asset.showMore">Meer</span><span v-if="asset.showMore">Minder</span> <i class="fas fa-chevron-down chevron"></i></span>
                            <div v-show="asset.showMore" v-on:click="selectAsset(asset)" class="show-more-expanded" style="margin-bottom: -10px;">
                                <div class="row" v-if="asset.key_number || asset.asset_type_name">
                                    <div class="col-sm-6" v-if="asset.key_number">
                                        <label>{{profile.assets.assetmodal.key_number}}</label>
                                        <p v-if="asset.key_number">{{asset.key_number}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.asset_type_name">
                                        <label>{{profile.assets.assetmodal.assettype}}</label>
                                        <p v-if="asset.asset_type_name">{{asset.asset_type_name}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_a || asset.extra_description_b)">
                                    <div class="col-sm-6" v-if="asset.extra_description_a">
                                        <label>{{asset.assetType.extra_description_a}}</label>
                                        <p v-if="asset.extra_description_a">{{asset.extra_description_a}}</p>
                                        <p v-if="!asset.extra_description_a && (asset.assetType && asset.assetType.extra_description_a_default)">{{assetType.extra_description_a_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_b">
                                        <label>{{asset.assetType.extra_description_b}}</label>
                                        <p v-if="asset.extra_description_b">{{asset.extra_description_b}}</p>
                                        <p v-if="!asset.extra_description_b && asset.assetType.extra_description_b_default">{{assetType.extra_description_b_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_c || asset.extra_description_d)">
                                    <div class="col-sm-6" v-if="asset.extra_description_c">
                                        <label>{{asset.assetType.extra_description_c}}</label>
                                        <p v-if="asset.extra_description_c">{{asset.extra_description_c}}</p>
                                        <p v-if="!asset.extra_description_c && asset.assetType.extra_description_c_default">{{assetType.extra_description_c_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_d">
                                        <label>{{asset.assetType.extra_description_d}}</label>
                                        <p v-if="asset.extra_description_d">{{asset.extra_description_d}}</p>
                                        <p v-if="!asset.extra_description_d && asset.assetType.extra_description_d_default">{{assetType.extra_description_d_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_e || asset.extra_description_f)">
                                    <div class="col-sm-6" v-if="asset.extra_description_e">
                                        <label>{{asset.assetType.extra_description_e}}</label>
                                        <p v-if="asset.extra_description_e">{{asset.extra_description_e}}</p>
                                        <p v-if="!asset.extra_description_e && asset.assetType.extra_description_e_default">{{assetType.extra_description_e_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_f">
                                        <label>{{asset.assetType.extra_description_f}}</label>
                                        <p v-if="asset.extra_description_f">{{asset.extra_description_f}}</p>
                                        <p v-if="!asset.extra_description_f && asset.assetType.extra_description_f_default">{{assetType.extra_description_f_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_g || asset.extra_description_h)">
                                    <div class="col-sm-6" v-if="asset.extra_description_g">
                                        <label>{{asset.assetType.extra_description_g}}</label>
                                        <p v-if="asset.extra_description_g">{{asset.extra_description_g}}</p>
                                        <p v-if="!asset.extra_description_g && asset.assetType.extra_description_g_default">{{assetType.extra_description_g_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_h">
                                        <label>{{asset.assetType.extra_description_h}}</label>
                                        <p v-if="asset.extra_description_h">{{asset.extra_description_h}}</p>
                                        <p v-if="!asset.extra_description_h && asset.assetType.extra_description_h_default">{{assetType.extra_description_h_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="asset.assetType && (asset.extra_description_i || asset.extra_description_j)">
                                    <div class="col-sm-6" v-if="asset.extra_description_i">
                                        <label>{{asset.assetType.extra_description_i}}</label>
                                        <p v-if="asset.extra_description_i">{{asset.extra_description_i}}</p>
                                        <p v-if="!asset.extra_description_i && asset.assetType.extra_description_i_default">{{assetType.extra_description_i_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="asset.extra_description_j">
                                        <label>{{asset.assetType.extra_description_j}}</label>
                                        <p v-if="asset.extra_description_j">{{asset.extra_description_j}}</p>
                                        <p v-if="!asset.extra_description_j && asset.assetType.extra_description_j_default">{{assetType.extra_description_j_default}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="notification notification--error" v-if="!tempAssets.length && assetSearchActive">Geen objecten gevonden met deze zoekterm</div>
                    </div>
                    <div class="selected-asset" v-if="selectedAsset.name">
                        <span class="remove-selection" @click="removeAssetSelection()"><i class="fas fa-times"></i></span>
                        <h3 style="margin-top: 0;">{{selectedAsset.name}}</h3>
                        <span class="id">{{selectedAsset.id_asset}}</span>
                        <div class="location-contents">
                            <div class="location-info">
                                <div class="row" v-if="selectedAsset.key_number || selectedAsset.id_external">
                                    <div class="col-sm-6" v-if="selectedAsset.key_number">
                                        <label>{{profile.assets.assetmodal.key_number}}</label>
                                        <p>{{selectedAsset.key_number}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAsset.id_external && selectedAsset.id_external !== 'null'">
                                        <label>{{profile.assets.assetmodal.external_id}}</label>
                                        <p v-if="selectedAsset.id_external !== 'null'">{{selectedAsset.id_external}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="selectedAsset.assetType">
                                    <div class="col-sm-6">
                                        <label>{{profile.assets.assetmodal.assettype}}</label>
                                        <p>{{selectedAsset.assetType.display_name}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_a || selectedAsset.extra_description_b)">
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_a">
                                        <label>{{selectedAsset.assetType.extra_description_a}}</label>
                                        <p v-if="selectedAsset.extra_description_a">{{selectedAsset.extra_description_a}}</p>
                                        <p v-if="!selectedAsset.extra_description_a && selectedAsset.assetType.extra_description_a_default">{{assetType.extra_description_a_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_b">
                                        <label>{{selectedAsset.assetType.extra_description_b}}</label>
                                        <p v-if="selectedAsset.extra_description_b">{{selectedAsset.extra_description_b}}</p>
                                        <p v-if="!selectedAsset.extra_description_b && selectedAsset.assetType.extra_description_b_default">{{assetType.extra_description_b_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_c || selectedAsset.extra_description_d)">
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_c">
                                        <label>{{selectedAsset.assetType.extra_description_c}}</label>
                                        <p v-if="selectedAsset.extra_description_c">{{selectedAsset.extra_description_c}}</p>
                                        <p v-if="!selectedAsset.extra_description_c && selectedAsset.assetType.extra_description_c_default">{{assetType.extra_description_c_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_d">
                                        <label>{{selectedAsset.assetType.extra_description_d}}</label>
                                        <p v-if="selectedAsset.extra_description_d">{{selectedAsset.extra_description_d}}</p>
                                        <p v-if="!selectedAsset.extra_description_d && selectedAsset.assetType.extra_description_d_default">{{assetType.extra_description_d_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_e || selectedAsset.extra_description_f)">
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_e">
                                        <label>{{selectedAsset.assetType.extra_description_e}}</label>
                                        <p v-if="selectedAsset.extra_description_e">{{selectedAsset.extra_description_e}}</p>
                                        <p v-if="!selectedAsset.extra_description_e && selectedAsset.assetType.extra_description_e_default">{{assetType.extra_description_e_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_f">
                                        <label>{{selectedAsset.assetType.extra_description_f}}</label>
                                        <p v-if="selectedAsset.extra_description_f">{{selectedAsset.extra_description_f}}</p>
                                        <p v-if="!selectedAsset.extra_description_f && selectedAsset.assetType.extra_description_f_default">{{assetType.extra_description_f_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_g || selectedAsset.extra_description_h)">
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_g">
                                        <label>{{selectedAsset.assetType.extra_description_g}}</label>
                                        <p v-if="selectedAsset.extra_description_g">{{selectedAsset.extra_description_g}}</p>
                                        <p v-if="!selectedAsset.extra_description_g && selectedAsset.assetType.extra_description_g_default">{{assetType.extra_description_g_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_h">
                                        <label>{{selectedAsset.assetType.extra_description_h}}</label>
                                        <p v-if="selectedAsset.extra_description_h">{{selectedAsset.extra_description_h}}</p>
                                        <p v-if="!selectedAsset.extra_description_h && selectedAsset.assetType.extra_description_h_default">{{assetType.extra_description_h_default}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="selectedAsset.assetType && (selectedAsset.extra_description_i || selectedAsset.extra_description_j)">
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_i">
                                        <label>{{selectedAsset.assetType.extra_description_i}}</label>
                                        <p v-if="selectedAsset.extra_description_i">{{selectedAsset.extra_description_i}}</p>
                                        <p v-if="!selectedAsset.extra_description_i && selectedAsset.assetType.extra_description_i_default">{{assetType.extra_description_i_default}}</p>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAsset.extra_description_j">
                                        <label>{{selectedAsset.assetType.extra_description_j}}</label>
                                        <p v-if="selectedAsset.extra_description_j">{{selectedAsset.extra_description_j}}</p>
                                        <p v-if="!selectedAsset.extra_description_j && selectedAsset.assetType.extra_description_j_default">{{assetType.extra_description_j_default}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="selectedAsset.name">
                    <div class="col">
                        <div class="notification notification--warn">Let op! De QR code van het geselecteerde object wordt vervangen met de QR code van het gescande object!</div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button type="submit" class="button button--right button--icon" :disabled="!this.selectedAsset.name" :class="{disabled: !this.selectedAsset.name}" style="margin-bottom: 0;"><i class="fas fa-cube"></i>QR code van dit object vervangen</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="block block--actions" style="border-radius: 20px;" v-if="connectSuccess || replaceSuccess">
            <section class="incident-head">
                <h1 style="width: 100%;">QR code gekoppeld</h1>
            </section>
            <div class="notification notification--success">
                Gelukt! De QR code is gekoppeld aan object <router-link tag="a" style="display: inline;" v-bind:to="'/buildingmanager/asset-detail?asset=' + newAssetId">{{newAssetName}}</router-link>.
            </div>
            <span class="button button--right button--icon" style="margin: 10px 0 0 0;" v-if="connectSuccess" @click="resetAll('ConnectQr')"><i class="fas fa-qrcode"></i>Nog een object koppelen</span>
            <span class="button button--right button--icon" style="margin: 10px 0 0 0;" v-if="replaceSuccess" @click="resetAll('ReplaceQr')"><i class="fas fa-qrcode"></i>Nog een QR code vervangen</span>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    name: 'connectQr',
    data() {
        return {
            profile: {},
            selectedAsset: {},
            assetSearchTerm: '',
            assetSearchActive: false,
            tempAssets: [],
            placesDropdownToggled: false,
            selectedPlace: {},
            placeSearchTerm: '',
            places: [],
            tempPlaces: [],
            connect: false,
            connectSuccess: false,
            replaceSuccess: false,
            replaceExisting: false,
            replaceExistingSuccess: false,
            asset: {},
            assets: [],
            assetsByPlace: [],
            locationInfo: {},
            assetType: {},
            assetTypesDropdownToggled: false,
            selectedAssetTypeId: 0,
            selectedAssetTypeName: '',
            assetTypes: [],
            fields: {
                assetName: ''
            },
            newAssetId: 0,
            newAssetName: '',
            hasAssets: false
        }
    },
    computed: {
        newObjectComplete() {
            if (this.assetsByPlace.length) {
                if (this.selectedAsset.id_asset) {
                    return true
                }
                else {
                    return false
                }
            }
            else {
                if (this.fields.assetName) {
                    return true
                }
                else {
                    return false
                }
            }
        }
    },
    props: ['qrCode', 'assetId', 'flow', 'selected_place'],
    created() {
        this.getAssetTypes();
        if (this.flow === 'ConnectQr') {
            this.connect = true;
        }
        if (this.flow === 'ReplaceQr') {
            this.replaceExisting = true;
        }
        if (!this.flow) {
            this.selectedPlace = false;
        }
        if (this.selected_place.id_place && this.flow) {
            this.selectedPlace = this.selected_place;
            this.getAssetsById(this.selectedPlace.id_place);
        }
    },
    methods: {
        getAssetsById(id_place, type) {
            this.hasAssets = false;
            this.assetsByPlace = [];
            let self = this;
            fetch('/api/v1/asset/activebyplace?id_place=' + id_place)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let asset = data.data[i];
                        asset.showMore = false;
                        for (var j = 0; j < self.assetTypes.length; j++) {
                            let assetType = self.assetTypes[j];
                            if (asset.id_asset_type === assetType.id_asset_type) {
                                asset.assetType = assetType;
                            }
                        }
                        if (type) {
                            if (asset.name) {
                                self.assetsByPlace.push(asset);
                            }
                        }
                        else {
                            if (!asset.qr_code && asset.name) {
                                self.assetsByPlace.push(asset);
                            }
                        }
                    }
                    console.log(self.assetsByPlace);
                });
            });
        },
        getAssetTypes() {
            let self = this;
            fetch('/api/v1/asset_type')
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                });
            });
        },
        selectAsset(asset) {
            this.selectedAsset = asset;
            this.tempAssets = [];
            this.assetSearchTerm = '';
        },
        searchAssets() {
            this.tempAssets = [];
            if (this.assetSearchTerm.length > 1) {
                this.assetSearchActive = true;
                let searchTerm = this.assetSearchTerm.toLowerCase();
                for (var i = 0; i < this.assetsByPlace.length; i++) {
                    let asset = this.assetsByPlace[i];
                    let assetName = asset.name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssets.push(asset);
                    }
                }
            }
            else {
                this.assetSearchActive = false;
            }
        },
        clearAssetSearch() {
            this.assetSearchTerm = '';
            this.tempAssets = [];
            this.assetSearchActive = false;
        },
        togglePlacesDropdown() {
            this.placesDropdownToggled = !this.placesDropdownToggled;
        },
        selectPlace(selectedPlace, type) {
            this.places = [];
            this.placesDropdownToggled = false;
            this.selectedPlace = selectedPlace;
            this.placeSearchTerm = '';
            this.getAssetsById(selectedPlace.id_place);
            if (type) {
                this.getAssetsById(selectedPlace.id_place, 'allAssets');
            }
        },
        searchPlaces() {
            if (this.placeSearchTerm.length > 2) {
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '&search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/withownerinfo?limit=100' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            self.places.push(place);
                        }
                        if (!self.places) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
            }
        },
        searchPlacesWithQr() {
            if (this.placeSearchTerm.length > 2) {
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '?search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/bybuildingmanageronlywithassets' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            self.places.push(place);
                        }
                        if (!self.places) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
            }
        },
        removePlaceSelection() {
            this.selectedPlace = {};
            this.places = [];
            this.selectedAsset = {};
            this.asset = {};
        },
        removeAssetSelection() {
            this.selectedAsset = {};
            this.asset = {};
            this.tempAssets = [];
            this.assetSearchActive = false;
        },
        removeAssetTypeSelection() {
            this.placesDropdownToggled = false;
            this.assetTypesDropdownToggled = false;
            this.selectedAssetTypeId = 0;
        },
        selectAssetType(assetType) {
            this.assetTypesDropdownToggled = false;
            this.selectedAssetTypeName = assetType.display_name;
            if (assetType === 'none') {
                this.selectedAssetTypeId = 0;
            }
            else {
                this.selectedAssetTypeId = assetType.id_asset_type;
            }
        },
        createNew() {
            this.assetsByPlace = [];
            this.hasAssets = true;
        },
        saveConnect() {
            const data = new URLSearchParams();
            data.append('qr_code', this.qrCode);
            data.append('id_place', this.selectedPlace.id_place);
            if (this.selectedAsset.id_asset) {
                data.append('existing_asset', this.selectedAsset.id_asset);
                data.append('name', this.selectedAsset.name);
            }
            else {
                data.append('id_asset', this.assetId);
                data.append('name', this.fields.assetName);
                data.append('holder', true);
            }
            let self = this;
            fetch('/api/v1/updateasset', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.connectSuccess = true;
                self.newAssetId = data.id_asset;
                self.newAssetName = data.name;
            });
        },
        saveReplaceExisting() {
            const data = new URLSearchParams();
            data.append('qr_code', this.qrCode);
            data.append('existing_asset', this.selectedAsset.id_asset);
            data.append('id_place', this.selectedPlace.id_place);
            let self = this;
            fetch('/api/v1/updateasset', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.replaceSuccess = true;
                self.newAssetId = data.id_asset;
                self.newAssetName = data.name;
            });
        },
        saveAssetType() {
            const data = new URLSearchParams();
            data.append('id_asset', this.newAssetId);
            data.append('id_asset_type', this.selectedAssetTypeId);
            let self = this;
            fetch('/api/v1/asset/updateidassettype', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
        },
        resetAll(type) {
            let flowObject = {
                type: type,
                selectedPlace: this.selectedPlace
            }
            this.$emit('reset', flowObject);
        },
        backToScanner() {
            this.$emit('reset');
        }
    }
}
</script>
