<template>
    <div v-if="profile.places">
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block block--assets">
                    <section class="incident-head">
                        <h1 data-testid="newLocTitle">{{profile.places.addaplace}}</h1>
                    </section>
                    <form @submit.prevent>
                        <br />
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-field" :class="{focus:fields.name.length}" style="cursor: pointer;">
                                    <label>{{profile.places.placetable.name}} *</label>
                                    <input type="text" ref="name" class="form-control" data-testid="newLocName" v-model="fields.name" required>
                                    <span class="tooltip-indicator" v-if="industry.includes('housing')" v-tooltip.right="profile.places.name_tooltip"><i class="fas fa-info-circle"></i></span>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <select class="form-select" v-model="fields.country" data-testid="newLocCountry" required @change="clearValues()">
                                    <option disabled>{{profile.places.placetable.country}} *</option>
                                    <option value="NL">{{profile.places.placetable.NL}}</option>
                                    <option value="FR">{{profile.places.placetable.FR}}</option>
                                    <option value="ES">{{profile.places.placetable.ES}}</option>
                                    <option value="BE">{{profile.places.placetable.BE}}</option>
                                    <option value="DE">{{profile.places.placetable.DE}}</option>
                                    <option value="IT">{{profile.places.placetable.IT}}</option>
                                    <option value="LU">{{profile.places.placetable.LU}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-field" :class="{focus:fields.zipcode.length, error: !validZipCode}">
                                    <label>{{profile.places.placetable.zipcode}} *</label>
                                    <input type="text" class="form-control" data-testid="newLocZipcode" v-model="fields.zipcode" required @input="zipCodeValidation()">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-field" :class="{focus:fields.house_number.length}">
                                    <label>{{profile.places.placetable.house_number}} *</label>
                                    <input type="text" class="form-control" data-testid="newLocHouseNumber" v-model="fields.house_number" required @input="zipCodeValidation()">
                                </div>
                            </div>
                        </div>
                        <div class="notification notification--error" style="margin-bottom: 17px;" v-if="addressError"><i class="fas fa-exclamation-triangle"></i> {{profile.places.placetable.valid_zip}}</div>
                        <div class="row" v-if="!addressError">
                            <div class="col-md-6" v-if="fields.address || fields.country !== 'NL'">
                                <div class="form-field" :class="{focus:fields.address.length}">
                                    <label>{{profile.places.placetable.address}}</label>
                                    <input type="text" class="form-control" data-testid="newLocAddress" v-model="fields.address" :disabled="fields.country === 'NL'">
                                </div>
                            </div>
                            <div class="col-md-6" v-if="fields.city || fields.country !== 'NL'">
                                <div class="form-field" :class="{focus:fields.city.length}">
                                    <label>{{profile.places.placetable.city}}</label>
                                    <input type="text" class="form-control" data-testid="newLocCity" v-model="fields.city" :disabled="fields.country === 'NL'">
                                </div>
                            </div>
                        </div>
                        <div v-if="industry !== 'leasing'">
                            <p v-if="existingPlaces.length && !existingPlacesOtherBms.length" style="font-weight: 500; font-size: 16px; border: 1px solid #d9d9d9; padding: 13px; border-radius: 5px;">{{profile.places.placetable.known_locations_self}}</p>
                            <p v-if="existingPlaces.length && existingPlacesOtherBms.length" style="font-weight: 500; font-size: 16px; border: 1px solid #d9d9d9; padding: 13px; border-radius: 5px;" v-html="profile.places.placetable.known_locations_bm"></p>
                            <p v-if="!existingPlaces.length && existingPlacesOtherBms.length" style="font-weight: 500; font-size: 16px; border: 1px solid #d9d9d9; padding: 13px; border-radius: 5px;" v-html="profile.places.placetable.known_locations"></p>
                            <label v-if="existingPlaces.length && (!locationChoice || locationChoice === 'ownLocation')" style="font-weight: 500; font-size: 14px; margin-bottom: 5px; clear: both;">{{profile.places.places_known}} <i class="fas fa-info-circle" v-tooltip.right="profile.places.floor_tooltip"></i></label>
                            <div class="existing-places" v-show="existingPlaces.length && (!locationChoice || locationChoice === 'ownLocation')" style="margin-bottom: 15px;">
                                <div class="block existing-place"  v-for="(place, index) in existingPlaces" :class="{active: place.toggled}" :key="index" v-show="place.floor_name" @click="getAssets(place)">
                                    <p>{{place.name}}<br />
                                    <span style="font-weight: 100;">{{ place.address }} {{ place.house_number }}, {{ place.zipcode }} {{ place.city }} <br />{{ profile.places.placetable.floor }}: {{ place.floor_name }}</span>
                                    </p>
                                    <i class="fas fa-chevron-down chevron"></i>
                                    <div v-if="place.toggled && place.assets.length">
                                        <span style="font-weight: 500; display: block; margin: 10px 0;">{{profile.assets.tablename}}</span>
                                        <div v-for="(asset, index) in place.assets" :key="index" class="asset-collection" v-show="asset.name">
                                            <div class="asset-item" :class="{assettype:asset.asset_type_name}">
                                                <label class="asset-type" v-if="asset.asset_type_name" v-tooltip.right="'Object typenaam'"><i class="fas fa-cube"></i> {{asset.asset_type_name}}</label>
                                                <h4>{{asset.name}}</h4>
                                                <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                                <span class="inactive-label" v-if="asset.hidden && asset.qr_code">{{profile.assets.inactive}}</span>
                                                <span class="indication-label" v-if="asset.qr_code">{{asset.id_asset}}</span>
                                                <div class="properties">
                                                    <span class="property inactive" v-if="!asset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.no_sticker}}</span>
                                                    <span class="property" v-if="asset.qr_code"><i class="fas fa-qrcode"></i>{{profile.assets.sticker}}</span>
                                                    <span class="property" v-if="asset.qr_code"><i class="fas fa-umbrella"></i> {{profile.assets.main_object}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                            
                                </div>
                            </div>
                            <label v-if="existingPlacesOtherBms.length && (!locationChoice || locationChoice === 'bmLocation')" style="font-weight: 500; font-size: 14px; margin-bottom: 5px; clear: both;">{{profile.places.places_known_other_bm}}</label>
                            <div class="existing-places" v-show="existingPlacesOtherBms && (!locationChoice || locationChoice === 'bmLocation')">
                                <div class="block existing-place" v-for="(place, index) in existingPlacesOtherBms" :class="{active: place.toggled}" :key="index" @click="getAssets(place)">
                                    <p>{{place.name}}<br />
                                    <span style="font-weight: 100;">{{ place.address }} {{ place.house_number }}, {{ place.zipcode }} {{ place.city }} <br />{{ profile.places.placetable.floor }}: {{ place.floor_name }}</span>
                                    </p>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </div>
                            </div>
                            <hr v-if="existingPlaces.length" />
                            <div class="row" v-if="existingPlaces.length && existingPlacesOtherBms.length && !locationChoice">
                                <div class="col">
                                    <p style="font-size: 16px; font-weight: 500; margin-bottom: 20px;">{{profile.places.placetable.want_create}}</p>
                                    <div class="button-wrap">
                                        <span class="button button--right" @click="locationChoiceSelection('bmLocation')">{{profile.places.placetable.copy_location}}</span>
                                        <span class="button button--right" @click="locationChoiceSelection('ownLocation')">{{profile.places.placetable.known_location_floor}}</span>
                                    </div>
                                </div>
                            </div>     
                            <p v-if="locationChoice === 'ownLocation'" style="display: block; font-weight: 500;">{{profile.places.placetable.create_location_other}}</p>
                            <p v-if="locationChoice === 'bmLocation'" style="display: block; font-weight: 500; margin-top: 20px;">{{profile.places.placetable.create_location_other_bm}}</p>                 
                        </div> 
                        <div class="row" v-if="industry === 'leasing' && existingPlaces.length" style="margin-bottom: 20px;">
                            <div class="col">
                                <p style="font-weight: 500; font-size: 16px; float: left;">{{profile.places.places_known}} <i class="fas fa-info-circle" v-tooltip.right="profile.places.floor_tooltip"></i></p>
                                <div style="clear: both;">
                                    <div class="block existing-place floor" v-for="(place, index) in existingPlaces" :key="index" v-show="place.floor_name" style="cursor: initial;">
                                        <p>{{place.floor_name}}</p>                       
                                    </div>
                                </div>                              
                            </div>     
                        </div>
                        <div class="row" v-if="locationChoice">
                            <div class="col-md-6" v-if="industry !== 'leasing'">
                                <select class="form-select" v-model="fields.floor_name" data-testid="newLocFloor" required v-if="locale === 'nl'">
                                    <option :value="null" disabled>{{profile.places.placetable.floor}} *</option>
                                    <option v-for="(option, index) in floorOptions" :value="option.value" :disabled="option.disabled" :key="index">{{option.name_nl}}</option>
                                </select>
                                <select class="form-select" v-model="fields.floor_name" data-testid="newLocFloor" required v-if="locale === 'en'">
                                    <option :value="null" disabled>{{profile.places.placetable.floor}} *</option>
                                    <option v-for="(option, index) in floorOptions" :value="option.value" :disabled="option.disabled" :key="index">{{option.name_en}}</option>
                                </select>
                            </div>
                            <div class="col-md-6" v-if="industry === 'leasing'">
                                <div class="form-field" :class="{focus:fields.floor_name, warn: floorError}">
                                    <label>{{profile.places.placetable.floor}} *</label>
                                    <input type="text" class="form-control" data-testid="newLocFloor" v-model="fields.floor_name" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row" v-if="!ownerId">
                                    <div class="col-11" :class="{'col-12': createdOwnerId}" >
                                        <div class="dropdown-section">
                                            <span class="dropdown" data-testid="newLocOwnerDropdown" v-on:click="ownersDropdownToggled = !ownersDropdownToggled" :class="{active: ownersDropdownToggled, error: ownerError}">
                                                <span v-if="!selectedOwnerId">{{profile.places.placetable.owner}} *</span>
                                                <span v-if="selectedOwnerId" class="selected">{{selectedOwnerName}}</span>
                                                <i class="fas fa-chevron-down chevron"></i>
                                            </span>
                                            <div class="dropdown-list" data-testid="newLocOwnerDropdownList" v-if="ownersDropdownToggled" style="padding-top: 10px;">
                                                <input type="text" data-testid="newLocOwnerSearch" :placeholder="profile.places.search_owner" @input="e => ownersSearchTerm = e.target.value" :value="ownersSearchTerm" @keyup="searchOwners()" v-if="owners.length > 2" />
                                                <ul>
                                                    <li v-for="owner in owners" v-on:click="selectOwner(owner)" :key="owner.id_owner" v-show="!tempOwners.length && !ownersNoResults">{{owner.name}}</li>
                                                    <li v-for="(owner, index) in tempOwners" v-on:click="selectOwner(owner)" :key="index" v-show="tempOwners.length">{{owner.name}}</li>
                                                    <li v-if="ownersNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>                                  
                                    <div style="float: right; width: 20px; margin-left: 0; padding: 0;" v-if="!createdOwnerId">
                                        <div class="edit-button icon" @click="ownerEdit($event)" data-testid="newLocOwnerEditSubmit" style="position: relative; right: 0; top: 8px;">
                                            <i class="fa-solid fa-plus" data-testid="newOwnerButton" style="font-size: 22px;"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col">
                                        <label v-if="vve">{{profile.owners.ownerdetail.vve}}</label>
                                        <label v-else>{{profile.owners.ownerdetail.owner}}</label>
                                        <p>{{ selectedOwnerName }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sub-form" v-if="createNewOwner">
                            <h4>{{profile.owners.ownertable.createowner}}</h4>
                            <span class="close" @click="cancelCreateOwner()"><i class="fa fa-times"></i></span>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-field" :class="{focus:ownerFields.name.length}">
                                        <label>{{profile.repair_companies.repaircompanytable.name}} *</label>
                                        <input type="text" data-testid="newLocOwnerName" ref="name" class="form-control" v-model="ownerFields.name" required>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-field" :class="{focus:ownerFields.email.length, valid: validEmail, error: invalidEmail}">
                                        <label>{{profile.incidents.new_incident.emailaddress}}</label>
                                        <input type="text" data-testid="newLocOwnerEmail" class="form-control" v-model="ownerFields.email" @input="validateEmail()">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <vue-tel-input v-model="ownerFields.mobile" data-testid="newLocOwnerMobile" class="form-control" v-bind="bindProps" style="padding-left: 0;" :class="{valid: validMobile, error: invalidMobile}" @input="validateMobile"></vue-tel-input>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-field" :class="{focus:ownerFields.phone.length, valid: validPhone, error: invalidPhone}">
                                        <label>{{profile.incidents.new_incident.phone}}</label>
                                        <input type="text" class="form-control" data-testid="newLocOwnerPhone" v-model="ownerFields.phone" @input="validatePhone()">
                                    </div>
                                </div>
                            </div>
                            <div class="form-field" :class="{focus:ownerFields.description.length}">
                                <label>{{profile.owners.ownertable.description}}</label>
                                <input type="text" class="form-control" data-testid="newLocOwnerDescription" v-model="ownerFields.description">
                            </div>
                            <div class="switch-wrapper switch-wrapper--full" :class="{active: ownerIsManager}">
                                <div class="inner" @click="ownerIsManager = !ownerIsManager">
                                    <i class="fa-solid fa-user-tie"></i>
                                    <p>{{profile.owners.ownertable.owner_manager}}</p>
                                    <span class="label">{{profile.switches.owner_is_manager}}</span>
                                    <span class="label"></span>
                                    <label class="switch" style="float: left;" data-testid="newLocOwnerIsManager">
                                        <span class="slider round" data-testid="newLocOwnerSwitch"></span>
                                    </label>
                                </div>      
                            </div>
                            <div class="form-field clearable" :class="{focus:managerSearchTerm.length}" style="margin: 20px 0 10px 0;" v-if="ownerIsManager">
                                <div>
                                    <div class="selected-info seperate" v-if="selectedManagerId" style="position: relative;">
                                        <h4 style="margin: 5px 0; font-size: 16px;">{{selectedManagerName}}</h4>
                                        <span v-if="selectedManagerId" class="remove-selection" @click="clearSelectedManager()" style="top: 0; cursor: pointer;"><i class="fas fa-times"></i></span>
                                    </div>
                                    <input type="text" ref="place" data-testid="newLocManagerSearch" :placeholder="profile.owners.ownertable.select_owner" class="form-control" @input="e => managerSearchTerm = e.target.value" :value="managerSearchTerm" @keyup="searchManagers()" v-if="!selectedManagerId" />
                                </div>
                            </div>
                            <ul v-if="tempManagers.length" class="dropdown-list" data-testid="newLocManagerDropdown" style="padding: 0; margin-top: -12px;">
                                <li v-for="manager in tempManagers" :key="manager.id" class="dropdown-list-item" v-on:click="selectManager(manager)">
                                    <h4 data-testid="newLocManagerList" style="margin: 5px 0; font-size: 16px;">{{manager.name}}</h4>
                                </li>
                            </ul>
                            <div class="row">
                                <div class="col">
                                    <span class="button button--icon" @click="createOwner()" id="ownerbutton" data-testid="newLocOwnerSubmit"><i class="fas fa-toolbox"></i>{{profile.owners.ownertable.createowner}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="notification notification--warn" v-if="floorError">{{profile.places.floor_error}}.</div>
                        <span class="text-link" data-testid="newAssetLink" @click="assetEdit()" v-if="!createNewAsset && !createNewOwner && fields.floor_name">+ {{profile.places.placetable.create_asset}}</span>
                        <div class="sub-form" v-if="createNewAsset">
                            <h4>{{profile.assets.add_an_object}}</h4>
                            <span class="close" @click="cancelCreateAsset()"><i class="fa fa-times"></i></span>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label>{{profile.assets.assettable.name}} *</label>
                                    <div class="form-field" :class="{focus:assetFields.name.length}">
                                        <label>{{profile.assets.assettable.name}}</label>
                                        <input type="text" class="form-control" data-testid="newLocAssetName" v-model="assetFields.name" required />
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="dropdown-section" style="margin-bottom: 20px;">
                                        <label>{{profile.assets.select_objecttype}}</label>
                                        <span class="dropdown" data-testid="newLocAssetTypeDropdown" :class="{active: assetTypeDropdownToggled}" v-on:click="assetTypeDropdownToggled = !assetTypeDropdownToggled">
                                            <span v-if="!selectedAssetType.id_asset_type" class="placeholder">{{profile.assets.select_objecttype}}</span>
                                            <span v-if="selectedAssetType.id_asset_type" class="selected">{{selectedAssetType.display_name}}</span>
                                            <i class="fas fa-chevron-down chevron"></i>
                                        </span>
                                        <div class="dropdown-list" data-testid="newLocAssetTypeDropdownList" v-if="assetTypeDropdownToggled" :class="{search: assetTypes.length > 9}">
                                            <input type="text" data-testid="newLocAssetTypeSearch" @input="e => assetTypeSearchTerm = e.target.value" :value="assetTypeSearchTerm" :placeholder="profile.assets.select_objecttype" @keyup="searchAssetTypes()" v-if="assetTypes.length > 9"  />
                                            <ul>
                                                <li data-testid="newLocAssetTypeDropdownResult" v-on:click="deselectAssetType()" v-if="!assetTypeNoResult && !tempAssetTypes.length">{{profile.assets.none}}</li>
                                                <li data-testid="newLocAssetTypeDropdownResult" v-for="assetType in assetTypes" v-on:click="selectAssetType(assetType)" :key="assetType.id" v-show="!tempAssetTypes.length && !assetTypeNoResult">{{assetType.display_name}}</li>
                                                <li data-testid="newLocAssetTypeDropdownResult" v-for="(assetType, index) in tempAssetTypes" v-on:click="selectAssetType(assetType)" :key="index" v-show="tempAssetTypes.length">{{assetType.display_name}}</li>
                                                <li data-testid="newLocAssetTypeDropdownResult" v-if="assetTypeNoResult" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="selectedAssetType.id_asset_type">
                                <label>{{profile.assets.objecttype_fields}}</label>
                                <div class="row">
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_a">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_a_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_a}}</label>
                                            <input type="text" data-testid="newLocAssetType_a" class="form-control" v-model="assetTypeFields.extra_description_a" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_b">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_b_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_b}}</label>
                                            <input type="text" data-testid="newLocAssetType_b" class="form-control" v-model="assetTypeFields.extra_description_b" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_c">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_c_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_c}}</label>
                                            <input type="text" data-testid="newLocAssetType_c" class="form-control" v-model="assetTypeFields.extra_description_c" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_d">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_d_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_d}}</label>
                                            <input type="text" data-testid="newLocAssetType_d" class="form-control" v-model="assetTypeFields.extra_description_d" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_e">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_e_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_e}}</label>
                                            <input type="text" data-testid="newLocAssetType_e" class="form-control" v-model="assetTypeFields.extra_description_e" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_f">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_f_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_f}}</label>
                                            <input type="text" data-testid="newLocAssetType_f" class="form-control" v-model="assetTypeFields.extra_description_f" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_g">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_g_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_g}}</label>
                                            <input type="text" data-testid="newLocAssetType_g" class="form-control" v-model="assetTypeFields.extra_description_g" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_h">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_h_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_h}}</label>
                                            <input type="text" data-testid="newLocAssetType_h" class="form-control" v-model="assetTypeFields.extra_description_h" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_i">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_i_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_i}}</label>
                                            <input type="text" data-testid="newLocAssetType_i" class="form-control" v-model="assetTypeFields.extra_description_i" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                    <div class="col-sm-6" v-if="selectedAssetType.extra_description_j">
                                        <div class="form-field focus" :class="{focus:selectedAssetType.extra_description_j_default}">
                                            <label style="font-weight: 500;">{{selectedAssetType.extra_description_j}}</label>
                                            <input type="text" data-testid="newLocAssetType_j" class="form-control" v-model="assetTypeFields.extra_description_j" style="margin-bottom: 10px;" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="notification notification--error" v-if="nameError">{{profile.assets.fill_name}}.</div>
                        <button type="submit" data-testid="newLocSubmit" @click="createNewLocation()" class="button button--icon" v-if="!createNewOwner && !createNewAsset && fields.floor_name"><i class="fas fa-map-marker-alt"></i>{{profile.places.addplace}}</button>
                        <button type="submit" data-testid="newLocObjectSubmit" @click="createNewLocation('object')" class="button button--icon" v-if="!createNewOwner && createNewAsset" id="assetbutton"><i class="fas fa-map-marker-alt"></i>{{profile.places.addplace_object}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import { VueTelInput } from 'vue-tel-input';
import sortBy from 'lodash/sortBy';
import debounce from 'lodash/debounce';

export default {
    mixins: [setLocale],
    name: 'newLocationModal',
    components: {
        VueTelInput
    },
    data() {
        return {
            profile: {},
            owners: [],
            tempOwners: [],
            ownersNoResults: false,
            fields: {
                name: '',
                address: '',
                house_number: '',
                zipcode: '',
                city: '',
                country: 'NL',
                floor_name: null,
                owner: ''
            },
            ownerFields: {
                kvk: '',
                name: '',
                email: '',
                description: '',
                phone: '',
                mobile: ''
            },
            assetFields: {
                name: ''
            },
            ownersSearchTerm: '',
            ownersDropdownToggled: false,
            selectedOwnerId: 0,
            selectedOwnerName: '',
            validZipCode: true,
            addressError: false,
            existingPlaces: [],
            existingPlacesOtherBms: [],
            floorOptions: [],
            createNewOwner: false,
            validEmail: false,
            invalidEmail: false,
            validPhone: false,
            invalidPhone: false,
            validMobile: false,
            invalidMobile: false,
            ownerIsManager: false,
            managers: [],
            tempManagers: [],
            createdOwnerId: 0,
            createNewAsset: false,
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                onlyCountries: ["NL", "BE", "DE", "GB", "FR", "ES", "PT", "LU", "IT"],
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder:'',
                }
            },
            places: [],
            nameError: false,
            floorError: false,
            floorErrorShown: false,
            assetTypes: [],
            tempAssetTypes: [],
            assetTypeNoResult: false,
            assetTypeDropdownToggled: false,
            assetTypeSearchTerm: '',
            selectedAssetType: {},
            createdPlace: {},
            ownerError: false,
            ownerformElement: null,
            managerDropdownToggled: false,
            selectedManagerId: 0,
            selectedManagerName: '',
            managerSearchTerm: '',
            noManagersResults: false,
            assetTypeFields: {
                extra_description_a: '',
                extra_description_b: '',
                extra_description_c: '',
                extra_description_d: '',
                extra_description_e: '',
                extra_description_f: '',
                extra_description_g: '',
                extra_description_h: '',
                extra_description_i: '',
                extra_description_j: '',
            },
            ownerId: null,
            locationChoice: null
        }
    },
    computed: mapState(['bmId', 'vve']),
    created() {
        this.bindProps.inputOptions.placeholder = this.profile.accountPage.account.contactmobile;
        let self = this;
        fetch('/floors.json')
        .then(response => { response.json().then(
            function(data) {
                for (var i = 0; i < data.floors.length; i++) {
                    let option = data.floors[i];
                    option.disabled = false;
                    self.floorOptions.push(option);
                }
            });
        });
        this.getOwners();
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        let queryString = window.location.search;
        this.ownerId = queryString.split('=')[1];
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getOwners() {
            let self = this;
            fetch('/api/v1/owner/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.owners = data.data;
                    if (self.createdOwnerId) {
                        self.selectedOwnerId = self.createdOwnerId;
                    }
                    for (var i = 0; i < self.owners.length; i++) {
                        let owner = self.owners[i];
                        if (owner.id_owner === self.createdOwnerId) {
                            self.selectedOwnerName = owner.name;
                        }
                        if (self.ownerId === owner.id_owner.toString()) {
                            self.selectedOwnerName = owner.name;
                            self.selectedOwnerId = owner.id_owner;
                        }
                    }
                });
            });
        },
        getAssetTypes() {
            let self = this;
            fetch('/api/v1/asset_type/bybuildingmanager?id_building_manager=' + this.bmId)
            .then(response => { response.json().then(
                function(data) {
                    self.assetTypes = data.data;
                    self.assetTypes = sortBy(self.assetTypes, 'display_name');
                });
            });
        },
        zipCodeValidation: debounce(function (e) {
            this.locationChoice = null;
            this.fields.floor_name = null;
            this.existingPlaces = [];
            this.existingPlacesOtherBms = [];
            if (this.fields.country === 'NL') {
                let self = this;
                let zipcodeRegex = new RegExp('^[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}$');
                this.validZipCode = zipcodeRegex.test(this.fields.zipcode);
                if (this.validZipCode && this.fields.house_number) {
                    setTimeout(function () {
                        fetch('/api/v1/zipcode/getaddress?zipcode=' + this.fields.zipcode + '&housenumber=' + this.fields.house_number)
                        .then(response => {
                            if (!response.ok) {
                                // Handle 400 or 404 errors
                                if (response.status === 400 || response.status === 404) {
                                    console.error('Error:', response.statusText);
                                    self.addressError = true;
                                    return;
                                }
                            }
                            // If response is OK, parse JSON
                            return response.json();
                        })
                        .then(data => {
                           if (data.message) {
                                self.addressError = true;
                            } else {
                                self.addressError = false;
                                self.fields.address = data.street;
                                self.fields.city = data.city;
                                self.zipCodeSearch();
                                if (self.industry === 'leasing') {
                                    self.locationChoice = 'ownLocation';
                                }
                            }
                        })
                        .catch(error => {
                            console.error('Fetch error:', error);
                            self.addressError = true;
                        });
                    }.bind(this), 500);
                }
            }
             if (this.fields.country === 'ES') {
                let zipcodeRegex = new RegExp('^(0[1-9]|[1-4][0-9]|5[0-2])[0-9]{3}$');
                this.validZipCode = zipcodeRegex.test(this.fields.zipcode);
                if (this.validZipCode) {
                    this.zipCodeSearch();
                }
                else {
                    this.validZipCode = false;
                }
            }
            if (this.fields.country === 'FR' || this.fields.country === 'DE' || this.fields.country === 'IT') {
                let zipcodeRegex = new RegExp('^[0-9]{5}$');
                this.validZipCode = zipcodeRegex.test(this.fields.zipcode);
                if (this.validZipCode) {
                    this.zipCodeSearch();
                }
                else {
                    this.validZipCode = false;
                }
            }
            if (this.fields.country === 'BE') {
                let zipcodeRegex = new RegExp('^[1-9][0-9]{3}$');
                this.validZipCode = zipcodeRegex.test(this.fields.zipcode);
                if (this.validZipCode) {
                    this.zipCodeSearch();
                }
                else {
                    this.validZipCode = false;
                }
            }
            if (this.fields.country === 'LU') {
                let zipcodeRegex = new RegExp('^[0-9]{4}$');
                this.validZipCode = zipcodeRegex.test(this.fields.zipcode);
                if (this.validZipCode) {
                    this.zipCodeSearch();
                }
                else {
                    this.validZipCode = false;
                }
            }
        }, 500),
        zipCodeSearch() {
            this.existingPlaces = [];
            this.existingPlacesOtherBms = [];
            let self = this;
            for (var j = 0; j < this.floorOptions.length; j++) {
                let option = this.floorOptions[j];
                option.disabled = false;
            }
            fetch('/api/v1/place/byzipcodehousenumber?zipcode=' + this.fields.zipcode + '&housenumber=' + this.fields.house_number)
            .then(response => { response.json().then(
                function(data) {
                    if (data.message) {
                        self.addressError = true;
                    }
                    else {
                        if (data.length) {
                            for (var j = 0; j < data.length; j++) {
                                let place = data[j];
                                place.assets = [];
                                place.toggled = false;
                                if (place.floor_name === '0') {
                                    if (self.locale === 'nl') {
                                        place.floor_name = 'Begane grond';
                                    }
                                    else {
                                        place.floor_name = 'Ground floor';
                                    }
                                }
                                place.lowerCaseName = place.name.toLowerCase();
                                self.existingPlaces.push(place);
                            }
                            self.existingPlaces = sortBy(self.existingPlaces, 'lowerCaseName');
                        }
                        else {
                            self.locationChoice = 'newLocation';
                        }
                    }
                });
            });
            if (self.industry !== 'leasing') {
                fetch('/api/v1/place/byzipcodehousenumber?show_other_bms=true&zipcode=' + this.fields.zipcode + '&housenumber=' + this.fields.house_number)
                .then(response => { response.json().then(
                    function(data) {
                        if (!data.message) {
                            for (var j = 0; j < data.length; j++) {
                                let place = data[j];
                                place.assets = [];
                                place.toggled = false;
                                if (place.floor_name === '0') {
                                    if (self.locale === 'nl') {
                                        place.floor_name = 'Begane grond';
                                    }
                                    else {
                                        place.floor_name = 'Ground floor';
                                    }
                                }
                                place.lowerCaseName = place.name.toLowerCase();
                                self.existingPlacesOtherBms.push(place);
                            }
                            self.existingPlacesOtherBms = sortBy(self.existingPlacesOtherBms, 'lowerCaseName');
                        }                         
                    });
                });
                setTimeout(function () {
                    if (self.existingPlaces.length && !self.existingPlacesOtherBms.length) {
                        self.locationChoiceSelection('ownLocation');
                    }
                    if (!self.existingPlaces.length && self.existingPlacesOtherBms.length) {
                        self.locationChoice = 'bmLocation';
                    }
                }, 1000)
            }
        },
        locationChoiceSelection(type) {
            this.locationChoice = type;
            if (this.locationChoice === 'ownLocation') {
                for (var i = 0; i < this.existingPlaces.length; i++) {
                    let place = this.existingPlaces[i];
                    let placeFloor = place.floor_name;
                    if (placeFloor === 'Begane grond') {
                        placeFloor = '0';
                    }
                    for (var j = 0; j < this.floorOptions.length; j++) {
                        let option = this.floorOptions[j];
                        if (option.value === placeFloor) {
                            option.disabled = true;
                        }
                    }
                }
            }
            else {
                for (var j = 0; j < this.floorOptions.length; j++) {
                    let option = this.floorOptions[j];
                    option.disabled = false;
                }
            }
        },
        clearValues() {
            this.fields.address = '';
            this.fields.city = '';
            this.existingPlaces = '';
            this.existingPlacesOtherBms = '';
            this.fields.floor_name = null;
            this.fields.house_number = '';
            this.fields.zipcode = '';
        },
        getAssets(place) {
            place.assets = [];
            place.toggled = !place.toggled;
            if (place.toggled) {
                fetch('/api/v1/asset/filtered?id_place=' + place.id_place)
                .then(response => { response.json().then(
                    function(data) {
                        let assets = data.data;
                        for (var i = 0; i < assets.length; i++) {
                            let asset = assets[i];
                            if (!asset.partof_id_asset) {
                                place.assets.push(asset);
                            }
                        }
                        place.assets = sortBy(place.assets, 'qr_code');
                    });
                });
            }
        },
        createNewLocation(asset) {
            if (!this.floorError && !this.floorErrorShown) {
                if (this.existingPlaces.length && this.existingPlaces.filter(e => e.floor_name === this.fields.floor_name).length > 0) {
                    this.floorError = true;
                    this.floorErrorShown = true;
                }
            }
            else {
                this.floorError = false;
            }
            if (!this.selectedOwnerId) {
                this.ownerError = true;
            }
            else {
                this.ownerError = false;
            }
            if (this.fields.name && this.selectedOwnerId && this.fields.floor_name && !this.floorError) {
                this.ownerError = false;
                if (asset && !this.assetFields.name) {
                    this.nameError = true;
                }
                if (!asset || (asset && this.assetFields.name)) {
                    const data = new URLSearchParams();
                    let nameParam = 'data[0][name]';
                    let addressParam = 'data[0][address]';
                    let houseNumberParam = 'data[0][house_number]';
                    let zipcodeParam = 'data[0][zipcode]';
                    let cityParam = 'data[0][city]';
                    let countryParam = 'data[0][country]';
                    let floorNameParam = 'data[0][floor_name]';
                    let idOwnerParam = 'data[0][id_owner]';
                    let strippedZip = this.fields.zipcode.replace(/\s/g, '').toUpperCase();
                    data.append('action', 'create');
                    data.append(nameParam, this.fields.name);
                    data.append(addressParam, this.fields.address);
                    data.append(houseNumberParam, this.fields.house_number);
                    data.append(zipcodeParam, strippedZip);
                    data.append(cityParam, this.fields.city);
                    data.append(countryParam, this.fields.country);
                    data.append(floorNameParam, this.fields.floor_name);
                    data.append(idOwnerParam, this.selectedOwnerId);
                    if (this.validZipCode) {
                        let self = this;
                        fetch('/api/v1/place', {
                            method: 'post',
                            body: data
                        }).then((resp) => {return resp.json();
                        }).then(function(data) {
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                            self.createdPlace = data.data[0];
                            if (self.assetFields.name) {
                                self.createAsset();
                            }
                            else {
                                if (self.ownerId) {
                                    self.$router.push('/buildingmanager/owner-detail?id=' + self.ownerId+ '&locations=true');
                                }
                                else {
                                    self.$router.push('/buildingmanager/locations');
                                }
                            }
                        });
                    }
                }
            }
        },
        selectOwner(owner) {
            this.ownersDropdownToggled = false;
            this.selectedOwnerName = owner.name;
            this.selectedOwnerId = owner.id_owner;
            this.tempOwners = [];
            this.ownersSearchTerm = '';
        },
        searchOwners() {
            this.tempOwners = [];
            if (this.ownersSearchTerm.length > 1) {
                let searchTerm = this.ownersSearchTerm.toLowerCase();
                for (var i = 0; i < this.owners.length; i++) {
                    let owner = this.owners[i];
                    let ownerName = owner.name.toLowerCase();
                    if (ownerName.includes(searchTerm)) {
                        this.tempOwners.push(owner);
                    }
                    if (!this.tempOwners.length) {
                        this.ownersNoResults = true;
                    }
                    else {
                        this.ownersNoResults = false;
                    }
                }
            }
            else {
                this.ownersNoResults = false;
            }
        },
        closeModal() {
            this.$emit('close');
            this.fields = [];
            document.querySelector('body').classList.remove('modal-open');
        },
        ownerEdit(e) {
            this.createNewAsset = false;
            this.ownersDropdownToggled = false;
            this.createNewOwner = true;
            this.getManagers();
            setTimeout(function () {
                document.getElementById('ownerbutton').scrollIntoView({
                    behavior: "smooth"
                });
            }.bind(this), 200);
        },
        cancelCreateOwner() {
            this.createNewOwner = false;
            this.ownerFields.kvk = '';
            this.ownerFields.name = '';
            this.ownerFields.email = '';
            this.ownerFields.description = '';
            this.ownerFields.phone = '';
            this.ownerFields.mobile = '';
        },
        createOwner() {
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][name]', this.ownerFields.name);
            data.append('data[0][email]', this.ownerFields.email);
            data.append('data[0][description]', this.ownerFields.description);
            data.append('data[0][phone]', this.ownerFields.phone);
            data.append('data[0][mobile]', this.ownerFields.mobile);
            let validOwnerManager = true;
            let self = this;
            if (!this.fields.email) {
                this.validEmail = true;
            }
            if (!this.fields.phone) {
                this.validPhone = true;
            }
            if (!this.fields.mobile) {
                this.validMobile = true;
            }
            if (this.ownerIsManager && !this.selectedManagerId) {
                validOwnerManager = false;
            }
            if (this.ownerIsManager && this.selectedManagerId) {
                validOwnerManager = true;
                data.append('data[0][is_id_building_manager]', this.selectedManagerId);
            }
            if (this.validEmail & this.validPhone && this.validMobile && validOwnerManager) {
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.createdOwnerId = data.data[0].id_owner;
                    self.$vToastify.success(self.profile.toast.owner + ' ' + self.profile.toast.created_successfully, self.profile.toast.changes_saved);
                    self.createNewOwner = false;
                    self.getOwners();
                });
            }
        },
        // Create a new owner methods
        validateMobile: function (number, object) {
            if (object.formatted) {
                if (object.valid) {
                    this.validMobile = true;
                    this.invalidMobile = false;
                    this.formattedMobile = object.number;
                }
                else {
                    this.validMobile = false;
                    this.invalidMobile = true;
                }
            }
            else {
                this.validMobile = false;
                this.invalidMobile = false;
            }
        },
        validateEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.ownerFields.email)) {
                this.validEmail = true;
                this.invalidEmail = false;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
            }
        },
        validatePhone() {
            let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            if (pattern.test(this.ownerFields.phone)) {
                this.validPhone = true;
                this.invalidPhone = false;
            }
            else {
                this.invalidPhone = true;
                this.validPhone = false;
            }
        },
        getManagers() {
            let self = this;
            fetch('/api/v1/buildingmanager/all_with_name?search_name=digitaal')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let manager = data.data[i];
                        if (manager.id_building_manager === self.selectedManagerId) {
                            self.assignedManager = manager.name;
                            self.selectedManagerName = manager.name;
                            self.selectedManagerId = manager.id_building_manager;
                        }
                    }
                    self.managers = data.data;
                });
            });
        },
        searchManagers() {
            this.tempManagers = [];
            if (this.managerSearchTerm.length > 1) {
                let searchTerm = this.managerSearchTerm.toLowerCase();
                for (var i = 0; i < this.managers.length; i++) {
                    let manager = this.managers[i];
                    let managerName = manager.name.toLowerCase();
                    if (managerName.includes(searchTerm)) {
                        this.tempManagers.push(manager);
                    }
                    if (!this.tempManagers.length) {
                        this.noManagersResults = true;
                    }
                    else {
                        this.noManagersResults = false;
                    }
                }
            }
            else {
                this.noManagersResults = false;
            }
        },
        selectManager(manager) {
            this.managerDropdownToggled = false;
            this.selectedManagerName = manager.name;
            this.selectedManagerId = manager.id_building_manager;
            this.tempManagers = [];
            this.managerSearchTerm = '';
        },
        clearSelectedManager() {
            this.managerDropdownToggled = false;
            this.selectedManagerName = '';
            this.selectedManagerId = '';
            this.tempManagers = [];
            this.managerSearchTerm = '';
        },
        assetEdit() {
            this.createNewAsset = true;
            this.getAssetTypes();
            setTimeout(function () {
                document.getElementById('assetbutton').scrollIntoView({
                    behavior: "smooth"
                });
            }.bind(this), 200);
        },
        cancelCreateAsset() {
            this.createNewAsset = false;
            this.assetFields.name = '';
            this.selectedAssetType = {};
        },
        searchAssetTypes() {
            this.tempAssetTypes = [];
            if (this.assetTypeSearchTerm.length > 1) {
                let searchTerm = this.assetTypeSearchTerm.toLowerCase();
                for (var i = 0; i < this.assetTypes.length; i++) {
                    let assetType = this.assetTypes[i];
                    let assetName = assetType.display_name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssetTypes.push(assetType);
                    }
                    if (!this.tempAssetTypes.length) {
                        this.assetTypeNoResult = true;
                    }
                    else {
                        this.assetTypeNoResult = false;
                    }
                }
            }
            else {
                this.assetTypeNoResult = false;
            }
        },
        selectAssetType(assetType) {
            this.assetTypeDropdownToggled = false;
            this.selectedAssetType = assetType;
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
            if (assetType.extra_description_a_default) {
                this.assetTypeFields.extra_description_a = assetType.extra_description_a_default;
            }
            if (assetType.extra_description_b_default) {
                this.assetTypeFields.extra_description_b = assetType.extra_description_b_default;
            }
            if (assetType.extra_description_c_default) {
                this.assetTypeFields.extra_description_c = assetType.extra_description_c_default;
            }
            if (assetType.extra_description_d_default) {
                this.assetTypeFields.extra_description_d = assetType.extra_description_d_default;
            }
            if (assetType.extra_description_e_default) {
                this.assetTypeFields.extra_description_e = assetType.extra_description_e_default;
            }
            if (assetType.extra_description_f_default) {
                this.assetTypeFields.extra_description_f = assetType.extra_description_f_default;
            }
            if (assetType.extra_description_g_default) {
                this.assetTypeFields.extra_description_g = assetType.extra_description_g_default;
            }
            if (assetType.extra_description_h_default) {
                this.assetTypeFields.extra_description_h = assetType.extra_description_h_default;
            }
            if (assetType.extra_description_i_default) {
                this.assetTypeFields.extra_description_i = assetType.extra_description_i_default;
            }
            if (assetType.extra_description_j_default) {
                this.assetTypeFields.extra_description_j = assetType.extra_description_j_default;
            }
        },
        deselectAssetType() {
            this.assetTypeDropdownToggled = false;
            this.selectedAssetType = {};
            this.tempAssetTypes = [];
            this.assetTypeSearchTerm = '';
        },
        createAsset() {
            let self = this;
            const data = new URLSearchParams();
            let qrcodeParam = 'data[0][qr_code]';
            let idPlaceParam = 'data[0][id_place]';
            let holderParam = 'data[0][holder]';
            let shortNameParam = 'data[0][short_name]';
            let nameParam = 'data[0][name]';
            let assetTypeParam = 'data[0][id_asset_type]';
            let assetTypeFieldAParam = 'data[0][extra_description_a]';
            let assetTypeFieldBParam = 'data[0][extra_description_b]';
            let assetTypeFieldCParam = 'data[0][extra_description_c]';
            let assetTypeFieldDParam = 'data[0][extra_description_d]';
            let assetTypeFieldEParam = 'data[0][extra_description_e]';
            let assetTypeFieldFParam = 'data[0][extra_description_f]';
            let assetTypeFieldGParam = 'data[0][extra_description_g]';
            let assetTypeFieldHParam = 'data[0][extra_description_h]';
            let assetTypeFieldIParam = 'data[0][extra_description_i]';
            let assetTypeFieldJParam = 'data[0][extra_description_j]';
            data.append('action', 'create');
            data.append(qrcodeParam, '');
            data.append(idPlaceParam, self.createdPlace.id_place);
            data.append(holderParam, true);
            data.append(shortNameParam, '');
            data.append(nameParam, self.assetFields.name);
            if (self.selectedAssetType.id_asset_type) {
                data.append(assetTypeParam, self.selectedAssetType.id_asset_type);
            }
            if (self.selectedAssetType.id_asset_type) {
                data.append(assetTypeParam, self.selectedAssetType.id_asset_type);
            }
            if (self.assetTypeFields.extra_description_a) {
                data.append(assetTypeFieldAParam, self.assetTypeFields.extra_description_a);
            }
            if (self.assetTypeFields.extra_description_b) {
                data.append(assetTypeFieldBParam, self.assetTypeFields.extra_description_b);
            }
            if (self.assetTypeFields.extra_description_c) {
                data.append(assetTypeFieldCParam, self.assetTypeFields.extra_description_c);
            }
            if (self.assetTypeFields.extra_description_d) {
                data.append(assetTypeFieldDParam, self.assetTypeFields.extra_description_d);
            }
            if (self.assetTypeFields.extra_description_e) {
                data.append(assetTypeFieldEParam, self.assetTypeFields.extra_description_e);
            }
            if (self.assetTypeFields.extra_description_f) {
                data.append(assetTypeFieldFParam, self.assetTypeFields.extra_description_f);
            }
            if (self.assetTypeFields.extra_description_g) {
                data.append(assetTypeFieldGParam, self.assetTypeFields.extra_description_g);
            }
            if (self.assetTypeFields.extra_description_h) {
                data.append(assetTypeFieldHParam, self.assetTypeFields.extra_description_h);
            }
            if (self.assetTypeFields.extra_description_i) {
                data.append(assetTypeFieldIParam, self.assetTypeFields.extra_description_i);
            }
            if (self.assetTypeFields.extra_description_j) {
                data.append(assetTypeFieldJParam, self.assetTypeFields.extra_description_j);
            }
            fetch('/api/v1/asset', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                if (self.ownerId) {
                    self.$router.push('/buildingmanager/owner-detail?id=' + self.ownerId + '&locations=true');
                }
                else {
                    self.$router.push('/buildingmanager/locations');
                }
            });
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
