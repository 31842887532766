<template>
    <div class="modal-wrapper" v-if="profile.owners">
        <div class="modal">
            <div class="modal-header">
                <h3 v-if="vve" data-testid="newOwnerTitle"><i class="fas fa-toolbox"></i>{{profile.owners.ownertable.createvve}}</h3>
                <h3 v-else><i class="fas fa-toolbox"></i>{{profile.owners.ownertable.createowner}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createOwner()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.name.length}">
                                <label v-if="vve">{{profile.owners.ownertable.vvename}} *</label>
                                <label v-else>{{profile.owners.ownertable.ownername}} *</label>
                                <input type="text" data-testid="newOwnerName" ref="name" class="form-control" v-model="fields.name" required>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.email.length, valid: validEmail, error: invalidEmail}">
                                <label>{{profile.incidents.new_incident.emailaddress}}</label>
                                <input type="text" data-testid="newOwnerEmail" class="form-control" v-model="fields.email" @input="validateEmail()">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <vue-tel-input v-model="fields.mobile" data-testid="newOwnerMobile" class="form-control" v-bind="bindProps" style="padding-left: 0;" :class="{valid: validMobile, error: invalidMobile}" @input="validateMobile"></vue-tel-input>
                        </div>
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:fields.phone.length, valid: validPhone, error: invalidPhone}">
                                <label>{{profile.incidents.new_incident.phone}}</label>
                                <input type="text" class="form-control" data-testid="newOwnerPhone" v-model="fields.phone" @input="validatePhone()">
                            </div>
                        </div>
                    </div>
                    <div class="form-field" :class="{focus:fields.description.length}">
                        <label>{{profile.owners.ownertable.description}}</label>
                        <input type="text" class="form-control" data-testid="newOwnerDescription" v-model="fields.description">
                    </div>
                    <div class="switch-wrapper switch-wrapper--full"  :class="{ active: ownerIsManager}">
                        <div class="inner" @click.stop="ownerIsManager = !ownerIsManager">
                            <i class="fa-solid fa-user-check"></i>
                            <p v-if="vve">{{profile.owners.ownertable.vve_manager}}</p>
                            <p v-else>{{profile.owners.ownertable.owner_manager}}</p>
                            <span v-if="vve">{{profile.switches.vve_account}}</span>
                            <span v-else>{{profile.switches.owner_account}}</span>
                            <label class="switch" style="float: left;">
                                <span class="slider round"></span>
                            </label>
                        </div>    
                    </div>
                    <div class="form-field clearable" v-if="ownerIsManager" :class="{focus:managerSearchTerm.length}" style="margin-bottom: 10px; margin-top: 10px;">
                        <label v-if="!selectedManagerId">{{profile.owners.ownertable.select_owner}}</label>
                        <div>
                            <div class="selected-info seperate" v-if="selectedManagerId">
                                <h4 data-testid="ownerManagerText">{{selectedManagerName}}</h4>
                                <span v-if="selectedManagerId" class="remove-selection" @click="removeManagerSelection()"><i class="fas fa-times"></i></span>
                            </div>
                            <input type="text" v-if="!selectedManagerId" class="form-control" data-testid="newOwnerOwnerManagerSearch" @input="e => managerSearchTerm = e.target.value" :value="managerSearchTerm" @keyup="searchManagers()" />
                        </div>
                    </div>
                    <div class="dropdown-list" data-testid="newOwnerManagerDropdown" v-if="managers.length" style="margin-top: -12px;">
                        <ul>
                            <li v-for="manager in managers" v-on:click="selectManager(manager)" :key="manager.id" v-show="!noManagersResults">{{manager.name}}</li>
                            <li v-if="noManagersResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                        </ul>
                    </div>
                    <button type="submit" class="button button--icon" data-testid="newOwnerSubmit"><i class="fas fa-toolbox"></i><span v-if="vve" style="font-weight: 500;">{{profile.owners.ownertable.createvve}}</span><span style="font-weight: 500;" v-else>{{profile.owners.ownertable.createowner}}</span></button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { VueTelInput } from 'vue-tel-input';
import { mapState } from 'vuex';

export default {
    mixins: [setLocale],
    name: 'newOwnerModal',
    components: {
        VueTelInput
    },
    data() {
        return {
            profile: {},
            fields: {
                kvk: '',
                name: '',
                email: '',
                description: '',
                phone: '',
                mobile: ''
            },
            validPhone: false,
            invalidPhone: false,
            validMobile: false,
            invalidMobile: false,
            formattedMobile: '',
            validEmail: false,
            invalidEmail: false,
            managersList: [],
            managers: [],
            ownerIsManager: false,
            managerDropdownToggled: false,
            selectedManagerId: 0,
            selectedManagerName: '',
            managerSearchTerm: '',
            noManagersResults: false,
            bindProps: {
                defaultCountry: "NL",
                disabledFetchingCountry: false,
                disabled: false,
                disabledFormatting: false,
                required: false,
                enabledCountryCode: false,
                enabledFlags: true,
                autocomplete: "off",
                name: "telephone",
                maxLen: 25,
                dropdownOptions: {
                    disabledDialCode: false,
                    showFlags: true
                },
                inputOptions: {
                    showDialCode: false,
                    placeholder:'',
                }
            }
        }
    },
    computed: mapState(['vve']),
    created() {
        this.bindProps.inputOptions.placeholder = this.profile.accountPage.account.contactmobile;
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.getManagers();
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        validateEmail() {
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(this.fields.email)) {
                this.validEmail = true;
                this.invalidEmail = false;
            }
            else {
                this.invalidEmail = true;
                this.validEmail = false;
            }
        },
        validatePhone() {
            let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
            if (pattern.test(this.fields.phone)) {
                this.validPhone = true;
                this.invalidPhone = false;
            }
            else {
                this.invalidPhone = true;
                this.validPhone = false;
            }
        },
        validateMobile: function (number, object) {
            if (object.formatted) {
                if (object.valid) {
                    this.validMobile = true;
                    this.invalidMobile = false;
                    this.formattedMobile = object.number;
                }
                else {
                    this.validMobile = false;
                    this.invalidMobile = true;
                }
            }
            else {
                this.validMobile = false;
                this.invalidMobile = false;
            }
        },
        getManagers() {
            let self = this;
            fetch('/api/v1/buildingmanager/all_with_name')
            .then(response => { response.json().then(
                function(data) {
                    self.managersList = data.data;
                });
            });
        },
        searchManagers() {
            if (this.managerSearchTerm.length > 2) {
                this.managers = [];
                let searchTerm = this.managerSearchTerm.toLowerCase();
                for (var i = 0; i < this.managersList.length; i++) {
                    let manager = this.managersList[i];
                    let managerName = manager.name.toLowerCase();
                    if (managerName.includes(searchTerm)) {
                        this.managers.push(manager);
                    }
                    if (!this.managers.length) {
                        this.noManagersResults = true;
                    }
                    else {
                        this.noManagersResults = false;
                    }
                }
            }
            else {
                this.noManagersResults = false;
            }
        },
        removeManagerSelection() {
            this.selectedManagerId = null;
            this.selectedManagerName = '';
            this.managers = [];
        },
        selectManager(manager) {
            this.managerDropdownToggled = false;
            this.managers = [];
            this.selectedManagerName = manager.name;
            this.selectedManagerId = manager.id_building_manager;
            this.managerSearchTerm = '';
        },
        createOwner() {
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][name]', this.fields.name);
            data.append('data[0][email]', this.fields.email);
            data.append('data[0][description]', this.fields.description);
            data.append('data[0][phone]', this.fields.phone);
            data.append('data[0][mobile]', this.formattedMobile);
            let validOwnerManager = true;
            let self = this;
            if (!this.fields.email) {
                this.validEmail = true;
            }
            if (!this.fields.phone) {
                this.validPhone = true;
            }
            if (!this.fields.mobile) {
                this.validMobile = true;
            }
            if (this.ownerIsManager && !this.selectedManagerId) {
                validOwnerManager = false;
            }
            if (this.ownerIsManager && this.selectedManagerId) {
                validOwnerManager = true;
                data.append('data[0][is_id_building_manager]', this.selectedManagerId);
            }
            if (this.validEmail & this.validPhone && this.validMobile && validOwnerManager) {
                fetch('/api/v1/owner', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$router.push('/buildingmanager/owner-detail?id=' + data.data[0].id_owner);
                });
            }
        }
    }
}
</script>

<style src="vue-tel-input/dist/vue-tel-input.css"></style>
