<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.incident_detail.cancel_mechanic_repair}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.incident_detail.cancel_mechanic_repair_sure}}<br /><br /></p>
                <button class="button button--icon button--right" data-testid="cancelRepair" v-on:click="cancelRepair()"><i class="fas fa-ban"></i>{{profile.incident_detail.cancel_mechanic_repair}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'newLocationModal',
    data() {
        return {
            profile: {},
        }
    },
    props: ['incidentId', 'appointmentId'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        cancelRepair() {
            let paramAppointment = 'data[' + this.appointmentId + '][id_appointment]';
            let paramMechanic =  'data[' + this.appointmentId + '][id_mechanic]';
            let paramIncidentId = 'data[' + this.appointmentId + '][id_incident]';
            const data = new URLSearchParams();
            data.append('action', 'remove');
            data.append(paramAppointment, this.appointmentId);
            data.append(paramMechanic, 0);
            data.append(paramIncidentId, this.incidentId);
            let self = this;
            fetch('/api/v1/appointment', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Afspraak annuleren',
                });
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('removed');
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
