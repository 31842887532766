<template>
    <div>
        <div class="main-content" v-if="profile.owners">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners">
                            <section class="incident-head"  :class="{empty: !owners.length}">
                                <h1 v-if="vve && showTitle">{{profile.nav.vves}}</h1>
                                <h1 v-if="!vve && showTitle">{{profile.owners.ownertable.tablename}}</h1>
                            </section>
                            <span class="edit-button icon" @click="newOwnerModalOpen = true" v-if="admin && !disableEdit"><i class="fa-solid fa-plus"></i></span>
                            <section class="search search-alt" v-if="owners.length">
                                <form @submit.prevent="searchSuggest()">
                                    <input type="text" ref="search" v-bind:placeholder="profile.places.search_vve" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" v-if="vve" />
                                    <input type="text" ref="search" v-bind:placeholder="profile.places.search_owner" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" v-else />
                                    <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                </form>
                            </section>
                            <div v-if="searchActive">
                                <h4 class="result-counter" data-testid="ownerSearchResult" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" data-testid="ownerSearchResult" v-if="!searchSuggestions.length">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" data-testid="ownerSearchResult" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                <div v-for="(owner, index) in searchSuggestions" :key="index" class="list-item list-item--setheight" @click="selectOwner(owner.id_owner)">
                                    <div class="item-contents">
                                        <h3>{{owner.name}}</h3>
                                    </div>
                                    <img :src="owner.profile_image_loc" v-if="owner.profile_image_loc" />
                                </div>
                            </div>
                            <div v-else>
                                <h4 class="result-counter" v-if="vve && owners.length > 1">{{owners.length}} {{ profile.nav.vves }}</h4>
                                <h4 class="result-counter" v-if="!vve && owners.length > 1" style="text-transform: lowercase;">{{owners.length}} {{ profile.nav.owners }}</h4>
                                <div v-for="owner in owners" :key="owner.id_owner" class="list-item list-item--setheight" @click="selectOwner(owner.id_owner)" v-tooltip.right="owner.description">
                                    <div class="item-contents">
                                        <h3>{{owner.name}}</h3>
                                    </div>
                                    <img :src="owner.profile_image_loc" v-if="owner.profile_image_loc" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewOwnerModal v-if="newOwnerModalOpen" @close="newOwnerModalOpen = false" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import NewOwnerModal from "@/components/modals/NewOwnerModal.vue";

export default {
    mixins: [setLocale],
    components: {
        NewOwnerModal
    },
    data() {
        return {
            profile: {},
            owners: [],
            newOwnerModalOpen: false,
            searchTerm: '',
            searchSuggestions: [],
            searchActive: false,
            admin: false,
            disableEdit: false,
            showTitle: false
        }
    },
    computed: mapState(['vve']),
    created() {
        setTimeout(function () {
            this.showTitle = true;
            if (this.profile.owners) {
                document.title = 'S1MONE | ' + this.profile.owners.ownertable.tablename;
            }
        }.bind(this), 200);
        this.getOwners();
        this.getAccountInfo();
    },
    mounted() {
        if (this.$refs.search) {
            this.$refs.search.focus();
        }
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(resp => {
                if (!resp.ok) {
                    console.log('Backend error occurred, redirecting to signin page.');
                    window.location = '/signin.html';
                    return; 
                }
                return resp.json();
            })
            .then(data => {
                self.admin = data.building_manager_admin;
                self.disableEdit = data.disable_edit_basedata;
            });
        },
        getOwners() {
            let self = this;
            fetch('/api/v1/owner/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.owners = data.data;
                    self.owners = sortBy(self.owners, 'name');
                    for (var i = 0; i < self.owners.length; i++) {
                        let owner = self.owners[i];
                        if (owner.profile_image_loc) {
                            if (owner.profile_image_loc.includes('no-profile')) {
                                owner.profile_image_loc = null;
                            }
                        }
                    }
                    if (localStorage.getItem('S1-ownersSearchTerm')) {
                        self.searchTerm = localStorage.getItem('S1-ownersSearchTerm');
                        self.searchSuggest();
                    }
                });
            });
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.owners.length; i++) {
                    let owner = this.owners[i];
                    let ownerName = owner.name.toLowerCase();
                    if (ownerName.includes(searchTerm)) {
                        this.searchSuggestions.push(owner);
                    }
                }
                localStorage.setItem('S1-ownersSearchTerm', this.searchTerm);
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
                localStorage.setItem('S1-ownersSearchTerm', this.searchTerm);
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
                localStorage.setItem('S1-ownersSearchTerm', this.searchTerm);
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
            localStorage.removeItem('S1-ownersSearchTerm');
        },
        selectOwner(id) {
            this.$router.push('/buildingmanager/owner-detail?id=' + id);
        }
    }
}
</script>
