<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-sticky-note"></i>{{profile.incident_detail.create_note}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fa fa-times"></i></span>
                <div class="row">
                    <div class="col">
                        <div class="link-switch">
                            <span @click="selectTab('note')" :class="{active: currentTab === 'note'}">{{ profile.incident_detail.note }}</span>
                            <span @click="selectTab('link')" :class="{active: currentTab === 'link'}">Hyperlink</span>
                        </div>
                    </div>
                </div>
                <form v-on:submit.prevent="addHandler()" v-if="currentTab === 'note'">
                    <h4>{{profile.incident_detail.create_note}}</h4>
                    <div class="form-field" :class="{focus:note.length}">
                        <label>{{profile.incident_detail.note}}</label>
                        <textarea class="form-control" v-model="note" data-testid="noteTextInput" style="resize: vertical; height: 200px;"></textarea>
                    </div>
                    <div class="row" v-if="incidentInfo.serviced_by_bm_user_id && incidentInfo.current_user_id !== incidentInfo.serviced_by_bm_user_id && !supplier">
                        <div class="col">
                            <div class="switch-wrapper">
                                <label class="switch" style="display: inline-block;">
                                    <input type="checkbox" v-model="important">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 100;">{{profile.incident_detail.important_short}} <i class="fas fa-info-circle" v-tooltip.right="profile.incident_detail.important_tooltip"></i></span>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="button button--icon" data-testid="noteSubmitButton"><i class="fas fa-sticky-note"></i>{{profile.buttons.save}}</button>
                </form>
                <form v-on:submit.prevent="addHandler()" v-else>
                    <h4>{{profile.incident_detail.create_hyperlink}}</h4>
                    <div class="col">
                        <div class="row">
                            <span class="pre-url" style="float: left; margin: 12px 10px 0 0; font-weight: 500;">https://</span>
                            <div class="form-field" :class="{focus:note.length}" style="float: left;">
                                <label>Hyperlink</label>
                                <input type="text" class="form-control url" v-model="note" />
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="button button--icon" data-testid="noteSubmitButton"><i class="fas fa-sticky-note"></i>{{profile.buttons.save}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'createNoteModal',
    data() {
        return {
            profile: {},
            note: '',
            currentTab: 'note',
            important: false
        }
    },
    props: ['incidentInfo', 'supplier'],
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
    },
    beforeDestroy() {
        this.removeHandler();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        addHandler() {
            window.addEventListener('submit', this.saveNote);
        },
        removeHandler() {
            window.removeEventListener('submit', this.saveNote);
        },
        saveNote() {
            let currentIncidentId = this.getUrlParameter('id');
            if (currentIncidentId && currentIncidentId === this.incidentInfo.id_incident.toString()) {
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                if (this.currentTab === 'link') {
                    data.append('content', 'https://' + this.note);
                    data.append('is_url', true)
                }
                else {
                    data.append('content', this.note);
                    data.append('is_url', false)
                }
                data.append('action', 'create');
                let self = this;
                fetch('/api/v1/incident_note/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Incident detail - Nieuwe notitie',
                    });
                    if (self.important) {
                        self.saveImportant(data.id_incident_note);
                    }
                    else {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.closeModal();
                        self.$emit('getnotes');
                    }
                });
            }
        },
        saveImportant(id) {
            const data = new URLSearchParams();
            data.append('id_incident_note', id);
            data.append('id_shiro_user_receiver', this.incidentInfo.serviced_by_bm_user_id);
            let self = this;
            fetch('/api/v1/incident_note/addnotification', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.closeModal();
                self.$emit('getnotes');
            });
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        },
        selectTab(type) {
            this.currentTab = type;
            this.note = '';
        }
    }
}
</script>
