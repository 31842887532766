<template>
    <div class="modal-wrapper" v-if="profile.teams">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.teams.create_team}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createTeam()">
                    <div class="form-field" :class="{focus:fields.teamname.length}">
                        <label>{{profile.employees.name}} *</label>
                        <input type="text" ref="name" class="form-control" data-testid="teamInputName" v-model="fields.teamname" required />
                    </div>
                    <button type="submit" class="button button--icon" data-testid="teamSubmitButton"><i class="fas fa-user"></i>{{profile.teams.create_team}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'newLocationModal',
    data() {
        return {
            profile: {},
            fields: {
                teamname: '',
            }
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        createTeam() {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('name', this.fields.teamname);
            fetch('/api/v1/team/create', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.closeModal();
            });
        }
    }
}
</script>
