<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-user"></i>{{profile.incident_detail.fixed_mechanic}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="manualCheckOut()">
                    <div class="row">
                        <div class="col-sm-3">
                            <select class="form-select" data-testid="checkOutDay" v-model="fields.day" required>
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <select class="form-select" data-testid="checkOutMonth" v-model="fields.month" required>
                                <option value="1">{{profile.months_short.jan}}</option>
                                <option value="2">{{profile.months_short.feb}}</option>
                                <option value="3">{{profile.months_short.mar}}</option>
                                <option value="4">{{profile.months_short.apr}}</option>
                                <option value="5">{{profile.months_short.may}}</option>
                                <option value="6">{{profile.months_short.jun}}</option>
                                <option value="7">{{profile.months_short.jul}}</option>
                                <option value="8">{{profile.months_short.aug}}</option>
                                <option value="9">{{profile.months_short.sep}}</option>
                                <option value="10">{{profile.months_short.oct}}</option>
                                <option value="11">{{profile.months_short.nov}}</option>
                                <option value="12">{{profile.months_short.dec}}</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <select class="form-select" data-testid="checkOutYear" v-model="fields.year" required>
                                <option data-testid="checkOutYearResult" v-for="(year, index) in years" :key="index" :value="year" :selected="year === 2018">{{year}}</option>
                            </select>
                        </div>
                        <div class="col-sm-3">
                            <flat-pickr data-testid="checkOutTime" v-model="fields.time" class="form-control" :config="flatPickrTimeConfig"></flat-pickr>
                        </div>
                    </div>
                    <div class="form-field" :class="{focus:fields.feedback.length}">
                        <label>{{profile.incident_detail.feedback}}</label>
                        <textarea class="form-control" v-model="fields.feedback"></textarea>
                    </div>
                    <div class="switch-wrapper switch-wrapper--full" :class="{active: fields.followup}">
                        <div class="inner" @click="fields.followup = !fields.followup">
                            <i class="fa-regular fa-calendar-plus"></i>
                            <p>{{profile.incident_detail.followup_label}}</p>
                            <span class="label">{{profile.switches.followup}}</span>
                            <label class="switch">
                                <span class="slider round" data-testid="followUpSlider"></span>
                            </label>
                        </div>   
                    </div>
                    <button type="submit" data-testid="checkOutSubmit" class="button button--icon"><i class="fa fa-toolbox"></i>{{profile.incident_detail.fixed_mechanic}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';

export default {
    mixins: [setLocale],
    name: 'newLocationModal',
    components: {
        flatPickr
    },
    data() {
        return {
            profile: {},
            years: [],
            fields: {
                day: 1,
                month: 1,
                year: 2011,
                time: '',
                followup: false,
                feedback: ''
            },
            flatPickrTimeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            }
        }
    },
    props: ['incidentId', 'supplier'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var startYear = 2018;
        while (startYear <= currentYear) {
            this.years.push(startYear);
            startYear++
        }
        this.fields.day = currentDate.getDate();
        this.fields.month = currentDate.getMonth() + 1;
        this.fields.year = currentDate.getFullYear();
        this.fields.time = moment(currentDate).format('HH:mm');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        manualCheckOut() {
            let epoch = new Date();
            epoch.setDate(this.fields.day);
            epoch.setMonth(this.fields.month - 1);
            epoch.setFullYear(this.fields.year);
            let hours = this.fields.time.split(':')[0];
            let minutes = this.fields.time.split(':')[1];
            epoch.setHours(hours);
            epoch.setMinutes(minutes);
            epoch.setSeconds(0);
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('epoch', epoch.getTime());
            data.append('checkout_message', this.fields.feedback);
            let self = this;
            fetch('/api/v1/incident/checkout', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Specialist uitchecken',
                });
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                if (self.fields.followup) {
                    self.followUp();
                }
                else {
                    self.$emit('checkout');
                    self.closeModal();
                }
            });
        },
        followUp() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('wants_copy', true);
            let self = this;
            fetch('/api/v1/incident/setcopywanted', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('checkout');
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
