<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Stickers</h1>
                            </section>          
                            <form v-on:submit.prevent="makeStickers()" style="margin-top: 15px;">
                                <div class="row">
                                    <div class="col-2" style="margin-top: 12px;">
                                        <span>1. Kies een beheerder</span>
                                    </div>
                                    <div class="col-6">
                                        <div class="dropdown-section">
                                            <span class="dropdown" v-on:click="managerDropdownToggle = !managerDropdownToggle" :class="{active: managerDropdownToggle}">
                                                <span v-if="selectedManager.id_building_manager" class="selected">{{selectedManager.name}}</span>
                                                <i class="fas fa-chevron-down chevron"></i>
                                            </span>
                                            <div class="dropdown-list" v-if="managerDropdownToggle" :class="{search: managers.length > 9}">
                                                <ul>
                                                    <li v-for="manager in managers" v-on:click="selectManager(manager)" :key="manager.id_building_manager">
                                                        {{manager.name}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2" style="margin-top: 23px;">
                                        <span>2. Type zoekterm</span>
                                    </div>
                                    <div class="col-6">
                                        <section class="search search-alt" v-if="managers.length">
                                            <form @submit.prevent="searchSuggest()">
                                                <input type="text" ref="search" v-bind:placeholder="'Zoek een object op naam'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                                <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                            </form>
                                        </section>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2" style="margin-top: 17px;">
                                        <span>3. Selecteer stickers</span>
                                    </div>
                                    <div class="col-6" style="margin-top: 7px;">
                                        <section class="search search-alt" v-if="assets.length">
                                            <form @submit.prevent="searchSuggestAsset()">
                                                <input type="text" ref="search" v-bind:placeholder="'Zoek een object op naam of postcode'" @input="e => assetSearchTerm = e.target.value" :value="assetSearchTerm" v-on:keyup="searchSuggestAsset($event)" class="no-margin" />
                                                <span class="close-suggestions" @click="clearAssetSuggestions()" v-if="assetSearchActive"><i class="fas fa-times"></i></span>
                                            </form>
                                        </section> 
                                        <div v-if="!assetSearchActive">
                                            <div v-for="asset in assets" :key="asset.id_asset" class="btn btn-info select-asset" style="margin-left:10px;margin-bottom:10px;" @click="handleClick(asset)" @dblclick="handleDblClick(asset)">
                                                <b style="float: left; margin-left: 5px;">{{asset.name}} - {{ asset.place_zipcode }} {{ asset.place_house_number }}</b>
                                                <div v-if="asset.showHowMany">
                                                    <form @submit.prevent="addXTimes(asset)" @click.stop>
                                                        <input type="number" v-model.number="asset.quantity" min="1" style="width: 60px; margin-left: 10px;" />
                                                        <button type="submit" class="btn btn-primary" style="margin-left: 5px;">Toevoegen</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <div v-for="asset in assetSearchSuggestions" :key="asset.id_asset" class="btn btn-info select-asset" style="margin-left:10px;margin-bottom:10px;" @click="handleClick(asset)" @dblclick="handleDblClick(asset)">
                                                <b style="float: left; margin-left: 5px;">{{asset.name}} - {{ asset.place_zipcode }} {{ asset.place_house_number }}</b>
                                                <div v-if="asset.showHowMany">
                                                    <form @submit.prevent="addXTimes(asset)" @click.stop>
                                                        <input type="number" v-model.number="asset.quantity" min="1" style="width: 60px; margin-left: 10px;" />
                                                        <button type="submit" class="btn btn-primary" style="margin-left: 5px;">Toevoegen</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btn btn-success" style="margin-left:10px;margin-bottom:10px;" v-if="assets.length > 0" @click="selectAllAssets()">
                                            <b style="float: left; margin-left: 5px;">Allen toevoegen</b>
                                        </div>
                                    </div>
                                    <div class="col-2" style="margin-top: 25px;">
                                        <span v-if="assetSearchSuggestions.length > 0">{{ assetSearchSuggestions.length }} resultaten gevonden</span>
                                        <span v-if="totalAssets && assetSearchSuggestions <= 0">{{ totalAssets }} resultaten gevonden</span>
                                    </div>
                                </div>
                                <hr>
                                <div class="row" style="margin-top: 10px;">
                                    <div class="col-2" style="margin-top: 12px;">
                                        <span>4. Controleer geselecteerde stickers (kies niet meer dan 300 stickers per keer)</span>
                                    </div>
                                    <div class="col-6" style="margin-top: 12px;">
                                        <div v-for="(asset, index) in selectedAssets" :key="`${asset.id_asset}-${index}`" class="btn btn-info select-asset" style="margin-left:10px;margin-bottom:10px;" @click="deselectAsset(asset)">
                                            <span style="float: left;"> <i class="fa fa-close" style="color:red;margin-top: 2px;"></i> </span>
                                            <b style="float: left; margin-left: 5px;">{{asset.name}} - {{ asset.place_zipcode }} {{ asset.place_house_number }}</b>
                                        </div>
                                    </div>
                                    <div class="col-2" style="margin-top: 12px;">
                                        <span v-if="selectedAssets.length>0">{{ selectedAssets.length }} stickers geselecteerd</span>
                                        <div class="btn btn-danger" style="margin-top:10px;" v-if="selectedAssets.length > 0" @click="deselectAllAssets()">
                                                <b style="float: left; margin-left: 5px;">Allen verwijderen</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" style="margin-top: 15px">
                                    <div class="col-2" style="margin-top:17px;">
                                        <span>5. Kies formaat</span>
                                    </div>
                                    <div class="col-6">
                                        <div class="dropdown-section">
                                            <span class="dropdown" v-on:click="optionsDropdownToggle = !optionsDropdownToggle" :class="{active: optionsDropdownToggle}">
                                                <span v-if="size" class="selected">{{size}}</span>
                                                <i class="fas fa-chevron-down chevron"></i>
                                            </span>
                                            <div class="dropdown-list" v-if="optionsDropdownToggle">
                                                <ul>
                                                    <li v-for="(option, index) in options" v-on:click="selectOption(index)" :key="index">
                                                        {{option.label}}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2" style="margin-top: 17px;">
                                        <span>6. Type instructietext voor drukker (optioneel)</span>
                                    </div>
                                    <div class="col-6" style="margin-top: 7px;">
                                        <div class="form-field ">
                                            <input type="text" ref="name" class="form-control" v-model="additionalText" >
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2"></div>
                                    <div><button type="submit" style="margin-top: 15px; margin-left: 10px" class="btn btn-primary"> Maak PDF </button></div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            managers: [],
            assets: [],
            selectedAssets: [],
            totalAssets: '',
            searchSuggestions: [],
            searchActive: false,
            searchTerm: '',
            managerDropdownToggle: false,
            optionsDropdownToggle: false,
            selectedManager: {},
            additionalText: '',
            size: '',
            sizeValue: '',
            options: [
                { label: 'Small', value: 'S' },
                { label: 'Medium', value: 'M' },
                { label: 'Large', value: 'L' },
                { label: 'S to cut', value: 'S-TO-CUT' },
                { label: 'Simplified whitelabel vertical', value: 'SIMPLIFIED-WHITE-LABEL-V' },
                { label: 'Simplified whitelabel horizontal', value: "SIMPLIFIED-WHITE-LABEL-H"}
            ],
            showHowMany: false,
            howMany: 0,
            shownAssets: [],
            assetSearchSuggestions: [],
            assetSearchActive: false,
            assetSearchTerm: ''
        }
    },
    created() {
        document.title = 'S1MONE | Stickers';
        this.loadManagers();
    },
    methods: {
        makeStickers(){
            let assetIds = [];
            for (let i = 0; i < this.selectedAssets.length; i++) {
                assetIds.push(this.selectedAssets[i].id_asset);
            }
            var link = document.createElement('a');
            link.href = '/api/v1/stickercreation/createfromlist?assets_json='+ JSON.stringify(assetIds) + '&size=' + this.sizeValue + '&instruction_text=' + this.additionalText;
            link.download = 'stickers.pdf';
            document.body.appendChild(link);
            link.dispatchEvent(new MouseEvent('click'));
        },
        loadManagers() {
            let self = this;
            fetch('/api/v1/buildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                    self.selectManager(self.managers[0]);
                });
            });
        },
        selectManager(manager) {
            this.managerDropdownToggle = false;
            this.selectedManager = manager;
        },
        getAssets(searchTerm){
            let self = this;
             fetch('/api/v1/asset/filtered?limit=300&search_name='+ searchTerm + '&id_building_manager=' + this.selectedManager.id_building_manager)
              .then(response => { response.json().then(
                   function(data) {
                        self.assets = sortBy(data.data.map(asset => {
                            asset.showHowMany = false;
                            asset.quantity = 1;
                            return asset;
                            }), 'name');
                        self.totalAssets = data.recordsFiltered;
                });
            });
        },
        selectAsset(asset){
            this.selectedAssets.push(asset);
        },
        deselectAsset(asset){
            let index = this.selectedAssets.findIndex(x => x.id_asset === asset.id_asset);
            this.selectedAssets.splice(index, 1);
        },
        selectAllAssets(){
            if(this.assetSearchActive){
                for (let i = 0; i < this.assetSearchSuggestions.length; i++) {
                    this.selectedAssets.push(this.assetSearchSuggestions[i]);
                }
            } 
            else {
                for (let i = 0; i < this.assets.length; i++) {
                    this.selectedAssets.push(this.assets[i]);
                }
            }
        },
        deselectAllAssets(){
            this.selectedAssets = [];
        },
        selectOption(index){
            this.optionsDropdownToggle = false;
            this.size = this.options[index].label;
            this.sizeValue = this.options[index].value;
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 3) {
                this.searchActive = true;
                this.getAssets(searchTerm);
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
            this.assets = [];
            this.totalAssets = '';
            this.assetSearchSuggestions = [];
            this.assetSearchTerm = '';
            this.assetSearchActive = false;
        },
        searchSuggestAsset($event) {
            this.assetSearchSuggestions = [];
            let assetSearchTerm = this.assetSearchTerm.toLowerCase();
            if (assetSearchTerm.length > 2) {
                this.assetSearchActive = true;
                for (var i = 0; i < this.assets.length; i++) {
                    let asset = this.assets[i];
                    let name = asset.name.toLowerCase();
                    let zipcode = asset.place_zipcode ? asset.place_zipcode.toLowerCase().trim() : '';
                    if (name.includes(assetSearchTerm) || zipcode.includes(assetSearchTerm)) {
                        this.assetSearchSuggestions.push(asset);
                    }
                }
            }
            if (assetSearchTerm.length === 0) {
                this.assetSearchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.assetSearchActive = false;
                this.assetSearchTerm = '';
                this.assetSearchSuggestions = [];
            }
        },
        clearAssetSuggestions() {
            this.assetSearchSuggestions = [];
            this.assetSearchTerm = '';
            this.assetSearchActive = false;
        },
        handleClick(asset) {
            // Clear any existing timeout to prevent the single click action
            clearTimeout(this.clickTimeout);

            // Set a timeout to trigger the single click action after a delay
            this.clickTimeout = setTimeout(() => {
                this.selectAsset(asset);
            }, 300);
        },
        handleDblClick(asset) {
            // Clear the timeout to prevent the single click action
            clearTimeout(this.clickTimeout);

            asset.showHowMany = true;
        },
        addXTimes(asset) {
            for (let i = 0; i < asset.quantity; i++) {
                this.selectAsset(asset);
            }
            asset.showHowMany = false;
            asset.quantity = 1;
        }
    }
}
</script>
