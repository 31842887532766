<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3>{{profile.actionable.nothing_to_report}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p v-html="profile.actionable.confirm_text"></p>
                <button class="button button--icon button--right" data-testid="submitActionButton" v-on:click="submitAction()">{{profile.actionable.confirm_actionable}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';

export default {
    mixins: [setLocale],
    props: ['selectedActionId', 'selectedAction', 'totalActions'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        submitAction() {
            let descrFieldId = this.selectedAction.planned_action_fields[1].id_planned_action_field;
            const dataa = new URLSearchParams();
            dataa.append('id_planned_action_field', descrFieldId);
            dataa.append('value', 'Geen bijzonderheden.');
            fetch('/api/v1/plannedactionfield/update', {
                method: 'post',
                body: dataa
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
            });

            const data = new URLSearchParams();
            data.append('id_planned_action', this.selectedActionId);
            let self = this;
            fetch('/api/v1/plannedaction/setcompleted', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                document.querySelector('body').classList.remove('modal-open');
                if (self.totalActions === 1) {
                    self.$router.push('/monteur/opnames');
                }
                else {
                    self.$emit('success');
                }
            });

        },
    }
}
</script>
