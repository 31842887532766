<template>
    <div>
        <div class="block block--incident_detail" style="overflow: visible;">
            <section class="incident-head">
                <div class="row">
                    <div class="col-sm-9 col-md-10">
                        <span class="date">{{incidentInfo.day}} {{incidentInfo.date}}, {{incidentInfo.time}}</span>
                        <span  data-testid="incidentStatus" class="status-bar" v-if="incidentInfo.incident_status && !profile['statusses'][incidentInfo.incident_status].buildingmanager">
                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic'"></div>
                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <span data-testid="incidentLabelPaused" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                            <span data-testid="incidentLabelCancelled" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                            <span data-testid="incidentLabelPauseCancelled" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && incidentInfo.incident_status !== 'cancelled'">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                        </span>
                        <span  data-testid="incidentStatus2" class="status-bar" v-if="incidentInfo.incident_status && profile['statusses'][incidentInfo.incident_status].buildingmanager">
                            <div v-html="profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && profile['status_icons'][incidentInfo.incident_status].buildingmanager"></div>
                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && !profile['status_icons'][incidentInfo.incident_status].buildingmanager"></div>
                            <div v-html="profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic' && profile['status_icons'][incidentInfo.incident_status].buildingmanager" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <span data-testid="incidentLabelPaused2" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                            <span data-testid="incidentLabelCancelled2" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                            <span data-testid="incidentLabelPauseCancelled2" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && incidentInfo.incident_status !== 'cancelled'">{{profile['statusses'][incidentInfo.incident_status].buildingmanager}}</span>
                        </span>
                        <span class="time-indicator" :class="incidentInfo.overtimeStatus"><i class="fas fa-stopwatch"></i>{{profile.incidents.incidenttable.time_in_status}} {{incidentInfo.overtime}}</span>
                        <span class="status_reason" v-if="incidentInfo.cancelled_reason"><b>{{profile.incident_detail.status_reason}}:</b> {{incidentInfo.cancelled_reason}}</span>
                    </div>
                </div>
                <span class="suspend-indicator" data-testid="incidentParkLabel" v-if="incidentInfo.service_desk_suspended_until_date && incidentInfo.service_desk_suspended_reason" v-tooltip.top="profile.incident_detail.park_reason + ': ' + incidentInfo.service_desk_suspended_reason"><i class="fas fa-parking"></i> {{profile.incident_detail.parked_since}} {{incidentInfo.service_desk_suspended_until_date}} <i class="fas fa-info-circle" style="color: #212529;"></i></span>
                <span class="suspend-indicator" data-testid="incidentParkLabel1" v-if="incidentInfo.service_desk_suspended_until_date && !incidentInfo.service_desk_suspended_reason"><i class="fas fa-parking"></i> {{profile.incident_detail.parked_since}} {{incidentInfo.service_desk_suspended_until_date}}</span>
                <h1 data-testid="incidentDetailNumber" class="incident_nr">{{incidentInfo.id_incident}}</h1>
                <span class="agreement-bar" v-if="incidentInfo.incident_agreement_flow === 'NO_CHOICE'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.no_choice_status}}</span>
                <span class="agreement-bar" v-if="incidentInfo.incident_agreement_flow === 'WAITING_ON_PERSON' || incidentInfo.incident_agreement_flow === 'WAITING_ON_PERSON_REMINDED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_on_person}}</span>
                <span class="agreement-bar" v-if="incidentInfo.incident_agreement_flow === 'WAITING_ON_SERVICE_DESK'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.waiting_status}}</span>
                <span class="agreement-bar" v-if="incidentInfo.incident_agreement_flow === 'AGREED'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.agreed_status}}</span>
                <span class="agreement-bar" v-if="incidentInfo.incident_agreement_flow === 'DO_IT_MYSELF'"><i class="fas fa-user-check"></i>{{profile.owners.agreementflow.dim_status}}</span>
                <span class="mandate-violation active" data-testid="statusBackLabel" v-if="incidentInfo.incident_status === 'returned_to_buildingmanager' && incidentInfo.mandate_warning === 'no_warning'"><i class="fa fa-exclamation"></i> {{profile.invoicing.commission_cancel}}</span>
                <span class="mandate-violation active" data-testid="statusViolationLabel" v-if="incidentInfo.mandate_warning !== 'no_warning'"><i class="fa fa-exclamation"></i> {{profile.mandates.mandate_violation}}</span>
                <span class="followup" v-if="incidentInfo.wants_copy"><i class="fas fa-calendar-plus"></i> {{profile.incident_detail.followup_label}}</span>
                <span class="handmade" v-if="incidentInfo.origin === 'service_desk_manual'"><i class="fas fa-hand-pointer"></i>{{profile.incidents.incidenttable.hand_made}}</span>
                <span class="handmade" v-if="incidentInfo.origin === 'recurring_action'"><i class="fas fa-redo"></i>{{profile.actionable.repeating_job}}</span>

                <span class="copy-indicator" @click="connectedIncidentsModalOpen = true" v-if="incidentInfo.copiedFrom && incidentInfo.copiedFrom[0]">{{incidentInfo.copiedFrom[0].id_ori_incident}} <i class="fas fa-copy"></i></span>
                <span class="copy-indicator" @click="connectedIncidentsModalOpen = true" v-if="incidentInfo.copiedTo && incidentInfo.copiedTo[0]">{{incidentInfo.copiedTo[0].id_ori_incident}} <i class="fas fa-copy"></i></span>
                <span class="autodispatch-indicator" data-testid="autoDispatchLabel" v-if="incidentInfo.autodispatch">auto <i class="fas fa-forward"></i></span>
            </section>
            <div class="incident-contents" id="incidentHead">
                <div class="incident-info">
                    <div class="row" style="clear: both;">
                        <div class="col-md-6 col-lg-4">
                            <label>{{profile.incident_detail.category}}</label>
                            <!-- Only category name for readonly incidents -->
                            <p data-testid="categoryName" v-if="(locale === 'nl' && incidentInfo.incidentCategory)">{{incidentInfo.incidentCategory.dutch_name}}</p>
                            <p data-testid="categoryName" v-if="(locale === 'en' && incidentInfo.incidentCategory)">{{incidentInfo.incidentCategory.english_name}}</p> 
                        </div>
                    </div>
                    <label style="position: relative;">{{profile.incident_detail.description}}</label>
                    <div style="overflow: hidden;">
                        <p data-testid="descriptionText" v-if="(incidentInfo.incidentCategory && incidentInfo.incidentCategory.dutch_name !== 'INLOSSALDO' && incidentInfo.incidentCategory.dutch_name !== 'AMORTISATIESCHEMA' && incidentInfo.incidentCategory.dutch_name !== 'RENTETABEL')" v-html="incidentInfo.incident_description" style="margin-bottom: 0; float: left; max-width: 90%; width: auto;"></p>
                    </div>
                    <p v-if="!incidentInfo.incidentCategory" v-html="incidentInfo.incident_description" style="margin-bottom: 0;"></p>
                    <div v-if="(readOnly && incidentInfo.urgency_level && !incidentInfo.serviced_by_bm_user_id) || (readOnly && incidentInfo.serviced_by_bm_user_id && !admin)">
                        <span class="urgent incident_detail" v-if="incidentInfo.urgency_level"><i class="fas fa-exclamation-circle"></i> URGENT</span>
                    </div>
                </div>
            </div>
            <ConnectedIncidentsModal v-if="connectedIncidentsModalOpen" @close="connectedIncidentsModalOpen = false" :incidentInfo="incidentInfo" />
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import ConnectedIncidentsModal from "@/components/modals/ConnectedIncidentsModal.vue";

export default {
    name: 'MainBlock',
    mixins: [setLocale],
    components: {
        ConnectedIncidentsModal
    },
    data: function () {
        return {
            categoryDropdownToggled: false,
            selectedCategory: 0,
            connectedIncidentsModalOpen: false,
        }
    },
    props: ['buildingmanager',
            'incidentInfo',
            'readOnly',
            'forwardedReadOnly',
            'admin',
            'categories',
            'readyToFinish',
            'readyForRestart',
            'newIncident',
            'readyForTakeover',
            'commissionStart',
            'hideRestart'
        ]
}
</script>
