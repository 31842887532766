<template>
    <div>
        <div class="main-content" v-if="profile.repair_companies">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--repaircompanies">
                            <section class="incident-head" :class="{empty: !repaircompanies.length}">
                                <h1>{{profile.repair_companies.repaircompanytable.tablename}}</h1>
                            </section>
                            <span class="edit-button icon" @click="importRepairCompaniesModalOpen = true" style="right: 60px;" v-tooltip.right="profile.repair_companies.repaircompanytable.importrepaircompanies" v-if="admin"><i class="fas fa-file-excel"></i></span>
                            <span class="edit-button icon" @click="newRepairCompanyModalOpen = true" v-if="admin"><i class="fa-solid fa-plus"></i></span>
                            <section class="search search-alt" v-if="repaircompanies.length">
                                <form @submit.prevent="searchSuggest()">
                                    <input type="text" ref="search" v-bind:placeholder="profile.repair_companies.repaircompanytable.search_repaircompany" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                    <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                </form>
                            </section>
                            <div v-if="searchActive">
                                <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="!searchSuggestions.length">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                <div v-for="repaircompany in searchSuggestions" :key="repaircompany.id_repair_company" class="list-item list-item--setheight" @click="selectRepairCompany(repaircompany.id_repair_company)">
                                    <div class="item-contents">
                                        <h3 @click="selectRepairCompany(repaircompany.id_repair_company)">{{repaircompany.name}}</h3>
                                        <a :href="`tel:${repaircompany.mobile}`" v-if="repaircompany.mobile"><span class="hidden-xs">&nbsp;|&nbsp;</span><i class="fas fa-mobile-alt"></i>{{repaircompany.mobile}}</a>
                                    </div>
                                    <img :src="repaircompany.profile_image_loc" v-if="repaircompany.profile_image_loc" />
                                    <span class="no_simone" v-if="repaircompany.only_email || repaircompany.no_communication && serviceTeamId !== repaircompany.id_repair_company">{{profile.incident_detail.no_s1mone}}</span>
                                    <span class="no_simone serviceteam" v-if="serviceTeamId === repaircompany.id_repair_company">{{profile.accountPage.account.own_service}}</span>
                                </div>
                            </div>
                            <div v-else>
                                <h4 class="result-counter" v-if="repaircompanies.length > 1" style="text-transform: lowercase;">{{repaircompanies.length}} {{ profile.nav.repaircompanies }}</h4>
                                <div v-for="(repaircompany, index) in repaircompanies" :key="index" class="list-item list-item--setheight" v-show="repaircompany.name" @click.self="selectRepairCompany(repaircompany.id_repair_company)" :class="{no_simone: repaircompany.only_email || repaircompany.no_communication}">
                                    <div class="item-contents">
                                        <h3 @click="selectRepairCompany(repaircompany.id_repair_company)">{{repaircompany.name}}</h3>
                                        <a :href="`tel:${repaircompany.mobile}`" v-if="repaircompany.mobile"><span class="hidden-xs">&nbsp;|&nbsp;</span><i class="fas fa-mobile-alt"></i>{{repaircompany.mobile}}</a>
                                    </div>
                                    <img :src="repaircompany.profile_image_loc" v-if="repaircompany.profile_image_loc" />
                                    <span class="no_simone" v-if="repaircompany.only_email || repaircompany.no_communication && serviceTeamId !== repaircompany.id_repair_company">{{profile.incident_detail.no_s1mone}}</span>
                                    <span class="no_simone serviceteam" v-if="serviceTeamId === repaircompany.id_repair_company">{{profile.accountPage.account.own_service}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewRepairCompanyModal v-if="newRepairCompanyModalOpen" @close="loadRepairCompanies()" />
        <ImportRepairCompaniesModal v-if="importRepairCompaniesModalOpen" @close="loadRepairCompanies()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';
import NewRepairCompanyModal from "@/components/modals/NewRepairCompanyModal.vue";
import ImportRepairCompaniesModal from "@/components/modals/ImportRepairCompaniesModal.vue";

export default {
    mixins: [setLocale],
    components: {
        NewRepairCompanyModal,
        ImportRepairCompaniesModal
    },
    data() {
        return {
            profile: {},
            repaircompanies: [],
            newRepairCompanyModalOpen: false,
            importRepairCompaniesModalOpen: false,
            searchTerm: '',
            searchSuggestions: [],
            searchActive: false,
            admin: false,
            serviceTeamId: 0
        }
    },
    created() {
        if (this.profile.repair_companies) {
            document.title = 'S1MONE | ' + this.profile.repair_companies.repaircompanytable.tablename;
        }
        this.loadRepairCompanies();
        this.getAccountInfo();
    },
    mounted() {
        if (this.$refs.search) {
            this.$refs.search.focus();
        }
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(resp => {
                if (!resp.ok) {
                    console.log('Backend error occurred, redirecting to signin page.');
                    window.location = '/signin.html';
                    return; 
                }
                return resp.json();
            })
            .then(data => {
                self.admin = data.building_manager_admin;
                self.serviceTeamId = data.canbe_id_repair_company;
            });
        },
        loadRepairCompanies() {
            this.newRepairCompanyModalOpen = false;
            this.importRepairCompaniesModalOpen = false;
            let self = this;
            fetch('/api/v1/repaircompany/bybuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.repaircompanies = data.data;
                    self.repaircompanies =  sortBy(self.repaircompanies, 'name');
                    if (localStorage.getItem('S1-suppliersSearchTerm')) {
                        self.searchTerm = localStorage.getItem('S1-suppliersSearchTerm');
                        self.searchSuggest();
                    }
                });
            });

        },
        selectRepairCompany(id) {
            this.$router.push('/buildingmanager/repaircompany-detail?id_repair_company=' + id);
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.repaircompanies.length; i++) {
                    let repaircompany = this.repaircompanies[i];
                    let repaircompanyName = repaircompany.name.toLowerCase();
                    if (repaircompanyName.includes(searchTerm)) {
                        this.searchSuggestions.push(repaircompany);
                    }
                }
                localStorage.setItem('S1-suppliersSearchTerm', this.searchTerm);
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
                localStorage.removeItem('S1-suppliersSearchTerm');
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
                localStorage.removeItem('S1-suppliersSearchTerm');
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
            localStorage.removeItem('S1-suppliersSearchTerm');
        }
    }
}
</script>
