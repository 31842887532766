<template>
    <div class="block block--account block--border">
        <div class="account-info">
            <h4>{{profile.accountPage.account.unfinished_incidents}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.unfinished_tooltip" style="cursor: pointer;"></i></h4>
            <div class="switch-wrapper switch-wrapper--full alt-hover" :class="{disabled: !accountInfo.building_manager_admin && !twinq, active: accountInfo.push_empty_incident}">
                <div class="inner" @click="toggleEmptyIncidents()">
                    <i class="fa-regular fa-hourglass-clock"></i>
                    <p>{{profile.accountPage.account.unfinished_incidents_text}}</p>
                    <span>{{profile.switches.incidents_24h}}</span>
                    <label class="switch">
                        <span class="slider round"></span>
                    </label>
                </div>  
            </div>                               
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';

export default {
    mixins: [setLocale],
    data: function () {
        return {
            profile: {}
        }
    },
    props: ['accountInfo'],
    computed: mapState(['twinq', 'admin']),
    methods: {
        toggleEmptyIncidents() {
            this.accountInfo.push_empty_incident = !this.accountInfo.push_empty_incident;
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][push_empty_incident]', this.accountInfo.push_empty_incident);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
