<template>
    <div class="block block--account block--border" style="margin-bottom: 0;">
        <div class="account-info">
            <div class="row">
                <div class="col">
                    <h4>{{profile.accountPage.account.own_service}}
                        <span class="edit-button edit-button--small" @click="ownServiceEdit = true" v-if="!ownServiceEdit && this.accountInfo.building_manager_admin" data-testid="serviceEditButton" ><i class="fas fa-pen"></i></span>
                        <span class="edit-button edit-button--small" @click="saveOwnService()" v-if="ownServiceEdit" data-testid="serviceSaveButton"><i class="fas fa-save"></i></span>
                        <i class="far fa-question-circle" v-tooltip.left="{content: profile.accountPage.account.own_service_tooltip, classes: ['tooltip-wide']}" style="cursor: pointer;" data-testid="teamShowInfo"></i>
                    </h4>
                    <div class="own-service-img" v-if="accountInfo.ownRepairCompany && !ownServiceEdit">
                        <i class="fas fa-image" v-if="!accountInfo.ownRepairCompany.profile_image_loc"></i>
                        <img :src="accountInfo.ownRepairCompany.profile_image_loc" v-if="accountInfo.ownRepairCompany.profile_image_loc" />
                    </div>
                    <h6 v-if="!accountInfo.ownRepairCompany && !ownServiceEdit" style="margin-bottom: 15px;">{{profile.accountPage.account.no_own_service_yet}}</h6>
                    <div class="own-service-info" v-if="accountInfo.ownRepairCompany && !ownServiceEdit">
                        <h6 data-testid="serviceNameText">{{accountInfo.ownRepairCompany.name}}</h6>
                        <span>{{accountInfo.ownRepairCompany.contact_name}}</span>
                    </div>
                    <div class="own-service-img" v-if="tempOwnRepairCompany && tempOwnRepairCompany.name && !ownServiceEdit">
                        <i class="fas fa-image" v-if="!tempOwnRepairCompany.profile_image_loc"></i>
                        <img :src="tempOwnRepairCompany.profile_image_loc" v-if="tempOwnRepairCompany.profile_image_loc" />
                    </div>
                    <div class="own-service-info" v-if="tempOwnRepairCompany && tempOwnRepairCompany.name && !ownServiceEdit">
                        <h6>{{tempOwnRepairCompany.name}}</h6>
                        <span>{{tempOwnRepairCompany.contact_name}}</span>
                    </div>
                    <span class="button button--icon button--right" @click="removeOwnServiceCompany()" v-if="accountInfo.building_manager_admin && accountInfo.ownRepairCompany && !ownServiceEdit" data-testid="teamDeactivateButton" ><i class="fas fa-trash-alt"></i>{{profile.accountPage.account.deselect_service}}</span>
                    <div v-if="ownServiceEdit && this.accountInfo.building_manager_admin">
                        <div class="dropdown-section">
                            <span class="dropdown" v-on:click="ownServiceCompanyDropdownToggled = !ownServiceCompanyDropdownToggled" :class="{active: ownServiceCompanyDropdownToggled}" data-testid="teamSelectField" >
                                <span v-if="!tempOwnRepairCompany.name">{{profile.accountPage.account.select_service}}</span>
                                <span v-if="tempOwnRepairCompany.name" class="selected">{{tempOwnRepairCompany.name}}</span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </span>
                            <div class="dropdown-list" v-if="ownServiceCompanyDropdownToggled" :class="{search: accountInfo.repaircompanies.length > 9}" data-testid="teamListField" >
                                <input type="text" placeholder="Zoek..." @input="e => ownServiceSearchTerm = e.target.value" :value="ownServiceSearchTerm" @keyup="searchServiceCompanies()" v-if="accountInfo.repaircompanies.length > 9" />
                                <ul>
                                    <li v-for="(company, index) in accountInfo.repaircompanies" v-on:click="selectOwnRepairCompany(company)" :key="index" v-show="!tempOwnServiceCompanies.length && !noOwnServiceResults">{{company.name}}</li>
                                    <li v-for="company in tempOwnServiceCompanies" v-on:click="selectOwnRepairCompany(company)" :key="company.id_repair_company" v-show="tempOwnServiceCompanies.length">{{company.name}}</li>
                                    <li v-if="noOwnServiceResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                </ul>
                            </div>
                        </div>
                        <i class="fa fa-times" @click="cancelOwnServiceEdit()"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="account-info mechanics" style="margin-top: 20px; position: relative;" v-if="accountInfo.canbe_id_repair_company !== -1 && !tempOwnRepairCompany.id_repair_company">
            <div class="row">
                <div class="col">
                    <h5 v-if="accountInfo.ownRepairCompany && !ownServiceEdit">{{profile.nav.mechanics}}</h5>
                    <span class="edit-button icon" @click="newMechanicModalOpen = true" data-testid="specialistAddButton" v-if="accountInfo.building_manager_admin && accountInfo.ownRepairCompany && !ownServiceEdit" style="margin-top: -3px; right: 15px;">
                        <i class="fa-solid fa-plus"></i>
                    </span>
                    <div class="employees" style="margin-top: 15px;" v-if="accountInfo.ownRepairCompany">
                        <div class="employee" v-for="(mechanic, index) in mechanics" :key="index">
                            <span class="edit-button icon" @click="mechanic.edit = true" data-testid="specialistEditButton" style="margin-right: 22px;" v-if="!mechanic.edit && accountInfo.building_manager_admin">
                                <i class="fas fa-pen"></i>
                            </span>
                            <span class="edit-button edit-button--small" @click="saveMechanic(mechanic)" data-testid="specialistSaveButton" v-if="mechanic.edit" style="margin-right: 22px;"><i class="fas fa-save"></i></span>
                            <span class="edit-button icon" @click="removeMechanic(mechanic)" data-testid="specialistRemoveButton" v-if="accountInfo.building_manager_admin">
                                <i class="far fa-trash-alt"></i>
                            </span>
                            <div v-if="!mechanic.edit">
                                <h3 data-testid="specialistNameText">{{mechanic.name}}</h3>
                                <div class="specialisms" v-if="mechanic.specialismsList.length">
                                    <span class="specialism" v-for="specialism in mechanic.specialismsList" :key="specialism.id_specialism">{{specialism.name_specialism}}</span>
                                </div>
                                <span class="username" data-testid="specialistEmailText"><i class="fas fa-at"></i> {{mechanic.email}}</span> <span v-if="mechanic.mobile" class="seperator">|</span>
                                <span class="phone" data-testid="specialistPhoneText" v-if="mechanic.mobile"><i class="fas fa-phone-alt"></i> {{mechanic.mobile}}</span>
                                <span data-testid="specialistDescriptionText" v-if="mechanic.description" style="display: block; font-weight: 500; clear: both; font-size: 14px; margin-bottom: 5px;">"{{mechanic.description}}"</span>
                                <span data-testid="specialistCommunicationText" v-if="mechanic.no_communication" style="display: block; font-weight: 500; clear: both; font-size: 14px;"><i class="fas fa-times"></i> {{profile.repair_companies.repaircompanydetail.nocommunication}}</span>
                                <span data-testid="specialistEmailSliderText" v-if="mechanic.only_email" style="display: block; font-weight: 500; clear: both; font-size: 14px;"><i class="fas fa-envelope"></i> {{profile.repair_companies.repaircompanydetail.onlyemail}}</span>
                                <span data-testid="specialistWChatText" v-if="mechanic.notify_whatsapp_chat" style="display: block; font-weight: 500; clear: both; font-size: 14px;"><i class="fab fa-whatsapp"></i> {{profile.accountPage.account.chat_updates_wa}}</span>
                                <span data-testid="specialistAlertText" v-if="mechanic.notify_whatsapp_new_incident" style="display: block; font-weight: 500; clear: both; font-size: 14px;"><i class="fab fa-whatsapp"></i> {{profile.accountPage.account.new_incidents_wa}}</span>
                                <span data-testid="specialistStatusText" v-if="mechanic.notify_whatsapp_status" style="display: block; font-weight: 500; clear: both; font-size: 14px;"><i class="fab fa-whatsapp"></i> {{profile.accountPage.account.status_updates_wa}}</span>
                            </div>
                            <div v-else>
                                <label>{{profile.employees.name}}</label>
                                <input type="text" class="form-control" v-model="mechanic.name" data-testid="specialistEditName" :disabled="!mechanic.no_communication && !mechanic.only_email" />
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label>{{profile.incidents.new_incident.emailaddress}}</label>
                                        <input type="text" class="form-control" v-model="mechanic.email" data-testid="specialistEditEmail" @input="validateMechanicEmail(mechanic)" :class="{valid: validMechanicEmail, error: invalidMechanicEmail}" :disabled="!mechanic.no_communication && !mechanic.only_email"/>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{profile.incidents.new_incident.phone}}</label>
                                        <input type="text" class="form-control" v-model="mechanic.mobile" data-testid="specialistEditMobile" @input="validateMechanicPhone(mechanic)" :class="{valid: validMechanicPhone, error: invalidMechanicPhone}" :disabled="!mechanic.no_communication && !mechanic.only_email" />
                                    </div>
                                </div>
                                <label>{{profile.specialists.specialism}}</label>
                                <div class="specialisms" v-if="mechanic.specialismsList.length">
                                    <span class="specialism" v-for="specialism in mechanic.specialismsList" :key="specialism.id_specialism" style="cursor: pointer;" @click="removeSpecialism(specialism, mechanic)">{{specialism.name_specialism}} <i class="fas fa-times"></i></span>
                                </div>
                                <div class="dropdown-section" style="width: 100%; margin-bottom: 10px;">
                                    <span class="dropdown" v-on:click="specialismDropdownToggled = !specialismDropdownToggled" :class="{active: specialismDropdownToggled}" data-testid="specialismField">
                                        <span v-if="mechanic.name_specialism" class="selected">{{mechanic.name_specialism}}</span>
                                        <span v-else>{{profile.specialists.choose_specialism}}</span>
                                        <i class="fas fa-chevron-down chevron"></i>
                                    </span>
                                    <div class="dropdown-list" v-if="specialismDropdownToggled" data-testid="specialistTypeSelect" >
                                        <ul>
                                            <li data-testid="specialistTypeList" v-for="(specialism, index) in specialisms" v-on:click="saveSpecialism(mechanic, specialism)" :key="index">{{specialism.name}}</li>
                                        </ul>
                                    </div>
                                </div>
                                <br />
                                <label>{{profile.repair_companies.repaircompanydetail.description}} *</label>
                                <textarea class="form-control" data-testid="specialistEditDescription" v-model="mechanic.description" style="height: 80px; margin-bottom: 10px;" :disabled="!mechanic.no_communication && !mechanic.only_email"></textarea>
                                <h3 style="font-size: 16px; color: #212529; padding-left: 0;">{{profile.mechanics.whatsapp_settings}}</h3>
                                <div class="switch-wrapper no-hover" style="clear: both;">
                                    <label class="switch">
                                        <input type="checkbox" v-model="mechanic.notify_whatsapp_new_incident">
                                        <span class="slider round" data-testid="specialistAlertEditSlider"></span>
                                    </label>
                                    <span style="font-weight: 100;">{{profile.accountPage.account.new_incidents_wa}}</span>
                                </div>
                                <div class="switch-wrapper no-hover" style="clear: both;">
                                    <label class="switch">
                                        <input type="checkbox" v-model="mechanic.notify_whatsapp_status">
                                        <span class="slider round" data-testid="specialistStatusEditSlider"></span>
                                    </label>
                                    <span style="font-weight: 100;">{{profile.accountPage.account.status_updates_wa}}</span>
                                </div>
                                <div class="switch-wrapper no-hover" style="clear: both; overflow: hidden;">
                                    <label class="switch">
                                        <input type="checkbox" v-model="mechanic.notify_whatsapp_chat">
                                        <span data-testid="specialistChatEditSlider" class="slider round"></span>
                                    </label>
                                    <span style="font-weight: 100;">{{profile.accountPage.account.chat_updates_wa}}</span>
                                </div>
                                <h3 style="color: #212529; padding-left: 0; margin-top: 10px; clear: both;">{{profile.repair_companies.repaircompanydetail.communication}}</h3>
                                <div class="switch-wrapper no-hover" style="clear: both;">
                                    <label class="switch">
                                        <input type="checkbox" v-model="mechanic.only_email" @change="mechanic.no_communication = false">
                                        <span class="slider round" data-testid="specialistEmailEditSlider"></span>
                                    </label>
                                    <span>{{profile.repair_companies.repaircompanydetail.onlyemail}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanydetail.onlyemail_tooltip" style="cursor: pointer;"></i></span>
                                </div>
                                <div class="switch-wrapper no-hover" style="clear: both;">
                                    <label class="switch">
                                        <input type="checkbox" v-model="mechanic.no_communication" @change="mechanic.only_email = false">
                                        <span class="slider round" data-testid="specialistCommunicationEditSlider"></span>
                                    </label>
                                    <span>{{profile.repair_companies.repaircompanydetail.nocommunication}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanydetail.nocommunication_tooltip" style="cursor: pointer;"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="visible-xs">
            <div class="block block--incident_detail location" style="min-height: 0; overflow: hidden;" v-if="accountInfo.service_qr_code">
                <section class="incident-head">
                    <h1 style="max-width: 100%;">{{profile.accountPage.account.need_service}}</h1>
                </section>
                <img :src="'/api/v1/asset/qrcodeasimage?qr_code=' + accountInfo.service_qr_code" class="qr-code" v-tooltip.right="profile.accountPage.account.service_qr" />
                <a :href="'/open/onboarding/?qrcode=' + accountInfo.service_qr_code" target="blank" class="button button-qr-code"><i class="fas fa-concierge-bell" style="margin-right: 10px;"></i>{{profile.accountPage.account.ask_service}}</a>
            </div>
        </div>
        <NewMechanicModal v-if="newMechanicModalOpen" @close="getMechanics()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import NewMechanicModal from "@/components/modals/NewMechanicModal.vue";

export default {
    mixins: [setLocale],
    components: {
        NewMechanicModal
    },
    name: 'serviceTeamBlock',
    data() {
        return {
            profile: {},
            ownServiceEdit: false,
            tempOwnRepairCompany: {},
            ownServiceCompanyDropdownToggled: false,
            ownServiceSearchTerm: '',
            noOwnServiceResults: false,
            newMechanicModalOpen: false,
            validMechanicPhone: false,
            invalidMechanicPhone: false,
            validMechanicEmail: false,
            invalidMechanicEmail: false,
            specialismDropdownToggled: false,
            tempOwnServiceCompanies: []
        }
    },
    props: ['accountInfo', 'admin', 'mechanics', 'specialisms'],
    methods: {
        selectOwnRepairCompany(company) {
            this.tempOwnRepairCompany = company;
            this.ownServiceCompanyDropdownToggled = false;
            this.tempOwnServiceCompanies = [];
            this.ownServiceSearchTerm = '';
        },
        cancelOwnServiceEdit() {
            this.ownServiceEdit = false;
            this.tempOwnRepairCompany = {};
        },
        removeOwnServiceCompany(company) {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][canbe_id_repair_company]', '-1');
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$emit('getAccountInfo');
                self.ownServiceEdit = false;
                for (var i = 0; i < self.mechanics.length; i++) {
                    let mechanic = self.mechanics[i];
                    mechanic.edit = false;
                }
                self.mechanics = [];
                self.ownRepairCompany = {};
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.accountInfo.canbe_id_repair_company = -1;
            });
        },
        searchServiceCompanies() {
            this.tempOwnServiceCompanies = [];
            if (this.ownServiceSearchTerm.length > 1) {
                let searchTerm = this.ownServiceSearchTerm.toLowerCase();
                for (var i = 0; i < this.accountInfo.repaircompanies.length; i++) {
                    let repaircompany = this.accountInfo.repaircompanies[i];
                    let repaircompanyName = repaircompany.name.toLowerCase();
                    if (repaircompanyName.includes(searchTerm)) {
                        this.tempOwnServiceCompanies.push(repaircompany);
                    }
                    if (!this.tempOwnServiceCompanies.length) {
                        this.noOwnServiceResults = true;
                    }
                    else {
                        this.noOwnServiceResults = false;
                    }
                }
            }
            else {
                this.noOwnServiceResults = false;
            }
        },
        saveOwnService() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][canbe_id_repair_company]', this.tempOwnRepairCompany.id_repair_company);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.tempOwnRepairCompany = {};
                self.ownServiceEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('getAccountInfo');
                self.serviceTeamNoComms();
            });
        },
        serviceTeamNoComms() {
            const onlyEmailData = new URLSearchParams();
            onlyEmailData.append('id_repair_company', this.accountInfo.canbe_id_repair_company);
            onlyEmailData.append('only_email', false);
            let self = this;
            fetch('/api/v1/repaircompany/updateonlyemail', {
                method: 'post',
                body: onlyEmailData
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
            const noCommData = new URLSearchParams();
            noCommData.append('id_repair_company', this.accountInfo.canbe_id_repair_company);
            noCommData.append('no_communication', false);
            fetch('/api/v1/repaircompany/updatenocommunication', {
                method: 'post',
                body: noCommData
            }).then((resp) => {return resp.text();
            }).then(function(data) {
            });
        },
        getMechanics() {
            this.newMechanicModalOpen = false;
            this.$emit('getMechanics');
        },
        saveMechanic(mechanic) {
            this.validateMechanicEmail(mechanic);
            this.validateMechanicPhone(mechanic);
            this.toggleNotsWa(mechanic);
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + mechanic.id_mechanic + '][name]', mechanic.name);
            data.append('data[' + mechanic.id_mechanic + '][email]', mechanic.email);
            data.append('data[' + mechanic.id_mechanic + '][mobile]', mechanic.mobile);
            data.append('data[' + mechanic.id_mechanic + '][description]', mechanic.description);
            data.append('data[' + mechanic.id_mechanic + '][only_email]', mechanic.only_email);
            data.append('data[' + mechanic.id_mechanic + '][no_communication]', mechanic.no_communication);
            if (this.validMechanicPhone && this.validMechanicEmail) {
                fetch('/api/v1/mechanic', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                });
            }
        },
        saveSpecialism(mechanic, specialism) {
            this.specialismDropdownToggled = false;
            let self = this;
            const data = new URLSearchParams();
            data.append('id_specialism', specialism.id_specialism);
            data.append('id_mechanic', mechanic.id_mechanic);
            let specialismItem = {
                id_specialism: specialism.id_specialism,
                name_specialism: specialism.name
            }
            mechanic.specialismsList.push(specialismItem);
            fetch('/api/v1/specialism/addmechanic', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        toggleNotsWa(mechanic) {
            const data = new URLSearchParams();
            data.append('id_mechanic', mechanic.id_mechanic);
            data.append('notify_whatsapp_chat',  mechanic.notify_whatsapp_chat);
            data.append('notify_whatsapp_new_incident', mechanic.notify_whatsapp_new_incident);
            data.append('notify_whatsapp_status', mechanic.notify_whatsapp_status);
            let self = this;
            fetch('/api/v1/mechanic/updatenotify', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                for (var i = 0; i < self.mechanics.length; i++) {
                    let mechanic = self.mechanics[i];
                    mechanic.edit = false;
                }
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        removeMechanic(mechanic) {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'remove');
            data.append('data[' + mechanic.id_mechanic + '][id_mechanic]', mechanic.id_mechanic);
            fetch('/api/v1/mechanic', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getMechanics();
            });
        },
        removeSpecialism(specialism, mechanic) {
            let self = this;
            const data = new URLSearchParams();
            data.append('id_specialism', specialism.id_specialism);
            data.append('id_mechanic', mechanic.id_mechanic);
            for (var i = 0; i < mechanic.specialismsList.length; i++) {
                let specialismItem = mechanic.specialismsList[i];
                if (specialismItem.id_specialism === specialism.id_specialism) {
                    mechanic.specialismsList.splice(i, 1);
                }
            }
            fetch('/api/v1/specialism/deletemechanic', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        validateMechanicPhone(mechanic) {
            if (mechanic.mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(mechanic.mobile)) {
                    this.validMechanicPhone = true;
                    this.invalidMechanicPhone = false;
                }
                else {
                    this.invalidMechanicPhone = true;
                    this.validMechanicPhone = false;
                }
            }
            else {
                this.validMechanicPhone = true;
                this.invalidMechanicPhone = false;
            }
        },
        validateMechanicEmail(mechanic) {
            if (mechanic.email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(mechanic.email)) {
                    this.validMechanicEmail = true;
                    this.invalidMechanicEmail = false;
                }
                else {
                    this.invalidMechanicEmail = true;
                    this.validMechanicEmail = false;
                }
            }
            else {
                this.invalidMechanicEmail = false;
                this.validMechanicEmail = false;
            }
        }
    }
}
</script>
