<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-photo-video"></i> {{profile.incident_detail.add_imagery}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" data-testid="closeUploadModal" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.accountPage.account.upload_media_recordings}}<br /></p>
                <form v-on:submit.prevent="uploadMedia()" v-if="!loading">
                    <div class="form-field-upload">
                        <input type="file" data-testid="inputMediaFile" @change="fileChange" multiple />
                    </div>
                    <div class="form-field" :class="{focus:fields.descr.length}">
                        <label>{{profile.incidents.new_incident.description}}</label>
                        <textarea class="form-control" data-testid="mediaDescription" v-model="fields.descr"></textarea>
                    </div>
                    <span class="notification notification--error" data-testid="uploadError" v-if="uploadError" style="margin-bottom: 10px;">{{profile.accountPage.account.upload_media_recordings}}</span>
                    <span class="notification notification--error" data-testid="uploadError" v-if="pdfError" style="margin-bottom: 10px;">{{profile.accountPage.account.uploadpdf_error}}</span>
                    <button type="submit" data-testid="submitMedia" class="button button--icon" :disabled="!fields.files"><i class="fas fa-photo-video"></i>{{profile.incident_detail.add_imagery}}</button>
                </form>
                <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            fields: {
                files: null,
                descr: ''
            },
            uploadError: false,
            pdfError: false,
            loading: false,
            videoPresent: false,
            uploadIds: []
        }
    },
    props: ['actionListId', 'mediaFields'],
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode === 27) {
                self.closeModal();
            }
        };
        console.log(this.mediaFields);
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        fileChange(e) {
            this.fields.files = e.target.files || e.dataTransfer.files;
        },
        uploadMedia() {  
            this.uploadError = false;
            this.numberOfFiles = this.fields.files.length;
            let self = this;
            for (var i = 0; i < this.fields.files.length; i++) {
                let file = this.fields.files[i];
                this.numberOfFiles = i;
                if (file.type === 'application/pdf') {
                    this.pdfError = true;
                }
                if (file.type === 'video/mp4' || file.type === 'video/3gp' || file.type === 'video/ts' || file.type === 'video/mkv' || file.type === 'video/mov' || file.type === 'video/quicktime' || file.type === 'video/avi') {
                    this.loading = true;
                    this.videoPresent = true;
                    const data = new FormData();
                    data.append('file', file);
                    data.append('filename', file.name);
                    if (this.fields.descr) {
                        data.append('description', this.fields.descr);
                    }
                    fetch('/api/v1/fileupload/uploadplannedactionvideo', {
                        method: 'post',
                        body: data
                    }).then((resp) => {
                        if (!resp.ok) {
                            self.uploadError = true;
                            self.loading = false;
                        }
                        return resp.json();
                    }).then(function(data) {
                        if (!data.url) {
                            self.uploadError = true;
                            self.loading = false;
                        }
                        self.uploadIds.push(data.id_file_upload);
                        if (self.uploadIds.length === self.numberOfFiles + 1) {
                            self.saveToAction();
                        }
                    });
                }
                if (file.type === 'image/bmp' ||
                    file.type === 'image/eps' ||
                    file.type === 'image/gif' ||
                    file.type === 'image/ico' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/pcx' ||
                    file.type === 'image/png' ||
                    file.type === 'image/raf' ||
                    file.type === 'image/tiff' ||
                    file.type === 'image/webp') {
                    this.loading = true;
                    const data = new FormData();
                    data.append('file', file);
                    if (this.fields.descr) {
                        data.append('description', this.fields.descr);
                    }                    
                    fetch('/api/v1/fileupload/uploadplannedactionimage', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        if (!data.url) {
                            self.uploadError = true;
                            self.loading = false;
                        }
                        self.uploadIds.push(data.id_file_upload);
                        if (self.uploadIds.length === self.numberOfFiles + 1) {
                            self.saveToAction();
                        }
                    });
                }
            }
        },
        saveToAction() {
            for (var i = 0; i < this.uploadIds.length; i++) {
                let uploadId = this.uploadIds[i];
                const data = new URLSearchParams();
                let actionFieldId = this.mediaFields[i].id_planned_action_field;
                data.append('id_planned_action_field', actionFieldId);
                data.append('id_file_upload', uploadId);
                let self = this;
                fetch('/api/v1/plannedactionfield/update', {
                    method: 'post',
                    body: data
                }).then((resp) => {
                    if (!resp.ok) {
                        self.uploadError = true;
                    }
                    return resp.json();
                }).then(function(data) {
                    self.loading = false;
                    if (!self.uploadError) {
                        self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                        self.closeModal();
                        self.$emit('success');
                    }
                });
            }
        }
    }
}
</script>
