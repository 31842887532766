<template>
    <div class="modal-wrapper" v-if="profile.repair_company">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-ban"></i>{{profile.invoicing.cancel_commission}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" data-testid="commissionCancelCancel" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.invoicing.cancel_commission_message}}<br /><br /></p>
                <div class="form-field" :class="{focus:fields.descr.length}">
                    <label>{{profile.incident_detail.cancelled_reason}}</label>
                    <textarea class="form-control" data-testid="commissionCancelText" v-model="fields.descr" style="margin-bottom: 15px;"></textarea>
                </div>
                <button class="button button--icon button--right" data-testid="commissionCancelSubmit" @click="addHandler()"><i class="fas fa-times"></i>{{profile.invoicing.cancel_commission}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'removeCommissionModal',
    props: ['incidentInfo'],
    data() {
        return {
            profile: {},
            fields : {
                descr: ''
            }
        }
    },
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    beforeDestroy() {
        this.removeHandler();
    },
    methods: {
        addHandler() {
            window.addEventListener('click', this.removeRepairCompany);
        },
        removeHandler() {
            window.removeEventListener('click', this.removeRepairCompany);
        },
        removeRepairCompany() {
            this.applicationLog();
            let currentIncidentId = this.getUrlParameter('id');
            if (currentIncidentId && currentIncidentId === this.incidentInfo.id_incident.toString()) {
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('cancel_order_reason', this.fields.descr);
                let self = this;
                if (this.incidentInfo.id_repair_company) {
                    fetch('/api/v1/incident/cancelrepaircompany', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.incident_detail.select_this_repaircompany);
                        self.$emit('remove');
                        document.querySelector('body').classList.remove('modal-open');
                    });
                }
                else {
                    fetch('/api/v1/incident/cancelservicedesk', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function(data) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.incident_detail.select_this_repaircompany);
                        self.$emit('remove');
                        document.querySelector('body').classList.remove('modal-open');
                    });
                }
            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        applicationLog() {
            try {
                const data = new URLSearchParams();
                data.append('message', 'Cancel Commission');
                data.append('id_incident', this.incidentInfo.id_incident);
                let self = this;
                fetch('/api/v1/applicationlog/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {});
            } catch (err) {
                console.log(err);
            }
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        }
    }
}
</script>
