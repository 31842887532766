<template>
    <div>
        <div>
            <div id="qr-reader-div">
                <video id="qr-video" ref="video" style="width: 100%;" v-if="!unknownQr"></video>
            </div>
            <div id="qr-reader-results"></div>
            <div class="back-to-scanner" @click="restartScanner()" v-if="unknownQr"><i class="fa fa-chevron-left"></i> {{profile.scanner.back_to_scanner}}</div>
            <div class="notification notification--error" v-if="unknownQr">{{profile.scanner.code_unknown}}</div>
            <div class="row" v-if="unknownQr">
                <div class="col">
                    <span class="button button--right button--icon" style="margin: 20px 0 0 0;" @click="restartScanner()"><i class="fas fa-qrcode"></i>{{profile.scanner.scan_another}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import QrScanner from 'qr-scanner/qr-scanner.umd.min.js';

export default {
    mixins: [setLocale],
    name: 'autoDispatch',
    data() {
        return {
            profile: {},
            errorCount: 0,
            scanner: null,
            video: null,
            qrCode: '',
            asset: {},
            unknownQr: false
        }
    },
    mounted() {
        this.video = this.$refs.video;
        this.createScanner();
        this.startScanner();
    },
    methods: {
        restartScanner() {
            this.unknownQr = false;
            this.scanner.destroy();
            setTimeout(function () {
                this.video = this.$refs.video;
                this.createScanner();
                this.startScanner();
            }.bind(this), 200);
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null;
        },
        createScanner() {
            this.scanner = new QrScanner(this.video, result => this.qrCodeSuccessCallback(result), error => this.qrCodeErrorCallback(error));
        },
        startScanner() {
            this.scanner.start().then(
       		    () => {
            		console.log("Scanner.start() true");
       		    },
       		    (error) => {
                    console.log("Scanner.start() false", error);
       		    }
       		);
        },
        qrCodeSuccessCallback(result) {
            if (result.includes('waldorf') || result.includes('s1monegroup.com')) {
                this.qrCode = result.split('qrcode=')[1];
            }
        	this.scanner.stop();
        	this.scanner.destroy();
            this.getAssetId();
        },
        qrCodeErrorCallback(error) {
           	if (error && this.errorCount<5 && (error+"").indexOf("No QR code found")==-1) {
           		this.errorCount++;
           		console.log("qrCodeErrorCallback", error);
           	}
        },
        getAssetId() {
            if (this.qrCode) {
                let self = this;
                fetch('/api/v1/assetbyqrcode?qr_code=' + this.qrCode)
                .then(response => { response.json().then(
                    function(data) {
                        let object = {
                            'id_asset': data.id_asset,
                            'qr_code': self.qrCode
                        }
                        self.$emit('scanned', object);
                    });
                });
            }
            else {
                this.unknownQr = true;
            }
        }
    }
}
</script>
