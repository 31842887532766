<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-solid fa-plus"></i>{{profile.actionable.add_action}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="createAction()">
                    <div class="form-field" :class="{focus:fields.name.length}">
                        <label>{{profile.assets.assetmodal.name}} *</label>
                        <input type="text" data-testid="newActionName" class="form-control" v-model="fields.name" ref="name" required>
                    </div>
                    <div class="form-field" :class="{focus:fields.type.length}">
                        <label>{{profile.actionable.group}}</label>
                        <input type="text" data-testid="newActionGroup" class="form-control" v-model="fields.type" ref="type">
                        <span class="tooltip-indicator" v-tooltip.right="profile.actionable.group_tooltip"><i class="fas fa-info-circle"></i></span>
                    </div>
                    <button type="submit" data-testid="newActionSubmit" class="button button--icon"><i class="fa-solid fa-plus"></i>{{profile.actionable.add_action}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['selectedRecording'],
    data() {
        return {
            profile: {},
            fields: {
                type: '',
                name: ''
            }
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    mounted() {
        this.$refs.name.focus();
    },
    methods: {
        createAction() {
            const data = new URLSearchParams();
            data.append('id_actionable_list', this.selectedRecording.id_actionable_list);
            data.append('name', this.fields.name);
            if (this.fields.type) {
                data.append('group_name', this.fields.type);
            }
            data.append('actionable_type', 'check');
            if (this.fields.name.length) {
                let self = this;
                fetch('/api/v1/actionable/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    document.querySelector('body').classList.remove('modal-open');
                    self.$emit('success', self.selectedRecording.id_actionable_list);
                });
            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
