<template>
    <div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            managers: [],
            profile: {}
        }
    },
    created() {
        document.title = 'S1MONE | Dashboard';
    },
    methods: {
       
    }
}
</script>
