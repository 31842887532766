<template>
    <div class="modal-wrapper" v-if="profile.incidents">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-qrcode"></i> {{profile.assets.generate_qr}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner" id="inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p v-html="profile.assets.generate_qr_expla"></p>
                <span class="button button--icon" data-testid="submitConnectAssetButton" @click="connectAsset()" style="float: right;"><i class="fas fa-qrcode"></i> {{profile.assets.generate_qr}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['asset'],
    data() {
        return {
            profile: {},
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };      
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        connectAsset() {
            const data = new URLSearchParams();
            data.append('id_asset', this.asset.id_asset);
            let self = this;
            fetch('/api/v1/asset/setgetqrcode', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('success');
            });
        }
    }
}
</script>
