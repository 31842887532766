<template>
    <div class="modal-wrapper" v-if="profile.owners">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-trash-alt"></i>{{profile.mechanic_detail.remove_specialist}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p v-html="profile.mechanic_detail.areyousure"></p>
                <button class="button button--icon button--right" v-on:click="removeSpecialist()"><i class="fas fa-trash-alt"></i>{{profile.mechanic_detail.remove_specialist}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'removeSpecialistModal',
    props: ['specialistInfo'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        removeSpecialist() {
            const data = new URLSearchParams();
            data.append('action', 'remove');
            data.append('data[' + this.specialistInfo.id_mechanic + '][id_mechanic]', this.specialistInfo.id_mechanic);
            let self = this;
            fetch('/api/v1/mechanic', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.closeModal();
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$router.push('/supplier/specialists');
            });
        }
    }
}
</script>
