export const websocketSupplier = {
    data() {
        return {
            errCount: 0,
            logs: [],
            status: 'disconnected',
            socket: null,
            connection: null
        }
    },
    created() {
        if (!window.location.href.includes('8080') && !window.location.href.includes('8081')) {
            this.startWebSocket();
        }
    },
    methods: {
        startWebSocket() {
            let wsProtocol = 'wss://';
            let host = window.location.host;
            let wsUri = wsProtocol + host + "/ws/v2/chatbase/supplier";
            this.connection = new WebSocket(wsUri);
            let self = this;
            this.connection.onopen = function(event) { self.onOpen(event); }
            this.connection.onmessage = function(event) { self.onMessage(event); }
            this.connection.onerror = function(event) { self.onError(event); }
            this.connection.onclose = function(event) { self.onClose(event); }
        },
        onOpen() {
            let self = this;
            fetch('/api/v1/keyforwebsocket', {
            }).then((resp) => {
                return resp.json();
            }).then(function(json) {
                self.sendToServer("auth", JSON.stringify(json));
            });
        },
        onMessage(event) {
            this.getMessagesManager();
            let message = JSON.parse(event.data);
            if (message.action) {
                setTimeout(function () {
                    for (var i = 0; i < this.dialogs.length; i++) {
                        let dialog = this.dialogs[i];
                        if (dialog.reporterChat && (message.action === 'sendmessage' || message.action === 'sendmessage_external_reporter' || dialog.lastMessage.body.includes(this.incidentInfo.building_manager_name))) {
                            if (!this.selectedDialog.reporterChat) {
                                dialog.unread = true;
                                this.newChats = true;
                            }
                        }
                        if (dialog.managerChat && (message.action === 'sendmessage_servicedesk_to_supplier'  || message.action === 'sendmessage_supplier_to_servicedesk ')) {
                            if (!this.selectedDialog.managerChat) {
                                dialog.unread = true;
                                this.newChats = true;
                            }
                        }
                    }
                    if (this.selectedDialog.managerChat) {
                        this.setwhatsappasread('servicedeskChat')
                    }
                    if (this.selectedDialog.type === 'reporterChat') {
                        this.setwhatsappasread('reporterChat')
                    }
                    if (this.selectedDialog.messages && this.selectedDialog.messages.length) {
                        setTimeout(function () {
                            let element = document.getElementById('messages');
                            if (element) {
                                element.scrollTop = element.scrollHeight;
                            }
                        }.bind(this), 500);
                    }
                    this.backDisabled = false;
                }.bind(this), 2000);
            }
        },
        onClose(event) {
            if (!event.wasClean) {
                let self = this;
                fetch('/api/v1/isauthenticated/assupplier')
                .then(response => { response.text().then(
                    function(data) {
                        if (data === 'true') {
                            self.startWebSocket();
                        }
                        else {
                            window.location = '/signin.html';
                        }
                    });
                });
            }
        },
        onError(error) {
            console.log('[error]', error.message);
        },
        sendToServer(action, body, idIncident, from, to) {
            var message = new Object();
            message.action     = action;
            message.body       = body;
            message.idIncident = idIncident;
            message.from = from;
            message.to   = to;
            this.sendMessageToServer(message);
        },
        sendMessageToServer(message) {
            message = JSON.stringify(message);
            if (this.connection && this.connection.readyState==1) {
                this.connection.send(message);
                this.errCount=0;
                setTimeout(function () {
                    let element = document.getElementById('messages');
                    if (element) {
                        element.scrollTop = element.scrollHeight;
                    }
                }.bind(this), 500);
            } else if (!this.connection || (this.connection.readyState==3 && this.errCount<5)) {
                this.errCount++;
            } else if (this.errCount<20) {
                this.errCount++;
            } else {
                if (this.errCount%10 == 0 ) console.log("too many errors");
            }
        }
    }
}
