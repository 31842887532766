<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-pause"></i>{{profile.incident_detail.paused_mechanic_alt}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="pause()">
                    <div class="form-field" :class="{focus:fields.descr.length}">
                        <label>{{profile.incident_detail.paused_incident_reason}}</label>
                        <textarea class="form-control" v-model="fields.descr"></textarea>
                    </div>
                    <button type="submit" class="button button--icon"><i class="fas fa-pause"></i>{{profile.incident_detail.paused_mechanic_alt}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
export default {
    mixins: [setLocale],
    name: 'MechanicCheckPauseModal',
    data() {
        return {
            profile: {},
            fields : {
                descr: ''
            }
        }
    },
    props: ['incidentId'],
    methods: {
        pause() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('pause_reason', this.fields.descr);
            let self = this;
            fetch('/api/v1/appointmentbyqrcode/pause_mechanic', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                document.querySelector('body').classList.remove('modal-open');
                self.$emit('pause');
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
