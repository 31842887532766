<template>
    <div class="block block--account block--border" style="min-height: 0; overflow: hidden;" v-if="accountInfo.has_agreement_flow">
        <div class="account-info">
            <h4>{{profile.owners.agreementflow.agreement_flow}} <div class="premium-label" v-tooltip.right="profile.accountPage.account.payed_functionality"><i class="fas fa-euro-sign"></i> Premium</div>
                <span class="edit-button edit-button--small" data-testid="agreementEditButton" @click="agreementFlowEditMode()" v-if="!agreementFlowEdit && accountInfo.building_manager_admin"><i class="fas fa-pen"></i></span>
                <span class="edit-button edit-button--small" data-testid="agreementSaveButton" @click="saveAgreementFlow()" v-if="agreementFlowEdit  && accountInfo.building_manager_admin"><i class="fas fa-save"></i></span>
            </h4>
            <div v-if="agreementFlowEdit">
                <div class="row">
                    <div class="col-md-6">
                        <label>{{this.profile.owners.agreementflow.reminderHoursLabel}}</label>
                        <input type="text" class="form-control" data-testid="agreementHoursInput" v-model="fields.agreement_flow_reminder_hours" @input="validateReminderHours($event)" />
                    </div>
                    <div class="col-md-6">
                        <label>{{this.profile.owners.agreementflow.forcedHoursLabel}}</label>
                        <input type="text" class="form-control" data-testid="agreementForcedInput" v-model="fields.agreement_flow_forced_hours" @input="validateForcedHours($event)" />
                    </div>
                </div>
                <div class="notification notification--error" v-if="timeError">{{this.profile.owners.agreementflow.hoursError}}</div>
            </div>
            <div v-else>
                <div class="row">
                    <div class="col-md-6">
                        <label>{{this.profile.owners.agreementflow.reminderHoursLabel}}</label>
                        <p data-testid="agreementHoursText" style="margin-bottom: 0;">{{accountInfo.agreement_flow_reminder_hours}}</p>
                    </div>
                    <div class="col-md-6">
                        <label>{{this.profile.owners.agreementflow.forcedHoursLabel}}</label>
                        <p data-testid="agreementForcedText" style="margin-bottom: 0;">{{accountInfo.agreement_flow_forced_hours}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    name: 'Agreementflow',
    mixins: [setLocale],
    data: function () {
        return {
            agreementFlowEdit: false,
            fields: {
                agreement_flow_reminder_hours: 0,
                agreement_flow_forced_hours: 0
            },
            timeError: false
        }
    },
    props: ['accountInfo', 'admin'],
    methods: {
        agreementFlowEditMode() {
            this.agreementFlowEdit = true;
            this.fields.agreement_flow_reminder_hours = this.accountInfo.agreement_flow_reminder_hours;
            this.fields.agreement_flow_forced_hours = this.accountInfo.agreement_flow_forced_hours;
        },
        validateReminderHours() {
            if (this.fields.agreement_flow_reminder_hours.includes(',')) {
                this.fields.agreement_flow_reminder_hours = this.fields.agreement_flow_reminder_hours.replace(',', '');
            }
            if (this.fields.agreement_flow_reminder_hours.includes('.')) {
                this.fields.agreement_flow_reminder_hours = this.fields.agreement_flow_reminder_hours.replace('.', '');
            }
            if (isNaN(this.fields.agreement_flow_reminder_hours)) {
                this.fields.agreement_flow_reminder_hours = '';
            }
            if (this.fields.agreement_flow_reminder_hours > this.fields.agreement_flow_forced_hours) {
                this.timeError = true;
            }
            else {
                this.timeError = false;
            }
        },
        validateForcedHours() {
            if (this.fields.agreement_flow_forced_hours.includes(',')) {
                this.fields.agreement_flow_forced_hours = this.fields.agreement_flow_forced_hours.replace(',', '');
            }
            if (this.fields.agreement_flow_forced_hours.includes('.')) {
                this.fields.agreement_flow_forced_hours = this.fields.agreement_flow_forced_hours.replace('.', '');
            }
            if (isNaN(this.fields.agreement_flow_forced_hours)) {
                this.fields.agreement_flow_forced_hours = '';
            }
            if (this.fields.agreement_flow_reminder_hours > this.fields.agreement_flow_forced_hours) {
                this.timeError = true;
            }
            else {
                this.timeError = false;
            }
        },
        saveAgreementFlow() {
            const params = new URLSearchParams();
            let reminderMinutes = this.fields.agreement_flow_reminder_hours * 60;
            let forcedMinutes = this.fields.agreement_flow_forced_hours * 60;
            params.append('action', 'edit');
            params.append('data[0][agreement_flow_reminder_minutes]', reminderMinutes);
            params.append('data[0][agreement_flow_forced_minutes]', forcedMinutes);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$emit('getAccountInfo');
                self.agreementFlowEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
