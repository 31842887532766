<template>
    <div class="modal-wrapper" v-if="profile.incidents" v-show="!loading">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-cube"></i>{{profile.assets.assetmodal.change_asset}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="saveAsset()" style="overflow: hidden;">
                    <h5>{{profile.assets.assetmodal.change_asset}}</h5>
                    <div class="form-field clearable" :class="{focus: placeSearchTerm.length, error: placeError, active: places.length || generalPlaces.length}" style="margin-bottom: 10px;">
                        <label v-if="!selectedPlace.id_place">{{profile.incidents.new_incident.loc_placeholder}} *</label>
                        <div>
                            <div class="selected-info seperate" v-if="selectedPlace.name">
                                <h4>{{selectedPlace.name}}</h4>
                                <p>{{selectedPlace.address}} {{selectedPlace.house_number}}, {{selectedPlace.city}}</p>
                                <span>{{profile.incident_detail.floor_name}} {{selectedPlace.floor_name}}</span>
                                <span v-if="selectedPlace.id_place" class="remove-selection" @click="removePlaceSelection()"><i class="fas fa-times"></i></span>
                                <span class="general-label" v-if="selectedPlace.floor_name === 'VVEVEST'">{{profile.incidents.new_incident.general_spaces}}</span>
                            </div>
                            <input type="text" ref="place" class="form-control" data-testid="inputLocation" @input="e => placeSearchTerm = e.target.value" :value="placeSearchTerm" @keyup="searchPlaces()" v-if="!selectedPlace.name" />
                        </div>
                    </div>
                    <ul v-if="places.length || generalPlaces.length" class="dropdown-list" style="padding: 0; margin-top: -12px;">
                        <li v-for="place in generalPlaces" :key="place.id_place" class="dropdown-list-item" data-testid="listLocation" v-on:click="selectPlace(place)">
                            <h4>{{place.name}}</h4>
                            <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                            <span class="general-label" v-if="place.floor_name === 'VVEVEST'">{{profile.incidents.new_incident.general_spaces}}</span>
                        </li>
                        <li v-for="place in places" :key="place.id_place" class="dropdown-list-item" data-testid="listLocation" v-on:click="selectPlace(place)">
                            <h4>{{place.name}}</h4>
                            <p>{{place.address}} {{place.house_number}}, {{place.city}}</p>
                            <span>{{profile.incident_detail.floor_name}} {{place.floor_name}}</span>
                        </li>
                    </ul>
                    <div v-if="selectedPlace.id_place">
                        <div class="dropdown-section">
                            <span class="dropdown" data-testid="selectObject" v-on:click="assetsDropdownToggled = !assetsDropdownToggled" :class="{active: assetsDropdownToggled, error: assetError}">
                                <span v-if="!selectedAssetId">{{profile.incidents.new_incident.choose_asset}} *</span>
                                <span v-if="selectedAssetId" class="selected">{{selectedAssetName}}</span>
                                <i class="fas fa-chevron-down chevron"></i></span>
                            <div class="dropdown-list" data-testid="listObject" v-if="assetsDropdownToggled" :class="{search: assets.length > 9}">
                                <input type="text" @input="e => assetSearchTerm = e.target.value" :value="assetSearchTerm" placeholder="Zoek een locatienaam..." @keyup="searchAssets()" v-if="assets.length > 9"  />
                                <ul>
                                    <li v-for="asset in assets" v-on:click="selectAsset(asset)" :key="asset.id_asset" v-show="!tempAssets.length && !assetsNoResults">
                                        {{asset.name}}
                                        <span class="active-label" v-if="!asset.hidden && asset.qr_code">{{profile.assets.assettable.active}}</span>
                                        <span class="inactive-label" v-if="asset.hidden">{{profile.assets.inactive}}</span>
                                    </li>
                                    <li v-for="(asset, index) in tempAssets" v-on:click="selectAsset(asset)" :key="index" v-show="tempAssets.length">{{asset.name}}</li>
                                    <li v-if="assetsNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="button button--icon" data-testid="buttonSubmit" id="incidentSubmit"><i class="fas fa-cube"></i>{{profile.assets.assetmodal.change_asset}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    props: ['incidentInfo'],
    data() {
        return {
            profile: {},
            places: [],
            generalPlaces: [],
            assets: [],
            placesDropdownToggled: false,
            assetsDropdownToggled: false,
            selectedPlace: {},
            selectedAssetId: 0,
            selectedAssetName: '',
            assetsNoResults: false,
            placesNoResults: false,
            tempAssets: [],
            placeSearchTerm: '',
            assetSearchTerm: '',
            placeError: false,
            assetError: false,
            loading: false
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        this.loading = true;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.placeSearchTerm = this.incidentInfo.place_name;
        this.searchPlaces(true);
    },
    mounted() {
        this.$refs.place.focus();
    },
    methods: {
        getAssets() {
            let self = this;
            fetch('/api/v1/asset/activebyplacewithqr?id_place=' + this.selectedPlace.id_place)
            .then(response => { response.json().then(
                function(data) {
                    if (data.data.length) {
                        self.assets = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let item = data.data[i];
                            if (item.name) {
                                self.assets.push(item);
                            }
                        }
                    }
                });
            });
        },
        selectPlace(place) {
            this.places = [];
            this.generalPlaces = [];
            this.placesDropdownToggled = false;
            this.selectedPlace = place;
            this.placeSearchTerm = '';
            this.getAssets();
            this.selectedAssetId = 0;
            this.assetsDropdownToggled = false;
            this.placeError = false;
            this.loading = false;
        },
        selectAsset(asset) {
            this.assetsDropdownToggled = false;
            this.selectedAssetName = asset.name;
            this.selectedAssetId = asset.id_asset;
            this.tempAssets = [];
            this.assetSearchTerm = '';
            this.assetError = false;
        },
        searchPlaces(prefill) {
            if (this.placeSearchTerm.length > 2) {
                let searchParam = '';
                if (this.placeSearchTerm) {
                    searchParam = '?search_name=' + this.placeSearchTerm;
                }
                let self = this;
                fetch('/api/v1/place/bybuildingmanageronlywithassets' + searchParam)
                .then(response => { response.json().then(
                    function(data) {
                        self.places = [];
                        self.generalPlaces = [];
                        for (var i = 0; i < data.data.length; i++) {
                            let place = data.data[i];
                            if (place.floor_name === '0') {
                                if (self.locale === 'nl') {
                                    place.floor_name = 'BG';
                                }
                                else {
                                    place.floor_name = 'GF';
                                }
                            }
                            if (place.floor_name === 'VVEVEST') {
                                self.generalPlaces.push(place);
                            }
                            else {
                                self.places.push(place);
                            }
                            if (prefill && place.id_place === self.incidentInfo.id_place) {
                                self.selectPlace(place);
                            }
                        }
                        self.generalPlaces = sortBy(self.generalPlaces, 'floor_name', 'VVEVEST');

                        if (!self.places && !self.generalPlaces) {
                            self.placesNoResults = true;
                        }
                    });
                });
            }
            else {
                this.placesNoResults = false;
                this.places = [];
                this.generalPlaces = [];
            }
        },
        removePlaceSelection() {
            this.selectedPlace = {};
            this.generalPlaces = [];
            this.places = [];
        },
        searchAssets() {
            this.tempAssets = [];
            if (this.assetSearchTerm.length > 1) {
                let searchTerm = this.assetSearchTerm.toLowerCase();
                for (var i = 0; i < this.assets.length; i++) {
                    let asset = this.assets[i];
                    let assetName = asset.name.toLowerCase();
                    if (assetName.includes(searchTerm)) {
                        this.tempAssets.push(asset);
                    }
                    if (!this.tempAssets.length) {
                        this.assetsNoResults = true;
                    }
                    else {
                        this.assetsNoResults = false;
                    }
                }
            }
            else {
                this.assetsNoResults = false;
            }
        },
        saveAsset() {
            this.assetsDropdownToggled = false;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('id_asset', this.selectedAssetId);
            let self = this;
            fetch('/api/v1/incident/updateasset', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$gtag.event('click', {
                    event_category: 'Button',
                    event_label: 'Incident detail - Object switchen',
                });                
                document.querySelector('body').classList.remove('modal-open');
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('getIncidentInfo');
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
