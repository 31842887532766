<template>
    <div class="block block--account">
        <div class="account-info">
            <h4>{{profile.mandates.mandates}}
                <span class="edit-button edit-button--small" data-testid="mandateCreateNew" @click="startCreateNew()" v-if="!disableEdit"><i class="fa-solid fa-plus" style="font-size: 18px; margin: 0;"></i></span>
            </h4>
            <div class="row">
                <div class="col">
                    <span class="button" data-testid="mandateCreateDefault" @click="createFromDefault()" style="margin: 10px 0 20px 0; float: right;" v-if="!ownerMandates.length && hasDefaultMandates && !disableEdit">Mandaten aanmaken vanuit standaard</span>
                </div>
            </div>
            <div class="account-contents">
                <div class="row" v-if="createNewMandate">
                    <div class="col">
                        <div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label>Level</label>
                                    <p data-testid="mandateLevelInput">{{tempMandate.level}}</p>
                                </div>
                                <div class="col-sm-6">
                                    <label>{{profile.accountPage.account.amount}}</label>
                                    <input type="number" data-testid="mandateAmountInput" class="form-control" v-model="tempMandate.amount" />
                                </div>
                            </div>
                            <label>{{profile.employees.name}}</label>
                            <input type="text" data-testid="mandateNameInput" class="form-control" v-model="tempMandate.name" />
                            <div class="switch-wrapper">
                                <label class="switch">
                                    <input type="checkbox" v-model="tempMandate.in_btw">
                                    <span class="slider round" data-testid="mandateBtwSlider"></span>
                                </label>
                                <span><b>Incl. BTW</b></span>
                            </div>
                            <span class="button button--right button--icon" data-testid="mandateCreateSubmit" style="margin: 10px 0 0 0;" @click="createMandate()"><i class="fa-solid fa-plus"></i>{{profile.mandates.create_mandate}}</span>
                            <div class="errors" v-if="nameError || amountError">
                                <span data-testid="mandateNameError" class="notification notification--error" v-if="nameError">{{profile.mandates.name_error}}</span>
                                <span data-testid="mandateAmountError" class="notification notification--error" v-if="amountError">{{profile.mandates.amount_error}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div v-for="ownerMandate in ownerMandates" :key="ownerMandate.id_default_mandate" class="mandate" @click.stop="startEdit(ownerMandate)">
                            <span data-testid="mandateEditButton" class="edit-button edit-button--small"  v-if="!ownerMandate.edit && !disableEdit" style="margin-right: 15px; margin-top: 10px;"><i class="fas fa-pencil-alt"></i></span>
                            <span data-testid="mandateSaveButton" class="edit-button edit-button--small" @click="saveMandate(ownerMandate)" v-if="ownerMandate.edit" style="margin-right: 15px; margin-top: 10px;"><i class="fas fa-save"></i></span>
                            <span data-testid="mandateDeleteButton" class="edit-button edit-button--small" @click="deleteMandate(ownerMandate)" v-if="ownerMandate.allowedDelete && !disableEdit" style="margin-right: 40px; margin-top: 10px;"><i class="far fa-trash-alt"></i></span>
                            <div v-if="!ownerMandate.edit">
                                <h6 data-testid="mandateNameText" style="margin-bottom: 10px;">{{ownerMandate.name}}</h6>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label>Level</label>
                                        <p data-testid="mandateLevelText" style="margin-bottom: 0;">{{ownerMandate.level}}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{profile.accountPage.account.amount}}</label>
                                        <p data-testid="mandateAmountText" style="margin-bottom: 0;">€ {{ownerMandate.amount}}</p>
                                        <span data-testid="mandateBtwInText" class="btw" style="float: right; padding: 3px 5px; margin-top: -3px;" v-if="ownerMandate.in_btw">Incl. BTW</span>
                                        <span data-testid="mandateBtwOutText" class="btw btw--out" style="float: right; padding: 3px 5px; margin-top: -3px;" v-else>{{profile.mandates.out_btw}}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else style="padding-top: 15px;">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label>Level</label>
                                        <p data-testid="mandateLevelEdit" style="margin-bottom: 0;">{{ownerMandate.level}}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{profile.accountPage.account.amount}}</label>
                                        <input data-testid="mandateNumberEdit" type="number" class="form-control" v-model="ownerMandate.amount" />
                                    </div>
                                </div>
                                <label>{{profile.employees.name}}</label>
                                <input data-testid="mandateNameEdit" type="text" class="form-control" v-model="ownerMandate.name" />
                                <div class="switch-wrapper switch-wrapper--full alt-hover" :class="{active: ownerMandate.in_btw}">
                                    <div class="inner" @click.stop="ownerMandate.in_btw = !ownerMandate.in_btw">
                                        <i class="fa-regular fa-scroll"></i>
                                        <p>Incl. BTW</p>
                                        <span class="label">{{ profile.switches.mandate_inc }}</span>
                                        <label class="switch">
                                            <span data-testid="mandateBtwEdit" class="slider round"></span>
                                        </label>
                                    </div>     
                                </div>
                                <div class="errors" v-if="nameError || amountError">
                                    <span data-testid="mandateNameErrorEdit" class="notification notification--error" v-if="nameError">{{profile.mandates.name_error}}</span>
                                    <span data-testid="mandateAmountErrorEdit" class="notification notification--error" v-if="amountError">{{profile.mandates.amount_error}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';
import 'core-js/features/array/at';

export default {
    mixins: [setLocale],
    data: function () {
        return {
            profile: {},
            ownerMandates: [],
            createNewMandate: false,
            tempMandate: {
                name: '',
                amount: 0,
                in_btw: false,
                level: 1
            },
            lastMandateAmount: 0,
            lastMandateLevel: 0,
            amountError: false,
            nameError: false,
            hasDefaultMandates: false
        }
    },
    props: ['owner', 'disableEdit'],
    created() {
        this.getDefaultMandates();
        this.getOwnerMandates();
    },
    methods: {
        getDefaultMandates() {
            let self = this;
            fetch('/api/v1/defaultmandate')
            .then(response => { response.json().then(
                function(data) {
                    if (data.length) {
                        self.hasDefaultMandates = true;
                    }
                });
            });
        },
        getOwnerMandates() {
            let self = this;
            fetch('/api/v1/mandate/byowner?id_owner=' + this.owner.id_owner)
            .then(response => { response.json().then(
                function(data) {
                    self.createNewMandate = false;
                    self.amountError = false;
                    self.nameError = false;
                    self.ownerMandates = [];
                    if (data.length) {
                        for (var i = 0; i < data.length; i++) {
                            let mandate = data[i];
                            mandate.edit = false;
                            self.ownerMandates.push(mandate);
                        }
                        self.ownerMandates = sortBy(self.ownerMandates, 'level');
                        self.ownerMandates.at(-1).allowedDelete = true;
                    }
                    else {
                        self.tempMandate.level = 1;
                        self.lastMandateLevel = 0;
                    }
                });
            });
        },
        startCreateNew() {
            if (this.ownerMandates.length) {
                this.lastMandateAmount = this.ownerMandates.at(-1).amount;
                this.lastMandateLevel = this.ownerMandates.at(-1).level;
                this.tempMandate.level = this.ownerMandates.at(-1).level + 1;
            }
            this.createNewMandate = true;
        },
        startEdit(ownerMandate) {
            if (!ownerMandate.edit && !this.disableEdit) {
                ownerMandate.edit = true;
            }
        },
        saveMandate(ownerMandate) {
            this.lastMandateAmount = 0;
            this.lastMandateLevel = 0;
            this.amountError = false;
            this.nameError = false;
            let indexPrev;
            for (var i = 0; i < this.ownerMandates.length; i++) {
                let mandate = this.ownerMandates[i];
                if (mandate.id_mandate === ownerMandate.id_mandate) {
                    indexPrev = i - 1;
                }
            }
            if (indexPrev !== -1) {
                this.lastMandateAmount = this.ownerMandates[indexPrev].amount;
            }
            const data = new URLSearchParams();
            data.append('id_mandate', ownerMandate.id_mandate);
            data.append('name', ownerMandate.name);
            data.append('amount', ownerMandate.amount);
            data.append('in_btw', ownerMandate.in_btw);
            data.append('level', ownerMandate.level);
            if (!ownerMandate.name.length) {
                this.nameError = true;
            }
            else {
                this.nameError = false;
            }
            if (ownerMandate.amount <= this.lastMandateAmount) {
                this.amountError = true;
            }
            else {
                this.amountError = false;
            }
            if (!this.nameError && !this.amountError) {
                ownerMandate.edit = false;
                let self = this;
                fetch('/api/v1/mandate/update', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getOwnerMandates();
                });
            }
        },
        deleteMandate(ownerMandate) {
            const data = new URLSearchParams();
            data.append('id_mandate', ownerMandate.id_mandate);
            let self = this;
            fetch('/api/v1/mandate/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getOwnerMandates();
            });
        },
        createMandate() {
            const data = new URLSearchParams();
            data.append('id_owner', this.owner.id_owner);
            data.append('name', this.tempMandate.name);
            data.append('amount', this.tempMandate.amount);
            data.append('in_btw', this.tempMandate.in_btw);
            data.append('level', this.tempMandate.level);
            data.append('free_amount', true);

            if (!this.tempMandate.name.length) {
                this.nameError = true;
            }
            else {
                this.nameError = false;
            }
            if (this.tempMandate.amount <= this.lastMandateAmount) {
                this.amountError = true;
            }
            else {
                this.amountError = false;
            }
            if (!this.nameError && !this.amountError) {
                let self = this;
                fetch('/api/v1/mandate/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getOwnerMandates();
                });
            }
        },
        createFromDefault() {
            const data = new URLSearchParams();
            data.append('id_owner', this.owner.id_owner);
            let self = this;
            fetch('/api/v1/mandate/createfromdefault', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getOwnerMandates();
            });
        }
    }
}
</script>
