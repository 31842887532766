<template>
    <div>
        <div class="main-content" v-if="profile.repair_companies">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--repaircompanies">
                            <section class="incident-head" :class="{empty: !specialists.length}">
                                <h1>{{profile.mechanics.mechanictable.tablename}}</h1>
                            </section>
                            <span data-testid="specialistSearchEdit" class="edit-button icon" @click="newSpecialistModalOpen = true"><i class="fa-solid fa-plus"></i></span>
                            <section class="search search-alt" v-if="specialists.length">
                                <form>
                                    <input data-testid="specialistSearchInput" type="text" ref="search" v-bind:placeholder="profile.specialists.search_specialist" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                    <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                </form>
                            </section>
                            <div v-if="searchActive">
                                <h4 data-testid="specialistSearchCounter" class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{profile.dashboard.building_manager.result}} '{{searchTerm}}'</h4>
                                <h4 data-testid="specialistSearchNoResult" class="result-counter" v-if="!searchSuggestions.length">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                                <h4 data-testid="specialistSearchCounter2" class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                                <div v-for="specialist in searchSuggestions" :key="specialist.id_repair_company" class="list-item list-item--setheight" @click="selectSpecialist(specialist.id_mechanic)">
                                    <div class="item-contents">
                                        <h3 data-testid="specialistSearchName" @click="selectSpecialist(specialist.id_mechanic)">{{specialist.name}}</h3>
                                        <a data-testid="specialistSearchPhone" :href="`tel:${specialist.mobile}`" v-if="specialist.mobile"><span class="hidden-xs">&nbsp;|&nbsp;</span><i class="fas fa-mobile-alt"></i>{{specialist.mobile}}</a>
                                    </div>
                                    <img data-testid="specialistSearchImage" :src="specialist.profile_image_loc" v-if="specialist.profile_image_loc" />
                                </div>
                            </div>
                            <div v-else>
                                <div v-for="(specialist, index) in specialists" :key="index" class="list-item list-item--setheight" @click.self="selectSpecialist(specialist.id_mechanic)">
                                    <div class="item-contents">
                                        <h3 data-testid="specialistSearchName2" @click="selectSpecialist(specialist.id_mechanic)">{{specialist.name}}</h3>
                                        <a data-testid="specialistSearchMobile2" :href="`tel:${specialist.mobile}`" v-if="specialist.mobile"><span class="hidden-xs">&nbsp;|&nbsp;</span><i class="fas fa-mobile-alt"></i>{{specialist.mobile}}</a>
                                    </div>
                                    <img data-testid="specialistSearchImage2" :src="specialist.profile_image_loc" v-if="specialist.profile_image_loc" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewSpecialistModal v-if="newSpecialistModalOpen" @close="newSpecialistModalOpen = false" @save="getSpecialists()" :specialists="specialists" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import sortBy from 'lodash/sortBy';
import NewSpecialistModal from "@/components/supplier/modals/NewSpecialistModal.vue";

export default {
    mixins: [setLocale],
    components: {
        NewSpecialistModal
    },
    data() {
        return {
            profile: {},
            specialists: [],
            newSpecialistModalOpen: false,
            searchTerm: '',
            searchSuggestions: [],
            searchActive: false,
        }
    },
    created() {
        document.title = 'S1MONE | ' + this.profile.mechanics.mechanictable.tablename;
        this.getSpecialists();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getSpecialists() {
            this.newSpecialistModalOpen = false;
            let self = this;
            fetch('/api/v1/mechanic')
            .then(response => { response.json().then(
                function(data) {
                    self.specialists = data.data;
                    self.specialists =  sortBy(self.specialists, 'name');
                    console.log(self.specialists);
                });
            });
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.specialists.length; i++) {
                    let specialist = this.specialists[i];
                    let specialistName = specialist.name.toLowerCase();
                    if (specialistName.includes(searchTerm)) {
                        this.searchSuggestions.push(specialist);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        selectSpecialist(id) {
            this.$router.push('/supplier/specialist-detail?id_specialist=' + id);
        },
    }
}
</script>
