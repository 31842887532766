<template>
    <div class="modal-wrapper" v-if="profile.nav">
        <div class="modal">
            <div class="modal-header" :class="{warning: news.type === 'WARNING'}">
                <h3><i class="far fa-newspaper"></i>{{profile.nav.latest_news}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner" data-testid="newsHeader">
                <span class="close" data-testid="newsClose" @click="closeModal()"><i class="fas fa-times"></i></span>
                <span class="date" data-testid="newsDate">{{ this.date }}</span>
                <h4 style="clear: both; padding-top: 10px; display: block;" data-testid="newsTitle">{{ news.title }}</h4>
                <div v-html="news.body" class="news" data-testid="newsDescription"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';

export default {
    mixins: [setLocale],
    props: ['news'],
    data() {
        return {
            profile: {},
            date: ''
        }
    },
    created() {
        this.date = moment(this.news.created).locale('nl').format('D MMMM YYYY');
        document.querySelector('body').classList.add('modal-open');
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
