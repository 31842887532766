<template>
    <div>
        <div class="block block--location_detail">
            <section class="incident-head">
                <h1>API Gebruikers</h1>
            </section>
            <div class="table-wrap" style="height: 300px;">
                <table class="table table-striped" v-if="apiUsers.length">
                    <colgroup>
                        <col style="width: 30%;" />
                        <col style="width: 60%;" />
                        <col style="width: 10%;" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th scope="col-5" @click="sortTable('username')" :class="{active: tableSort === 'username'}">API Gebruiker <i class="fas fa-sort"></i></th>
                            <th scope="col-5" @click="sortTable('api_key')" :class="{active: tableSort === 'api_key'}">API sleutel <i class="fas fa-sort"></i></th>
                            <th scope="col-2" > Verwijderen </th> 
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(user, index) in apiUsers" :key="index" @click="editCategory(category)">
                            <td>{{user.username}}</td>
                            <td>{{user.api_key}}</td>
                            <td><span class="edit-button icon" @click="removeAPIUser(user.username)" style="position: absolute; top: 7px; right: 50px;
                                bottom: 0; margin: auto; height: 35px;"><i class="fa fa-times"></i></span></td>
                        </tr>
                    </tbody>
                </table>
                <div class="incident-contents" style="position:relative;">
                        <div class="incident-info">
                            <div class="row">
                                <div class="col-sm-3">
                                    <div class="dropdown-section" style="margin-bottom: 20px;">
                                        <select class="form-select" v-model="userToAdd">
                                            <option value="" disabled selected>Kies een gebruiker</option>
                                            <option v-for="(user, index) in noAPIUsers" :value="user.id_shiro_user" :key="index">{{user.username}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <button class="button button--icon" v-on:click="addUser()"><i class="fa-solid fa-plus"></i>Gebruiker toevoegen</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    props: ['managerID'],
    data() {
        return {
            apiUsers: [],
            noAPIUsers: [],
            BmID: '',
            userToAdd: '',
            tableSort: '',
            sortOrder: 'asc'
        }
    },
    created() {
        this.getAPIUsers();
        this.getNoAPIUsers();
    },
    methods: {
        getAPIUsers() {
            let self = this;
            fetch('/api/v1/shiro_user/withapibyidbuildingmanager?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.apiUsers = data.data;
                });
            });
        },
        getNoAPIUsers() {
            let self = this;
            fetch('/api/v1/shiro_user/noapikey?id_building_manager=' + self.managerID)
            .then(response => { response.json().then(
                function(data) {
                    self.noAPIUsers = data.data;
                });
            });
        },
        addUser(){
            const data = new URLSearchParams();
            data.append('id_building_manager', this.managerID);
            data.append('id_shiro_user', this.userToAdd);
            let self = this;
            fetch('/api/v1/shiro_user/createapikey', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.getAPIUsers();
                self.getNoAPIUsers();
                if(self.userToAdd != "") {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                }
                self.userToAdd = "";
            });
        },
        removeAPIUser(username) {
            const data = new URLSearchParams();
            data.append('id_building_manager', this.managerID);
            data.append('username', username);
            let self = this;
            fetch('/api/v1/shiro_user/removeapikey', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.getAPIUsers();
                self.getNoAPIUsers();
                if(self.userToAdd != "") {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                }
                self.userToAdd = "";
            });
        },
        sortTable(sorter) {
            this.tableSort = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.apiUsers = sortBy(this.apiUsers, sorter);
            if (this.sortOrder === 'desc') {
                this.apiUsers.reverse();
            }
        }
    }
}

</script>