<template>
    <div class="block block--account block--border block--border-large" v-if="accountInfo.building_manager_admin">
        <div class="account-info">
            <h4 :class="{active: activeEmployeesToggled}" @click="activeEmployeesToggled = !activeEmployeesToggled" style="margin-bottom: 0; cursor: pointer;">{{profile.employees.employees}} ({{employees.length}})<i class="fas fa-chevron-down" v-if="employees.length" style="margin-left: 10px;" data-testid="employeeShowAll" ></i></h4>
            <span class="edit-button icon" @click="newEmployeeModalOpen = true" v-if="accountInfo.building_manager_admin" data-testid="employeeAddButton" style="top: 20px;">
                <i class="fa-solid fa-plus"></i>
            </span>
            <div class="employees" style="margin-top: 20px;" v-if="employees.length" v-show="activeEmployeesToggled">
                <div class="employee" v-for="(item, index) in employees" :key="index">
                    <span class="edit-button icon" @click="removeEmployeeModal(item)" v-if="accountInfo.building_manager_admin && !item.edit">
                        <i class="fas fa-trash-alt"></i>
                    </span>
                    <span class="edit-button icon" @click="disableEmployeeModal(item)" v-if="!item.edit && !item.employee_disabled && accountInfo.building_manager_admin" v-tooltip.left="profile.employees.delete_employee" data-testid="employeeDeactivateButton" style="right: 36px;">
                        <i class="fas fa-toggle-off"></i>
                    </span>
                    <span class="edit-button icon" @click="editEmployee(item)" v-if="!item.edit && !item.employee_disabled && accountInfo.building_manager_admin" style="right: 65px;" data-testid="employeeEditButton" >
                        <i class="fas fa-pen"></i>
                    </span>
                    <span class="edit-button icon" @click="checkEmailActive(item)" v-if="item.edit" v-tooltip.right="profile.buttons.save" data-testid="employeeEditSaveButton">
                        <i class="fas fa-save"></i>
                    </span>
                    <div v-if="!item.edit">
                        <h3>{{item.actual_name}}
                            <span class="admin" v-if="item.buildingmanager_administrator">ADMIN</span>
                            <span style="float: none;" v-if="accountInfo.updates_via_wa_active">
                                <span style="float: none;" v-if="item.notify_whatsapp_chat || item.notify_whatsapp_status || item.notify_whatsapp_new_incident">| </span>
                            </span>
                            <span v-if="accountInfo.updates_via_wa_active" style="float: none;">
                                <span v-if="item.notify_whatsapp_chat || item.notify_whatsapp_status || item.notify_whatsapp_new_incident" class="wa-notifications" v-tooltip.right="profile.accountPage.account.notifications_via_wa" style="float: none; color: #25D366;"><i class="fab fa-whatsapp"></i></span>
                            </span>
                        </h3>
                        <span class="username" data-testid="employeeEmailText"><i class="fas fa-at"></i> {{item.username}}</span> <span v-if="item.mobile" class="seperator">|</span>
                        <span class="phone" data-testid="employeePhoneText" v-if="item.mobile"><i class="fas fa-phone-alt"></i> {{item.mobile}}</span>
                        <span class="mfa" v-if="item.mfa_required"><i class="fas fa-sign-in-alt"></i> {{profile.employees.MFA}}</span>
                        <span class="portfolio" @click="portFolioModal(item)"><i class="fas fa-briefcase" data-testid="employeeOpenPortfolioButton" ></i>Portfolio</span>
                    </div>
                    <div v-else class="account-contents">
                        <div class="account-info">
                            
                        </div>
                        <h3>{{profile.employees.edit_employee}}</h3>
                        <div class="row">
                            <div class="col-sm-6">
                                <label>{{profile.employees.name}}</label>
                                <input type="text" class="form-control" v-model="item.actual_name" data-testid="employeeEditName"/>
                            </div>
                            <div class="col-sm-6">
                                <label>{{profile.employees.phone}}</label>
                                <input type="text" class="form-control" v-model="item.tempMobile" @input="validateEmployeePhone(item)" :class="{error: item.invalidPhone}" data-testid="employeeEditPhone" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <label>{{profile.nav.account.account}}</label>
                                <input type="text" class="form-control" v-model="item.username" @input="validateEmail(item)" :class="{error: item.invalidEmail}" data-testid="employeeEditEmail" />
                            </div>
                            <div class="col-sm-6" v-if="showEmailErrorActive || showEmailErrorInactive">
                                <div class="notification notification--error">
                                    <p v-if="showEmailErrorActive"><i class="fas fa-exclamation-circle"></i> {{profile.bm_exists}}</p>
                                    <p v-if="showEmailErrorInactive"><i class="fas fa-exclamation-circle"></i> {{profile.employee_exists}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="accountInfo.building_manager_admin">
                            <div class="col">
                                <div class="switch-wrapper switch-wrapper--full" :class="{active: item.mfa_required}">
                                    <div class="inner" @click="toggleMfa(item)">
                                        <i class="fa-regular fa-key"></i>
                                        <p>{{profile.employees.MFA}}</p>
                                        <span>{{profile.switches.mfa_login}}</span>
                                        <label class="switch">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>          
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="switch-wrapper switch-wrapper--full" :class="{active: item.buildingmanager_administrator}">
                                    <div class="inner" @click="toggleAdmin(item)">
                                        <i class="fa-regular fa-user-crown"></i>                                        
                                        <p>{{profile.employees.admin}}</p>
                                        <span>{{profile.switches.is_admin}}</span>
                                        <label class="switch">
                                            <span class="slider round" data-testid="employeeAdminSwitch"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>          
                        </div>    
                        <div class="switch-wrapper switch-wrapper--full" :class="{disabled: !item.mobile, active: item.notify_whatsapp_new_incident}" v-if="accountInfo.updates_via_wa_active">
                            <div class="inner" @click="toggleNotsWA(item, 'new')">
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.new_incidents_wa}}</p>
                                <span class="label">{{ profile.switches.wa_new }}</span>
                                <label class="switch">
                                    <input type="checkbox">
                                    <span class="slider round" data-testid="employeeNotificationSwitch"></span>
                                </label>
                            </div>   
                        </div>
                        <div class="switch-wrapper switch-wrapper--full" v-if="accountInfo.updates_via_wa_active" :class="{item: !item.mobile, active: item.notify_whatsapp_status}">
                            <div class="inner" @click="toggleNotsWA(item, 'status')">
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.status_updates_wa}}</p>
                                <span>{{ profile.switches.wa_status }}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="employeeStatusSwitch"></span>
                                </label>
                            </div>
                        </div>
                        <div class="switch-wrapper switch-wrapper--full" v-if="accountInfo.updates_via_wa_active" :class="{disabled: !item.mobile, active: item.notify_whatsapp_chat}">
                           <div class="inner" @click="toggleNotsWA(item, 'chat')" >
                                <i class="fa-brands fa-whatsapp"></i>
                                <p>{{profile.accountPage.account.chat_updates_wa}}</p>
                                <span class="label">{{ profile.switches.wa_chat }}</span>
                                <label class="switch">
                                    <span class="slider round" data-testid="employeeChatSwitch"></span>
                                </label>
                           </div>  
                        </div>
                        <span class="notification notification--warning" v-if="!item.mobile" style="margin: 15px 0;">
                            {{profile.employees.wa_not_allowed}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <hr />
                    <h3 :class="{active: inactiveEmployeesToggled}" style="cursor: pointer; margin-bottom: 0;" @click="inactiveEmployeesToggled = !inactiveEmployeesToggled">{{profile.inactive_employees}} ({{inactiveEmployees.length}})<i class="fas fa-chevron-down" v-if="inactiveEmployees.length" style="margin-left: 10px;" data-testid="employeeShowInactive" ></i></h3>
                </div>
            </div>
            <div class="employees" style="margin-top: 20px;" v-if="inactiveEmployees.length" v-show="inactiveEmployeesToggled">
                <div class="employee" v-for="(item, index) in inactiveEmployees" :key="index" :class="{disabled: item.employee_disabled}">
                    <span class="edit-button icon" @click="enableEmployeeModal(item)" v-tooltip.right="profile.enable_employee" data-testid="employeeActivateButton">
                        <i class="fas fa-toggle-on"></i>
                    </span>
                    <div>
                        <h3>{{item.actual_name}} <span class="admin" v-if="item.buildingmanager_administrator">ADMIN</span> <span style="float: none;">|</span> <span class="wa-notifications" v-tooltip.right="profile.accountPage.account.notifications_via_wa" style="float: none; color: #25D366;"><i class="fab fa-whatsapp"></i></span></h3>
                        <span class="username"><i class="fas fa-at"></i> {{item.username}}</span> <span v-if="item.mobile" class="seperator">|</span>
                        <span class="phone" v-if="item.mobile"><i class="fas fa-phone-alt"></i> {{item.mobile}}</span>
                    </div>
                </div>
            </div>
            <hr />
            <div class="teams">
                <h4 :class="{active: teamsToggled}" @click="teamsToggled = !teamsToggled" style="margin-bottom: 0; cursor: pointer;">Teams ({{teams.length}})<i class="fas fa-chevron-down" style="margin-left: 10px;" data-testid="teamsShowAll" v-if="teams.length"></i></h4>
                <span class="edit-button icon" @click="newTeamModalOpen = true" v-if="accountInfo.building_manager_admin" data-testid="teamAddButton">
                    <i class="fa-solid fa-plus"></i>
                </span>
                <div class="employees" v-if="teamsToggled" style="margin-top: 20px;">
                    <div class="employee" v-for="team in teams" :key="team.id_team">
                        <span class="edit-button icon" @click="removeTeam(team)" v-if="!team.edit && accountInfo.building_manager_admin" data-testid="teamRemoveButton">
                            <i class="fas fa-trash-alt"></i>
                        </span>
                        <span class="edit-button icon" @click="team.edit = true" v-if="!team.edit && accountInfo.building_manager_admin" style="right: 36px;" data-testid="teamEditButton" >
                            <i class="fas fa-pen"></i>
                        </span>
                        <span class="edit-button icon" @click="saveTeam(team)" v-if="team.edit" data-testid="teamEditSaveButton">
                            <i class="fas fa-save"></i>
                        </span>
                        <div class="row" v-if="team.edit">
                            <div class="col-sm-6">
                                <label>{{profile.employees.name}}</label>
                                <input type="text" class="form-control" v-model="team.name" data-testid="teamEditName" />
                            </div>
                        </div>
                        <div v-else>
                            <h4 data-testid="teamNametext">{{team.name}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PortfolioModal v-if="portfolioModalOpen" @close="portfolioModalOpen = false" :selectedUser="selectedUser" />
        <NewEmployeeModal v-if="newEmployeeModalOpen" @close="getEmployees()" />
        <NewTeamModal v-if="newTeamModalOpen" @close="getTeams()" />
        <DisableEmployeeModal v-if="disableEmployeeModalOpen" @close="getEmployees()" :selectedUser="selectedUser" />
        <EnableEmployeeModal v-if="enableEmployeeModalOpen" @close="getEmployees()" :selectedUser="selectedUser" />
        <RemoveEmployeeModal v-if="removeEmployeeModalOpen" @close="getEmployees()" :selectedUser="selectedUser" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import NewEmployeeModal from "@/components/modals/NewEmployeeModal.vue";
import NewTeamModal from "@/components/modals/NewTeamModal.vue";
import DisableEmployeeModal from "@/components/modals/DisableEmployeeModal.vue";
import EnableEmployeeModal from "@/components/modals/EnableEmployeeModal.vue";
import RemoveEmployeeModal from "@/components/modals/RemoveEmployeeModal.vue";
import PortfolioModal from "@/components/modals/PortfolioModal.vue";
import sortBy from 'lodash/sortBy';

export default {
    name: 'MainBlock',
    mixins: [setLocale],
    components: {
        NewEmployeeModal,
        NewTeamModal,
        PortfolioModal,
        DisableEmployeeModal,
        EnableEmployeeModal,
        RemoveEmployeeModal
    },
    data: function () {
        return {
            employees: [],
            inactiveEmployees: [],
            inactiveEmployeesToggled: false,
            activeEmployeesToggled: false,
            portfolioModalOpen: false,
            newEmployeeModalOpen: false,
            disableEmployeeModalOpen: false,
            enableEmployeeModalOpen: false,
            removeEmployeeModalOpen: false,
            employeesSortType: '',
            teamsToggled: false,
            newTeamModalOpen: false,
            showEmailErrorActive: false,
            showEmailErrorInactive: false,
            originalEmail: '',
            teams: []
        }
    },
    props: ['accountInfo', 'userName'],
    created() {
        this.getEmployees();
        this.getTeams();
    },
    methods: {
        getEmployees() {
            this.newEmployeeModalOpen = false;
            this.disableEmployeeModalOpen = false;
            this.enableEmployeeModalOpen = false;
            this.removeEmployeeModalOpen = false;
            let self = this;
            fetch('/api/v1/buildingmanageremployee')
            .then(response => { response.json().then(
                function(data) {
                    self.employees = [];
                    self.inactiveEmployees = [];
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.validPhone = false;
                        item.invalidPhone = false;
                        item.invalidEmail = false;
                        item.canEditWA = false;
                        item.notAllowedWA = false;
                        item.tempMobile = item.mobile;
                        if (item.username.includes('DISABLED')) {
                            let name = item.username.split(/[, ]+/).pop();
                            item.username = name;
                        }
                        item.edit = false;

                        if (item.employee_disabled) {
                            self.inactiveEmployees.push(item);
                        }
                        else {
                            if (item.username !== self.userName) {
                                self.employees.push(item);
                            }
                        }
                    }
                    self.employees = sortBy(self.employees, 'actual_name');
                    self.inactiveEmployees = sortBy(self.inactiveEmployees, 'actual_name');
                });
            });
        },
        getTeams() {
            this.teams = [];
            this.newTeamModalOpen = false;
            let self = this;
            fetch('/api/v1/team')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let team = data.data[i];
                        team.edit = false;
                        self.teams.push(team);
                    }
                });
            });
        },
        saveTeam(team) {
            let self = this;
            const params = new URLSearchParams();
            params.append('id_team', team.id_team);
            params.append('name', team.name);
            team.edit = false;
            fetch('/api/v1/team/update', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        removeTeam(team) {
            let self = this;
            const params = new URLSearchParams();
            params.append('id_team', team.id_team);
            team.edit = false;
            fetch('/api/v1/team/delete', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getTeams();
            });
        },
        toggleAdmin(item) {
            item.buildingmanager_administrator = !item.buildingmanager_administrator;
            const data = new URLSearchParams();
            data.append('id_shiro_user', item.id_shiro_user);
            data.append('admin', item.buildingmanager_administrator);
            let self = this;
            fetch('/api/v1/buildingmanageremployee/setadmin', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        toggleMfa(item) {
            item.mfa_required = !item.mfa_required;
            const data = new URLSearchParams();
            data.append('id_shiro_user', item.id_shiro_user);
            data.append('mfa_required', item.mfa_required);
            let self = this;
            fetch('/api/v1/buildingmanageremployee/setmfarequired', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        editEmployee(item) {
            this.originalEmail = item.username;
            item.edit = true;
            if (item.mobile) {
                item.canEditWA = true;
            }
            else {
                item.canEditWA = false;
            }
        },
        saveEmployee(item) {
            if (!item.invalidPhone && !item.invalidEmail) {
                item.edit = false;
                item.mobile = item.tempMobile;
                item.notAllowedWA = false;
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + item.id_shiro_user + '][actual_name]', item.actual_name);
                data.append('data[' + item.id_shiro_user + '][mobile]', item.mobile);
                data.append('data[' + item.id_shiro_user + '][username]', item.username);
                let self = this;
                fetch('/api/v1/buildingmanageremployee', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        removeEmployeeModal(item) {
            this.selectedUser = item;
            this.removeEmployeeModalOpen = true;
        },
        disableEmployeeModal(item) {
            this.selectedUser = item;
            this.disableEmployeeModalOpen = true;
        },
        enableEmployeeModal(item) {
            this.selectedUser = item;
            this.enableEmployeeModalOpen = true;
        },
        setOwnRepairCompanyName() {
            for (var i = 0; i < this.accountInfo.repaircompanies.length; i++) {
                let repaircompany = this.accountInfo.repaircompanies[i];
                if (this.accountInfo.canbe_id_repair_company === repaircompany.id_repair_company) {
                    this.ownRepairCompany.name = repaircompany.name;
                }
            }
        },
        validateEmployeePhone(item) {
            if (item.tempMobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(item.tempMobile)) {
                    item.validPhone = true;
                    item.invalidPhone = false;
                }
                else {
                    item.invalidPhone = true;
                    item.validPhone = false;
                }
            }
            else {
                item.validPhone = true;
                item.invalidPhone = false;
            }
            if (item.notify_whatsapp_chat || item.notify_whatsapp_new_incident || item.notify_whatsapp_status) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(item.tempMobile)) {
                    item.validPhone = true;
                    item.invalidPhone = false;
                }
                else {
                    item.invalidPhone = true;
                    item.validPhone = false;
                }
            }
        },
        validateEmail(item) {
            this.emailexists = false;
            let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            if (pattern.test(item.username)) {
                item.invalidEmail = false;
            }
            else {
                item.invalidEmail = true;
            }
        },
        canEditWACheck(item) {
            if (!item.mobile) {
                item.notAllowedWA = true;
            }
            else {
                item.notAllowedWA = false;
            }
        },
        toggleNotsWA(item, type) {
            if (item.mobile) {
                if (type === 'email') {
                item.notify_incident_assigned = !item.notify_incident_assigned;
            }
            if (type === 'new') {
                item.notify_whatsapp_new_incident = !item.notify_whatsapp_new_incident;
            }
            if (type === 'status') {
                item.notify_whatsapp_status = !item.notify_whatsapp_status;
            }
            if (type === 'chat') {
                item.notify_whatsapp_chat = !item.notify_whatsapp_chat;
            }
            const data = new URLSearchParams();
            data.append('id_shiro_user', item.id_shiro_user);
            data.append('notify_whatsapp_chat', item.notify_whatsapp_chat);
            data.append('notify_whatsapp_new_incident', item.notify_whatsapp_new_incident);
            data.append('notify_whatsapp_status', item.notify_whatsapp_status);
            data.append('notify_incident_assigned', item.notify_incident_assigned);
            let self = this;
            fetch('/api/v1/buildingmanageremployee/notifywhatsapp', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
            }
        },
        portFolioModal(item) {
            this.selectedUser = item;
            this.portfolioModalOpen = true;
        },
        checkEmailActive(item) {
            let self = this;
            if (item.username === this.originalEmail) {
                self.checkEmailInactive(item);
            } else {
                fetch('/api/v1/emailexists/buildingmanageraccounts?email=' + item.username)
                .then(response => { response.text().then(
                    function(data) {
                        if (data === 'false') {
                            self.showEmailErrorActive = false;
                            self.checkEmailInactive(item);
                        } else if (data === 'true') {
                            self.showEmailErrorActive = true;
                        }
                    });
                });
            }
        },
        checkEmailInactive(item) {
            let self = this;
            if (item.username === this.originalEmail) {
                self.saveEmployee(item);
            } else {
                fetch('/api/v1/emailexists?email=' + item.username)
                .then(response => { response.text().then(
                    function(data) {
                        if (data === 'false') {
                            self.showEmailErrorInactive = false;
                            self.saveEmployee(item);
                        }
                        else {
                            self.showEmailErrorInactive = true;
                        }
                    });
                });
            }
        },
    }
}
</script>
