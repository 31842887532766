<template>
    <div class="block block--account block--border block--border-large" style="padding-top: 0 !important; padding-bottom: 0 !important;">
        <div class="switch-wrapper switch-wrapper--full alt-hover" v-if="accountInfo.building_manager_admin" :class="{active: officeHours.active }">
            <div class="inner" @click="toggleOoo()">
                <i class="fa-regular fa-clock"></i>
                <p>Kantooruren activeren</p>
                <span class="label">Stel je kantooruren in</span>
                <label class="switch">
                    <span class="slider round" data-testid="officeOnOffSwitch" ></span>
                </label>
            </div>      
        </div>
        <div class="account-info" v-if="officeHours.active" style="margin-top: 15px; margin-bottom: 15px;">
            <h4 style="margin-bottom: 0; cursor: pointer;" @click="expandOfficeHours = !expandOfficeHours"><span style="font-weight:500;" data-testid="officeShowDays">{{profile.accountPage.account.office_hours}}</span> <i class="fas fa-chevron-down" style="margin-left: 5px;" :class="{active: expandOfficeHours }"></i>
                <i class="far fa-question-circle margin" :class="{left: !accountInfo.building_manager_admin}" v-tooltip.left="{content: profile.accountPage.account.ooo_tooltip, classes: ['tooltip-wide']}" style="cursor: pointer; margin-top: 3px; float: right;" data-testid="officeShowInfo"></i>
            </h4>          
            <div v-if="expandOfficeHours && officeHours.active" style="margin-top: 20px;">
                <div class="opening-hours-wrapper" v-if="profile.accountPage">
                    <!-- Monday -->
                    <div class="notification" v-if="!oooPhone || oooPhone === 'null'">
                        <i class="far fa-lightbulb"></i>
                        <span>{{profile.accountPage.account.no_ooo}}</span>
                    </div>
                    <div v-if="oooPhone">
                        <div class="day-wrapper">
                            <div class="switch-wrapper">
                                <span>{{profile.accountPage.account.monday}}</span>
                                <span class="closed" v-if="!officeHours.monday_open"> | {{profile.accountPage.account.ooo_closed}}</span>
                                <label class="switch" v-if="admin">
                                    <input type="checkbox" v-model="officeHours.monday_open">
                                    <span class="slider round" data-testid="officeMondaySwitch"></span>
                                </label>
                            </div>
                            <div class="row" v-if="officeHours.monday_open">
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.from}}</span>
                                    <flat-pickr v-model="officeHours.monday_from" class="form-control" :config="flatPickrTimeConfig" :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
                                </div>
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.to}}</span>
                                    <flat-pickr v-model="officeHours.monday_to" class="form-control" :config="flatPickrTimeConfig" :placeholder="'17:00'" :disabled="!admin"></flat-pickr>
                                </div>
                            </div>
                        </div>
                        <!-- Tuesday -->
                        <div class="day-wrapper">
                            <div class="switch-wrapper">
                                <label class="switch" v-if="admin">
                                    <input type="checkbox" v-model="officeHours.tuesday_open">
                                    <span class="slider round" data-testid="officeTuesdaySwitch"></span>
                                </label>
                                <span>{{profile.accountPage.account.tuesday}}</span>
                                <span class="closed" v-if="!officeHours.tuesday_open"> | {{profile.accountPage.account.ooo_closed}}</span>
                            </div>
                            <div class="row" v-if="officeHours.tuesday_open">
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.from}}</span>
                                    <flat-pickr v-model="officeHours.tuesday_from" class="form-control" :config="flatPickrTimeConfig" :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
                                </div>
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.to}}</span>
                                    <flat-pickr v-model="officeHours.tuesday_to" class="form-control" :config="flatPickrTimeConfig" :placeholder="'17:00'" :disabled="!admin"></flat-pickr>
                                </div>
                            </div>
                        </div>
                        <!-- Wednesday -->
                        <div class="day-wrapper">
                            <div class="switch-wrapper">
                                <label class="switch" v-if="admin">
                                    <input type="checkbox" v-model="officeHours.wednesday_open">
                                    <span class="slider round" data-testid="officeWednesdaySwitch"></span>
                                </label>
                                <span>{{profile.accountPage.account.wednesday}}</span>
                                <span class="closed" v-if="!officeHours.wednesday_open"> | {{profile.accountPage.account.ooo_closed}}</span>
                            </div>
                            <div class="row" v-if="officeHours.wednesday_open">
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.from}}</span>
                                    <flat-pickr v-model="officeHours.wednesday_from" class="form-control" :config="flatPickrTimeConfig" :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
                                </div>
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.to}}</span>
                                    <flat-pickr v-model="officeHours.wednesday_to" class="form-control" :config="flatPickrTimeConfig" :placeholder="'17:00'" :disabled="!admin"></flat-pickr>
                                </div>
                            </div>
                        </div>
                        <!-- Thursday -->
                        <div class="day-wrapper">
                            <div class="switch-wrapper">
                                <label class="switch" v-if="admin">
                                    <input type="checkbox" v-model="officeHours.thursday_open">
                                    <span class="slider round" data-testid="officeThursdaySwitch"></span>
                                </label>
                                <span>{{profile.accountPage.account.thursday}}</span>
                                <span class="closed" v-if="!officeHours.thursday_open"> | {{profile.accountPage.account.ooo_closed}}</span>
                            </div>
                            <div class="row" v-if="officeHours.thursday_open">
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.from}}</span>
                                    <flat-pickr v-model="officeHours.thursday_from" class="form-control" :config="flatPickrTimeConfig" :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
                                </div>
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.to}}</span>
                                    <flat-pickr v-model="officeHours.thursday_to" class="form-control" :config="flatPickrTimeConfig" :placeholder="'17:00'" :disabled="!admin"></flat-pickr>
                                </div>
                            </div>
                        </div>
                        <!-- Friday -->
                        <div class="day-wrapper">
                            <div class="switch-wrapper">
                                <label class="switch" v-if="admin">
                                    <input type="checkbox" v-model="officeHours.friday_open">
                                    <span class="slider round" data-testid="officeFridaySwitch"></span>
                                </label>
                                <span>{{profile.accountPage.account.friday}}</span>
                                <span class="closed" v-if="!officeHours.friday_open"> | {{profile.accountPage.account.ooo_closed}}</span>
                            </div>
                            <div class="row" v-if="officeHours.friday_open">
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.from}}</span>
                                    <flat-pickr v-model="officeHours.friday_from" class="form-control" :config="flatPickrTimeConfig" :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
                                </div>
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.to}}</span>
                                    <flat-pickr v-model="officeHours.friday_to" class="form-control" :config="flatPickrTimeConfig" :placeholder="'17:00'" :disabled="!admin"></flat-pickr>
                                </div>
                            </div>
                        </div>
                        <!-- Saturday -->
                        <div class="day-wrapper">
                            <div class="switch-wrapper">
                                <label class="switch" v-if="admin">
                                    <input type="checkbox" v-model="officeHours.saturday_open">
                                    <span class="slider round" data-testid="officeSaturdaySwitch"></span>
                                </label>
                                <span>{{profile.accountPage.account.saturday}}</span>
                                <span class="closed" v-if="!officeHours.saturday_open"> | {{profile.accountPage.account.ooo_closed}}</span>
                            </div>
                            <div class="row" v-if="officeHours.saturday_open">
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.from}}</span>
                                    <flat-pickr v-model="officeHours.saturday_from" class="form-control" :config="flatPickrTimeConfig" :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
                                </div>
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.to}}</span>
                                    <flat-pickr v-model="officeHours.saturday_to" class="form-control" :config="flatPickrTimeConfig" :placeholder="'17:00'" :disabled="!admin"></flat-pickr>
                                </div>
                            </div>
                        </div>
                        <!-- Sunday -->
                        <div class="day-wrapper">
                            <div class="switch-wrapper">
                                <label class="switch" v-if="admin">
                                    <input type="checkbox" v-model="officeHours.sunday_open">
                                    <span class="slider round" data-testid="officeSundaySwitch"></span>
                                </label>
                                <span>{{profile.accountPage.account.sunday}}</span>
                                <span class="closed" v-if="!officeHours.sunday_open"> | {{profile.accountPage.account.ooo_closed}}</span>
                            </div>
                            <div class="row" v-if="officeHours.sunday_open">
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.from}}</span>
                                    <flat-pickr v-model="officeHours.sunday_from" class="form-control" :config="flatPickrTimeConfig" :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
                                </div>
                                <div class="col-sm-6">
                                    <span class="oh-indicator">{{profile.accountPage.account.to}}</span>
                                    <flat-pickr v-model="officeHours.sunday_to" class="form-control" :config="flatPickrTimeConfig" :placeholder="'17:00'" :disabled="!admin"></flat-pickr>
                                </div>
                            </div>
                        </div>
                        <button class="button button--icon button--right" data-testid="officeSaveButton" @click="saveOfficeHours()" v-if="admin"><i class="fas fa-save"></i>{{profile.buttons.save}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
    mixins: [setLocale],
    components: {
        flatPickr
    },
    name: 'officeHours',
    data() {
        return {
            profile: {},
            expandOfficeHours: false,
            officeHoursActive: false,
            flatPickrTimeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            }
        }
    },
    props: ['accountInfo', 'officeHours', 'oooPhone', 'admin'],
    methods: {
        toggleOoo() {
            this.officeHours.active = !this.officeHours.active;
            if (this.officeHours.active) {
                this.officeHoursActive = true;
            }
            if (this.officeHours.active && !this.officeHours.id_opening_hours) {
                this.createOfficeHours();
            }
            if (this.officeHours.id_opening_hours) {
                this.saveOfficeHours();
            }
        },
        createOfficeHours() {
            const data = new URLSearchParams();
            let openingHours = {
                monday_open: null,
                monday_from: null,
                monday_to: null,
                tuesday_open: null,
                tuesday_from: null,
                tuesday_to: null,
                wednesday_open: null,
                wednesday_from: null,
                wednesday_to: null,
                thursday_open: null,
                thursday_from: null,
                thursday_to: null,
                friday_open: null,
                friday_from: null,
                friday_to: null,
                saturday_open: null,
                saturday_from: null,
                saturday_to: null,
                sunday_open: null,
                sunday_from: null,
                sunday_to: null
            }
            data.append('action', 'create');
            data.append('data[0]', openingHours);
            let self = this;
            fetch('/api/v1/opening_hours', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveOfficeHours() {
            if (!this.officeHours.id_opening_hours) {
                this.createOfficeHours();
            }
            else {
                let monday_from = this.officeHours.monday_from;
                if (monday_from) {
                    monday_from = monday_from.replace(':', '');
                    if (monday_from.charAt(0) === '0') {
                        monday_from = monday_from.substring(1);
                    }
                }
                else {
                    monday_from = '';
                }
                let monday_to = this.officeHours.monday_to;
                if (monday_to) {
                    monday_to = monday_to.replace(':', '');
                    if (monday_to.charAt(0) === '0') {
                        monday_to = monday_to.substring(1);
                    }
                }
                else {
                    monday_to = '';
                }
                let tuesday_from = this.officeHours.tuesday_from;
                if (tuesday_from) {
                    tuesday_from = tuesday_from.replace(':', '');
                    if (tuesday_from.charAt(0) === '0') {
                        tuesday_from = tuesday_from.substring(1);
                    }
                }
                else {
                    tuesday_from = '';
                }
                let tuesday_to = this.officeHours.tuesday_to;
                if (tuesday_to) {
                    tuesday_to = tuesday_to.replace(':', '');
                    if (tuesday_to.charAt(0) === '0') {
                        tuesday_to = tuesday_to.substring(1);
                    }
                }
                else {
                    tuesday_to = '';
                }
                let wednesday_from = this.officeHours.wednesday_from;
                if (wednesday_from) {
                    wednesday_from = wednesday_from.replace(':', '');
                    if (wednesday_from.charAt(0) === '0') {
                        wednesday_from = wednesday_from.substring(1);
                    }
                }
                else {
                    wednesday_from = '';
                }
                let wednesday_to = this.officeHours.wednesday_to;
                if (wednesday_to) {
                    wednesday_to = wednesday_to.replace(':', '');
                    if (wednesday_to.charAt(0) === '0') {
                        wednesday_to = wednesday_to.substring(1);
                    }
                }
                else {
                    wednesday_to = '';
                }
                let thursday_from = this.officeHours.thursday_from;
                if (thursday_from) {
                    thursday_from = thursday_from.replace(':', '');
                    if (thursday_from.charAt(0) === '0') {
                        thursday_from = thursday_from.substring(1);
                    }
                }
                else {
                    thursday_from = '';
                }
                let thursday_to = this.officeHours.thursday_to;
                if (thursday_to) {
                    thursday_to = thursday_to.replace(':', '');
                    if (thursday_to.charAt(0) === '0') {
                        thursday_to = thursday_to.substring(1);
                    }
                }
                else {
                    thursday_to = '';
                }
                let friday_from = this.officeHours.friday_from;
                if (friday_from) {
                    friday_from = friday_from.replace(':', '');
                    if (friday_from.charAt(0) === '0') {
                        friday_from = friday_from.substring(1);
                    }
                }
                else {
                    friday_from = '';
                }
                let friday_to = this.officeHours.friday_to;
                if (friday_to) {
                    friday_to = friday_to.replace(':', '');
                    if (friday_to.charAt(0) === '0') {
                        friday_to = friday_to.substring(1);
                    }
                }
                else {
                    friday_to = '';
                }
                let saturday_from = this.officeHours.saturday_from;
                if (saturday_from) {
                    saturday_from = saturday_from.replace(':', '');
                    if (saturday_from.charAt(0) === '0') {
                        saturday_from = saturday_from.substring(1);
                    }
                }
                else {
                    saturday_from = '';
                }
                let saturday_to = this.officeHours.saturday_to;
                if (saturday_to) {
                    saturday_to = saturday_to.replace(':', '');
                    if (saturday_to.charAt(0) === '0') {
                        saturday_to = saturday_to.substring(1);
                    }
                }
                else {
                    saturday_to = '';
                }
                let sunday_from = this.officeHours.sunday_from;
                if (sunday_from) {
                    sunday_from = sunday_from.replace(':', '');
                    if (sunday_from.charAt(0) === '0') {
                        sunday_from = sunday_from.substring(1);
                    }
                }
                else {
                    sunday_from = '';
                }
                let sunday_to = this.officeHours.sunday_to;
                if (sunday_to) {
                    sunday_to = sunday_to.replace(':', '');
                    if (sunday_to.charAt(0) === '0') {
                        sunday_to = sunday_to.substring(1);
                    }
                }
                else {
                    sunday_to = '';
                }
                const data = new URLSearchParams();
                let oh_id = this.officeHours.id_opening_hours;
                let paramActive = 'data[' + oh_id + '][active]';
                let paramMo = 'data[' + oh_id + '][monday_open]';
                let paramMf = 'data[' + oh_id + '][monday_from]';
                let paramMt = 'data[' + oh_id + '][monday_to]';
                let paramTo = 'data[' + oh_id + '][tuesday_open]';
                let paramTf = 'data[' + oh_id + '][tuesday_from]';
                let paramTt = 'data[' + oh_id + '][tuesday_to]';
                let paramWo = 'data[' + oh_id + '][wednesday_open]';
                let paramWf = 'data[' + oh_id + '][wednesday_from]';
                let paramWt = 'data[' + oh_id + '][wednesday_to]';
                let paramTho = 'data[' + oh_id + '][thursday_open]';
                let paramThf = 'data[' + oh_id + '][thursday_from]';
                let paramTht = 'data[' + oh_id + '][thursday_to]';
                let paramFo = 'data[' + oh_id + '][friday_open]';
                let paramFf = 'data[' + oh_id + '][friday_from]';
                let paramFt = 'data[' + oh_id + '][friday_to]';
                let paramSao = 'data[' + oh_id + '][saturday_open]';
                let paramSaf = 'data[' + oh_id + '][saturday_from]';
                let paramSat = 'data[' + oh_id + '][saturday_to]';
                let paramSuo = 'data[' + oh_id + '][sunday_open]';
                let paramSuf = 'data[' + oh_id + '][sunday_from]';
                let paramSut = 'data[' + oh_id + '][sunday_to]';
                data.append('action', 'edit');
                data.append(paramActive, this.officeHours.active);
                if (this.officeHours.monday_open) {
                    data.append(paramMo, this.officeHours.monday_open);
                }
                else {
                    data.append(paramMo, '');
                }
                data.append(paramMf, monday_from);
                data.append(paramMt, monday_to);
                if (this.officeHours.tuesday_open) {
                    data.append(paramTo, this.officeHours.tuesday_open);
                }
                else {
                    data.append(paramTo, '');
                }
                data.append(paramTf, tuesday_from);
                data.append(paramTt, tuesday_to);
                if (this.officeHours.wednesday_open) {
                    data.append(paramWo, this.officeHours.wednesday_open);
                }
                else {
                    data.append(paramWo, '');
                }
                data.append(paramWf, wednesday_from);
                data.append(paramWt, wednesday_to);
                if (this.officeHours.thursday_open) {
                    data.append(paramTho, this.officeHours.thursday_open);
                }
                else {
                    data.append(paramTho, '');
                }
                data.append(paramThf, thursday_from);
                data.append(paramTht, thursday_to);
                if (this.officeHours.friday_open) {
                    data.append(paramFo, this.officeHours.friday_open);
                }
                else {
                    data.append(paramFo, '');
                }
                data.append(paramFf, friday_from);
                data.append(paramFt, friday_to);
                if (this.officeHours.friday_open) {
                    data.append(paramSao, this.officeHours.saturday_open);
                }
                else {
                    data.append(paramSao, '');
                }
                data.append(paramSaf, saturday_from);
                data.append(paramSat, saturday_to);
                if (this.officeHours.friday_open) {
                    data.append(paramSuo, this.officeHours.sunday_open);
                }
                else {
                    data.append(paramSuo, '');
                }
                data.append(paramSuf, sunday_from);
                data.append(paramSut, sunday_to);
                let self = this;
                fetch('/api/v1/opening_hours', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.accountPage.account.office_hours_saved, self.profile.accountPage.account.office_hours);
                });
            }
        }
    }
}
</script>
