<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h3>Objecten die in de afgelopen <input type="number" min="1" max="100" v-model="sinceDays" @change="loadAssets()" size="5"/> dagen zijn aangemaakt</h3>
                            </section>
                            <div class="row">
                                <div class="col-sm-8">
                                    <section class="search search-alt" v-if="assets.length">
                                        <form @submit.prevent="searchSuggest()">
                                            <input type="text" ref="search" v-bind:placeholder="'Zoek een object op id, beheerder, datum, adres of naam'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                        </form>
                                    </section>
                                </div>
                            </div>
                            <div>
                                <h4 class="result-counter" v-if="!searchActive">{{assets.length}} {{'aangemaakte objecten'}}</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="!searchSuggestions.length && searchActive">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                            </div>
                            <div class="table-wrap" style="height: 600px; overflow: auto;">
                                <table class="table table-striped" v-if="assets.length && !searchActive">
                                    <colgroup>
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 4%;" />
                                        <col style="width: 5%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 2%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('id_asset')" :class="{active: assetSearchType === 'id_asset'}">Object id <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('building_manager_name')" :class="{active: assetSearchType === 'building_manager_name'}">Beheerder <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('asset_created')" :class="{active: assetSearchType === 'asset_created'}">Aangemaakt <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('place_address')" :class="{active: assetSearchType === 'place_address'}">Adres <i class="fas fa-sort"></i></th>  
                                            <th scope="col" @click="sortTable('asset_name')" :class="{active: assetSearchType === 'asset_name'}">Naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('asset_qr_code')" :class="{active: assetSearchType === 'asset_qr_code'}">Qr-code <i class="fas fa-sort"></i></th> 
                                            <th scope="col" @click="sortTable('asset_id_external')" :class="{active: assetSearchType === 'asset_id_external'}">Extern id <i class="fas fa-sort"></i></th> 
                                            <th scope="col" @click="sortTable('asset_active')" :class="{active: assetSearchType === 'asset_active'}">Actief <i class="fas fa-sort"></i></th> 
                                            <th scope="col" @click="sortTable('asset_hidden')" :class="{active: assetSearchType === 'asset_hidden'}">Verborgen <i class="fas fa-sort"></i></th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(asset, index) in assetsShown" :key="index">
                                            <td>{{asset.id_asset}}</td>
                                            <td>{{asset.building_manager_name}}</td>
                                            <td>{{toDate(asset.asset_created)}}</td>
                                            <td>{{asset.place_address}}</td>
                                            <td>{{asset.asset_name}}</td>
                                            <td>{{asset.asset_qr_code}}</td>
                                            <td>{{asset.asset_id_external}}</td>
                                            <td>{{asset.asset_active}}</td>
                                            <td>{{asset.asset_hidden}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-striped" v-if="assets.length && searchActive">
                                    <colgroup>
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 4%;" />
                                        <col style="width: 5%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 15%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 2%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('id_asset')" :class="{active: assetSearchType === 'id_asset'}">Object id <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('building_manager_name')" :class="{active: assetSearchType === 'building_manager_name'}">Beheerder <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('asset_created')" :class="{active: assetSearchType === 'asset_created'}">Aangemaakt <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('place_address')" :class="{active: assetSearchType === 'place_address'}">Adres <i class="fas fa-sort"></i></th>  
                                            <th scope="col" @click="sortTable('asset_name')" :class="{active: assetSearchType === 'asset_name'}">Naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('asset_qr_code')" :class="{active: assetSearchType === 'asset_qr_code'}">Qr-code <i class="fas fa-sort"></i></th> 
                                            <th scope="col" @click="sortTable('asset_id_external')" :class="{active: assetSearchType === 'asset_id_external'}">Extern id <i class="fas fa-sort"></i></th> 
                                            <th scope="col" @click="sortTable('asset_active')" :class="{active: assetSearchType === 'asset_active'}">Actief <i class="fas fa-sort"></i></th> 
                                            <th scope="col" @click="sortTable('asset_hidden')" :class="{active: assetSearchType === 'asset_hidden'}">Verborgen <i class="fas fa-sort"></i></th> 
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(asset, index) in searchSuggestions" :key="index">
                                            <td>{{asset.id_asset}}</td>
                                            <td>{{asset.building_manager_name}}</td>
                                            <td>{{toDate(asset.asset_created)}}</td>
                                            <td>{{asset.place_address}}</td>
                                            <td>{{asset.asset_name}}</td>
                                            <td>{{asset.asset_qr_code}}</td>
                                            <td>{{asset.asset_id_external}}</td>
                                            <td>{{asset.asset_active}}</td>
                                            <td>{{asset.asset_hidden}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                         
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            searchTerm: '',
            searchSuggestions: [],
            assets: [],
            assetsShown: [],
            searchActive: false,
            sinceDays: 50,
            assetSearchType: '',
        }
    },
    created() {
        document.title = 'S1MONE | CreatedAssets';
        this.loadAssets();
    },
    methods: {
        loadAssets() {
            let self = this;
            fetch('/api/v1/asset/createdinlastndays?days=' + this.sinceDays)
            .then(response => { response.json().then(
                function(data) {
                    self.assets = data.data;
                    self.assetsShown = data.data;
                });
            });
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.assetsShown.length; i++) {
                    let asset = this.assetsShown[i];
                    let objectId = asset.id_asset;
                    let address = asset.place_address.toLowerCase();
                    let bmName = asset.building_manager_name.toLowerCase();
                    let assetName = asset.asset_name.toLowerCase();
                    let created = this.toDate(asset.asset_created).toLowerCase();
                    if (address.includes(searchTerm) || bmName.includes(searchTerm) || assetName.includes(searchTerm) || created.includes(searchTerm) || objectId.toString().includes(searchTerm)) {
                        this.searchSuggestions.push(asset);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        toDate(date) {
            let options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            return new Date(date).toLocaleDateString('nl', options);
        },
        sortTable(sorter) {
            this.assetSearchType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.assetsShown = sortBy(this.assetsShown, sorter);
            if (this.sortOrder === 'desc') {
                this.assetsShown.reverse();
            }
        }
        // selectasset(id) {
        //     this.$router.push('/s1mone/buildingmanager-detail?id=' + id);
        // }
    }
}
</script>
